import ApiServices from "../service";

export const user = {
  getGenderFieldOptions: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=user&field_name=gender&ordering=order`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getNamePrefixFieldOptions: (accessToken) =>
    ApiServices.getData(
      `/api/field_options/?content_type=user&field_name=name_prefix&ordering=order`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getManagedUsers: (accessToken, pagination = null) => {
    let paginate = pagination ? `${pagination}` : "";

    let query = paginate ? "?" : "";
    let deliminator = paginate ? "&" : "";
    return ApiServices.getData(
      `/api/users/managed_users/${query}${deliminator}${paginate}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },
};
