import React, { useContext, useEffect, useState } from "react";
import { List } from "@mui/material";
import HourDisplayRow from "./HourDisplayRow";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import AccordionShell from "../../AccordionShell";

export default function HoursOfOperation({ providerDetail, activeProvObj }) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [operationHours, setOperationHours] = useState([]);
  const [defaultWeek, setDefaultWeek] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!userRoles.permissions.includes("utilities.view_fieldoptions")) {
      return;
    } else {
      axios
        .get(
          `/api/field_options/?content_type=providerofficehours&field=day&ordering=order`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then(function (response) {
          setDefaultWeek(response.data.results);
        })
        .catch(function (error) {});
    }
  }, [accessToken, userRoles.permissions, setDefaultWeek, trigger]);

  const createHoursArr = (data, valKey) => {
    let arr = [];
    if (data.length === 0) {
      return arr;
    } else {
      for (const item in data) {
        if (valKey === data[item].day) {
          arr.push(data[item]);
        }
      }
    }
    return arr;
  };

  useEffect(() => {
    if (
      !providerDetail ||
      !userRoles.permissions.includes("provider.view_providerofficehours")
    ) {
      return;
    } else {
      axios
        .get(
          `/api/provider_office_hours/?ordering=day,start_time&provider=${providerDetail.pk}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then(function (response) {
          let data = response.data.results;
          setOperationHours(data);
        })
        .catch(function (error) {});
    }
  }, [providerDetail, accessToken, trigger, userRoles.permissions]);

  return (
    <AccordionShell title="Hours of Operation" defaultExpanded={true}>
      <List>
        {!defaultWeek
          ? ""
          : defaultWeek.map((day) => {
              return (
                <HourDisplayRow
                  key={day.key}
                  day={day.label}
                  dayKey={day.key}
                  defaultValues={createHoursArr(operationHours, day.key)}
                  provider={providerDetail.pk}
                  setTrigger={setTrigger}
                  trigger={trigger}
                  activeProvObj={activeProvObj}
                />
              );
            })}
      </List>
    </AccordionShell>
  );
}
