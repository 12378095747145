import { Chip, Tooltip } from "@mui/material";

export const CustomChip = ({
  setActiveItems,
  activeItems,
  setSelectedItems,
  title,
  resetPagination,
}) => {
  const truncate = (str, max) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, max) + "..." : str;
  };

  const clearQuery = (active, selected) => {
    active([]);
    selected([]);
    if (resetPagination) {
      resetPagination();
    }
  };

  return (
    <>
      {activeItems.length === 0 ? (
        ""
      ) : (
        <Tooltip
          title={
            activeItems.length >= 5 ? activeItems.join(", ") : "Clear Filters"
          }
        >
          <Chip
            className={`px-2 m-1 text-left rounded-lg shadow-inner hover:bg-red-400 py-[5px]`}
            onClick={() => clearQuery(setActiveItems, setSelectedItems)}
            onDelete={() => clearQuery(setActiveItems, setSelectedItems)}
            label={
              activeItems.length >= 5
                ? `${title}: ${activeItems.map((prov) => truncate(prov, 25))}`
                : `${title}: ` +
                  activeItems.map((prov) =>
                    prov.length >= 75 ? truncate(prov, 25) : prov
                  )
            }
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
            }}
          />
        </Tooltip>
      )}
    </>
  );
};
