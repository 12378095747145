import ApiServices from "../service";

export const callLog = {
  getPhoneLogs: (
    userId,
    accessToken,
    searchValue = null,
    pagination = null
  ) => {
    let user = userId ? `user__in=${userId}` : "";
    let search = searchValue ? `${searchValue}` : "";
    let paginate = pagination ? `${pagination}` : "";

    let query = userId || search || paginate ? "?" : "";
    let deliminator =  paginate ? "&" : "";
    let deliminator2 = search  ? "&" : "";
    return ApiServices.getData(
      `/api/call_logs/${query}${user}${deliminator}${paginate}${deliminator2}${search}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },

  getSMSLogs: (userId, accessToken, searchValue = null, pagination = null) => {
    let user = userId ? `user__in=${userId}` : "";
    let search = searchValue ? `${searchValue}` : "";
    let paginate = pagination ? `${pagination}` : "";

    let query = userId || search || paginate ? "?" : "";
    let deliminator =  paginate ? "&" : "";
    let deliminator2 = search  ? "&" : "";

    return ApiServices.getData(
      `/api/sms_messages/${query}${user}${deliminator}${paginate}${deliminator2}${search}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },
};
