import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import ControlPointOutlined from "@mui/icons-material/ControlPointOutlined";
import ModalShell from "../../../../../../global/ModalShell";
import { Help, Info } from "@mui/icons-material";
import ResponseModal from "../../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";

export default function PermissionsManager({ userInfo, trigger, setTrigger }) {
  const { accessToken, userRoles, loggedInUser } = useContext(DataContext);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [addGroup, setAddGroup] = useState(false);
  const [selectedPerms, setSelectedPerms] = useState([]);
  const [selectedPermsArr, setSelectedPermsArr] = useState([]);
  const [remove, setRemove] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPerms(value);
    let newPermArr = [];
    value.forEach((element) => {
      newPermArr.push(element.group);
    });
    setSelectedPermsArr(newPermArr);
  };

  const handleModalClose = () => {
    setAddGroup(false);
    setSelectedPerms([]);
  };

  const updatePermissions = () => {
    // http://localhost:8000/api/users/{id}/modify_permission_sets/
    // selectedPerms.map((item) => permArr.push(item.group));
    let patchData = {
      permission_sets: selectedPermsArr,
      remove: remove,
    };

    axios
      .patch(`/api/users/${userInfo.pk}/modify_permission_sets/`, patchData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown(
          !remove
            ? "Permissions successfully added"
            : "Permissions successfully removed"
        );
        setIsError(false);
        setAddGroup(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setSelectedPerms([]);
    selectedPermsArr([]);
    setRemove(false);
  };

  const updateGroup = (removeVal, perm) => {
    setAddGroup(true);
    setRemove(removeVal);
    if (!removeVal) {
      return;
    } else {
      setSelectedPerms([perm]);
      setSelectedPermsArr([perm.group]);
    }
  };

  const handleCloseSuccessModal = () => {
    setTrigger(!trigger);
    closeResModal();
  };

  return !userInfo ? (
    ""
  ) : (
    <div>
      <div>
        <div className="flex items-center w-full border-b mb-2 pb-1">
          <h2 className="font-bold">Active Permissions</h2>
          {userRoles.permissions.includes("users.change_user") ? (
            <Tooltip title="Add Permissions">
              {" "}
              <IconButton
                onClick={() => updateGroup(false, false)}
                className="text-green-600"
              >
                <ControlPointOutlined className="block" />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <h2>Permissions Groups</h2>
        <div>
          {!userInfo.permission_sets
            ? ""
            : userInfo.permission_sets.map((perm) => (
                <Tooltip title={perm.description}>
                  <Chip
                    key={perm.group}
                    label={perm.name}
                    className="mr-1 mb-1"
                    onDelete={
                      userRoles.permissions.includes("users.change_user")
                        ? () => updateGroup(true, perm)
                        : false
                    }
                  />
                </Tooltip>
              ))}
        </div>
      </div>
      <ModalShell
        open={addGroup}
        setOpen={setAddGroup}
        customCloseFunc={handleModalClose}
        customWidth={"md"}
        color={!remove ? "#1976d2" : "#d32f2f"}
        title={!remove ? "Add Permissions" : "Remove Permissions"}
      >
        <FormControl sx={{ my: 2, width: 300 }}>
          <InputLabel id="perm-options">Permissions</InputLabel>
          <Select
            labelId="perm-options"
            id="permissions-options-select"
            multiple
            value={selectedPerms}
            onChange={handleChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={() => selectedPerms.map((item) => item.name + ",")}
            MenuProps={MenuProps}
          >
            {!loggedInUser
              ? ""
              : loggedInUser.permission_sets
                  .filter((perm) => perm.shareable === true)
                  .map((perm) => (
                    <MenuItem key={perm.group} value={perm}>
                      <Checkbox
                        checked={selectedPermsArr.includes(perm.group)}
                      />
                      <ListItemText primary={perm.name} />
                      <Tooltip title={perm.description} placement="right">
                        <Info className="text-gray-400" />
                      </Tooltip>
                    </MenuItem>
                  ))}
          </Select>
        </FormControl>
        {!remove ? (
          ""
        ) : (
          <Alert color="error" className="max-w-[75%]">
            <p className="text-[12px]">
              Any permissions you've selected that are not displayed in the list
              results above will not be accessible once they are removed. If you
              need to add them back, use <Help className="text-[12px]" /> Help
              button below to contact NetProApp support.
            </p>
          </Alert>
        )}
        <Button
          onClick={() => updatePermissions(false)}
          className={`hover:text-white mt-1 ${
            !remove
              ? "hover:bg-sky-600 border-sky-600"
              : "hover:bg-red-600 border-red-600 hover:border-red-600 text-red-600"
          }`}
          variant="outlined"
        >
          {!remove ? "Add Permissions" : "Remove Permissions"}
        </Button>
      </ModalShell>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={handleCloseSuccessModal}
      />
    </div>
  );
}
