import { Checkbox, CircularProgress, ListItem, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import OptionsMenu from "../../ActionItems/OptionsMenu";
// import DataContext from "../../../../../context/DataContext";
// import OptionsMenu from "./OptionsMenu";

export default function ActionListItem({
  action,
  resultClasses,
  truncate,
  handleDeleteAction,
  handleDetailAction,
  handleEditAction,
  completeAction,
}) {
  const { caseManagerPk } = useContext(DataContext);
  const [postPending, setPostPending] = useState(false);

  const mockLoading = () => {
    setPostPending(true);
    setTimeout(() => {
      setPostPending(false);
    }, "1000");
  };

  return (
    <ListItem className="py-0 pt-0 text-sm px-0">
      <div
        className={
          "text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y"
        }
      >
        <div
          className={`block text-center pl-2 text-sm ${resultClasses} w-full md:w-1/4 w-1/3 uppercase md:block hidden`}
        >
          {!action
            ? ""
            : !action.acting_user
            ? "None Provided"
            : action.acting_user.name}
        </div>
        <div
          className={`w-[20%] md:block hidden text-left pl-2 text-center ${resultClasses}`}
        >
          {!action
            ? ""
            : !action.due_date
            ? "Unknown"
            : new Date(action.due_date).toLocaleDateString()}
        </div>
        <div className={`w-[30%] text-left pl-2 text-center ${resultClasses}`}>
          {!action
            ? ""
            : !action.action_detail
            ? "No Detail Provided"
            : truncate(action.action_detail, 15, 50)}
        </div>
        <div
          className={`block md:w-[12%] w-1/3 text-left pl-2 text-center ${resultClasses}`}
        >
          {!postPending ? (
            <Tooltip
              title={
                !action.acting_user
                  ? ""
                  : caseManagerPk === action.acting_user.pk
                  ? "Complete Action Item"
                  : "You aren't assigned to this item!"
              }
            >
              <span>
                <Checkbox
                  color="success"
                  checked={
                    !action ? false : action.status === "pending" ? false : true
                  }
                  disabled={
                    !action.acting_user
                      ? ""
                      : caseManagerPk === action.acting_user.pk
                      ? false
                      : true
                  }
                  onChange={() => {
                    completeAction(action.id, action.status);
                    mockLoading();
                  }}
                />
              </span>
            </Tooltip>
          ) : (
            <CircularProgress color="secondary" />
          )}
        </div>
        <div
          className={`md:block md:w-[10%] w-1/3 hidden text-left pl-2 text-center ${resultClasses} border-none`}
        >
          <OptionsMenu
            handleDeleteAction={handleDeleteAction}
            handleEditAction={handleEditAction}
            handleDetailAction={handleDetailAction}
            action={action}
          />
        </div>
      </div>
    </ListItem>
  );
}
