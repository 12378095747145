import { createContext, useState } from "react";
const ProviderSearchContext = createContext({});

export const ProCliProvider = ({ children }) => {
  const [search, setSearch] = useState("");
  const [providerType, setProviderType] = useState("");
  const [provider, setProvider] = useState("");
  const [treatmentStatus, setTreatmentStatus] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [provSearch, setProvSearch] = useState("");
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [lawfirmNames, setLawfirmNames] = useState();
  const [activeProviders, setActiveProviders] = useState([]);
  const [dateCreatedFrom, setDateCreatedFrom] = useState(null);
  const [dateCreatedTo, setDateCreatedTo] = useState(null);
  const [inNetwork, setInNetwork] = useState(true);
  const [providerList, setProviderList] = useState([]);

  return (
    <ProviderSearchContext.Provider
      value={{
        search,
        setSearch,
        providerType,
        setProviderType,
        provider,
        setProvider,
        treatmentStatus,
        setTreatmentStatus,
        resLimit,
        setResLimit,
        provSearch,
        setProvSearch,
        showMoreVisible,
        setShowMoreVisible,
        lawfirmNames,
        setLawfirmNames,
        activeProviders,
        setActiveProviders,
        dateCreatedFrom,
        setDateCreatedFrom,
        dateCreatedTo,
        setDateCreatedTo,
        inNetwork,
        setInNetwork,
        providerList,
        setProviderList,
      }}
    >
      {children}
    </ProviderSearchContext.Provider>
  );
};

export default ProviderSearchContext;
