import {
  Search,
  Person,
  Close,
  AddLocationAlt,
  CheckCircle,
} from "@mui/icons-material";
import {
  InputAdornment,
  TextField,
  ListItemButton,
  List,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import React, { useContext, useState } from "react";
import PlacesResponseList from "../../../../../global/PlacesResponseList";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import ResponseModal from "../../../../../global/ResponseModal";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function ClientSelect({
  clientSearch,
  setClientSearch,
  clientListResponse,
  setAddress,
  setActiveClient,
  setAddressType,
  activeClient,
  selectedProvider,
  setSelectedProvider,
}) {
  const { accessToken } = useContext(DataContext);

  const [altInputDisplay, setAltInputDisplay] = useState(false);
  const [customAddress, setCustomAddress] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handlePlaceSelect = async (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setAddress(latLng);
        setAddressType("alt");
        setCustomAddress(value);
      })
      .catch((error) => console.error("Error", error));
  };

  const handleClientSelect = (client) => {
    setActiveClient(client);
    setAddress(
      !client.address
        ? false
        : { lat: client.address.latitude, lng: client.address.longitude }
    );
    setAddressType("");
    setCustomAddress("");
    setAltInputDisplay(false);
    setClientSearch("");
  };

  const handleClearSelection = () => {
    setActiveClient("");
    setAddressType("alt");
    setAddress({
      lat: 34.03671,
      lng: -84.07817,
    });
  };

  const clearProvider = () => {
    setSelectedProvider("");
  };

  const clearAltAddress = () => {
    setAltInputDisplay(false);
    setCustomAddress("");
  };

  const handleAssignSuccess = () => {
    setActiveClient("");
    setSelectedProvider("");
    setAltInputDisplay(false);
    setCustomAddress("");
    setAddress({
      lat: 34.03671,
      lng: -84.07817,
    });
    setAddressType("alt");
    setOpenSuccess(false);
  };
  const assignProviderToCase = () => {
    let providerInfo = {
      client: activeClient.pk,
      provider: selectedProvider.pk,
    };

    axios
      .post("/api/pro_cli_status/", providerInfo, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
        }
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setOpenError(true);
        setErrorArray(errArr);
      });
  };

  return (
    <div className="relative m-2">
      <div>
        <h2 className="text-center font-bold text-xl mb-2 text-purple-700">
          Search Closest Providers
        </h2>
        <div className="flex">
          <div className="w-full">
            <TextField
              className="sm:mr-2 placeholder:text-center rounded-lg w-full"
              placeholder="Search Clients"
              value={
                !activeClient
                  ? clientSearch
                  : `${activeClient.name} - ${
                      !activeClient.address
                        ? "No Address Listed"
                        : activeClient.address.raw
                    }`
              }
              disabled={!activeClient ? false : true}
              onChange={(e) => setClientSearch(e.target.value)}
              InputProps={{
                startAdornment: !activeClient ? (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ) : (
                  <InputAdornment>
                    <CheckCircle className="text-green-600" />
                    &nbsp;
                  </InputAdornment>
                ),
                endAdornment: !activeClient ? (
                  ""
                ) : (
                  <InputAdornment>
                    <IconButton onClick={() => handleClearSelection()}>
                      <Close className="text-red-600" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!clientSearch ? (
              ""
            ) : clientListResponse.length === 0 ? (
              ""
            ) : (
              <div className="">
                {clientListResponse.length !== 0 ? (
                  <div className="w-full max-h-[300px] bg-white z-10 shadow-md rounded-b-med overflow-y-auto absolute p-0 mb-2">
                    {clientListResponse.map((client, id) => (
                      <List key={id} className="p-0 my-1 w-full">
                        <div className="">
                          <ListItemButton
                            onClick={(e) => handleClientSelect(client.client)}
                            className="place-content-center overflow-x-hidden text-center border-solid border-gray-300 rounded-lg"
                            data-testid="search-client-item"
                          >
                            {client.client.name}
                          </ListItemButton>
                        </div>
                      </List>
                    ))}
                  </div>
                ) : (
                  <h3 className="text-center rounded-lg py-2 flex items-center justify-center">
                    <span className="block">No clients found</span> <Person />
                  </h3>
                )}
              </div>
            )}
          </div>
          {altInputDisplay ? (
            ""
          ) : (
            <Tooltip title="Use Custom Location">
              <IconButton
                onClick={() => setAltInputDisplay(true)}
                className={
                  "hover:text-white hover:bg-green-600 text-green-600 rounded-md flex justify-center border-solid border-green-600 border w-fit mx-auto px-8 py-1 ml-2"
                }
              >
                <AddLocationAlt />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {!altInputDisplay ? (
          ""
        ) : (
          <div className="flex w-full items-center relative">
            <PlacesResponseList
              address={customAddress}
              setAddress={setCustomAddress}
              handlePlaceSelect={handlePlaceSelect}
              hasInputProps={true}
            />
            <Tooltip title="Use Client Address">
              <IconButton
                onClick={() => clearAltAddress()}
                className="text-red-600 hover:bg-red-600 hover:text-white"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </div>
        )}
        <div className="flex items-center">
          <TextField
            required
            className="w-full my-4 text-center"
            id="outlined-required"
            placeholder="Provider"
            disabled
            InputProps={{
              startAdornment: selectedProvider ? (
                <InputAdornment>
                  <CheckCircle className="text-green-600" />
                  &nbsp;
                </InputAdornment>
              ) : (
                ""
              ),
              endAdornment: !selectedProvider ? (
                ""
              ) : (
                <InputAdornment>
                  <IconButton onClick={() => clearProvider()}>
                    <Close className="text-red-600" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={!selectedProvider.name ? "" : selectedProvider.name}
          />
        </div>
        {selectedProvider && activeClient ? (
          <Button
            variant="outlined"
            className="text-purple-600 w-fit mx-auto border-purple-600 block"
            onClick={assignProviderToCase}
          >
            Assign Selected Provider to Case
          </Button>
        ) : (
          ""
        )}
      </div>
      <ResponseModal
        title="Provider Assigned"
        description={`You have successfully assigned ${selectedProvider.name} to ${activeClient.name}'s case`}
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleAssignSuccess}
      />
      <ResponseModal
        title="Error Assigning Provider to Case"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </div>
  );
}
