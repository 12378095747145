import React, { useContext } from "react";
import DataContext from "../../../../context/DataContext";
import CommLogComponent from "../../../global/CommLog";

export default function LogPhone() {
  const { userRoles } = useContext(DataContext);
  const LOG_TYPE = {
    sms: "sms",
    phone: "phone",
  };

  return (
    <CommLogComponent
      view_calllog={userRoles.permissions.includes("call_log.view_calllog")}
      logType={LOG_TYPE.phone}
      title={"Call Log"}
    />
  );
}
