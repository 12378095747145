import { useState, useEffect, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItemButton,
  InputAdornment,
} from "@mui/material/";
import SearchIcon from "@mui/icons-material/Search";
import { Edit, Info } from "@mui/icons-material";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import FormSubmitButton from "../../../../../global/FormComponents/FormSubmitButton";

export default function EditApptProcedurev2({ selectedBill, setOpenEditBill }) {
  const { trigger, setTrigger, accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [price, setPrice] = useState(selectedBill.price);
  const [signature, setSignature] = useState(selectedBill.signature);
  const [procedures, getProcedures] = useState([]);
  const [service, setService] = useState(
    !selectedBill.service ? null : selectedBill.service.pk
  );
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [filter, setFilterType] = useState("service_name");
  const [cquery, setCQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleFilter = (event) => {
    const { value } = event.target;
    setFilterType(value);
  };

  const populateServiceInfo = (procedure) => {
    setService(procedure.pk);
    setCQuery(procedure.service_name);
    getProcedures([]);
  };

  const setQuery = (e) => {
    setCQuery(e.target.value);
    setService(null);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenEditBill(false);
    setService();
    setPrice("");
    setSignature("");
    setTrigger(!trigger);
  };

  const handleApptProcedureEdit = () => {
    setLoading(true);
    const patchedProcedure = !service
      ? {
          ...(price === "" ? {} : { price: price }),
          ...(signature === "" ? {} : { signature: signature }),
        }
      : {
          ...(service === "" ? {} : { service: service }),
          ...(price === "" ? {} : { price: price }),
          ...(signature === "" ? {} : { signature: signature }),
        };

    axios
      .patch(`/api/administered/${selectedBill.pk}/`, patchedProcedure, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setOpenSuccess(!openSuccess);
        setLoading(false);
        return response;
      })
      .catch(function (error) {
        const res = error.response.data;
        const resArr = ApiRequestErrorHandler(res);
        setErrorArray(resArr);
        setOpenError(!openError);
        setLoading(false);
      });
  };

  // calling all services for provider
  useEffect(() => {
    axios
      .get(
        `/api/services/?provider=${selectedBill.administered_by.pk}&simplified=true`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((proceduresList) => {
        getProcedures(proceduresList.data.results);
      });
  }, [accessToken, selectedBill]);

  return (
    <div className="w-full px-10 flex-vertical justify-center bg-white">
      <div>
        {!selectedBill ? (
          "Please Select a Bill to Continue"
        ) : (
          <div className=" mt-4 rounded-md">
            <div className="my-2">
              <span
                className="underline text-lg"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  margin: "10px 0",
                  display: "block",
                }}
              >
                Appointment Info
              </span>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Provider Name:</span>{" "}
                <span>{selectedBill.administered_by.name}</span>
              </div>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Provider Phone:</span>{" "}
                <span>{selectedBill.administered_by.phone}</span>
              </div>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>
                  Appointment Date/Time:
                </span>{" "}
                <span>
                  {selectedBill.appointment.scheduled
                    ? new Date(
                        selectedBill.appointment.scheduled
                      ).toLocaleString()
                    : ""}{" "}
                </span>
              </div>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Appointment Notes:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedBill.appointment.notes,
                  }}
                ></span>
              </div>
            </div>

            <div className="my-2 grid grid-rows-2">
              <div>
                <span
                  className="underline text-lg"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  Client Info
                </span>
                <div className="text-sm">
                  <span style={{ fontWeight: "bold" }}>Client Name: </span>
                  <span>{selectedBill.administered_to.name}</span>
                </div>
                <div className="text-sm">
                  <span style={{ fontWeight: "bold" }}>Client Email: </span>
                  <span>{selectedBill.administered_to.email}</span>
                </div>
                <div className="text-sm">
                  <span style={{ fontWeight: "bold" }}>Client Phone: </span>
                  <span>{selectedBill.administered_to.phone}</span>
                </div>
              </div>

              <div>
                <span
                  className="underline text-lg"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  Bill Info
                </span>
                <div className="text-sm">
                  <span style={{ fontWeight: "bold" }}>Bill Name: </span>
                  <span>
                    {!selectedBill.service
                      ? "No Service"
                      : selectedBill.service.service_name}
                  </span>
                </div>
                <div className="text-sm">
                  <span style={{ fontWeight: "bold" }}>Bill Code: </span>
                  <span>
                    {!selectedBill.service
                      ? "No Service"
                      : selectedBill.service.service_code}
                  </span>
                </div>
                <div className="text-sm">
                  <span style={{ fontWeight: "bold" }}>Bill Price: </span>
                  <span>{formatter.format(selectedBill.price)}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <h3
          className="underline text-lg font-bold"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            margin: "10px 0",
            display: "block",
            textAlign: "center",
            color: "#9333ea",
          }}
        >
          Edit Bill Details
        </h3>
      </div>
      <div>
        <FormControl className="w-full">
          <div className="w-full mb-4">
            <InputLabel id="PF">Filter</InputLabel>
            <Select
              labelId="PF"
              id="PF"
              size="small"
              value={filter}
              label="PF"
              onChange={handleFilter}
              className="w-full"
            >
              <MenuItem value="service_name">Bill Name</MenuItem>
              <MenuItem value="service_code">Bill Code</MenuItem>
            </Select>
          </div>

          <div className="w-full">
            <TextField
              className="placeholder:text-center rounded-lg w-full"
              placeholder="Search Procedures"
              label="Selected Bill"
              value={cquery}
              onChange={(event) => setQuery(event)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </FormControl>
        <p
          style={{
            fontSize: "10px",
            color: "gray",
            textAlign: "left",
            margin: "0 0 10px 0",
          }}
        >
          <Info style={{ fontSize: "14px" }} /> If there are no related Services
          or CPT code, leave this input blank
        </p>
      </div>

      <div>
        {!cquery ? (
          ""
        ) : (
          <div className="text-sm">
            {procedures.length !== 0 ? (
              <div className="w-full h-full p-0 mb-2">
                {procedures
                  .filter((procedure) => {
                    if (cquery === "") {
                      return procedure;
                    } else if (
                      procedure[filter]
                        .toLocaleLowerCase()
                        .includes(cquery.toLocaleLowerCase())
                    ) {
                      return procedures;
                    }
                    return false;
                  })
                  .map((procedure, index) => (
                    <List key={index} className="p-0 my-1 w-full">
                      <div className="text-sm">
                        <ListItemButton
                          onClick={(e) => populateServiceInfo(procedure)}
                          className="place-content-center overflow-x-hidden text-center border-solid border-gray-300 rounded-lg"
                        >
                          <span>{procedure.service_name}</span>
                          <span className="ml-6">{procedure.service_code}</span>
                        </ListItemButton>
                      </div>
                    </List>
                  ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <TextField
        className="w-full text-center my-4"
        id="Service Bill Total"
        label="Procedure Amount"
        value={price}
        onChange={(event) => {
          setPrice(event.target.value);
        }}
      />
      <TextField
        className="w-full text-center"
        id="Signature"
        label="Signature"
        value={signature}
        onChange={(event) => {
          setSignature(event.target.value);
        }}
      />
      <p className="text-sm my-4">
        <span className="text-red-800 underline font-bold">Warning:</span>{" "}
        Clicking the "Update Bill Details" button below will permanently rewrite
        over the current bill data, after proceeding previous data will be
        unrecoverable. Please make sure you want the above changes to take
        effect before proceeding.
      </p>
      <FormSubmitButton
        isLoading={loading}
        handleSubmit={handleApptProcedureEdit}
        buttonText="Update Bill Details"
        buttonColor="purple"
        ButtonIcon={<Edit />}
      />
      <ResponseModal
        title="Bill Data"
        description="Successfully updated the bill in client's data."
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Updating Bill"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </div>
  );
}
