import {
  CheckCircleOutline,
  CloseOutlined,
  ControlPointOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  AlertTitle,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  TextField,
  Tooltip,
} from "@mui/material/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";
import ShowMoreButton from "../../../../../global/ShowMoreButton";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import CustomProvModal from "../../Settlement/MedicalBreakdown/CustomProvModal";
import LoadingIndicator from "../../../../../global/LoadingIndicator";
import FormSubmitButton from "../../../../../global/FormComponents/FormSubmitButton";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function AddClientProviderModal({
  setProvFormDisplay,
  trigger,
  setTrigger,
  caseInfo,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [assignedProvider, setAssignedProvider] = useState("");
  const [providerListResponse, setProviderListResponse] = useState("");
  const [openCustomProv, setOpenCustomProv] = useState(false);

  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(10);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const populateProviderInfo = (provider) => {
    setAssignedProvider(provider.pk);
    setSearch(provider.name);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(false);
    setProvFormDisplay(false);
    setTrigger(!trigger);
    setSearch("");
    setProviderListResponse("");
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const sendRequest = () => {
    let querytest = qs.stringify(
      {
        search: !search ? null : search,
      },
      {
        skipNull: true,
      }
    );

    let queryBase = `/api/provider/?limit=${resLimit}&ordering=name`;

    axios
      .get(queryBase + "&" + querytest, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.results;
        if (response.data.count <= resLimit) {
          setShowMoreVisible(false);
        } else {
          setShowMoreVisible(true);
        }
        setProviderListResponse(data);
      });
  };

  useDebouncedEffect(() => sendRequest(), [accessToken, search, resLimit], 250);

  //Post
  const handleAddProCliStatus = (e) => {
    setLoading(true);
    let assignNewProvider = {
      client: caseInfo.client.pk,
      provider: assignedProvider,
    };

    axios
      .post("/api/pro_cli_status/", assignNewProvider, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorMessage(errArr);
          setLoading(false);
        }
        setOpenError(!openError);
      });
  };

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const handleOpenProvModal = () => {
    setOpenCustomProv(!openCustomProv);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <FormControl style={{ width: "100%" }}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Tooltip title={assignedProvider ? search : "Search Providers"}>
              <TextField
                style={{
                  width: "90%",
                  textAlign: "center",
                  alignSelf: "center",
                }}
                placeholder="Search Providers" //{`Search ${filter}`}
                value={assignedProvider ? truncate(search, 5, 20) : search}
                onChange={(event) => handleSearch(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: assignedProvider ? (
                    <InputAdornment position="start">
                      <CheckCircleOutline style={{ color: "green" }} />
                    </InputAdornment>
                  ) : (
                    ""
                  ),
                }}
              />
            </Tooltip>
            {assignedProvider ? (
              <Tooltip title="Clear Selection">
                <IconButton onClick={() => setAssignedProvider("")}>
                  <CloseOutlined style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </FormControl>

        <div>
          <div className="">
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {!providerListResponse ||
              providerListResponse.length === 0 ||
              assignedProvider ? (
                <LoadingIndicator
                  isLoading={!providerListResponse ? true : false}
                />
              ) : (
                providerListResponse.map((provider, index) => (
                  <List key={index} className="p-0 my-1 w-full">
                    <div className="">
                      <ListItemButton
                        onClick={() => populateProviderInfo(provider)}
                      >
                        <span style={{ width: "25%", textAlign: "center" }}>
                          {provider.name}
                        </span>
                        <span style={{ width: "25%", textAlign: "center" }}>
                          {provider.type}
                        </span>
                        <span style={{ width: "50%", textAlign: "center" }}>
                          {provider.address.raw}
                        </span>
                      </ListItemButton>
                    </div>
                  </List>
                ))
              )}
              <ShowMoreButton
                showMoreVisible={showMoreVisible}
                setResLimit={setResLimit}
                resLimit={resLimit}
                showMoreAmt={10}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              color: "#FFA500",
              margin: "1rem 0 0",
            }}
          >
            {!assignedProvider || providerListResponse.length === 0 ? (
              <ModalButton
                action={handleOpenProvModal}
                baseColor="#9333EA"
                content={"Add Custom Provider"}
                icon="add"
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div style={{ padding: "1rem 0" }}>
          <FormSubmitButton
            isLoading={loading}
            handleSubmit={handleAddProCliStatus}
            buttonText="Assign Provider to Client's Case"
            buttonColor="sky"
            ButtonIcon={<ControlPointOutlined />}
          />
        </div>
        <Dialog open={openSuccess}>
          <Alert variant="outlined" severity="success" role="alert">
            <AlertTitle>Provider Added</AlertTitle>
            <p>
              Successfully Added Provider to Client's Data
              <br />
            </p>
          </Alert>
          <div style={{ padding: "1rem 0" }}>
            <ModalButton
              action={handleOpenSuccess}
              baseColor={"#1976d2"}
              content={"OK"}
            />
          </div>
        </Dialog>
        <ResponseModal
          title="Error Assigning Provider"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
        <CustomProvModal
          openCustomProv={openCustomProv}
          setOpenCustomProv={setOpenCustomProv}
        />
      </div>
    </LocalizationProvider>
  );
}
