import { useState, useContext } from "react";
import { Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "../../../../../../api/axios";
import ModalButton from "../../../../../../global/ModalButton";
import ResponseModal from "../../../../../../global/ResponseModal";
import DataContext from "../../../../../../../context/DataContext";

export default function DeleteExpense({
  deleteOpen,
  setDeleteOpen,
  activeActionItem,
  setActiveActionItem,
  trigger,
  setTrigger,
  typeChoices,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setDeleteOpen(false);
    setTrigger(!trigger);
  };

  const handleNewActionDelete = () => {
    axios
      .delete(`/api/additional_expense/${activeActionItem.pk}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        setOpenSuccess(!openSuccess);
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorArray(errArr);
        }
      });
  };

  const handleModalClose = () => {
    setDeleteOpen(false);
    setActiveActionItem(null);
  };

  return (
    <Dialog open={deleteOpen} fullWidth>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          {" "}
          <IconButton onClick={() => handleModalClose()}>
            <Close />
          </IconButton>
        </div>
        <h1
          style={{
            fontSize: 20,
            margin: "0",
            textAlign: "center",
            color: "#d32f2f",
            fontWeight: "bold",
          }}
        >
          Delete Expense
        </h1>

        {!activeActionItem ? (
          ""
        ) : (
          <div>
            <div style={{ margin: "1rem 0" }}>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Label:{" "}
                </span>{" "}
                <span style={{ fontSize: "1.2rem" }}>
                  {!activeActionItem.label ? "" : activeActionItem.label}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Amount:{" "}
                </span>{" "}
                <span style={{ fontSize: "1.2rem" }}>
                  {!activeActionItem.amount ? "" : activeActionItem.amount}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Deducted From:{" "}
                </span>{" "}
                <span style={{ fontSize: "1.2rem" }}>
                  {typeChoices.length === 0 || !activeActionItem
                    ? ""
                    : typeChoices[activeActionItem.deduction_type].replace(
                        "Deducted from",
                        ""
                      )}
                </span>
              </div>
            </div>
            <ModalButton
              action={handleNewActionDelete}
              baseColor={"#d32f2f"}
              content="Delete Expense"
              icon={"delete"}
            />
          </div>
        )}
        <ResponseModal
          title="Expense Removed"
          description="Expense has been successfully deleted"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleOpenSuccess}
        />
        <ResponseModal
          title="Error Removing Expense"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorArray}
        />
      </div>
    </Dialog>
  );
}
