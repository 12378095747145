import { AnalyticsOutlined, ControlPoint } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import qs from "query-string";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import OrderingArrow from "../../../../../global/OrderingArrow";
import PaginationControls from "../../../../../global/PaginationControls";
import ActionItemAdd from "../../ActionItems/Modals/ActionItemAdd";
import ActionItemDelete from "../../ActionItems/Modals/ActionItemDelete";
import ActionItemDetail from "../../ActionItems/Modals/ActionItemDetail";
import ResponseModal from "../../../../../global/ResponseModal";
import ActionListItem from "./ActionListItem";

export default function ActionItemsTab({ caseObj }) {
  const { accessToken, setSelectedClient, userRoles } = useContext(DataContext);
  const [actItems, setActItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [search, setSearch] = useState("");
  const [orderingVal, setOrderingVal] = useState("-status");

  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [activeActionItem, setActiveActionItem] = useState(null);

  const navigate = useNavigate();

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    //!would be nice to have a boolean to determine if the string is truncated and then add a tooltip to the desc of item to allow for quick view.
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setAddOpen(false);
    setTrigger(!trigger);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  const navigateToDetail = (client) => {
    setSelectedClient(client.pk);
    navigate(`/client-detail/${client.pk}`);
  };

  const actionItemSearch = () => {
    if (!caseObj) {
      return;
    } else {
      let query = qs.stringify(
        {
          search: !search ? null : search,
        },
        {
          skipNull: true,
        }
      );
      axios
        .get(
          `/api/actionable_items/?ordering=${orderingVal}&on_case=${caseObj.pk}&` +
            paginationUrl +
            query,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          const data = response.data.results;
          setResultCount({
            total: response.data.count,
            current: response.data.results.length,
          });
          setLoading(false);
          setActItems(data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    }
  };

  useDebouncedEffect(
    () => actionItemSearch(),
    [search, paginationUrl, trigger, orderingVal, caseObj],
    250
  );

  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:p-2 px-2 py-2 sm:border-b-0 self-center";

  const handleEditAction = (activeCase) => {
    setEditOpen(true);
    setActiveActionItem(activeCase);
  };
  const handleDeleteAction = (activeCase) => {
    setDeleteOpen(true);
    setActiveActionItem(activeCase);
  };
  const handleDetailAction = (activeCase) => {
    setDetailOpen(true);
    setActiveActionItem(activeCase);
  };

  const completeAction = (actionId, status) => {
    let patchData = {
      status: status === "done" ? "pending" : "done",
    };
    axios
      .patch(`/api/actionable_items/${actionId}/`, patchData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        setTrigger(!trigger);
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
        }
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`pr-4 xl:h-[60vh] overflow-hidden flex w-full`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div className={`w-full md:ml-4`}>
          <div className="flex justify-center mt-2">
            <Tooltip title="Search by Acting User Name">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm h-[2rem]"
                placeholder="Search"
                size="small"
                name="search"
                value={search}
                InputLabelProps={{ shrink: true }}
                label="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
            </Tooltip>
          </div>
          <div className="h-full overflow-hidden">
            <div className="flex px-0 text-center pt-2 border-b border-1 border-gray items-center">
              <div className="md:w-1/4 w-1/3 md:block hidden font-bold">
                <h2 className="text-sm">ACTING USER</h2>
              </div>
              <div className="w-[20%] md:block hidden font-bold">
                <h2 className="text-sm">
                  DUE DATE{" "}
                  <OrderingArrow
                    val={"due_date"}
                    orderingVal={orderingVal}
                    setOrderingVal={setOrderingVal}
                  />
                </h2>
              </div>
              <div className="w-[30%] block font-bold">
                <h2 className="text-sm">ACTION </h2>
              </div>
              <div className="md:w-[12%] w-1/3 font-bold">
                <h2 className="text-sm">
                  STATUS{" "}
                  <OrderingArrow
                    val={"status"}
                    orderingVal={orderingVal}
                    setOrderingVal={setOrderingVal}
                  />
                </h2>
              </div>
              <div className="w-[10%] md:block hidden font-bold flex">
                {" "}
                {userRoles.permissions.includes(
                  "casemanager.add_actionableitems"
                ) ? (
                  <Tooltip title="Add Action Item">
                    <IconButton
                      className="text-green-600 text-[16px]"
                      onClick={() => setAddOpen(true)}
                    >
                      <ControlPoint />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}
                <Tooltip title="View Action Item Counts">
                  <IconButton
                    className="text-purple-600 text-[16px]"
                    onClick={() => navigate(`/action-item-counts/`)}
                  >
                    <AnalyticsOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <List className={`grid max-h-[45vh] overflow-y-auto`}>
              {loading === "true" ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {actItems.map((action, index) => (
                    <ActionListItem
                      key={index}
                      handleDeleteAction={handleDeleteAction}
                      handleDetailAction={handleDetailAction}
                      handleEditAction={handleEditAction}
                      action={action}
                      completeAction={completeAction}
                      truncate={truncate}
                      resultClasses={resultClasses}
                      navigateToDetail={navigateToDetail}
                    />
                  ))}
                </>
              )}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
        {!addOpen ? (
          ""
        ) : (
          <ActionItemAdd
            addOpen={addOpen}
            setAddOpen={setAddOpen}
            activeActionItem={activeActionItem}
            setActiveActionItem={setActiveActionItem}
            caseObj={caseObj}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
        {!editOpen ? (
          ""
        ) : (
          <ActionItemAdd
            addOpen={editOpen}
            setAddOpen={setEditOpen}
            activeActionItem={activeActionItem}
            setActiveActionItem={setActiveActionItem}
            caseObj={caseObj}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
        {!deleteOpen ? (
          ""
        ) : (
          <ActionItemDelete
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            setActiveActionItem={setActiveActionItem}
            activeActionItem={activeActionItem}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
        {!detailOpen ? (
          ""
        ) : (
          <ActionItemDetail
            detailOpen={detailOpen}
            setDetailOpen={setDetailOpen}
            setActiveActionItem={setActiveActionItem}
            activeActionItem={activeActionItem}
            navigateToDetail={navigateToDetail}
          />
        )}
        <ResponseModal
          title={"Action Item Status Update"}
          description={"Action Item status has been succesfully updated"}
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleOpenSuccess}
        />
        <ResponseModal
          title={"Error updating Action Item Status"}
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
