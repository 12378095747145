import React, { useContext, useState } from "react";
import { Button, Dialog, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BankCheckStateContext } from "../../context/BankCheckStateContext";
import Form from "./Form";
import Success from "./Success";
import ErrorMessage from "./ErrorMessage";
import LoadingIndicator from "../../../../../../../global/LoadingIndicator";
import {
  CONTENT_ADD_CHECK,
  CONTENT_CHECKLIST,
  CONTENT_EDIT_CHECK,
  CONTENT_ERROR,
  CONTENT_SUCCESS,
} from "../../state/actions";
import CheckList from "./CheckList/CheckList";
import AddCheckOption from "./AddCheckOption";

export default function CheckDialog({
  showDialog,
  fullScreen,
  close,
  submitForm,
}) {
  const state = useContext(BankCheckStateContext);

  const dialogStyle = {
    padding: "0.75rem",
    textAlign: "center",
  };
 
  const closeButton = {
    textAlign: "right",
  };
  const titleStyle = {
    fontSize: "1.6rem",
    textAlign:'center',
    borderBottom:'1px solid #eee'
  }

  /*   function getDialogType(checkType) {
    if (checkType === "NEW") {
     return <Create title={"Add Note"} />;
    } else if (checkType === "EDIT") {
      return <Edit title={"Edit Note"} />;
    } else {
      return "";
    }
  } */

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    close();
  };

  const handleSubmitForm = async (formType) => {
    submitForm(formType);
  };

  const setUpDialogContent = () => {
    if (state.dialogContentIsLoading) {
      return <CircularProgress color="secondary" />;
    }
    return getDialogContentUI();
  };

  const getDialogContentUI = () => {
    switch (state.showContent) {
      case CONTENT_ADD_CHECK: {
        return <Form onClickSubmit={handleSubmitForm}  />;
      }
      case CONTENT_EDIT_CHECK: {
        return (<div>
          <Form onClickSubmit={handleSubmitForm} />
          <AddCheckOption/>
          </div>);
      }
      case CONTENT_CHECKLIST: {
        return  (<div>
          <CheckList listOfChecks={state.checkListResponse} />
          <AddCheckOption/>
          </div>);
      }
      case CONTENT_SUCCESS: {
        return <Success handleCloseFunc={handleClose} />;
      }
      case CONTENT_ERROR: {
        return (
          <ErrorMessage
            title={"Submission Error"}
            errorMessage={state.errorArray}
            handleCloseFunc={handleClose}
          />
        );
      }
      default: {
        return <LoadingIndicator isLoading={true} />;
      }
    }
  };

  return (
    <div data-testid="Bank-Check-Dialog">
      <Dialog open={showDialog} fullScreen={fullScreen}>
        <div className="outer" style={dialogStyle}>
          
          <div style={closeButton}>
            <Button onClick={(e) => handleClose(e)}>
              <CloseIcon />
            </Button>
          </div>
          <div style={dialogStyle}>
          <div style={titleStyle}>
            <h2 className="text-black">
              {state.showContent === CONTENT_ADD_CHECK && "Add a New Check"}
              {state.showContent === CONTENT_CHECKLIST && "Edit an Existing Check"}
              {state.showContent === CONTENT_EDIT_CHECK && "Edit an Existing Check"}

            </h2>
          </div>
            
            {state.dialogIsLoading ? (
              <LoadingIndicator isLoading={true} />
            ) : (
              <div style={dialogStyle} data-testid="Bank-Check-Dialog-Content">{setUpDialogContent()}</div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
