import React, { useState, useContext } from "react";
import {
  Button,
  Tooltip,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataContext from "../../context/DataContext";
import { CloseOutlined } from "@mui/icons-material";

function DynamicTextInput({
  title,
  value,
  setValue,
  queryKey,
  setQueryKey,
  query,
  resetPagination,
}) {
  const { trigger, setTrigger } = useContext(DataContext);
  const [toggleInput, setToggleInput] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const setKey = (e) => {
    setQueryKey(e.target.value);
    if (e.target.value === `${query}__isnull`) {
      setValue(true);
      setInputDisabled(true);
      setTrigger(!trigger);
    } else {
      setInputDisabled(false);
    }
  };

  const handlePost = () => {
    setToggleInput(false);
    setTrigger(!trigger);
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  const clearFunc = () => {
    setValue("");
    setTrigger(!trigger);
    setToggleInput(false);
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  const clearSubFilter = () => {
    setQueryKey(query);
    setInputDisabled(false);
    setTrigger(!trigger);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      {toggleInput === false ? (
        <>
          <Tooltip title={`Edit ${title}`}>
            <div
              onClick={() => setToggleInput(true)}
              className="cursor-pointer text-left flex"
            >
              {title}&nbsp;
              {!value ? <AddIcon className="text-sky-600" /> : ""}
              {!value ? (
                ""
              ) : (
                <div>
                  <span className="text-sky-600">
                    {queryKey === `${query}__lt`
                      ? ">"
                      : queryKey === `${query}__lte`
                      ? ">="
                      : queryKey === `${query}__gt`
                      ? "<"
                      : queryKey === `${query}__gte`
                      ? "<="
                      : "-"}{" "}
                    {value === true ? "No Value" : formatter.format(value)}
                  </span>
                </div>
              )}
            </div>
          </Tooltip>
          {!value ? (
            ""
          ) : (
            <Button
              onClick={clearFunc}
              className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
            >
              X
            </Button>
          )}
        </>
      ) : (
        <div className="flex">
          <div>
            <TextField
              className="placeholder:text-center rounded-lg w-full text-sm"
              placeholder={title}
              size="small"
              name="cm"
              type="number"
              value={value}
              InputLabelProps={{ shrink: true }}
              label={title}
              disabled={inputDisabled}
              onChange={(event) => setValue(event.target.value)}
            />
            <Accordion className="mt-2 w-full">
              <AccordionSummary
                className="text-sm"
                expandIcon={
                  <div className="flex items-center">
                    <AddIcon className="text-black" />
                  </div>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="text-sm">
                  {queryKey === `${query}__lt`
                    ? `Less than ${formatter.format(value)}`
                    : queryKey === `${query}__lte`
                    ? `Less than or equal to ${formatter.format(value)}`
                    : queryKey === `${query}__gt`
                    ? `Greater than ${formatter.format(value)}`
                    : queryKey === `${query}__gte`
                    ? `Greater than or equal to ${formatter.format(value)}`
                    : queryKey === `${query}__isnull`
                    ? `No value`
                    : "Filter By"}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    className="text-sm w-full"
                    defaultValue={null}
                    name="radio-buttons-group"
                    value={queryKey}
                    onChange={(e) => setKey(e)}
                  >
                    <FormControlLabel
                      value={`${query}__lt`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Less than{" "}
                          {value === true ? `$` + 0 : formatter.format(value)}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__lte`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Less than or equal to{" "}
                          {value === true ? `$` + 0 : formatter.format(value)}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__gt`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Greater than{" "}
                          {value === true ? `$` + 0 : formatter.format(value)}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__gte`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Greater than or equal to{" "}
                          {value === true ? `$` + 0 : formatter.format(value)}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__isnull`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Has no value
                        </Typography>
                      }
                      className="text-sm"
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  onClick={clearSubFilter}
                  variant="outlined"
                  className="text-sm text-red-600 border-red-600 hover:bg-red-600 hover:text-white"
                >
                  Clear Sub-Filter
                </Button>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Button
              onClick={handlePost}
              className="text-purple-600 hover:text-white hover:bg-purple-600 rounded-md text-sm"
            >
              Save
            </Button>
            <Button
              onClick={clearFunc}
              className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
            >
              <CloseOutlined />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default DynamicTextInput;
