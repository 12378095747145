import { CheckCircleOutline, Search } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ModalButton from "./ModalButton";
import ShowMoreButton from "./ShowMoreButton";
import DataContext from "../../context/DataContext";
import { useDebouncedEffect } from "../hooks/useDebounceEffect";
import axios from "../api/axios";

export default function CustomSearchSelect({
  selectedVal,
  setSelectedVal,
  searchTitle,
  title,
  nameKey,
  apiUrl,
}) {
  const { accessToken } = useContext(DataContext);
  const [resLimit, setResLimit] = useState(25);
  const [responseData, setResponseData] = useState([]);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [search, setSearch] = useState("");

  const handleValSelect = (val) => {
    setSelectedVal(val);
  };

  useDebouncedEffect(
    () => {
      axios
        .get(
          `${apiUrl}limit=${resLimit}&ordering=name${
            !search ? "" : `&search=${search}`
          }`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          let data = response.data.results;
          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }
          setResponseData(data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    },
    [resLimit, search],
    250
  );
  return (
    <div style={{ width: "100%" }}>
      <div>
        <h2
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          Select {title}
        </h2>
      </div>
      <div style={{ width: "100%", margin: "10px 0" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder={searchTitle} //{`Search ${filter}`}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {!search ? (
            ""
          ) : (
            <div style={{ marginLeft: "10px" }}>
              <ModalButton
                className="block"
                action={() => setSearch("")}
                baseColor="#d32f2f"
                content={"Reset"}
              >
                Reset
              </ModalButton>
            </div>
          )}
        </div>
        <FormControl
          className="justify-center my-4 text-sm w-full"
          style={{
            justifyContent: "center",
            margin: "1rem 0",
            width: "100%",
          }}
        >
          <InputLabel size="small">{title}</InputLabel>
          <Select
            size="small"
            label={title}
            value={selectedVal}
            onChange={(e) => handleValSelect(e.target.value)}
            className="text-sm p-2"
            MenuProps={{
              sx: { height: "300px" },
            }}
            startAdornment={
              selectedVal ? (
                <InputAdornment position="start">
                  <CheckCircleOutline size={20} style={{ color: "#16A34A" }} />
                </InputAdornment>
              ) : (
                ""
              )
            }
          >
            {!responseData ? (
              <MenuItem>No Results Found</MenuItem>
            ) : responseData.length === 0 ? (
              <MenuItem>No Results Found</MenuItem>
            ) : (
              responseData.map((item) => (
                <MenuItem
                  value={nameKey ? item[nameKey].pk : item.pk}
                  key={item.pk}
                  id={item.pk}
                  className="text-sm"
                >
                  {nameKey ? item[nameKey].name : item.name}
                </MenuItem>
              ))
            )}
            <ShowMoreButton
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={5}
            />
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
