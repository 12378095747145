import React from "react";
import { Button } from "@mui/material";
import { WarningAmberRounded } from "@mui/icons-material";
export default function NoticeInternal({
  noteType,
  isInternalOnly,
  setisNotePrivacySelected,
  backgroundColor,
  iconColor,
}) {
  function getPrivacyStatus() {
    return isInternalOnly ? "Internal Personnel Only" : "Everyone";
  }

  function getContent() {
    if (noteType === "New") {
      return (
        <p>
          This Note will be seen by <strong>{getPrivacyStatus()}</strong>.<br />
          <Button
            sx={{ color: iconColor }}
            onClick={() => setisNotePrivacySelected(false)}
          >
            Go Back
          </Button>
          to change this Setting.
        </p>
      );
    } 
      return (
      <p>
        This Note can be seen by <strong>{getPrivacyStatus()}</strong>.
      </p>);
    
 
  }
  return (
    <div
      className="privacy-notice"
      style={{
        padding: "1rem",
        margin: "0.5rem 0 1rem 0",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        backgroundColor: backgroundColor,
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      }}
    >
      <div className="px-2" style={{ padding: 10 }}>
        <WarningAmberRounded fontSize="large" />
      </div>
      <div className="text-center">{getContent()}</div>
    </div>
  );
}
