import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "../../../../../../../api/axios";
import DataContext from "../../../../../../../../context/DataContext";
import ResponseModal from "../../../../../../../global/ResponseModal";
import LoadingIndicator from "../../../../../../../global/LoadingIndicator";

export default function ApprovalChecklist({
  document,
  setDocTrigger,
  docTrigger,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [errorArray, setErrorArray] = useState([]);

  const isDisabled = userRoles.permissions.includes(
    "filemanager.add_documentapproval"
  )
    ? false
    : true;
  const updateItem = (e) => {
    setIsLoading(true);
    const fileData = {
      [e.target.name]: e.target.checked,
    };
    axios
      .patch(`/api/documents/${document.pk}/`, fileData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        // handle the response
        setUploadSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        // handle errors
        setUploadFailed(true);
        setIsLoading(false);
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setErrorArray(errArr);
      });
  };

  const handleUploadSuccess = () => {
    setUploadSuccess(false);
    setDocTrigger(!docTrigger);
  };
  return (
    <div className="flex flex-wrap">
      {" "}
      <FormControlLabel
        name="checked"
        disabled={isDisabled}
        label={"Checked"}
        control={
          <Tooltip title="Set Document Checked">
            <Checkbox color="info" checked={document.checked} />
          </Tooltip>
        }
        onChange={(e) => updateItem(e)}
      />
      <FormControlLabel
        name="approved"
        disabled={isDisabled}
        label={"Approved"}
        control={
          <Tooltip title="Set Document Approved">
            <Checkbox color="success" checked={document.approved} />
          </Tooltip>
        }
        onChange={(e) => updateItem(e)}
      />
      <LoadingIndicator isLoading={isLoading} />
      <ResponseModal
        title="Update Success"
        description="The image/document was successfully updated"
        openBool={uploadSuccess}
        setOpenBool={setUploadSuccess}
        handleCloseFunc={handleUploadSuccess}
      />
      <ResponseModal
        title="Update failed"
        isError={true}
        openBool={uploadFailed}
        setOpenBool={setUploadFailed}
        errorMessage={errorArray}
      />
    </div>
  );
}
