import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function LanguagePreferences({
  userInfo,
  trigger,
  setTrigger,
  languageObj,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [languageArr, setLanguageArr] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (userInfo) {
      let customLangArr = userInfo.spoken_language.map(
        (lang) => languageObj[lang]
      );
      setLanguageArr(customLangArr);
      setPreferredLanguage(languageObj[userInfo.preferred_language]);
    }

    if (userRoles.permissions.includes("utilities.view_language")) {
      axios
        .get(`/api/utilities/language/?ordering=created_at`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setLanguageOptions(data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    } else {
      return;
    }
  }, [
    accessToken,
    setLanguageOptions,
    userRoles.permissions,
    setLanguageArr,
    userInfo,
    setPreferredLanguage,
  ]);

  const addLanguage = () => {
    const ids = !languageArr ? [] : languageArr.map(({ pk }) => pk);

    let patchedData = {
      ...(languageArr.length === 0 ? {} : { spoken_language: ids }),
      ...(!preferredLanguage
        ? {}
        : { preferred_language: preferredLanguage.pk }),
    };

    axios
      .patch(`/api/users/${userInfo.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Language preferences updated");
        setIsError(false);
      })
      .catch((error) => {
        const res = error.response.data;
        const errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setTrigger(!trigger);
  };

  return (
    <div>
      {userRoles.permissions.includes("utilities.view_language") ? (
        <div>
          <h2
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              margin: "5px 0",
              color: "gray",
            }}
          >
            Preferred Language
          </h2>
          <Autocomplete
            options={!languageOptions ? [] : languageOptions}
            getOptionLabel={(option) => option.description}
            value={preferredLanguage}
            onChange={(e, value) => {
              setPreferredLanguage(value);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Languages" />
            )}
          />

          <h2
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              margin: "5px 0",
              color: "gray",
            }}
          >
            Spoken Languages
          </h2>
          <Autocomplete
            multiple
            options={
              !languageOptions ? [] : languageOptions.map((option) => option)
            }
            getOptionLabel={(option) => option.description}
            value={languageArr}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.description}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(e, value) => {
              let newArr = value.filter((obj, index) => {
                return index === value.findIndex((o) => obj.pk === o.pk);
              });
              setLanguageArr(newArr);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Languages" />
            )}
          />
          <div style={{ margin: "1rem auto 0" }}>
            <ModalButton
              action={() => addLanguage()}
              baseColor={"#1976d2"}
              content={"Update"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
