import React, { useContext, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import {
  PhoneForwardedOutlined,
  CallOutlined,
  Launch,
} from "@mui/icons-material";
import { HelpOutline } from "@mui/icons-material";
import Colors from "../../Colors";
import format from "date-fns/format";
import MultiUserDisplay from "./MultiUserDisplay";
import AccordionColumns from "./AccordionColumns";
import Columns from "./Columns";
import { useNavigate } from "react-router-dom";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import DataContext from "../../../../context/DataContext";
import { case_detail } from "../../../api/endpoints/case";
import LoadingIndicator from "../../LoadingIndicator";

export default function CommLogItem({ logType, log, idx, style }) {
  const { accessToken } = useContext(DataContext);
  const FALLBACK_STRING = "Unknown";

  const originKey = logType === "phone" ? "caller" : "sender";
  const destinationKey = logType === "phone" ? "receiver" : "receivers";
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const navigateToDetail = (clientId) => {
    if (clientId === null) {
      alert("Cannot find client detail");
    } else {
      case_detail
        .getCaseByClientId(clientId, accessToken)
        .then((response) => {
          navigate(`/client-detail/${response.results[0].pk}`);
          setIsLoading(false);
          return response;
        })
        .catch((error) => {
          setIsLoading(false);
          return ApiRequestErrorHandler(error.response);
        });
    }
  };

  const callDirectionIcon = (dir) => {
    switch (dir) {
      case "Inbound":
        return (
          <Tooltip title="Inbound">
            <CallOutlined sx={{ fontSize: "2rem", color: Colors.blue[500] }} />
          </Tooltip>
        );
      case "Outbound":
        return (
          <Tooltip title="Outbound">
            <PhoneForwardedOutlined
              sx={{ fontSize: "2rem", color: Colors.green[500] }}
            />
          </Tooltip>
        );
      case "Unknown":
        return (
          <Tooltip title="Unknown">
            <HelpOutline sx={{ fontSize: "2rem", color: Colors.gray[400] }} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Unknown">
            <HelpOutline sx={{ fontSize: "2rem", color: Colors.gray[400] }} />
          </Tooltip>
        );
    }
  };

  const formatted = (time) => {
    var n = new Date(0, 0);
    n.setSeconds(time * 60);
    return n
      .toLocaleTimeString("en-Gb", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace("AM", "")
      .replace("PM", "");
  };

  const getFormattedTime = (time) => {
    if (time === undefined || time === null || time === " " || time === "") {
      return " ";
    }
    return format(new Date(time), "p");
  };
  const getFormattedDate = (date) => {
    if (date === undefined || date === null || date === " " || date === "") {
      return " ";
    }
    return format(new Date(date), "P");
  };
  const formattedPhoneNumber = (phoneNumberString) => {
    if (phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
    }
    return " ";
  };
  const displayPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return "Unknown";
    }
    if (phoneNumber && phoneNumber !== "" && phoneNumber !== " ") {
    }

    return formattedPhoneNumber(phoneNumber);
  };
  const displayNameWithFallback = (id, users, phoneNumber) => {
    let typeCheck = Object.prototype.toString;
    if (typeCheck.call(users) === "[object String]") {
      return users;
    }
    if (typeCheck.call(users) === "[object Array]") {
      if (users) {
        if (users.length === 0) {
          return FALLBACK_STRING;
        }
        if (users.length === 1) {
          return users[1];
        }
        return (
          <MultiUserDisplay key={"md_display_" + id} logId={id} users={users} />
        );
      } else {
        if (phoneNumber && phoneNumber !== "" && phoneNumber !== " ") {
          return displayPhoneNumber(phoneNumber);
        } else {
          return FALLBACK_STRING;
        }
      }
    }
  };

  return (
    <div className="flex items-center">
      <LoadingIndicator isLoading={isLoading} />
      {!log || Object.keys(log).length === 0 ? (
        <div id="no-list-item" data-testid="no-list-item">
          Nothing to display.
        </div>
      ) : log[originKey]?.users?.length > 1 ||
        log[destinationKey]?.users?.length > 1 ? (
        <AccordionColumns
          logType={logType}
          log={log}
          colstyle={style}
          callDirectionIcon={callDirectionIcon}
          getFormattedDate={getFormattedDate}
          getFormattedTime={getFormattedTime}
          formatted={formatted}
          fallbackString={FALLBACK_STRING}
          displayNameWithFallback={displayNameWithFallback}
          originKey={originKey}
          destinationKey={destinationKey}
        />
      ) : (
        <Columns
          logType={logType}
          log={log}
          colstyle={style}
          callDirectionIcon={callDirectionIcon}
          getFormattedDate={getFormattedDate}
          getFormattedTime={getFormattedTime}
          formatted={formatted}
          fallbackString={FALLBACK_STRING}
          displayNameWithFallback={displayNameWithFallback}
          originKey={originKey}
          destinationKey={destinationKey}
        />
      )}
      {log.caller?.users[0]?.is_client || log.receiver?.users[0]?.is_client ? (
        <Tooltip title="Open Case Details">
          <IconButton
            onClick={() =>
              navigateToDetail(
                log.caller?.users[0]?.is_client && log.caller
                  ? log.caller?.users[0]?.user
                  : log.receiver?.users[0]?.is_client && log.receiver
                  ? log.receiver?.users[0]?.user
                  : null
              )
            }
            className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
          >
            <Launch />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
}
