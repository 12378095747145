import React, { useContext, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import qs from "query-string";
import axios from "../../../../../api/axios";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material";
import ShowMoreButton from "../../../../../global/ShowMoreButton";
import { Close, Launch, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";

export default function CountColumn({ title, endpoint, searchKey }) {
  const {
    accessToken,
    setActiveUsers,
    setSelectedUsers,
    setSelectedLawFirms,
    setActiveLawFirms,
    setSelectedCases,
    setActiveCases,
    setSelectedProviders,
    setActiveProviders,
  } = useContext(DataContext);

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const navigate = useNavigate();

  const navToActionSearch = (user) => {
    if (endpoint === "users") {
      setSelectedUsers([user.id]);
      setActiveUsers([user.name]);
      setSelectedProviders([]);
      setActiveProviders([]);
      setSelectedCases([]);
      setActiveCases([]);
      setSelectedLawFirms([]);
      setActiveLawFirms([]);
    } else if (endpoint === "law_firm") {
      setSelectedLawFirms([user.id]);
      setActiveLawFirms([user.name]);
      setSelectedProviders([]);
      setActiveProviders([]);
      setSelectedCases([]);
      setActiveCases([]);
      setSelectedUsers([]);
      setActiveUsers([]);
    } else if (endpoint === "cases") {
      setSelectedCases([user.id]);
      setActiveCases([user.name]);
      setSelectedProviders([]);
      setActiveProviders([]);
      setSelectedLawFirms([]);
      setActiveLawFirms([]);
      setSelectedUsers([]);
      setActiveUsers([]);
    } else if (endpoint === "provider") {
      setSelectedProviders([user.id]);
      setActiveProviders([user.name]);
      setSelectedCases([]);
      setActiveCases([]);
      setSelectedLawFirms([]);
      setActiveLawFirms([]);
      setSelectedUsers([]);
      setActiveUsers([]);
    }
    navigate(`/action-items`);
  };

  const actionItemSearch = () => {
    setLoading(true);
    let query = qs.stringify(
      {
        [searchKey]: !search ? null : search,
      },
      {
        skipNull: true,
      }
    );

    axios
      .get(
        `/api/actionable_items/stats/${endpoint}/?limit=${resLimit}&` + query,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        const data = response.data.results;
        if (response.data.count <= resLimit) {
          setShowMoreVisible(false);
        } else {
          setShowMoreVisible(true);
        }
        setTimeout(() => {
          setLoading(false);
        }, "500");
        setResults(data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  useDebouncedEffect(() => actionItemSearch(), [search, resLimit], 250);
  return (
    <div className="w-1/4">
      <h2 className="font-bold text-[1.2rem] text-center">{title} </h2>
      <div className="mx-2">
        <TextField
          className="placeholder:text-center rounded-lg w-full text-sm"
          placeholder="Search"
          size="small"
          name="search"
          value={search}
          InputLabelProps={{ shrink: true }}
          label="Search"
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: !search ? (
              ""
            ) : (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch("")}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {!loading ? (
        <List className="max-h-[90vh] overflow-auto">
          {!results ? (
            ""
          ) : results.length === 0 ? (
            <p className="text-center">No Results</p>
          ) : (
            results.map((item, idx) => (
              <ListItem key={idx} className="py-0 px-1">
                <div className="text-black shadow-lg py-2 my-1 w-full flex bg-white rounded-md border-gray-100 border-y px-2 text-sm justify-between">
                  <Tooltip title="Go to Action Search page">
                    <Button
                      onClick={() => navToActionSearch(item)}
                      className="text-black"
                    >
                      <span className="block font-bold">{item.name}</span>&nbsp;
                      <Launch className="text-[16px]" />
                    </Button>
                  </Tooltip>
                  <span className="block w-[10%]">
                    {!item.pending ? 0 : item.pending}/{item.total}
                  </span>
                </div>
              </ListItem>
            ))
          )}
          <ShowMoreButton
            showMoreVisible={showMoreVisible}
            setResLimit={setResLimit}
            resLimit={resLimit}
            showMoreAmt={5}
          />
        </List>
      ) : (
        <CircularProgress color="secondary" className="block mx-auto mt-2" />
      )}
    </div>
  );
}
