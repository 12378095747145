import {
  SET_FIELD_DATA,
  SET_API_ERRORS,
  SHOW_COMPONENT_SET,
  SET_SENDS_CHECK_TO_LAWFIRM,
  SET_PROVIDER_NAME,
  SET_CLEAR_FORM,
  SET_FIELD_DATA_VALIDATION,
  SHOW_CONTENT_SET,
  CONTENT_LOADING_TOGGLE,
  SET_CHECKLIST,
  SET_SELECTED_CHECK,
  SET_CLEAR_FORM_FOR_ADD_CHECK,
} from "./actions";

export const initialState = {
  errorArray: [],
  newCheckForm: {
    
    check: {
      id:null,
      check_number: "",
      amount: "",
      check_date: "",
      check_type: 2,
      law_firm_handled: false,
      updated_by: 0,
      case: 0,
      provider: 0,
      initial_amount:0,
      settled_amount:0
    },
    validation:{
      amount:false,
      check_number:false,
      check_date:false,
    }
  },
  selectedCheck:0,
  provider_name: "",
  showComponent: "",
  checkListResponse:null,
  showContent:"",
  dialogContentIsLoading:false,
  dialogIsLoading:false
};
export function BankCheckReducer(state, action) {
  switch (action.type) {
    case SET_CHECKLIST: {
      return {
        ...state, checkListResponse: action.payload
        }
    }
    case SET_SELECTED_CHECK: {
      let apiState = {...state.checkListResponse};
      return {
        ...state, newCheckForm:{
        ...state.newCheckForm,  check: apiState[action.payload]
        }
      }
    }
    case SET_FIELD_DATA: {
      return {
        ...state,
        newCheckForm: {
          ...state.newCheckForm,
          check: {
            ...state.newCheckForm.check,
            [action.payload.fieldName]: action.payload.data,
          },
        },
      };
    }
    case SET_FIELD_DATA_VALIDATION: {
      return {
        ...state,
        newCheckForm: {
          ...state.newCheckForm,
          validation: {
            ...state.newCheckForm.validation,
            [action.payload.fieldName]: action.payload.data,
          },
        },
      };
    }
    case SET_SENDS_CHECK_TO_LAWFIRM: {
      return {
        ...state,
        newCheckForm: {
          ...state.newCheckForm,
          check: {
            ...state.newCheckForm.check,
            law_firm_handled: action.payload,
          },
        },
      };
    }
    case SET_PROVIDER_NAME: {
      return { ...state, provider_name: action.payload };
    }
    case SHOW_COMPONENT_SET: {
      return { ...state, showComponent: action.payload };
    }
    case SHOW_CONTENT_SET: {
      return { ...state, showContent: action.payload };
    }
    case CONTENT_LOADING_TOGGLE: {
      return { ...state, dialogContentIsLoading: action.payload };
    }
    case SET_API_ERRORS: {
      return { ...state, errorArray: action.payload };
    }
    case SET_CLEAR_FORM: {
      return {
        ...state,
        newCheckForm: {
          ...state.newCheckForm,
          check: {
            id:null,
            check_number: "",
            amount: "",
            check_date: "",
            check_type: 2,
            law_firm_handled: false,
            updated_by: 0,
            case: 0,
            provider: 0,
            initial_amount:0,
            settled_amount:0
          },
          validation:{
            amount:false,
            check_number:false,
            check_date:false,
          }
        },
      };
    }
    case SET_CLEAR_FORM_FOR_ADD_CHECK: {
      return {
        ...state,
        newCheckForm: {
          ...state.newCheckForm,
          check: {
            id:null,
            check_number: "",
            amount: "",
            check_date: "",
            check_type: 2,
            law_firm_handled: false,
            updated_by: state.newCheckForm.check.updated_by,
            case: state.newCheckForm.check.case,
            provider: state.newCheckForm.check.provider,
            initial_amount:state.newCheckForm.check.initial_amount,
            settled_amount:state.newCheckForm.check.settled_amount
           
          },
          validation:{
            amount:false,
            check_number:false,
            check_date:false,
          }
        },
      };
    }
    default:
      return state;
  }
}
