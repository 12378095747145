import React from "react";

export default function PrintableProvCliList({
  componentRef,
  responseData,
  loading,
  title,
  activeFilters,
}) {
  return (
    <>
      {!responseData ? (
        ""
      ) : (
        <div ref={componentRef} className="hidden">
          <h1
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <span style={{ display: "block" }}>
              {activeFilters ? "Clients filtered by:" : ""}&nbsp;
            </span>
            {title}
          </h1>
          <p style={{ textAlign: "center" }}>
            Total: {responseData.length} as of {new Date().toLocaleDateString()}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "16px 24px",
              textAlign: "center",
              borderBottom: "1px solid #E3EBEC",
              margin: "0 1rem",
            }}
          >
            <div
              style={{
                width: "25%",
                fontWeight: "bold",
              }}
            >
              <h2>CLIENT NAME</h2>
            </div>
            <div style={{ width: "25%", fontWeight: "bold" }}>
              <h2>DATE CREATED</h2>
            </div>
            <div style={{ width: "25%", fontWeight: "bold" }}>
              <h2>STATUS</h2>
            </div>
            <div style={{ width: "25%", fontWeight: "bold" }}>
              <h2>PROVIDER</h2>
            </div>
          </div>
          <div style={{ padding: "16px 24px" }}>
            {loading === "true" ? (
              ""
            ) : (
              <>
                {responseData
                  .sort((a, b) =>
                    a.client.name
                      .toUpperCase()
                      .localeCompare(b.client.name.toUpperCase())
                  )
                  .map((client, index) => (
                    <div
                      key={index}
                      value={client}
                      style={{ margin: "0 1rem" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          borderBottom: "1px solid #E3EBEC",
                          padding: "5px 0",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            width: "25%",
                            color: "#000",
                          }}
                        >
                          {index + 1}.&nbsp;
                          {!client ? "" : client.client.name}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "25%",
                            color: "#000",
                          }}
                        >
                          {!client
                            ? ""
                            : new Date(client.created_at).toLocaleDateString(
                                "en-US",
                                {
                                  timeZone: "UTC",
                                }
                              )}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "25%",
                            color: "#000",
                          }}
                        >
                          {!client ? "" : client.case.status}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "25%",
                            color: "#000",
                          }}
                        >
                          {!client ? "" : client.provider.name}
                        </span>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
