import { Delete, Edit, ListAlt, MoreVert } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useState } from "react";
import DataContext from "../../../../../context/DataContext";

export default function OptionsMenu({
  handleEditAction,
  handleDetailAction,
  handleDeleteAction,
  action,
}) {
  const { caseManagerPk, userRoles } = useContext(DataContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="More Options" placement="right">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {" "}
        <MenuItem onClick={handleClose}>
          {" "}
          <Tooltip title="Action Item Detail" placement="right">
            <IconButton onClick={() => handleDetailAction(action)}>
              <ListAlt className="text-[18px]" />
            </IconButton>
          </Tooltip>
        </MenuItem>
        {caseManagerPk === action.created_by.pk ||
        userRoles.permissions.includes("casemanager.change_actionableitems") ? (
          <MenuItem onClick={handleClose}>
            {" "}
            <Tooltip
              placement="right"
              title={
                !action
                  ? false
                  : action.status === "done"
                  ? "Mark item incomplete to edit"
                  : "Edit Action Item"
              }
            >
              <span>
                <IconButton
                  className={`${
                    !action
                      ? "text-orange-400 hover:bg-orange-400 hover:text-white"
                      : action.status === "done"
                      ? ""
                      : "text-orange-400 hover:bg-orange-400 hover:text-white"
                  }`}
                  onClick={() => handleEditAction(action)}
                  disabled={
                    !action ? false : action.status === "done" ? true : false
                  }
                >
                  <Edit className="text-[18px]" />
                </IconButton>
              </span>
            </Tooltip>
          </MenuItem>
        ) : (
          ""
        )}
        {caseManagerPk === action.created_by.pk ||
        userRoles.permissions.includes("casemanager.delete_actionableitems") ? (
          <MenuItem onClick={handleClose}>
            {" "}
            <Tooltip
              placement="right"
              title={
                !action
                  ? false
                  : action.status === "done"
                  ? "Mark item incomplete to delete"
                  : "Delete Action Item"
              }
            >
              <span>
                <IconButton
                  className={`${
                    !action
                      ? "text-red-400 hover:bg-red-400 hover:text-white"
                      : action.status === "done"
                      ? ""
                      : "text-red-400 hover:bg-red-400 hover:text-white"
                  }`}
                  onClick={() => handleDeleteAction(action)}
                  disabled={
                    !action ? false : action.status === "done" ? true : false
                  }
                >
                  <Delete className="text-[18px]" />
                </IconButton>
              </span>
            </Tooltip>
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
    </div>
  );
}
