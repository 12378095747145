import { List, ListItem } from "@mui/material";

export default function ProviderListView({ providerList }) {
  return (
    <div id="Provider-List-View" data-testid="Provider-List-View">
      {providerList && providerList.length > 0 ?  (
        <List 
          id="Provider-List" 
          data-testid="Provider-List"
          sx = {{
            listStyleType: 'number',
            pl: 4,
            '& .MuiListItem-root': {
             display: 'list-item',
            },
           }}
           >
          {providerList.map((provider) => (
            <ListItem 
              className="provider-list-item" 
              key={provider.id}
              sx={{pl: 0}}>
              {provider.entity.name}
            </ListItem>
          ))}
        </List>
      )
    :
    <p>No Providers Relationships Found</p>
    }
    </div>
  );
}
