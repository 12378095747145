import { CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingIndicator({ isLoading }) {
  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            background: "#FFF",
            padding: "12px 10px 8px",
            boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
            zIndex: "100",
            borderRadius: "5px",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
