export const initialState = {
  loading: false,
  errorArray: [],
  callLogs: null,
  logSearch:"",
  resLimit:25,
  currentPage:1,
  paginationUrl:`limit=25&`,
  resultCount:{ total: "", current: "" }
};
