import ApiServices from "../service";



export const case_detail = {

  patch:(casePk, params)=>`/api/case/${!casePk ? params.id : casePk}/`,

  getCaseByClientId: (id, accessToken) =>
  ApiServices.getData(`/api/case/?client=${id}&simplified=true`, accessToken)
    .then((res) => res)
    .then((res) => res)
    .catch(err => console.log(err)),
}

export const case_fee_schedule = {
  retrieve: (id, accessToken) =>
  ApiServices.getData(`/api/case/${id}/fee_schedule/`, accessToken)
    .then((res) => res)
    .then((res) => res)
    .catch(err => console.log(err)),
}
