import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import ResponseModal from "../../../../../../../global/ResponseModal";

export default function AddCheck({
  setOpenAddCheck,
  caseDetail,
  trigger,
  setTrigger,
}) {
  const { accessToken } = useContext(DataContext);
  const [checkNumber, setCheckNumber] = useState("");
  const [checkAmount, setCheckAmount] = useState("");
  const [checkDate, setCheckDate] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [confirmation, setConfirmation] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleSubmitCheck = () => {
    const postData = {
      check_number: checkNumber,
      amount: checkAmount,
      check_date: new Date(checkDate).toLocaleDateString("en-CA"),
      check_type: 1,
      case: caseDetail.pk,
      law_firm: caseDetail.law_firm,
    };

    axios
      .post("/api/settlement_checks/", postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Law Firm Check Recieved");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setTrigger(!trigger);
    setOpenAddCheck(false);
  };

  return !caseDetail ? (
    ""
  ) : (
    <div className="min-w-[300px] max-w-[500px]">
      <div>
        <List>
          <ListItem className="p-0 justify-center">
            <strong>Law Firm Name:</strong>&nbsp;{caseDetail.law_firm_name}
          </ListItem>
          {activeStep === 1 ? (
            <ListItem className="p-0 justify-center">
              <strong>Medical Amount:</strong>&nbsp;
              {formatter.format(caseDetail.medical_amount)}
            </ListItem>
          ) : (
            ""
          )}
          <ListItem className="p-0 justify-center">
            <strong>Settlement Amount:</strong>&nbsp;
            {formatter.format(caseDetail.actual_total_settlement_amount)}
          </ListItem>
        </List>
        <div>
          <div>
            <FormControl
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
              className="flex"
              disabled={activeStep === 2 ? true : false}
            >
              <InputLabel htmlFor="check-number">Check Number</InputLabel>
              <Input
                id="check-number"
                value={checkNumber}
                inputProps={{ min: 0 }}
                type="number"
                onChange={(e) =>
                  setCheckNumber(e.target.value.replace("-", ""))
                }
                startAdornment={
                  <InputAdornment position="start">
                    {" "}
                    <i
                      className={`fa-solid fa-money-check-dollar text-black`}
                    ></i>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
              className="flex"
              disabled={activeStep === 2 ? true : false}
            >
              <InputLabel htmlFor="check-number">Check Amount</InputLabel>
              <Input
                id="check-amount"
                value={checkAmount}
                type="number"
                onChange={(e) =>
                  setCheckAmount(e.target.value.replace("-", ""))
                }
                inputProps={{ min: 0 }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="w-full ml-1 my-2 text-center"
                label="Check Date"
                value={checkDate}
                onChange={(newValue) => {
                  setCheckDate(newValue);
                }}
                slotProps={{ textField: { variant: "outlined" } }}
                disabled={activeStep === 2 ? true : false}
              />
            </LocalizationProvider>
          </div>
          {activeStep === 1 ? (
            <Button
              onClick={() => setActiveStep(2)}
              variant="contained"
              className="block mx-auto"
              disabled={checkDate && checkAmount && checkNumber ? false : true}
            >
              NEXT
            </Button>
          ) : (
            ""
          )}
        </div>
        {activeStep === 2 ? (
          <div>
            <FormControlLabel
              className="my-2 mx-auto"
              name="confirmation"
              control={<Checkbox color="success" checked={confirmation} />}
              onChange={(e) => setConfirmation(e.target.checked)}
              label="By checking this box, I confirm that the check information provided is accurate. Furthermore, any changes to this information require approval from NetPro administration."
            />
            <div className="flex justify-center mt-2">
              <Button
                onClick={() => {
                  setActiveStep(1);
                  setConfirmation(false);
                }}
                variant="contained"
                className="mx-1 bg-orange-500"
              >
                PREVIOUS
              </Button>
              <Button
                variant="contained"
                className="mx-1"
                disabled={!confirmation ? true : false}
                onClick={() => handleSubmitCheck()}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
