import {
  CURRENT_PAGE_SET,
  CURRENT_PAGE_URL_SET,
  REFRESH_USERS_LIST,
  RESULT_COUNT_SET,
  SEARCH,
  SET_FILTER_DATA,
  SET_SELECTED_USER,
  SHOW_COMPONENT_SET,
  SHOW_NEW_USER_FORM,
} from "./actions";

export const initialState = {
  errorArray: [],
  searchLoading: false,
  search: null,
  searchResults: [],
  searchError: null,
  showComponent: "",
  showNewUserForm: false,
  isRefreshUsersList: false,
  selectedUser: null,
  resLimit:25,
  currentPage:1,
  paginationUrl: `limit=25&`,
  resultCount: { total: "", current: "" },
  filters: {
    is_active: null,
    last_login__lte: null,
    last_login__gte: null,
    password_updated_at__lte: null,
    password_updated_at__gte: null,
    managedBy: null,
  },
};
export function upmReducer(state, action) {
  switch (action.type) {
    case CURRENT_PAGE_SET: {
      return { ...state, currentPage: action.payload };
    }
    case CURRENT_PAGE_URL_SET: {
      return { ...state, paginationUrl: action.payload };
    }
    case RESULT_COUNT_SET: {
      return { ...state, resultCount: action.payload };
    }
    case SEARCH: {
      return { ...state, search: action.payload };
    }
    case SHOW_NEW_USER_FORM: {
      return { ...state, showNewUserForm: action.payload };
    }
    case SHOW_COMPONENT_SET: {
      return { ...state, showComponent: action.payload };
    }
    case REFRESH_USERS_LIST: {
      return { ...state, isRefreshUsersList: action.payload };
    }
    case SET_SELECTED_USER: {
      return { ...state, selectedUser: action.payload };
    }
    case SET_FILTER_DATA: {
      return {
        ...state, filters: { 
          ...state.filters,  [action.payload.filterName]:action.payload.data 
        },
      };
    }
    default:
      return state;
  }
}
