export default function Columns({
  log,
  colstyle,
  callDirectionIcon,
  getFormattedDate,
  getFormattedTime,
  formatted,
  fallbackString,
}) {
  return (
    <div className={colstyle.inner}>
      <div className={colstyle.column1} id="direction" data-testid="direction">
        {callDirectionIcon(log.voice_system_direction)}
      </div>
      <div
        className={colstyle.column2}
        id="caller_userName"
        data-testid="caller_userName"
      >
        {log.caller && log.caller?.users && log.caller?.users[0]?.user_name ? (
          <div>
            {log.caller?.users[0].user_name}
            {/* {log.caller?.users[0]?.is_client &&
              (!isLoading ? (
                <Tooltip title="Open Case Details">
                  <IconButton
                    onClick={() => navigateToDetail(log.caller?.users[0].user)}
                    className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
                  >
                    <Launch />
                  </IconButton>
                </Tooltip>
              ) : (
                <CircularProgress color="secondary" />
              ))} */}
          </div>
        ) : (
          fallbackString
        )}
      </div>
      <div
        className={colstyle.column3}
        id="receiver_userName"
        data-testid="receiver_userName"
      >
        {log.receiver &&
        log.receiver?.users &&
        log.receiver?.users[0]?.user_name ? (
          <div>
            {log.receiver.users[0].user_name}
            {/* {log.receiver?.users[0]?.is_client &&
              (!isLoading ? (
                <Tooltip title="Open Case Details">
                  <IconButton
                    onClick={() =>
                      navigateToDetail(log.receiver?.users[0].user)
                    }
                    className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
                  >
                    <Launch />
                  </IconButton>
                </Tooltip>
              ) : (
                <CircularProgress color="secondary" />
              ))} */}
          </div>
        ) : (
          fallbackString
        )}
      </div>
      <div className={colstyle.column4} id="date" data-testid="date">
        <div className="date text-xs leading-6">
          {log.start_time ? getFormattedDate(log.start_time) : " "}
        </div>
        <div className="date text-sm leading-6" id="time" data-testid="time">
          {log.start_time ? getFormattedTime(log.start_time) : " "}
        </div>
      </div>
      <div className={colstyle.column5} id="duration" data-testid="duration">
        {log.duration ? formatted(log.duration) : " "}
      </div>
      <div className={colstyle.column6} id="outcome" data-testid="outcome">
        {log.outcome ?? " "}
      </div>
    </div>
  );
}
