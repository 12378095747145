import React, { useContext, useState } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import ResponseModal from "../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../global/ApiRequestErrorHandler";
import {
  CheckCircleOutline,
  Close,
  HighlightOff,
  Lock,
  Warning,
} from "@mui/icons-material";
import DataContext from "../../../../../context/DataContext";

export default function DeActivateUser({ selectedUser, submitForm, close }) {
  /**
   * DeActivateUser Component
   *  - Communicates with User Profile Manager.
   *  - Handles Form States, Form Data(User Entered and default values for fields) and API Requests
   *  -- Loading - Renders Loading when Form is busy/not ready to be rendered
   *  -- Form Init - Initiates and Renders Form when ready
   *  -- Form Submit - Gathers Data and Submits User to Backend
   *  -- Success - Shows SUCCESS Message upon Success Submission to API
   *  -- Error - Shows ERROR Message upon unsuccessful Submission to API
   */

  const { userRoles } = useContext(DataContext);
  const [toggleSuccessUI, setToggleSuccessUI] = useState(false);
  const [toggleErrorUI, setToggleErrorUI] = useState(false);
  const [errorArray, setAPIErrors] = useState([]);
  const [userEmailCompare, setUserEmailCompare] = useState("");

  const handleSuccessUI = () => setToggleSuccessUI(!toggleSuccessUI);

  const handleErrorUI = () => setToggleErrorUI(!toggleErrorUI);

  const handleSubmitForm = async (e) =>
    submitForm(e, null, selectedUser.id)
      .then((res) => {
        handleSuccessUI();
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response.data);
        setAPIErrors(errArr);
        handleErrorUI();
      });

  const unMountComponent = () => close();

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div>&nbsp;</div>
        <h2 className="font-bold text-[1.5rem] text-center">
          De-Activate{" "}
          <span className="text-red-600">{selectedUser.name}'s</span> User
          Account
        </h2>
        <IconButton onClick={(e) => unMountComponent(e)}>
          <Close />
        </IconButton>
      </div>
      <p className="text-center mx-auto w-full">
        Clicking below will remove this account from the NetProApp system.{" "}
        <span className="block font-bold my-1">
          <Warning className="text-red-600" /> This user will immediately lose
          access and the user information will not be accessible. If you need to
          unlock this account, please use the 'Help' form below to request
          assistance.
        </span>
      </p>
      <div className="my-2 w-full">
        <p className="text-center text-[14px] text-gray-600">
          Confirm user email to delete
        </p>
        <TextField
          className="w-full"
          placeholder={selectedUser.email}
          error={userEmailCompare !== selectedUser.email ? true : false}
          name="user-email"
          value={userEmailCompare}
          InputProps={{
            endAdornment:
              userEmailCompare !== selectedUser.email ? (
                <InputAdornment position="end" className="text-red-600">
                  <HighlightOff />
                </InputAdornment>
              ) : (
                <InputAdornment position="end" className="text-green-600">
                  <CheckCircleOutline />
                </InputAdornment>
              ),
          }}
          onChange={(e) => {
            setUserEmailCompare(e.target.value);
          }}
        />
      </div>
      <Button
        onClick={() => handleSubmitForm()}
        disabled={userEmailCompare !== selectedUser.email ? true : false}
        className="border-red-600 text-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white mx-auto flex"
        variant="outlined"
        endIcon={<Lock />}
      >
        Confirm account removal
      </Button>
      <ResponseModal
        title={"User deactivation successful"}
        openBool={toggleSuccessUI}
        setOpenBool={handleSuccessUI}
        handleCloseFunc={close}
      />
      <ResponseModal
        title={"User deactivation failed"}
        isError={true}
        openBool={toggleErrorUI}
        setOpenBool={handleErrorUI}
        errorMessage={errorArray}
      />
      {/* <div data-testid="DeActivate-User">
        <h1 className="text-2xl py-2 text-center text-purple-600 uppercase font-bold shadow-md">
          {STRINGS.TITLE}
        </h1>
        <p className="text-center py-3">
          <span className="uppercase font-bold">Important:</span>
          {STRINGS.SUBTITLE}
        </p>
        <Button
          onClick={(e) => unMountComponent(e)}
          className="hover:text-white hover:bg-sky-600 text-sky-600 rounded-md flex justify-center border-solid border-sky-600 border w-fit px-8 py-1 m-auto"
        >
          {STRINGS.CANCEL_BTN}
        </Button>
        <TextField
          className="w-full my-4 text-center"
          id="name"
          label={selectedUser.name}
          value={fieldData}
          variant="outlined"
          onChange={handleFieldOnChange}
          error={fieldData !== selectedUser.name ? true : false}
          helperText={
            fieldData !== selectedUser.name
              ? "You must type " +
                selectedUser.name +
                "as shown to delete user."
              : ""
          }
        />
        <Button
          onClick={(e) => handleSubmitForm(e)}
          className="hover:text-white hover:bg-sky-600 text-sky-600 rounded-md flex justify-center border-solid border-sky-600 border w-fit px-8 py-1 m-auto"
        >
          {STRINGS.DEACTIVATE_USER_BTN}
        </Button>
      </div>
      <ResponseModal
        title={STRINGS.SUCCESS}
        isError={true}
        openBool={toggleSuccessUI}
        setOpenBool={handleSuccessUI}
        handleCloseFunc={close}
      />
      <ResponseModal
        title={STRINGS.ERROR}
        isError={true}
        openBool={toggleErrorUI}
        setOpenBool={handleErrorUI}
        errorMessage={errorArray}
      /> */}
    </div>
  );
}
