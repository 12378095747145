import { Cancel, CheckCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import HtmlTooltip from "../../../../../../global/HtmlTooltip";

export default function ReceiveCheckButton({ display, checkReceived }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return display ? (
    <div className="w-[50px]">
      {/* <HtmlTooltip
        title={
          checkReceived ? (
            <div className="w-fit">
              <h2 className="text-[14px] text-center font-bold text-sky-600">
                Recent Payment
              </h2>
              <div className="px-4 text-center pb-2">
                <p className="text-black">
                  <strong>Payment #:</strong> {checkReceived.check_number}
                </p>
                <p>
                  <strong>Amount:</strong>{" "}
                  {formatter.format(checkReceived.amount)}
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {new Date(checkReceived.check_date).toLocaleDateString(
                    "en-US",
                    {
                      timeZone: "UTC",
                    }
                  )}
                </p>
              </div>
            </div>
          ) : (
            "No Checks Found"
          )
        }
        placement="right"
      > */}{" "}
      <IconButton disabled={true}>
        <i
          className={`fa-solid fa-money-check-dollar ${
            checkReceived ? "text-grey" : "text-black"
          }`}
        ></i>
        {checkReceived ? (
          <CheckCircle className="text-green-600" />
        ) : (
          <Cancel className="text-red-600" />
        )}
      </IconButton>
      {/* </HtmlTooltip> */}
    </div>
  ) : (
    ""
  );
}
