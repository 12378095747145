export const filterFieldData = (data, field) => {
  let filtered;
  filtered = data.filter((item) => item.field_name === field);
  return filtered;
};

export const createDisplayObj = (data, field) => {
  let newObj = {};
  for (const item in data) {
    if (data[item].field_name === field) {
      newObj[data[item].key] = data[item].label;
    }
  }
  return newObj;
};

export const createActiveObj = (data, compData, valKey) => {
  let newObj;

  for (const item in data) {
    if (compData[valKey] === data[item].key) {
      newObj = data[item];
    }
  }
  return newObj;
};
