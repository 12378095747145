import React from "react";
import { List, ListItemButton } from "@mui/material";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import Groups2Icon from "@mui/icons-material/Groups2";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

export default function ProviderPrint({
  componentRef,
  providerInfo,
  clients,
  address,
}) {
  return (
    <div>
      <div
        ref={componentRef}
        style={{ width: "100%", height: window.innerHeight }}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                margin: "1rem 0",
              }}
            >
              {providerInfo.name}
            </h1>
            <div
              style={{
                border: "2px solid #9333EA",
                borderRadius: "5px",
                margin: "1rem auto",
                width: "90%",
                padding: "1rem",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "1rem" }}>
                {providerInfo.in_network === true ? (
                  <span style={{ color: "#9333EA" }}>In-Network</span>
                ) : (
                  <span style={{ color: "red" }}>Non Network</span>
                )}{" "}
                - {providerInfo.type}
              </div>
              {/* <div className="mt-8">{String(providerInfo.address)=== null ? "" : String(providerInfo.address.raw)}</div> */}
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <span style={{ marginRight: "5px" }}>
                  <PhoneIphoneOutlinedIcon />
                </span>
                {providerInfo.phone}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <span style={{ marginRight: "5px" }}>
                  <MailOutlinedIcon />
                </span>
                {providerInfo.email === null
                  ? "No Associated Email"
                  : providerInfo.email}
              </div>
              <div style={{ marginBottom: "10px" }}>
                {clients.length < 1 ? (
                  "No Clients"
                ) : (
                  <h3>
                    <span style={{ marginRight: "5px" }}>
                      <Groups2Icon />
                    </span>
                    Client Count: {clients.length}
                  </h3>
                )}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <span style={{ marginRight: "5px" }}>
                  <RoomOutlinedIcon />
                </span>
                {address === null ? "No Address" : address}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            textAlign: "center",
            borderBottom: "1px solid #E5E7EB",
            padding: "1rem 1rem 5px",
            width: "90%",
            margin: "0 auto 1rem",
          }}
        >
          Client List
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
            margin: "5px auto",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <h3
            style={{
              borderBottom: "1px solid #9333EA",
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            Client Name
          </h3>
          <h3
            style={{
              borderBottom: "1px solid #9333EA",
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            Treatment Status
          </h3>
          <h3
            style={{
              borderBottom: "1px solid #9333EA",
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            Med-Docs Status
          </h3>
          <h3
            style={{
              borderBottom: "1px solid #9333EA",
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            Date Started
          </h3>
          <h3
            style={{
              borderBottom: "1px solid #9333EA",
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            Date Updated
          </h3>
        </div>
        <List>
          {clients.map((client, idx) => {
            return (
              <ListItemButton
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
                  margin: "0 0 5px 0",
                  textAlign: "center",
                }}
                key={idx}
              >
                <h3 style={{ textAlign: "center", padding: "0 10px 0 0" }}>
                  {idx + 1}.
                  <span style={{ paddingLeft: "5px" }}>
                    {client.client_name}
                  </span>
                </h3>
                <h3>
                  {client.finished_treatment === null ? (
                    <span style={{ color: "red" }}>Treating</span>
                  ) : client.finished_treatment === false ? (
                    <span style={{ color: "orange" }}>Treating</span>
                  ) : (
                    <span style={{ color: "green" }}>Complete</span>
                  )}
                </h3>
                <h3 className="text-center">
                  {client.medical_documents_recieved === null ? (
                    <span style={{ color: "red" }}>Not Requested</span>
                  ) : client.medical_documents_recieved === false ? (
                    <span style={{ color: "orange" }}>Waiting on Docs</span>
                  ) : (
                    <span style={{ color: "green" }}>Received</span>
                  )}
                </h3>
                <h3 className="text-center">
                  {String(client.created_at).slice(0, 10)}
                </h3>
                <h3 className="text-center">
                  {String(client.updated_at).slice(0, 10)}
                </h3>
              </ListItemButton>
            );
          })}
        </List>
      </div>
    </div>
  );
}
