import { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./context/AuthProvider";
import Cookies from "js-cookie";
import axios from "./components/api/axios";
import { NetworkBackground } from "./components/NetworkBackground";
import DataContext from "./context/DataContext";
import { Button, Dialog, TextField } from "@mui/material";
import PasswordCreate from "./components/cm/Dashboard/FullPages/PasswordCreate";
import ResponseModal from "./components/global/ResponseModal";
import CloseIcon from "@mui/icons-material/Close";
import ModalButton from "./components/global/ModalButton";
import { addDays } from "date-fns";
import FormSubmitButton from "./components/global/FormComponents/FormSubmitButton";

const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const {
    getCaseManagerName,
    setUserRoles,
    setCaseManagerPk,
    setUserType,
    getAccessToken,
    errorMessage,
    setErrorMessage,
  } = useContext(DataContext);
  const userRef = useRef();
  const errRef = useRef();

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [needsReset, setNeedsReset] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [passResetForgot, setPassResetForgot] = useState(false);
  const [resetEmailDialog, setResetEmailDialog] = useState(false);
  const [userResponse, setUserResponse] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  // const [errorMessage, setErrorMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    if (!Cookies.get("pnAccessToken")) {
      return;
    } else {
      navigate("/dashboard");
    }
  }, [navigate]);

  const countLoginAttempts = () => {
    setLoginAttempts(loginAttempts + 1);
    if (loginAttempts > -1) {
      setShowPasswordReset(true);
    }
  };

  const resetPasswordTrigger = () => {
    const userInfo = { email: email };

    axios
      .post(`/api/password_reset/`, userInfo, {
        "Content-Type": "application/json",
      })
      .then(() => {
        setEmail("");
        setShowPasswordReset(false);
        setResetEmailDialog(true);
        setPassResetForgot(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setErrorMessage(errArr);
      });
  };

  const search = window.location.search;
  const url_params = new URLSearchParams(search);
  const auth_token = url_params.get("auth_token");

  let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=600`;

  const handle_user_token = async (token, reset) => {
    axios
      .get("/api/users/me/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setUserResponse(res.data);
        setToken(token);
        if (reset) {
          return;
        } else {
          setCookies(res.data, token);
          navigate("/dashboard");
        }
      });
  };

  const setCookies = (meResponse, token) => {
    Cookies.set("pnAccessToken", JSON.stringify(token));
    Cookies.set("token_exp", addDays(new Date(), 1).toISOString());
    Cookies.set("pnCmPk", meResponse.pk);
    Cookies.set(
      "userType",
      !meResponse.entity ? "unknown" : meResponse.entity.entity_type_label
    );
    getAccessToken(token);
    getCaseManagerName(meResponse.name);
    setCaseManagerPk(meResponse.pk);
    setAuth({ username, password });
    setUserRoles({
      groups: meResponse.groups,
      permissions: meResponse.permissions,
    });
    setUserType(
      !meResponse.entity ? "unknown" : meResponse.entity.entity_type_label
    );
  };

  if (auth_token) {
    handle_user_token(auth_token, false).catch(() => {
      setErrorMessage("Token error, unable to log in as user");
      console.log("Token Error");
    });
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    axios
      .post(
        `/auth/login`,
        { username, password },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        Cookies.set("token_exp", response.data.expiry);
        setNeedsReset(response.data.requires_password_change);
        handle_user_token(
          response.data.token,
          response.data.requires_password_change
        );
        setLoading(false);
      })
      .catch(function (err) {
        setLoading(false);
        if (err.response) {
          countLoginAttempts();
          if (!err.response) {
            //*Add custom message when there’s more than 1 token (display error message - possibly just stop custom setting these messages and use what the server sends)
            setErrorMessage("No Server Response");
          } else if (err.response.status === 400) {
            setErrorMessage("Unable to log in with provided credentials");
          } else if (err.response.status === 401) {
            setErrorMessage("Unauthorized");
          } else if (
            err.response.status === 403 &&
            !err.response.data.non_field_errors
          ) {
            setErrorMessage("Error Logging In");
          } else {
            setErrorMessage("Login Failed");
          }
        }
      });
  };

  return (
    <div className="w-screen h-screen grid content-center justify-center">
      <section className="h-screen w-screen grid text-center place-items-center">
        <NetworkBackground />
        <div className="bg-gradient-to-b from-[#7128d9] to-transparent px-5 pt-24 rounded-md">
          <h1 className=" text-5xl text-gray-300 font-bold outline-2 outline-black">
            <img
              src="/assets/NetProApp_white_nobg.png"
              alt=""
              className="w-1/2 block mx-auto my-2"
            />
          </h1>
        </div>
        {needsReset ? (
          <PasswordCreate
            userResponse={userResponse}
            setCookies={setCookies}
            token={token}
          />
        ) : (
          <>
            <p
              ref={errRef}
              className={`text-red-600 text-2xl  ${
                errorMessage ? "errmsg" : "offscreen"
              }`}
              aria-live="assertive"
            >
              {errorMessage}
            </p>
            <form className="place-items-center sm:w-1/2 w-[95%] sm:mx-0 mb-[300px] l:mb-56 pt-10 rounded-xl grid grid-rows-3 border 2 bg-gray-200 shadow-lg">
              {" "}
              <TextField
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setusername(e.target.value)}
                label="Username"
                value={username}
                required
                className="w-4/5 my-4 text-center"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                type="password"
                id="password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
                label="Password"
                required
                className="w-4/5 my-4 text-center"
                InputLabelProps={{ shrink: true }}
              />
              <FormSubmitButton
                isLoading={loading}
                handleSubmit={handleSubmit}
                buttonText="Sign In"
                buttonColor="purple"
                buttonId="login-submit"
              />
              {showPasswordReset ? (
                <div className="pb-10">
                  <Button
                    onClick={() => setPassResetForgot(true)}
                    className="text-purple-600 hover:text-white hover:bg-purple-600"
                  >
                    Forgot password? Click here to reset
                  </Button>
                </div>
              ) : (
                ""
              )}
            </form>
          </>
        )}
        <div className="absolute bottom-[50px]">
          <Button
            className="text-white font-bold normal-case hover:bg-transparent hover:underline"
            onClick={() =>
              window.open(
                "https://api.netproapp.com/privacy",
                "Privacy Policy",
                params
              )
            }
          >
            Privacy Policy
          </Button>
        </div>
      </section>
      <Dialog open={passResetForgot}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            <Button onClick={() => setPassResetForgot(false)}>
              <CloseIcon />
            </Button>
          </div>
          <h1
            style={{
              fontSize: 20,
              color: "orange",
              margin: "0 0 1rem 0",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Send Reset Password Email
          </h1>
          <TextField
            style={{
              width: "100%",
              margin: "1rem 0",
            }}
            className="w-full my-4 text-center"
            placeholder="Account Email" //
            type="text"
            autoComplete="off"
            label="Email"
            value={email}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div style={{ margin: "0 0 2rem 0" }}>
          <ModalButton
            action={resetPasswordTrigger}
            baseColor={"#FFA500"}
            content={"Send"}
          />
        </div>
      </Dialog>
      <ResponseModal
        title="Success"
        description="Please check your email for a link to reset your password!"
        openBool={resetEmailDialog}
        setOpenBool={setResetEmailDialog}
      />
      {!isError ? (
        ""
      ) : (
        <ResponseModal
          title="Password update failed"
          isError={true}
          openBool={isError}
          setOpenBool={setIsError}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default Login;
