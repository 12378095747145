import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material/";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import SearchContext from "../../../../context/SearchContext";
import axios from "../../../api/axios";
import PaginationControls from "../../../global/PaginationControls";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";

export default function LawfirmSortLiability({ isDashboardVersion }) {
  const { accessToken } = useContext(DataContext);
  const { sortByLiabilityVal, setSortByLiabilityVal } =
    useContext(SearchContext);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [liabilityStatusChoices, setLiabilityStatusChoices] = useState([]);

  const navigate = useNavigate();

  const navigateToDetail = (client) => {
    navigate(`/client-detail/${client.pk}`);
  };

  useEffect(() => {
    axios
      .get(
        `/api/field_options/?content_type=case&ordering=order&field_name=liability_status`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((response) => {
        setLiabilityStatusChoices(response.data.results);
      });
  }, [setLiabilityStatusChoices, accessToken]);

  const sendRequest = () => {
    axios
      .get(
        `/api/case/?${
          paginationUrl +
          qs.stringify(
            {
              liability_status: !sortByLiabilityVal ? null : sortByLiabilityVal,
              archived: false,
              lost__isnull: true,
              settled: false,
              search: !search ? null : search,
            },
            {
              skipNull: true,
            }
          )
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => sendRequest(),
    [accessToken, search, sortByLiabilityVal, paginationUrl, setPaginationUrl],
    250
  );

  useDebouncedEffect(
    () => {
      setCurrentPage(1);
      setPaginationUrl(``);
    },
    [search, sortByLiabilityVal],
    250
  );

  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:last-of-type:border-0 sm:p-2 px-2 py-2 sm:border-b-0 self-center";

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`header-wrapper px-4 flex ${
          isDashboardVersion
            ? "max-h-[85vh] overflow-auto"
            : "h-full overflow-hidden"
        }`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div className="w-full md:ml-4">
          <div className="flex justify-center mt-4">
            <Accordion className="w-full mr-2 relative z-10 bg-white">
              <AccordionSummary
                className="text-sm"
                expandIcon={
                  <div className="flex items-center">
                    <ExpandMore />
                  </div>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>Liability Status</div>
                <div>
                  {!sortByLiabilityVal ? (
                    ""
                  ) : (
                    <div>
                      &mdash;
                      <span className="text-xs text-sky-600">
                        {sortByLiabilityVal}
                      </span>
                    </div>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails className="absolute bg-white shadow-md">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    className="text-sm"
                    defaultValue={null}
                    name="radio-buttons-group"
                    value={sortByLiabilityVal}
                    onChange={(e) => setSortByLiabilityVal(e.target.value)}
                  >
                    {!liabilityStatusChoices
                      ? ""
                      : liabilityStatusChoices.map((val, idx) => (
                          <FormControlLabel
                            key={idx}
                            value={val.key}
                            control={<Radio />}
                            label={val.label}
                            className="text-sm"
                          />
                        ))}
                  </RadioGroup>
                </FormControl>
                <Button
                  onClick={() => setSortByLiabilityVal("")}
                  variant="outlined"
                  className="text-sm text-red-600 border-red-600 hover:bg-red-600 hover:text-white"
                >
                  Clear Filter
                </Button>
              </AccordionDetails>
            </Accordion>
            <Tooltip title="Search by Name">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm"
                placeholder="Search"
                size="medium"
                name="search"
                value={search}
                InputLabelProps={{ shrink: true }}
                label="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
            </Tooltip>
          </div>
          <div className="h-full overflow-hidden">
            <div className="flex justify-between my-2">
              <p className="md:pl-5 py-4">
                Showing: {resultCount.current} / {resultCount.total}
              </p>
            </div>{" "}
            <div className="flex md:px-4 px-2 text-center py-2 border-b border-1 border-gray md:justify-start justify-between">
              <div className="md:w-1/4 font-bold">
                <h2>CLIENT NAME</h2>
              </div>
              <div className="w-1/4 w-1/3 font-bold md:block hidden">
                <h2>CASE NAME</h2>
              </div>
              <div className="md:w-1/4 w-1/3 font-bold">
                <h2>DATE CREATED</h2>
              </div>
              <div className="md:w-1/4 w-1/3 font-bold">
                <h2>STATUS</h2>
              </div>
            </div>
            <List
              className={`${
                isDashboardVersion ? `h-[65vh]` : ""
              }  overflow-y-auto max-h-[100%]`}
            >
              {loading ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {!responseData
                    ? ""
                    : responseData.map((client, index) => (
                        <ListItem
                          key={index}
                          value={client}
                          className="py-0 pt-0 md:px-4 px-0"
                        >
                          <Button
                            onClick={() => navigateToDetail(client)}
                            className={
                              "text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y hover:bg-purple-600 hover:text-white justify-between"
                            }
                          >
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-left ${resultClasses}`}
                            >
                              {currentPage > 1
                                ? index + 1 * ((currentPage - 1) * 25) + 1
                                : index + 1}
                              .&nbsp;
                              {!client ? "" : client.client.name}
                            </span>
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-center ${resultClasses} md:block hidden`}
                            >
                              {!client ? "" : client.name}
                            </span>
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-center ${resultClasses}`}
                            >
                              {!client.created_at
                                ? ""
                                : new Date(
                                    client.created_at
                                  ).toLocaleDateString("en-US", {
                                    timeZone: "UTC",
                                  })}
                            </span>
                            <span
                              className={`block w-1/4 text-left md:pl-2 text-center ${resultClasses} border-none`}
                            >
                              {!client ? "" : client.status}
                            </span>
                          </Button>
                        </ListItem>
                      ))}
                </>
              )}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}
