import React from "react";

export default function Error({ errorArray }) {
  return (
    <div data-testid="error-message">
      {errorArray.map((issue, index) => {
        if (
          issue === "" ||
          issue === " " ||
          issue === null ||
          issue === undefined
        ) {
          return <div>Error. Please Try Again.</div>;
        }
        return (
          <div key={index} value={issue}>
            {issue}
          </div>
        );
      })}
    </div>
  );
}
