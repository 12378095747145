import { Check, Close, Delete } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import ResponseModal from "../../ResponseModal";
import ModalShell from "../../ModalShell";
import PermissionWrapper from "../../PermissionWrapper";

export default function InlineEdit({
  index,
  activeWindow,
  provider,
  trigger,
  setTrigger,
  apiUrl,
  emailTypeOptions,
  handleCancelEdit,
  removeFormFields,
  typeDisplayObj,
  providerTypesActive,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailType, setEmailType] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeWindow) {
      setEmail(activeWindow.email);
      setEmailType(activeWindow.email_type);
    }
  }, [activeWindow]);

  const postNewEmail = () => {
    const postData = {
      provider: provider.pk,
      email_type: emailType,
      email: email,
    };
    axios
      .post(apiUrl, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown(`Provider Email Added`);
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const patchExistingEmail = () => {
    const patchData = {
      email_type: emailType,
      email: email,
    };

    axios
      .patch(`${apiUrl}${activeWindow.id}/`, patchData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Provider Email Updated");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const deleteExistingEmail = () => {
    axios
      .delete(`${apiUrl}${activeWindow.id}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Provider Email Deleted");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setLoading(false);
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setTrigger(!trigger);
    setConfirmDelete(false);
    handleCancelEdit();
  };

  const closeNewResModal = () => {
    if (isError) {
      setLoading(false);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
    } else {
      setLoading(false);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
      clearFormValState(index);
      handleCancelEdit();
      setTrigger(!trigger);
    }
  };

  const clearFormValState = (index) => {
    setEmail("");
    setEmailType("");
    removeFormFields(index);
  };

  return (
    <div className="flex items-center w-full my-2">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="flex w-full items-center">
          <FormControl className="md:w-1/2 w-full" size="small">
            <InputLabel id="email-type-label">Email Types</InputLabel>
            <Select
              labelId="email-type-label"
              id="email-type"
              value={emailType}
              label="Email Type"
              onChange={(e) => {
                setEmailType(e.target.value);
              }}
            >
              {!emailTypeOptions || emailTypeOptions.length === 0
                ? ""
                : emailTypeOptions.map((email, idx) => {
                    return (
                      <MenuItem
                        value={email.key}
                        key={idx}
                        disabled={
                          activeWindow &&
                          activeWindow.email_type ===
                            providerTypesActive[email.key]
                            ? false
                            : providerTypesActive[email.key] === email.key
                            ? true
                            : false
                        }
                      >
                        {email.label}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
          <TextField
            size="small"
            label="Email"
            value={email}
            className="w-full mx-auto text-black ml-2"
            onChange={(e) => setEmail(e.target.value)}
          />{" "}
        </div>
      </LocalizationProvider>
      <Tooltip title="Submit/Update Email">
        <IconButton
          onClick={() => {
            !activeWindow ? postNewEmail() : patchExistingEmail();
          }}
          className="text-green-600"
          disabled={loading}
        >
          <Check />
        </IconButton>
      </Tooltip>
      <PermissionWrapper
        permission={
          userRoles.permissions.includes("provider.delete_provideremail") &&
          activeWindow
        }
      >
        {" "}
        <Tooltip title="Delete email">
          <IconButton
            onClick={() => setConfirmDelete(true)}
            className="text-red-600"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </PermissionWrapper>

      <Tooltip title="Cancel">
        <IconButton
          onClick={() =>
            !activeWindow ? clearFormValState(index) : handleCancelEdit()
          }
        >
          <Close />
        </IconButton>
      </Tooltip>

      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={!activeWindow ? closeNewResModal : closeResModal}
      />
      {!activeWindow ? (
        ""
      ) : (
        <ModalShell
          open={confirmDelete}
          setOpen={setConfirmDelete}
          color={"red"}
          title="Confirm email removal"
        >
          <div>
            <p className="text-center">
              Are you sure you want to remove this email?
            </p>
            <p className="text-center font-bold">
              <span className="font-bold">
                {typeDisplayObj[activeWindow.email_type]}
              </span>
              : {activeWindow.email}{" "}
            </p>
            <div className="w-fit mx-auto my-2">
              {" "}
              <Button
                onClick={() => deleteExistingEmail()}
                endIcon={<Delete />}
                variant="outlined"
                className="text-red-600 border-red-600"
                disabled={loading}
              >
                Confirm Delete
              </Button>
            </div>
          </div>
        </ModalShell>
      )}
    </div>
  );
}
