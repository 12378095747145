import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material/";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AddUserStateContext } from "../context/AddUserStateContext";
import { AddUserDispatchContext } from "../context/AddUserDispatchContext";
import {
  setFieldData,
  setSendEmail,
  setPermissionSets,
} from "../state/actions";
import SmartyAutoComplete from "../../../../../../global/SmartyAutoComplete";
import DataContext from "../../../../../../../context/DataContext";
import ProviderToLocation from "./ProviderToLocation";
import axios from "../../../../../../api/axios";
import FormSubmitButton from "../../../../../../global/FormComponents/FormSubmitButton";

export default function Form({
  onClickSubmit,
  permission_set_list,
  selectedLocations,
  selectedRole,
  setSelectedLocations,
  setSelectedRole,
  loading,
}) {
  const { accessToken, loggedInUser, userRoles } = useContext(DataContext);
  const state = useContext(AddUserStateContext);
  const dispatch = useContext(AddUserDispatchContext);
  const [address, setFieldAddress] = useState("");
  const [dob, setDOB] = useState(new Date());
  const [managedLocations, setManagedLocations] = useState([]);

  useEffect(() => {
    if (
      loggedInUser.entity &&
      loggedInUser.entity.entity_type_label === "Provider"
    ) {
      axios
        .get(`/api/provider/?limit=100&managed_by=${loggedInUser.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setManagedLocations(response.data.results);
        });
    }
  }, [accessToken, loggedInUser, setManagedLocations]);

  const handleFieldOnChange = async (e) => {
    if (e.target.name === "permission_sets") {
      setPermissionSets(dispatch, e.target.value);
    } else {
      setFieldData(dispatch, {
        fieldName: e.target.name,
        data: e.target.value,
      });
    }
  };

  const handleDateOnChange = (e) => {
    setDOB(new Date(e));
    setFieldData(dispatch, {
      fieldName: "date_of_birth",
      data: new Date(e).toLocaleDateString("en-CA"),
    });
  };

  const handleSetSendEmail = (e) => setSendEmail(dispatch, e.target.checked);

  const handleSubmitForm = (e) => {
    if (
      state.newUserForm.user.email.trim() !== "" ||
      state.newUserForm.user.name.trim() !== ""
    ) {
      onClickSubmit(e);
    } else {
      //TODO: Show Error Modal or Message
    }
  };

  const setField = (value) => {
    setFieldAddress(value);
    setFieldData(dispatch, {
      fieldName: "address",
      data: !value
        ? {
            latitude: 0,
            longitude: 0,
            raw: "",
          }
        : value,
    });
  };

  return (
    <div data-testid="Add-User-Form">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TextField
          data-testid="field-input-name"
          required
          className="w-full my-4 text-center"
          id="name"
          name="name"
          label="Full Name (First & Last)"
          value={state.newUserForm.user.name}
          variant="outlined"
          onChange={(e) => handleFieldOnChange(e)}
          error={state.newUserForm.user.name === "" ? true : false}
          helperText={
            state.newUserForm.user.name === ""
              ? "This field is required. Please Enter Your Full Name."
              : ""
          }
        />
        <TextField
          data-testid="field-input-email"
          required
          className="w-full my-4 text-center"
          id="email"
          name="email"
          label="Email"
          placeholder="ex. email@email.com"
          value={state.newUserForm.user.email}
          onChange={handleFieldOnChange}
          error={state.newUserForm.user.email === "" ? true : false}
          helperText={
            state.newUserForm.user.name === ""
              ? "This field is required. Please Enter Your Email."
              : ""
          }
        />
        <TextField
          data-testid="field-input-phone"
          className="w-full my-4 text-center"
          id="phone"
          name="phone"
          label="Phone"
          placeholder="ex. +14044444444"
          value={state.newUserForm.user.phone}
          onChange={handleFieldOnChange}
          required
        />
        <DatePicker
          data-testid="field-datepicker-dob"
          className="w-full my-4 text-center"
          label="Date of Birth"
          id="date_of_birth"
          name="date_of_birth"
          value={new Date(dob)}
          onChange={(newValue) => {
            handleDateOnChange(newValue);
          }}
          slotProps={{ textField: { variant: "outlined" } }}
        />
        <SmartyAutoComplete address={address} setAddress={setField} />
        <FormControl fullWidth required={true}>
          <InputLabel id="user-permission-label">User Permissions</InputLabel>
          <Select
            data-testid="field-select-user-perm"
            labelId="user-permission-label"
            id="user-permission"
            name="permission_sets"
            multiple
            label="User Permissions"
            value={state.newUserForm.permission_sets}
            onChange={handleFieldOnChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em></em>;
              }
              let displayNames = permission_set_list
                .filter((perm) => selected.includes(perm.group))
                .map((perm) => perm.name);
              return displayNames.join(", ");
            }}
          >
            {permission_set_list.filter((perm) => perm.shareable === true)
              .length === 0 || !permission_set_list ? (
              <MenuItem>No Results</MenuItem>
            ) : (
              permission_set_list
                .filter((perm) => perm.shareable === true)
                .map((permission) => (
                  <MenuItem key={permission.group} value={permission.group}>
                    {permission.name}
                  </MenuItem>
                ))
            )}
          </Select>
        </FormControl>
        {userRoles.permissions.includes(
          "users.add_usertoproviderassignments"
        ) && managedLocations.length > 0 ? (
          <ProviderToLocation
            managedLocations={managedLocations}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            setSelectedRole={setSelectedRole}
            selectedRole={selectedRole}
          />
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            data-testid="field-checkbox-sendEmail"
            name="send_email"
            label="Send Email with Login Details"
            control={
              <Checkbox
                color="success"
                checked={state.newUserForm.send_email}
                onChange={handleSetSendEmail}
              />
            }
          />
        </div>
        <FormSubmitButton
          buttonId="add-new-user"
          testId={"button-submit"}
          isLoading={loading}
          handleSubmit={(e) => handleSubmitForm(e)}
          buttonText="Add User"
          buttonColor="sky"
        />
      </LocalizationProvider>
    </div>
  );
}
