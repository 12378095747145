import React from "react";
import {
  // Print,
  CallOutlined
} from "@mui/icons-material";

export default function CallLogHeader() {
  return (
    <div className="px-3 pt-4 flex justify-between flex-row-reverse border-black border-1 border-b mx-3">
      <div className="w-[50%] mx-auto my-2 text-right">
      </div>

      <div className="w-[50%] self-end rounded-t-md">
        <h2 className="w-full text-center py-1 text-lg flex items-center flex-row">
          
          <div className="block mr-1 mb-1">
            <CallOutlined />
          </div>
          <span className="block">Call Log</span>
        </h2>
      </div>
    </div>
  );
}
