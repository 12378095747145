import { ControlPointOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ModalShell from "../../../../../../global/ModalShell";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import ResponseModal from "../../../../../../global/ResponseModal";
import AutoCompleteSearch from "../../../../../../global/AutoCompleteSearch/AutoCompleteSearch";

export default function ProviderToLocation({ userInfo, managedLocations }) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [userToProviders, setUserToProviders] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [openProvLocations, setOpenProvLocations] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [activeLocations, setActiveLocations] = useState("");

  useEffect(() => {
    if (
      userRoles.permissions.includes("users.view_usertoproviderassignments") &&
      userInfo
    ) {
      axios
        .get(`/api/user_provider/?limit=100&user=${userInfo.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setUserToProviders(data);

          let activeObj = {};

          for (const item in data) {
            activeObj[data[item].provider.id] = data[item].id;
          }
          setActiveLocations(activeObj);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  }, [
    userRoles.permissions,
    setUserToProviders,
    trigger,
    userInfo,
    accessToken,
  ]);

  const postLocationPromise = (location) => {
    new Promise((resolve, reject) => {
      axios
        .post(
          `/api/user_provider/`,
          {
            user: userInfo.pk,
            provider: location.pk,
            role: selectedRole.pk,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${accessToken}`,
            },
          }
        )
        .then(() => {
          resolve("Posted");
        })
        .catch((error) => {
          let res = error.response.data;
          let errArr = ApiRequestErrorHandler(res);
          setIsError(true);
          setResponseModal(true);
          setResponseBreakdown(errArr);
          reject("Failed");
        });
    });
  };

  const postLocations = () => {
    let postCount = 0;
    Promise.all(
      selectedLocations.map((location) => {
        postCount = postCount + 1;
        postLocationPromise(location);
        return postCount;
      })
    )
      .then(() => {
        if (postCount === selectedLocations.length) {
          setResponseModal(true);
          setResponseBreakdown("User to Provider Locations added");
          setIsError(false);
          setSelectedRole("");
          setSelectedLocations([]);
        }
      })
      .catch(() => {
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(["An error occured, please try again"]);
      });
  };

  const deleteLocation = (id) => {
    axios
      .delete(`/api/user_provider/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("User to Provider Location removed");
        setIsError(false);
        setSelectedRole("");
        setSelectedLocations([]);
      })
      .catch(() => {
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(["An error occured, please try again"]);
      });
  };

  const closeResModal = () => {
    setOpenProvLocations(false);
    setTrigger(!trigger);
    setSelectedLocations([]);
    setSelectedRole("");
    setResponseModal(false);
  };

  return (
    <div>
      <div className="my-2">
        <div className="flex items-center w-full border-b mb-2 pb-1">
          <h2 className="font-bold">User to Provider Locations</h2>

          {userRoles.permissions.includes(
            "users.add_usertoproviderassignments"
          ) && managedLocations.length > 0 ? (
            <Tooltip title="Assign Location">
              {" "}
              <IconButton
                onClick={() => setOpenProvLocations(true)}
                className="text-green-600"
              >
                <ControlPointOutlined className="block" />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        <div>
          {userToProviders.length === 0 ? (
            <p>No Locations Found</p>
          ) : (
            userToProviders.map((loc) => (
              <Tooltip title={`Role: ${loc.role.name}`}>
                {" "}
                <Chip
                  label={loc.provider.name}
                  // onClick={() => handleOpenUserDetail(user.user)}
                  className="mr-1 mb-1"
                  onDelete={
                    userRoles.permissions.includes(
                      "users.delete_usertoproviderassignments"
                    )
                      ? () => deleteLocation(loc.id)
                      : false
                  }
                />
              </Tooltip>
            ))
          )}
        </div>
      </div>

      <ModalShell
        open={openProvLocations}
        setOpen={setOpenProvLocations}
        color="#1976d2"
        title="User to Provider Location"
      >
        <div
          style={{
            margin: "1rem auto 1rem",
            minWidth: "25vw",
          }}
        >
          <Autocomplete
            size="small"
            multiple
            options={
              !managedLocations
                ? []
                : managedLocations
                    .filter((loc) => !activeLocations[loc.pk])
                    .map((option) => option)
            }
            getOptionLabel={(option) => option.name}
            value={selectedLocations}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(e, value) => {
              setSelectedLocations(value);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Locations"
                error={selectedRole && !selectedLocations.length}
                helperText={
                  selectedRole && !selectedLocations.length
                    ? "Add at least one provider"
                    : ""
                }
              />
            )}
          />
          <div className="mt-4">
            <AutoCompleteSearch
              apiUrl="/api/user_roles/?for_type=provider"
              keyVal="role"
              title="Role"
              setVal={setSelectedRole}
              displayKey="name"
              hasError={!selectedRole && selectedLocations.length}
              helperText={
                !selectedRole && selectedLocations.length ? "Add Role" : ""
              }
            />
          </div>
          <p className="text-gray-400 text-[12px] text-center my-1">
            All inputs required
          </p>

          <div className="mt-4 mx-auto w-fit">
            <Button
              onClick={() => postLocations()}
              variant="outlined"
              disabled={selectedLocations.length && selectedRole ? false : true}
            >
              Assign
            </Button>
          </div>
        </div>
      </ModalShell>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={!isError ? closeResModal : false}
      />
    </div>
  );
}
