import React, { useState } from "react";
import { Link } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";

function ContactControlsMobile({ number, closeModal }) {
  const [toggleHoverBg, setToggleHoverBg] = useState("white");
  const [toggleHoverText, setToggleHoverText] = useState("#17a34a");
  const [toggleHoverBgSms, setToggleHoverBgSms] = useState("white");
  const [toggleHoverTextSms, setToggleHoverTextSms] = useState("#1976d2");

  const hoverActive = (baseColor) => {
    setToggleHoverBg(baseColor);
    setToggleHoverText("white");
  };

  const hoverOff = (baseColor) => {
    setToggleHoverBg("white");
    setToggleHoverText(baseColor);
  };

  const hoverActiveSMS = (baseColor) => {
    setToggleHoverBgSms(baseColor);
    setToggleHoverTextSms("white");
  };

  const hoverOffSMS = (baseColor) => {
    setToggleHoverBgSms("white");
    setToggleHoverTextSms(baseColor);
  };

  return (
    <div className="mx-auto flex my-2">
      <Link
        href={`tel:${number}`}
        target="_blank"
        rel="noreferrer"
        style={{
          padding: "5px 2rem",
          display: "flex",
          justifyContent: "center",
          border: `1px solid ${toggleHoverText}`,
          width: "fit-content",
          margin: "0 auto",
          backgroundColor: toggleHoverBg,
          color: toggleHoverText,
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
        onMouseEnter={() => hoverActive("#17a34a")}
        onMouseLeave={() => hoverOff("#17a34a")}
      >
        <PhoneIcon />
      </Link>
      <Link
        href={`sms:${number}`}
        rel="noreferrer"
        style={{
          padding: "5px 2rem",
          display: "flex",
          justifyContent: "center",
          border: `1px solid ${toggleHoverTextSms}`,
          width: "fit-content",
          margin: "0 auto",
          backgroundColor: toggleHoverBgSms,
          color: toggleHoverTextSms,
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
        onMouseEnter={() => hoverActiveSMS("#1976d2")}
        onMouseLeave={() => hoverOffSMS("#1976d2")}
      >
        <TextsmsIcon />
      </Link>
    </div>
  );
}

export default ContactControlsMobile;
