import React, { useState, useEffect } from "react";
import Colors from "../../../../../../styles/Colors";
import { isToday, isPast, differenceInCalendarDays } from "date-fns";
import  Button  from "@mui/material/Button";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material";

export default function AddCallbackButton({ caseObj, onclick, note, canEdit }) {
  const NOW = new Date();
  const COLOR = {
    OVERDUE: Colors.red[400],
    WARNING: Colors.orange[400],
    GOOD: Colors.green[400],
    DEFAULT: Colors.purple[400],
  };
  const defaultText = "Add Callback";
  const [bgColor, setBGColor] = useState(COLOR.DEFAULT);
  const [text, setText] = useState(defaultText);

  const CustomButton = styled(Button)({
    color: Colors.black,
    backgroundColor: bgColor,
    "&&:hover, &&:focus": {
      color: Colors.white,
      backgroundColor: bgColor,
    },
  });

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(14),
			lineHeight:theme.typography.pxToRem(40),
      border: "1px solid #dadde9",
    },
		[`& .${tooltipClasses.tooltip} .GOOD`]: {
      color: COLOR.GOOD,
      fontSize: theme.typography.pxToRem(20),
    },
		[`& .${tooltipClasses.tooltip} .WARNING`]: {
      color: COLOR.WARNING,
      fontSize: theme.typography.pxToRem(20),
    },
		[`& .${tooltipClasses.tooltip} .OVERDUE`]: {
      color: COLOR.OVERDUE,
      fontSize: theme.typography.pxToRem(20),
    },
		[`& .${tooltipClasses.tooltip} .DEFAULT`]: {
      color: COLOR.DEFAULT,
      fontSize: theme.typography.pxToRem(20),
    },
  }));

  let baseStyle =
    "text-black text-sm font-bold normal-case w-[90%] mx-auto block ";

  const getNumberOfDays = (callback_time) => {
    return differenceInCalendarDays(callback_time, NOW);
  };

  const isWithinRangeOfDays = (callback_time) => {
    if (isPast(callback_time)) {
      return COLOR.OVERDUE;
    }
    if (isToday(callback_time)) {
      return COLOR.OVERDUE;
    }
    if (getNumberOfDays(callback_time) >= 14) {
      return COLOR.GOOD;
    }

    return COLOR.WARNING;
  };

  const calculateBackgroundColor = () => {
    if (!caseObj || !caseObj.callback_time) {
      setBGColor(COLOR.DEFAULT);
    } else {
      setBGColor(isWithinRangeOfDays(new Date(caseObj.callback_time)));
    }
  };
  const calculateButtonText = () => {
    if (caseObj && caseObj.callback_time) {
      setText(new Date(caseObj.callback_time).toLocaleString());
    }
  };

  useEffect(() => {
    calculateBackgroundColor();
    calculateButtonText();
  });
  return (
    <>
      {caseObj ? (
        <div className="w-full">
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{note}</Typography>
                <hr/>
								<p><span className="DEFAULT">&#9632;</span> No Callback Date</p>
								<p><span className="GOOD">&#9632;</span> 2 Weeks or More</p>
								<p><span className="WARNING">&#9632;</span> 1 Week or Less</p>
                <p><span className="OVERDUE">&#9632;</span> Today or Past Due</p>
              </React.Fragment>
            }
          >
            <CustomButton
              variant="contained"
              className={baseStyle}
              fullWidth={true}
              role={"button"}
              disabled={canEdit ? false : true}
              onClick={onclick}
            >
              {text}
            </CustomButton>
          </HtmlTooltip>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
