import { Autocomplete, TextField, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import DataContext from "../../../context/DataContext";
import axios from "../../api/axios";
import ShowMoreButtonAutoComp from "./ShowMoreButtonAutoComp";

export default function AutoCompleteSearch({
  apiUrl,
  keyVal,
  title,
  setVal,
  displayKey,
  activeEditObj,
  customLimit,
  permissions,
  trigger,
  required,
  customSize,
  isDisabled,
  disabledReason,
  hasError,
  helperText,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(customLimit ? customLimit : 25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  useDebouncedEffect(
    () => {
      if (
        userRoles.permissions.includes(
          !permissions ? "utilities.view_fieldoptions" : permissions
        )
      ) {
        axios
          .get(
            apiUrl +
              `&limit=${resLimit}` +
              `${search ? `&search=${search}` : ""}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            setOptions(response.data.results);
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
          });
      } else {
        return;
      }
    },
    [userRoles.permissions, accessToken, setOptions, search, resLimit, trigger],
    250
  );

  return (
    <Tooltip title={disabledReason && isDisabled ? disabledReason : ""}>
      <Autocomplete
        className="w-full"
        disablePortal
        freeSolo
        size={customSize ? customSize : "small"}
        clearOnBlur={true}
        options={!options ? [] : options}
        getOptionLabel={(option) => {
          return option[displayKey];
        }}
        noOptionsText="No Results"
        onChange={(e, value) => {
          setVal(value, keyVal);
          setSearch("");
        }}
        disabled={isDisabled ? true : false}
        defaultValue={activeEditObj}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              id={option[displayKey]}
              key={option[displayKey] + Math.random()}
            >
              {option[displayKey]}
            </li>
          );
        }}
        renderInput={(params) => (
          <div className="flex items-center">
            <TextField
              {...params}
              label={title}
              onChange={(e) => setSearch(e.target.value)}
              required={!required ? false : true}
              error={hasError}
              helperText={helperText}
            />
            <ShowMoreButtonAutoComp
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={10}
              customTitle={title}
            />
          </div>
        )}
      />
    </Tooltip>
  );
}
