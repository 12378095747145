import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EventIcon from "@mui/icons-material/Event";

export default function DateRange({
  val,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  resetPagination,
}) {
  return (
    <Accordion className="w-full">
      <AccordionSummary
        className="text-sm"
        expandIcon={
          <div className="flex items-center">
            <ExpandMoreIcon />
          </div>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {val} Range&nbsp;
        <EventIcon style={{ color: "#757575" }} />
      </AccordionSummary>
      <AccordionDetails>
        <div className="">
          <div className="flex items-center mb-2">
            <DatePicker
              className="w-full ml-1 my-1 text-center"
              label={`${val} From`}
              value={!dateFrom ? new Date() : dateFrom}
              onChange={(newValue) => {
                setDateFrom(newValue);
                resetPagination();
              }}
              slotProps={{ textField: { variant: "outlined" } }}
            />
            {!dateFrom ? (
              ""
            ) : (
              <Button
                onClick={() => setDateFrom(null)}
                className="text-sm text-red-600 border-red-600  px-0 hover:bg-red-600 hover:text-white"
              >
                X
              </Button>
            )}
          </div>
          <div className="flex items-center">
            <DatePicker
              className="w-full ml-1 my-1 text-center"
              label={`${val} To`}
              value={!dateTo ? new Date() : dateTo}
              onChange={(newValue) => {
                setDateTo(newValue);
                resetPagination();
              }}
              slotProps={{ textField: { variant: "outlined" } }}
            />
            {!dateTo ? (
              ""
            ) : (
              <Button
                onClick={() => setDateTo(null)}
                className="text-sm text-red-600 border-red-600  px-0 hover:bg-red-600 hover:text-white"
              >
                X
              </Button>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
