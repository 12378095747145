import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import ResponseModal from "../../../../../../../global/ResponseModal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

export default function StatusSelect({
  provider,
  proCliTrigger,
  setProCliTrigger,
  docTypeOptions,
  docTypeOptionsObj,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = (val, key, prov) => {
    const status = {
      [key]: val,
    };

    axios
      .patch(`/api/pro_cli_status/${prov.pk}/`, status, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Provider Client Status has been updated");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setProCliTrigger(!proCliTrigger);
  };

  return (
    <div className="my-2">
      <Tooltip
        title={`${!provider.finished_treatment ? "Treating" : "Complete"}`}
        placement="right"
      >
        <FormControl
          style={{
            marginBottom: "1rem",
            backgroundColor: provider.finished_treatment
              ? `rgba(51, 205, 49, .5)`
              : ``,
          }}
          className=" mx-auto block w-full text-sm"
          fullWidth
          size="small"
        >
          <InputLabel className="font-bold text-gray-800" id="treatment-status">
            Treatment Status
          </InputLabel>
          <Select
            className="w-full text-sm"
            labelId="treatment-status"
            id="treatment-status-select"
            disabled={
              provider.can_edit &&
              userRoles.permissions.includes(
                "provider.change_providerclientstatus"
              )
                ? false
                : true
            }
            value={provider.finished_treatment}
            onChange={(e) =>
              handleSubmit(e.target.value, "finished_treatment", provider)
            }
          >
            <MenuItem value={false}>Treating</MenuItem>
            <MenuItem value={true}>Complete</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
      <Tooltip
        title={`${docTypeOptionsObj[provider.medical_documents_status]}`}
        placement="right"
      >
        <FormControl
          className=" mx-auto block text-sm"
          fullWidth
          style={{
            marginBottom: "1rem",
            backgroundColor:
              provider.medical_documents_status === 0
                ? ``
                : provider.medical_documents_status === 1
                ? `rgba(216, 180, 254, .5)`
                : provider.medical_documents_status === 2
                ? `rgba(253, 224, 71, .5)`
                : `rgba(51, 205, 49, .5)`,
          }}
          size="small"
        >
          <InputLabel className="font-bold text-gray-800" id="document-status">
            Document Status
          </InputLabel>
          <Select
            className="w-full text-sm"
            labelId="document-status"
            id="document-status-select"
            value={provider.medical_documents_status}
            onChange={(e) =>
              handleSubmit(e.target.value, "medical_documents_status", provider)
            }
            disabled={
              provider.can_edit &&
              userRoles.permissions.includes(
                "provider.change_providerclientstatus"
              )
                ? false
                : true
            }
          >
            {!docTypeOptions ? (
              <MenuItem>No results</MenuItem>
            ) : (
              docTypeOptions.map((type) => (
                <MenuItem value={type.key} key={type.key}>
                  {type.label}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Tooltip>
      {!responseModal ? (
        ""
      ) : (
        <ResponseModal
          title={`${isError ? "Failed" : "Successful"}`}
          isError={isError}
          description={isError ? "" : responseBreakdown}
          openBool={responseModal}
          setOpenBool={setResponseModal}
          errorMessage={responseBreakdown}
          handleCloseFunc={closeResModal}
        />
      )}
    </div>
  );
}
