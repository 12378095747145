import { ArrowForwardIos, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material/";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import DataContext from "../../../../context/DataContext";
import ProviderSearchContext from "../../../../context/ProviderSearchContext";
import axios from "../../../api/axios";
import ClientListItemProCli from "../../../global/ClientListItemProCli";
import DateRange from "../../../global/DateRange";
import DynamicTextInput from "../../../global/DynamicTextInput";
import PaginationControls from "../../../global/PaginationControls";
import PrintableProvCliList from "../../../global/PrintableProvCliList";
import SearchOptionGroup from "../../../global/SearchOptionGroup";
import { CustomChip } from "../../../global/CustomQueryChip";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import MultiSelectSearchV2 from "../../../global/MultiSelectSearchV2";

export default function ProviderClientSearch({ isDashboardVersion }) {
  const { trigger, setTrigger, userType, accessToken, userRoles } =
    useContext(DataContext);
  const {
    search,
    setSearch,
    providerType,
    setProviderType,
    provider,
    setProvider,
    treatmentStatus,
    setTreatmentStatus,
    provSearch,
    activeProviders,
    setActiveProviders,
    dateCreatedFrom,
    setDateCreatedFrom,
    dateCreatedTo,
    setDateCreatedTo,
    inNetwork,
    setInNetwork,
  } = useContext(ProviderSearchContext);

  const [loading, setLoading] = useState(true);
  const [toggleFilterMenu, setToggleFilterMenu] = useState(false);
  const [activeFilters, setActiveFilters] = useState();
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [responseData, setResponseData] = useState([]);
  const [printableListCount, setPrintableListCount] = useState();
  const [runningTotalKey, setRunningTotalKey] = useState("total_cost");
  const [runningTotal, setRunningTotal] = useState("");
  const [printableList, setPrintableList] = useState();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `Clients Missing Property Damage`,
  // });

  const menuOpenClose = () => {
    setToggleFilterMenu(!toggleFilterMenu);
  };

  // const printList = () => {
  //   setLoading(true);
  //   let querytest = qs.stringify(
  //     {
  //       search: !search ? null : search,
  //       provider__type: !providerType ? null : providerType,
  //       provider__in_network: inNetwork === null ? null : inNetwork,
  //       finished_treatment: !treatmentStatus ? null : treatmentStatus,
  //       [runningTotalKey]: !runningTotal ? null : runningTotal,
  //       created_at__gte: !dateCreatedFrom
  //         ? null
  //         : new Date(dateCreatedFrom).toLocaleDateString("en-CA"),
  //       created_at__lte: !dateCreatedTo
  //         ? null
  //         : new Date(dateCreatedTo).toLocaleDateString("en-CA"),
  //     },
  //     {
  //       skipNull: true,
  //     }
  //   );
  //   axios
  //     .get(
  //       `/api/pro_cli_status/?limit=${printableListCount}&ordering=name&${
  //         provider.length === 0 ? "" : `provider__in=${provider}&`
  //       }` +
  //         paginationUrl +
  //         querytest,

  //       {
  //         headers: { Authorization: `Token ${accessToken}` },
  //       }
  //     )
  //     .then(function (response) {
  //       setPrintableList(response.data.results);
  //       setTimeout(() => {
  //         handlePrint();
  //         setLoading(false);
  //       }, "1000");
  //     })
  //     .catch(function (error) {});
  // };

  const sendRequest = () => {
    setLoading(true);
    let querytest = qs.stringify(
      {
        search: !search ? null : search,
        provider__type: !providerType ? null : providerType,
        provider__in_network: inNetwork === null ? null : inNetwork,
        finished_treatment: !treatmentStatus ? null : treatmentStatus,
        [runningTotalKey]: !runningTotal ? null : runningTotal,
        created_at__gte: !dateCreatedFrom
          ? null
          : new Date(dateCreatedFrom).toLocaleDateString("en-CA"),
        created_at__lte: !dateCreatedTo
          ? null
          : new Date(dateCreatedTo).toLocaleDateString("en-CA"),
      },
      {
        skipNull: true,
      }
    );
    axios
      .get(
        `/api/pro_cli_status/?${
          provider.length === 0 ? "" : `provider__in=${provider}&`
        }` +
          paginationUrl +
          querytest,

        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
        setPrintableListCount(response.data.count);
      })
      .catch(function (error) {});

    querytest = qs.parse(querytest);
    setActiveFilters(Object.keys(querytest));
  };

  useDebouncedEffect(
    () => sendRequest(),
    [
      accessToken,
      search,
      setSearch,
      providerType,
      setProviderType,
      provider,
      setProvider,
      treatmentStatus,
      setTreatmentStatus,
      trigger,
      inNetwork,
      setInNetwork,
      dateCreatedFrom,
      dateCreatedTo,
      paginationUrl,
      setPaginationUrl,
    ],
    250
  );

  useDebouncedEffect(
    () => {
      setCurrentPage(1);
      setPaginationUrl(``);
    },
    [
      search,
      setSearch,
      providerType,
      setProviderType,
      provider,
      setProvider,
      inNetwork,
      setInNetwork,
      dateCreatedFrom,
      dateCreatedTo,
      treatmentStatus,
      setTreatmentStatus,
      trigger,
    ],
    250
  );

  useEffect(() => {
    setLoading(true);
  }, [
    search,
    setSearch,
    providerType,
    setProviderType,
    provider,
    setProvider,
    inNetwork,
    setInNetwork,
    treatmentStatus,
    setTreatmentStatus,
    trigger,
  ]);

  const resetPagination = () => {
    setPaginationUrl("");
    setCurrentPage(1);
  };

  let querytest = qs.stringify(
    {
      search: !provSearch ? null : provSearch,
      type: !providerType ? null : providerType,
      in_network: inNetwork === null ? null : inNetwork,
    },
    {
      skipNull: true,
    }
  );

  useEffect(() => {
    if (isDashboardVersion) {
      setToggleFilterMenu(true);
    }
    // eslint-disable-next-line
  }, []);

  const columnClasses =
    userType === "Client Case Management" ? "md:w-1/5 w-1/3" : "md:w-1/4 w-1/3";

  const filterBgColor = (val) => {
    if (val === "search") {
      return "bg-orange-400";
    } else if (val === "finished_treatment" && treatmentStatus === "true") {
      return "bg-red-500 text-white";
    } else if (val === "finished_treatment" && treatmentStatus === "false") {
      return "bg-green-500 text-white";
    } else if (val === "provider__type") {
      return "bg-purple-400 text-white";
    } else if (val === "provider__in") {
      return "bg-amber-400";
    } else {
      return;
    }
  };

  const clearFilter = (val) => {
    setTrigger(!trigger);

    if (val === "search") {
      setSearch("");
    } else if (val === "finished_treatment") {
      setTreatmentStatus(null);
    } else if (val === "provider__type") {
      setProviderType(null);
    } else if (val === "provider__in_network") {
      setInNetwork(null);
    } else if (val === "created_at__lte") {
      setDateCreatedTo(null);
    } else if (val === "created_at__gte") {
      setDateCreatedFrom(null);
    } else if (val === "created_at__lt") {
      setDateCreatedTo(null);
    } else if (val === "created_at__gt") {
      setDateCreatedFrom(null);
    } else if (val === "total_cost__lte") {
      setRunningTotal("");
    } else if (val === "total_cost__gte") {
      setRunningTotal("");
    } else if (val === "total_cost__lt") {
      setRunningTotal("");
    } else if (val === "total_cost__gt") {
      setRunningTotal("");
    } else {
      return;
    }
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={`header-wrapper ${
          isDashboardVersion ? "h-[85vh]" : "md:px-4"
        } flex overflow-hidden`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div
          className={`shadow-lg text-center 
              ${
                isDashboardVersion
                  ? !toggleFilterMenu
                    ? "w-fit absolute bg-white z-10 overflow-y-auto max-h-[60vh] md:max-h-[80vh]"
                    : "w-[0px] hidden"
                  : !toggleFilterMenu
                  ? "lg:w-[30%] lg:relative w-fit absolute bg-white z-10 overflow-y-auto h-full"
                  : "w-[0px] hidden"
              }`}
        >
          <div className="font-bold text-lg pt-[16px] pb-[12px] px-4 flex lg:justify-between justify-end">
            {!toggleFilterMenu || isDashboardVersion ? (
              <Tooltip
                title={`${
                  toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                }`}
              >
                <Button
                  onClick={menuOpenClose}
                  className={`${toggleFilterMenu ? "" : "rotate-180"}`}
                >
                  <ArrowForwardIos />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          {userRoles.permissions.includes("provider.view_provider") ? (
            <ListItem>
              <MultiSelectSearchV2
                title="Sort By Provider"
                searchTitle="Provider Search"
                selectedItems={provider}
                setSelectedItems={setProvider}
                setActiveItems={setActiveProviders}
                activeItems={activeProviders}
                nameKey="name"
                valueKey="pk"
                resetPagination={resetPagination}
                apiUrl={`/api/provider/?${querytest}&`}
              />
            </ListItem>
          ) : (
            ""
          )}
          <ListItem>
            <DynamicTextInput
              title="Running Total"
              value={runningTotal}
              setValue={setRunningTotal}
              queryKey={runningTotalKey}
              setQueryKey={setRunningTotalKey}
              query="total_cost"
            />
          </ListItem>
          <List>
            <ListItem>
              <Accordion className="w-full">
                <AccordionSummary
                  className="text-sm"
                  expandIcon={
                    <div className="flex items-center">
                      <ExpandMore />
                    </div>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>Provider Type</div>
                  <div>
                    {!providerType ? (
                      ""
                    ) : (
                      <div>
                        &mdash;
                        <span className="text-xs text-sky-600">
                          &nbsp;{providerType}
                        </span>
                      </div>
                    )}
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      className="text-sm"
                      name="radio-buttons-group"
                      value={providerType}
                      onChange={(e) => setProviderType(e.target.value)}
                    >
                      <FormControlLabel
                        value={"Chiropractor"}
                        control={<Radio />}
                        label="Chiropractor"
                        className="text-sm"
                      />
                      <FormControlLabel
                        value={"MRI"}
                        control={<Radio />}
                        label="MRI"
                        className="text-sm"
                      />
                      <FormControlLabel
                        value={"Orthopedic"}
                        control={<Radio />}
                        label="Orthopedic"
                        className="text-sm"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Button
                    onClick={() => setProviderType("")}
                    variant="outlined"
                    className="text-sm text-red-600 border-red-600 hover:bg-red-600 hover:text-white"
                  >
                    Clear Filter
                  </Button>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setInNetwork}
                value={inNetwork}
                title="Provider In Network"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <SearchOptionGroup
                setValue={setTreatmentStatus}
                value={treatmentStatus}
                title="Treatment Complete"
                resetPagination={resetPagination}
              />
            </ListItem>
            <ListItem>
              <DateRange
                val="Date Created"
                dateFrom={dateCreatedFrom}
                dateTo={dateCreatedTo}
                setDateFrom={setDateCreatedFrom}
                setDateTo={setDateCreatedTo}
              />
            </ListItem>
          </List>
        </div>

        <div className="w-full md:ml-4">
          <div className="flex justify-center mt-4">
            <Tooltip
              title={`${
                toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
              }`}
            >
              <Button
                onClick={menuOpenClose}
                className={`${toggleFilterMenu ? "" : "rotate-180"}`}
              >
                <ArrowForwardIos />
              </Button>
            </Tooltip>
            <Tooltip title="Search by Name, Email, Phone, or Notes">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm pr-4 md:pr-0"
                placeholder="Search"
                size="small"
                name="search"
                value={search}
                InputLabelProps={{ shrink: true }}
                label="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Tooltip>
          </div>
          <div className="m-2">
            <CustomChip
              setActiveItems={setActiveProviders}
              activeItems={activeProviders}
              selectedItems={provider}
              setSelectedItems={setProvider}
              title="Providers"
              resetPagination={resetPagination}
            />
            {!activeFilters
              ? ""
              : activeFilters.map((filt, idx) => (
                  <Tooltip title="Clear Filter" key={filt + idx}>
                    <Chip
                      className={`px-2 m-1 text-center rounded-lg shadow-inner hover:bg-red-400 ${filterBgColor(
                        filt
                      )}`}
                      onClick={() => clearFilter(filt)}
                      label={
                        filt === "finished_treatment"
                          ? `${
                              treatmentStatus === "true"
                                ? "Treatment Complete: True"
                                : "Treatment Complete: False"
                            }`
                          : filt === "search"
                          ? `Search: ${search}`
                          : filt === "provider__type"
                          ? `Provider Type: ${providerType}`
                          : filt === "provider__in_network"
                          ? `Provider In Network: ${inNetwork}`
                          : filt === "created_at__lte"
                          ? `Started treatment before: ${
                              !dateCreatedTo
                                ? ""
                                : new Date(dateCreatedTo).toLocaleDateString(
                                    "en-CA"
                                  )
                            }`
                          : filt === runningTotalKey
                          ? `Running Total: ${formatter.format(runningTotal)}`
                          : filt === "created_at__gte"
                          ? `Started treatment after: ${
                              !dateCreatedFrom
                                ? ""
                                : new Date(dateCreatedFrom).toLocaleDateString(
                                    "en-CA"
                                  )
                            }`
                          : ""
                      }
                    ></Chip>
                  </Tooltip>
                ))}
          </div>
          <div className="h-full overflow-hidden">
            <div className="flex justify-between my-2">
              <p className="pl-5 py-4">
                Showing: {resultCount.current} / {resultCount.total}
              </p>
              {/* <Button
                variant="outlined"
                color="success"
                onClick={printList}
                className="mt-2 hover:bg-purple-600 hover:text-white text-purple-600 border-purple-600 float-left ml-6 mr-2 relative"
              >
                {" "}
                Print
              </Button> */}
            </div>{" "}
            <div className="flex sm:px-4 px-0 text-center py-2 border-b border-1 border-gray justify-evenly">
              <div className={`md:w-[20%]  w-1/3 font-bold`}>
                <h2 className="md:block hidden">CLIENT NAME</h2>
                <h2 className="md:hidden">NAME</h2>
              </div>
              <div className={`md:w-[15%] font-bold hidden md:block`}>
                <h2>CREATED</h2>
              </div>
              <div className={`${columnClasses} font-bold`}>
                <h2>STATUS</h2>
              </div>
              <div className={`md:w-[25%] w-1/3 font-bold`}>
                <h2>PROVIDER</h2>
              </div>
              {userType === "Client Case Management" ? (
                <div className={`${columnClasses} font-bold hidden md:block`}>
                  <h2>LAW FIRM</h2>
                </div>
              ) : (
                ""
              )}
            </div>
            <List
              className={`grid ${
                isDashboardVersion ? `h-[60vh]` : "max-h-[80vh]"
              }  overflow-y-auto `}
            >
              {loading ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {!responseData
                    ? ""
                    : responseData.map((client, index) => (
                        <ClientListItemProCli
                          key={index}
                          client={client}
                          index={index}
                          currentPage={currentPage}
                        />
                      ))}
                </>
              )}
              <ListItem className={`justify-center py-10`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
        {/* PRINTABLE SECTION */}
        <PrintableProvCliList
          responseData={printableList}
          componentRef={componentRef}
          loading={loading}
          activeFilters={true}
          title={
            !activeFilters
              ? ""
              : activeFilters
                  .map((filt, idx) =>
                    filt === "finished_treatment"
                      ? `${
                          treatmentStatus === "true"
                            ? "Treatment Complete: True"
                            : "Treatment Complete: False"
                        }`
                      : filt === "search"
                      ? `Search: ${search}`
                      : filt === "provider__type"
                      ? `Provider Type: ${providerType}`
                      : filt === "provider__in"
                      ? `Provider: ${provider}`
                      : filt === "provider__in_network"
                      ? `Provider In Network: ${inNetwork}`
                      : filt === "created_at__lte"
                      ? `Started treatment before: ${
                          !dateCreatedTo
                            ? ""
                            : new Date(dateCreatedTo).toLocaleDateString(
                                "en-CA"
                              )
                        }`
                      : filt === "created_at__gte"
                      ? `Started treatment after: ${
                          !dateCreatedFrom
                            ? ""
                            : new Date(dateCreatedFrom).toLocaleDateString(
                                "en-CA"
                              )
                        }`
                      : ""
                  )
                  .join(", ")
          }
        />
      </div>
    </LocalizationProvider>
  );
}
