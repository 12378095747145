import { List, ListItem } from "@mui/material";
import React from "react";

export default function CaseDetailHeader({ caseDetail }) {
  return (
    <div className="shadow-md rounded-md w-full">
      <List>
        <ListItem className="py-0 text-[1.2rem]">
          <strong>Settlement Statement</strong>
        </ListItem>
        <ListItem className="py-0">
          <strong>Client:</strong>&nbsp;
          {!caseDetail ? "" : caseDetail.client.name}
        </ListItem>
        <ListItem className="py-0">
          Attorney:&nbsp;{!caseDetail ? "" : caseDetail.law_firm_name}
        </ListItem>
        <ListItem className="py-0">
          Case Number:&nbsp;{!caseDetail ? "" : caseDetail.name}
        </ListItem>
        <ListItem className="py-0">
          Date of Loss:&nbsp;
          {!caseDetail
            ? ""
            : new Date(caseDetail.date_of_accident).toLocaleDateString()}
        </ListItem>
      </List>
    </div>
  );
}
