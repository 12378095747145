import { useContext, useEffect } from "react";
import DataContext from "../../context/DataContext";
import Cookies from "js-cookie";
import AuthContext from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { isAfter } from "date-fns";

const useForceLogout = (isError) => {
  const { setAuth } = useContext(AuthContext);
  const {
    getAccessToken,
    setCaseManagerPk,
    setSelectedProvider,
    setSelectedClient,
    setUserType,
    setDashCompLimit,
    setErrorMessage,
  } = useContext(DataContext);
  const navigate = useNavigate();
  const exp = !Cookies.get("token_exp") ? false : Cookies.get("token_exp");
  const tokenExpired = isAfter(new Date(), new Date(exp));

  useEffect(() => {
    if (tokenExpired || isError) {
      setAuth(null);
      navigate("/");
      Cookies.remove("pnAccessToken");
      Cookies.remove("pnCmPk");
      Cookies.remove("userType");
      Cookies.remove("category");
      Cookies.remove("token_exp");
      getAccessToken("");
      setCaseManagerPk("");
      setSelectedProvider("");
      setSelectedClient("");
      setUserType("");
      setDashCompLimit(5);
      setErrorMessage("Your login has expired, please log in again!");
    }
    // eslint-disable-next-line
  }, [tokenExpired, isError]);
};

export default useForceLogout;
