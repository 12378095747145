import { AddPhotoAlternate } from "@mui/icons-material";
import { IconButton, Button, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import ClientDetailSummary from "./ClientDetailSummary";
import PdDropdown from "./PdDropdown";
import PhotoGallery from "./PhotoGallery";
import DataContext from "../../../../../../context/DataContext";
import { CalendarIcon } from "@mui/x-date-pickers";
import AvailabilityCalendar from "./AvailabilityCalendar";

export default function ClientInfo({
  images,
  caseInfo,
  showMoreImgsVisible,
  setShowMoreImgsVisible,
  imgResLimit,
  setImgResLimit,
  pdChoices,
  pdVal,
  setPdVal,
  setUploadDocument,
  docNeedsType,
  setViewDocuments,
  languageOptions,
  languageObj,
}) {
  const { userRoles } = useContext(DataContext);

  const [openCalModal, setOpenCalModal] = useState(false);
  const openUploadModal = () => {
    if (!docNeedsType || docNeedsType.docs_with_no_type === 0) {
      setUploadDocument(true);
      return;
    } else if (docNeedsType.docs_with_no_type > 0) {
      setUploadDocument(true);
      setViewDocuments(true);
    }
  };

  return (
    <div className="flex xl:flex-row flex-col justify-between items-center xl:h-[60vh]">
      {/* *!Need to figure out the correct perms that entail getting a particular
      users info */}
      <div className="lg:w-1/3 relative mb-4">
        {userRoles.permissions.includes("users.view_user") ? (
          <ClientDetailSummary
            caseInfo={caseInfo}
            languageOptions={languageOptions}
            openCalModal={openCalModal}
           languageObj={languageObj}
          />
        ) : (
          ""
        )}
        {userRoles.permissions.includes("users.view_usercontacthours") ? (
          <Button
            className="mx-4 font-bold block"
            variant="outlined"
            onClick={() => setOpenCalModal(true)}
          >
            Client Availability
            <CalendarIcon className="text-sky-600" />
          </Button>
        ) : (
          ""
        )}
      </div>
      <div className="lg:w-2/3 relative w-full">
        <div className="flex items-center ml-12 mb-2">
          <PdDropdown
            caseInfo={caseInfo}
            pdChoices={pdChoices}
            pdVal={pdVal}
            setPdVal={setPdVal}
          />
          {userRoles.permissions.includes("filemanager.add_document") ? (
            <Tooltip title="Upload Image to Case">
              <IconButton
                className="hover:bg-sky-600 hover:text-white border-none text-sky-600 absolute right-0 z-10"
                onClick={() => openUploadModal()}
              >
                <AddPhotoAlternate />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        {userRoles.permissions.includes("filemanager.view_document") ? (
          <div className="w-auto m-[16px]">
            <PhotoGallery
              images={images}
              showMoreImgsVisible={showMoreImgsVisible}
              setShowMoreImgsVisible={setShowMoreImgsVisible}
              imgResLimit={imgResLimit}
              setImgResLimit={setImgResLimit}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {userRoles.permissions.includes("users.view_usercontacthours") ? (
        <AvailabilityCalendar
          openCalModal={openCalModal}
          setOpenCalModal={setOpenCalModal}
          caseInfo={caseInfo}
        />
      ) : (
        ""
      )}
    </div>
  );
}
