import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import { Close, Info, UploadFile } from "@mui/icons-material";
import ResponseModal from "../../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import LoadingIndicator from "../../../../../../global/LoadingIndicator";

export default function ImageUpload({
  user,
  trigger,
  setTrigger,
  setUploadProfileImage,
  reloadTrigger,
  setReloadTrigger,
}) {
  const { accessToken } = useContext(DataContext);
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileReq, setFileReq] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);

  const clearFileInfo = () => {
    setFile("");
  };

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = (event) => {
    // get the selected file from the input
    event.preventDefault();
    setIsLoading(true);
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("profile_picture", file);

    axios
      .patch(`/api/users/${user.pk}/update_profile_picture/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Profile image has been updated");
        setIsError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
        setIsLoading(false);
      });
  };

  const closeResModal = () => {
    if (responseModal) {
      setTrigger(!trigger);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
      setFile("");
      setUploadProfileImage(false);
      if (reloadTrigger === true || reloadTrigger === false) {
        setReloadTrigger(!reloadTrigger);
      }
    } else {
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
      setUploadProfileImage(false);
      setFile("");
      if (reloadTrigger === true || reloadTrigger === false) {
        setReloadTrigger(!reloadTrigger);
      }
    }
  };

  return (
    <div>
      <LoadingIndicator isLoading={isLoading} />{" "}
      <form
        onSubmit={handleFileUpload}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            margin: "10px auto",
            display: "flex",
            width: "fit-content",
            borderBottom: "1px solid #1976d2",
          }}
        >
          <Button
            component="label"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            startIcon={<UploadFile />}
          >
            {!file ? <span>&nbsp; Select File</span> : file.name}

            <input type="file" hidden onChange={handleFileSelect} />
          </Button>
          {!file ? (
            ""
          ) : (
            <Button style={{ color: "red" }} onClick={clearFileInfo}>
              <Close />
            </Button>
          )}
        </div>
        {!file ? (
          ""
        ) : (
          <div>
            <p style={{ fontSize: "12px", color: "grey", textAlign: "center" }}>
              <Info style={{ fontSize: "14px" }} />
              &nbsp; Supported File Extensions - {!fileReq ? "TBD" : fileReq}
            </p>
          </div>
        )}
        <div style={{ display: "block", margin: "0 auto" }}>
          <Button type="submit" variant="outlined">
            {" "}
            Upload File
          </Button>
        </div>
      </form>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
