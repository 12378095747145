import React from "react";
import { Alert } from "@mui/material";

export default function Success() {
  return (
    <div>
      <div variant="outlined" severity="success" role="alert">
        <p className="text-center">
          <Alert>
            <h1 className="text-center text-[1.2rem] font-bold">
              Create User Success
            </h1>
            If you opted out of sending this user their email information,
            please contact admin to provide temporary password for this user{" "}
          </Alert>
        </p>
      </div>
    </div>
  );
}
