import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import AddExpense from "./Modals/AddExpense";
import { IconButton, List, ListItem, Tooltip } from "@mui/material";
import { ControlPoint } from "@mui/icons-material";
import ExpenseOptionsMenu from "./ExpenseOptionsMenu";
import DeleteExpense from "./Modals/DeleteExpense";

export default function LiensBalances() {
  const { accessToken, userRoles } = useContext(DataContext);
  const [expenseList, setExpenseList] = useState([]);
  const [expenseTotal, setExpenseTotal] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [typeChoices, setTypeChoices] = useState([]);
  const [activeExpense, setActiveExpense] = useState("");
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      return;
    } else {
      axios
        .get(`/api/additional_expense/?case=${params.id}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setExpenseTotal(response.data.total_amount);
          setExpenseList(data.results);
        })
        .catch((response) => {
          console.log(response);
        });

      axios
        .options(`/api/additional_expense/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.actions.POST.deduction_type.choices;
          let typeObj = {};
          for (const item in data) {
            typeObj[data[item].value] = data[item].display_name;
          }

          setTypeChoices(typeObj);
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }, [accessToken, params.id, trigger]);

  const openEditExpense = (expense) => {
    setAddOpen(true);
    setActiveExpense(expense);
  };

  const openDeleteExpense = (expense) => {
    setDeleteOpen(true);
    setActiveExpense(expense);
  };
  return (
    <div
      className="border border-2 border-black"
      style={{ width: "100%", border: "2px solid black" }}
    >
      <div
        className="flex justify-between"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          className="text-[1.2rem] font-bold p-2"
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            padding: "1rem",
            margin: "0",
          }}
        >
          Other Liens or Balances
        </h2>
        {userRoles.permissions.includes("provider.add_additionalexpense") ? (
          <div>
            <IconButton
              onClick={() => setAddOpen(true)}
              className="text-green-600"
              style={{ color: "#16A34A" }}
            >
              <Tooltip title="Add Expense">
                <ControlPoint />
              </Tooltip>
            </IconButton>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 0 0 1rem",
          borderBottom: "2px solid black",
        }}
      >
        <div style={{ width: "35%", fontWeight: "bold" }}>Label</div>
        <div style={{ width: "20%", fontWeight: "bold" }}>Amount</div>
        <div style={{ width: "50%", fontWeight: "bold" }}>Deducted From</div>
      </div>
      <List
        className="max-h-[15vh] overflow-auto p-2"
        style={{ maxHeight: "15vh", overflow: "auto", padding: "0 1rem 1rem" }}
      >
        {expenseList.length === 0
          ? "No Expenses"
          : expenseList.map((expense, idx) => (
              <ListItem
                className="flex justify-between"
                key={idx}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 0,
                }}
              >
                <div
                  className="font-bold"
                  style={{ fontWeight: "bold", width: "35%" }}
                >
                  {expense.label}
                </div>
                <div style={{ width: "20%" }}>${expense.amount}</div>
                <div style={{ width: "35%", fontSize: "14px" }}>
                  {typeChoices.length === 0 || !expense
                    ? ""
                    : typeChoices[expense.deduction_type].replace(
                        "Deducted from",
                        ""
                      )}
                </div>
                <div
                  className="flex flex-col"
                  style={{
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ExpenseOptionsMenu
                    handleEditAction={openEditExpense}
                    handleDeleteAction={openDeleteExpense}
                    action={expense}
                  />
                </div>
              </ListItem>
            ))}
      </List>
      <div
        className="border-t-2 border-black"
        style={{ borderTop: "2px solid black" }}
      >
        <div
          className="flex justify-between p-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <div className="font-bold" style={{ fontWeight: "bold" }}>
            Total:
          </div>
          <div>${Number(expenseTotal).toFixed(2)}</div>
        </div>
      </div>
      <AddExpense
        casePk={!params ? "" : params.id}
        addOpen={addOpen}
        setAddOpen={setAddOpen}
        trigger={trigger}
        setTrigger={setTrigger}
        activeExpense={activeExpense}
        setActiveExpense={setActiveExpense}
      />
      <DeleteExpense
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        activeActionItem={activeExpense}
        setActiveActionItem={setActiveExpense}
        trigger={trigger}
        setTrigger={setTrigger}
        typeChoices={typeChoices}
      />
    </div>
  );
}
