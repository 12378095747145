import { Alert, AlertTitle, Dialog, IconButton } from "@mui/material";
import React, { useContext } from "react";
import DataContext from "../../context/DataContext";
import Cookies from "js-cookie";
import { CloseOutlined } from "@mui/icons-material";
import ModalButton from "./ModalButton";

export default function PatchUpdateDialog() {
  const { refreshDialog, setRefreshDialog } = useContext(DataContext);
  const { patchNotif } = useContext(DataContext);
  const { setNeedsUpdate } = useContext(DataContext);

  const updateRefresh = () => {
    Cookies.set("patchStatus", patchNotif[0].version_hash);

    setRefreshDialog(false);
    setNeedsUpdate(false);
    window.location.reload();
  };

  const closeModal = () => {
    setRefreshDialog(false);
  };
  return (
    <Dialog open={refreshDialog}>
      <Alert
        variant="outlined"
        severity="warning"
        role="warning"
        style={{ paddingRight: "40px" }}
      >
        <AlertTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 0 1rem",
          }}
        >
          App Update{" "}
          <IconButton
            onClick={() => setRefreshDialog(false)}
            style={{ padding: "0" }}
          >
            <CloseOutlined style={{ color: "#FFA500" }} />
          </IconButton>
        </AlertTitle>
        <div style={{ padding: "1rem" }}>
          <p
            style={{
              textAlign: "center",
              padding: "0 0 10px",
              fontSize: "1rem",
            }}
          >
            There has been a software update.
          </p>
          <p style={{ textAlign: "center" }}>
            Please refresh the page to get the most up to date version.{" "}
          </p>
          <div style={{ display: "flex", margin: "1rem 0" }}>
            <ModalButton
              className="block"
              action={updateRefresh}
              baseColor="#32CD30"
              icon="refresh"
              content={"Refresh Now"}
            />
            <ModalButton
              className="block"
              action={closeModal}
              baseColor="#FFA500"
              content={"Close"}
            />
          </div>
        </div>
      </Alert>
    </Dialog>
  );
}
