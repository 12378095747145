import React, { useState, useEffect, useContext, useCallback } from "react";
import AccordionShell from "../../AccordionShell";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import DataContext from "../../../../context/DataContext";
import { IconButton, Tooltip } from "@mui/material";
import ResponseModal from "../../ResponseModal";
import { provider } from "../../../api/endpoints/provider";
import ProviderServicesView from "./ProviderServicesView";
import ProviderServicesEdit from "./ProviderServicesEdit";
import { Edit } from "@mui/icons-material";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import PermissionWrapper from "../../PermissionWrapper";

export default function ProviderServices({
  providerId,
  activeProvObj,
  parentTrigger,
  setParentTrigger,
}) {
  const { userRoles, loggedInUser, accessToken } = useContext(DataContext);
  const [availableServices, setAvailableServices] = useState(null);
  const [currentServices, setCurrentServices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [toggleEdit, setToggleEdit] = useState(false);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line
  const [resLimit, setResLimit] = useState(25);
  const [currServicesLimit, setCurrServicesLimit] = useState(1);
  const [availServicesPageUrl, setAvailServicesPageUrl] = useState(`limit=25`);
  const [availResultCount, setAvailResultCount] = useState(0);
  const [shouldFetchAvailableServices, setShouldFetchAvailableServices] =
    useState(true);
  const [shouldFetchCurrentServices, setShouldFetchCurrentServices] =
    useState(true);

  const perms = {
    canAddProviderServices: userRoles.permissions.includes(
      "provider.add_providerservice"
    ),
    canDeleteProviderServices: userRoles.permissions.includes(
      "provider.delete_providerservice"
    ),
  };

  const canEditProviderServices =
    perms.canAddProviderServices || perms.canDeleteProviderServices;

  const canEdit =
    loggedInUser.entity &&
    loggedInUser.entity.entity_type_label === "Provider" &&
    providerId !== activeProvObj[providerId]
      ? false
      : canEditProviderServices
      ? true
      : false;

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setParentTrigger(!parentTrigger);
  };

  const fetchAvailableServices = useCallback(
    (search, paginationUrl) => {
      return provider
        .getAvailableServices(providerId, accessToken, search, paginationUrl)
        .then((response) => {
          setAvailResultCount(response.count);
          setAvailableServices(response.results);
          return response;
        })
        .then((response) => {
          setIsLoading(false);
          return response;
        })
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response);
          setIsError(true);
          setResponseModal(true);
          setResponseBreakdown(errArr);
        });
    },
    [accessToken, providerId]
  );
  const fetchCurrentServices = useCallback(() => {
    return provider
      .getCurrentServices(providerId, accessToken, currServicesLimit)
      .then((response) => {
        if (response && response.count) {
          setCurrServicesLimit(response.count);
        }
        return response;
      })
      .then((response) => {
        return provider
          .getCurrentServices(providerId, accessToken, response.count)
          .then((response) => {
            if (response && response.count === 0) {
              setCurrentServices([]);
            } else {
              let data = response.results.map((ser) => ({
                id: ser.service.id,
                service_code: ser.service.service_code,
                service_name: ser.service.service_name,
                updated_at: ser.created_at,
                updated_by: ser.created_by.name,
              }));
              setCurrentServices(data);
            }

            return response;
          })
          .then((response) => {
            setIsLoading(false);
            return response;
          })
          .catch((error) => {
            let res = error.response;
            let errArr = ApiRequestErrorHandler(res);
            setIsError(true);
            setResponseModal(true);
            setResponseBreakdown(errArr);
          });
      })
      .catch((error) => {
        let res = error.response;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  }, [accessToken, providerId, currServicesLimit]);
  const postCurrentServices = (newServices) => {
    setIsLoading(true);
    setCurrentServices(null);
    provider
      .postCurrentServices(newServices, providerId, accessToken)
      .then((response) => {
        return response;
      })
      .then((response) => {
        setShouldFetchCurrentServices(true);
        return response;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };
  const removeCurrentServices = (newServices) => {
    setIsLoading(true);
    setCurrentServices(null);
    provider
      .removeCurrentServices(newServices, providerId, accessToken)
      .then((response) => {
        return response;
      })
      .then((response) => {
        setShouldFetchCurrentServices(true);
        return response;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const searchRequest = (searchValue) => {
    setSearch(searchValue);
    setIsLoading(true);
    setAvailableServices(null);
  };

  useEffect(() => {
    let active = true;
    if (active) {
      if (isLoading && shouldFetchAvailableServices) {
        fetchAvailableServices();
      }
      if (isLoading && shouldFetchCurrentServices) {
        fetchCurrentServices();
      }
    }
    return () => {
      setShouldFetchAvailableServices(false);
      setShouldFetchCurrentServices(false);
      active = false;
    };
  }, [
    shouldFetchAvailableServices,
    shouldFetchCurrentServices,
    fetchAvailableServices,
    fetchCurrentServices,
    isLoading,
    accessToken,
    providerId,
  ]);

  useDebouncedEffect(
    () => {
      fetchAvailableServices(search, availServicesPageUrl);
    },
    [search, availServicesPageUrl],
    800
  );

  const setToggleEditMode = (mode) => {
    setToggleEdit(mode);
    setIsLoading(true);
    setShouldFetchAvailableServices(true);
    setShouldFetchCurrentServices(true);
  };

  return (
    <div id="ProviderServices" data-testid="ProviderServices">
      <AccordionShell title="Services Provided" defaultExpanded={true}>
        <div>
          {!toggleEdit && (
            <h2 style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              List of Medical Services:
              <PermissionWrapper permission={canEdit}>
                <Tooltip title="Edit Services">
                  <IconButton onClick={() => setToggleEditMode(canEdit)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </PermissionWrapper>
            </h2>
          )}
        </div>
        {!toggleEdit ? (
          <ProviderServicesView
            currentServices={currentServices}
            isCurrSerApiIsLoading={isLoading}
          />
        ) : (
          canEdit && (
            <ProviderServicesEdit
              setToggleEdit={setToggleEditMode}
              availableServices={availableServices}
              currentServices={currentServices}
              isLoading={isLoading}
              searchRequest={searchRequest}
              postServices={postCurrentServices}
              removeServices={removeCurrentServices}
              resLimit={resLimit}
              setResLimit={setResLimit}
              availResultCount={availResultCount}
              setAvailServPageUrl={setAvailServicesPageUrl}
              permissions={perms}
            />
          )
        )}
      </AccordionShell>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
