import React, { useContext } from "react";
import { List, ListItem, Button } from "@mui/material";
import { BankCheckDispatchContext } from "../../../context/BankCheckDispatchContext";
import {
  CONTENT_CHECKLIST,
  CONTENT_EDIT_CHECK,
  setFieldData,
  setProviderName,
  setSelectedCheck,
  showContent,
  toggleContentIsLoading,
} from "../../../state/actions";
import { BankCheckStateContext } from "../../../context/BankCheckStateContext";

export default function CheckList({ listOfChecks }) {
  const state = useContext(BankCheckStateContext);
  const dispatch = useContext(BankCheckDispatchContext);

  const rowClass =
    "text-black shadow-sm w-full flex bg-white rounded-md  justify-between hover:bg-gray-100 p-2 ";

  const colClass =
    "text-sm border-gray-200 text-center w-1/3 self-center border-gray-300 sm:border-r sm:last-of-type:border-0";

  const handleSelectedCheck = (e, idx) => {
    if(state.showContent === CONTENT_CHECKLIST){
      toggleContentIsLoading(dispatch, true);
      setSelectedCheck(dispatch, idx);
      toggleContentIsLoading(dispatch, true);
      showContent(dispatch, CONTENT_EDIT_CHECK);
      toggleContentIsLoading(dispatch, false);
    }
   
  };

  
  return (
    <div data-testid="Bank-Check-List">
      <h2 className="text-left font-bold text-green-800 mb-3">There are multiple checks for this Provider. Please choose an existing check to edit:</h2>
      <div className="bg-gray-200 max-h-[20vh] overflow-y-auto">
        <div className="flex justify-between border-2 border-black w-full bg-white">
          <div className="w-1/3 text-center">Check Date</div>
          <div className="w-1/3 text-center">Check Number</div>
          <div className="w-1/3 text-center">Check Amount</div>
        </div>
        <List
          className="p-0 items-start"
          sx={{ "& .MuiListItem-root": { padding: "0.1rem" } }}
        >
          {listOfChecks.map((check, idx) => (
            <ListItem
              key={check.pk}
              onClick={(e) => handleSelectedCheck(e, idx)}
            >
              <div className={rowClass}>
                <div className={`block ${colClass}`}>{check.check_date}</div>
                <div className={`block  ${colClass}`}>{check.check_number}</div>
                <div className={`block ${colClass}`}>{check.amount}</div>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    
    </div>
  );
}
