import React, { useContext, useState } from "react";
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import DataContext from "../../../../context/DataContext";
import { case_detail } from "../../../api/endpoints/case";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";

export default function MultiUserDisplay({ logId, users }) {
  const { accessToken } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const navigateToDetail = (clientId) => {
    case_detail
      .getCaseByClientId(clientId, accessToken)
      .then((response) => {
        navigate(`/client-detail/${response.results[0].pk}`);
        setIsLoading(false);
        return response;
      })
      .catch((error) => {
        setIsLoading(false);
        return ApiRequestErrorHandler(error.response);
      });
  };

  return (
    <List
      id="Item-Multi"
      data-testid="Item-Multi"
      style={{
        height: "150px",
        bgcolor: "background.paper",
        overflowY: "auto",
        margin: "1rem",
        padding: "0rem 1rem 1rem 1rem",
        border: "1px solid #ccc",
      }}
      dense={true}
    >
      {!users ? (
        "Unknown"
      ) : users.length === 0 ? (
        "Unknown"
      ) : users.length === 1 ? (
        users[0].user_name ? (
          users[0].user_name
        ) : (
          "Unknown"
        )
      ) : (
        <div id={"list_" + logId} key={"list_" + logId}>
          <ListSubheader
            sx={{ backgroundColor: "#fff", borderBottom: "1px solid #ccc" }}
          >
            <p style={{ lineHeight: "1.5rem", paddingTop: "0.5rem" }}>
              *Users sharing the same phone number as: &nbsp;
              <br />
              <span className="text-blue-600">{users[0].user_name}</span>
            </p>
          </ListSubheader>
          {users.map((user, idx) => {
            return (
              <ListItem
                id={"listItem_" + logId + "_" + idx}
                key={"key_" + logId + "_" + idx}
              >
                {user.user_name && user.user_name !== users[0].user_name ? (
                  <div
                    className="w-full"
                    style={{
                      borderBottom: "1px solid #eee",
                      borderTop: "none",
                    }}
                  >
                    {user.user_name}
                    {user?.is_client &&
                      (!isLoading ? (
                        <Tooltip title="Open Case Details">
                          <IconButton
                            onClick={() => navigateToDetail(user?.user)}
                            className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
                          >
                            <Launch />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <CircularProgress color="secondary" />
                      ))}
                  </div>
                ) : (
                  ""
                )}
              </ListItem>
            );
          })}
        </div>
      )}
    </List>
  );
}
