import {
  Alert,
  AlertTitle,
  Dialog,
  List,
  ListItem,
  Button,
} from "@mui/material";
import React from "react";

export default function ResponseModal({
  title,
  description,
  openBool,
  setOpenBool,
  isError,
  errorMessage,
  handleCloseFunc,
}) {
  return (
    <div>
      <Dialog open={openBool}>
        <Alert
          variant="outlined"
          severity={isError ? "error" : "success"}
          role={isError ? "error" : "alert"}
        >
          <AlertTitle>{title}</AlertTitle>
          {isError ? (
            <div className="">
              <List>
                {!errorMessage
                  ? "Please try again"
                  : errorMessage.map((issue, index) => (
                      <ListItem key={index} value={issue}>
                        {issue}
                      </ListItem>
                    ))}
              </List>
              <br />
              <h3>
                Fix errors and then resubmit. If errors persist, contact your
                ITN Representative.
              </h3>
            </div>
          ) : (
            <p>{description}</p>
          )}
        </Alert>
        <Button
          onClick={() =>
            !handleCloseFunc ? setOpenBool(false) : handleCloseFunc()
          }
        >
          OK
        </Button>
      </Dialog>
    </div>
  );
}
