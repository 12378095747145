export const SET_FIELD_DATA = "SET_FIELD_DATA";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_COORDINATES = "SET_COORDINATES";
export const SET_SEND_EMAIL = "SET_SEND_EMAIL";
export const SUBMIT_FORM_INIT = "SUBMIT_FORM_INIT";
export const SET_TOGGLE_SUCCESS_UI = "TOGGLE_SUCCESS_UI";
export const SET_TOGGLE_ERROR_UI = "TOGGLE_ERROR_UI";
export const SET_PERMISSION_SETS = "SET_PERMISSION_SETS";
export const SET_API_ERRORS = "SET_API_ERRORS";

export const setFieldData = (dispatch, obj) =>
  dispatch({ type: SET_FIELD_DATA, payload: obj });

export const setAddress = (dispatch, text) =>
  dispatch({ type: SET_ADDRESS, payload: text });

export const setSendEmail = (dispatch, bool) =>
dispatch({ type: SET_SEND_EMAIL, payload: bool });

export const setPermissionSets = (dispatch, obj) =>
dispatch({ type: SET_PERMISSION_SETS, payload: obj });

export const submitForm = (dispatch, bool) =>
  dispatch({ type: SUBMIT_FORM_INIT, payload: bool });
  
export const toggleSuccessUI = (dispatch, bool) =>
  dispatch({ type: SET_TOGGLE_SUCCESS_UI, payload:bool });

export const toggleErrorUI = (dispatch, bool) =>
  dispatch({ type: SET_TOGGLE_ERROR_UI, payload:bool });

export const setAPIErrors = (dispatch, arr) =>
  dispatch({ type: SET_API_ERRORS, payload: arr});