import React, { useCallback, useContext, useState, useEffect } from "react";
import { provider } from "../../../api/endpoints/provider";
import DataContext from "../../../../context/DataContext";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import { Chip, CircularProgress, Stack, Tooltip } from "@mui/material";
import { SelectOptionsProviderDataProcessor } from "../../FormComponents/SelectBasic/utils/SelectOptionsProviderDataProcessor";
import InlineEditSelect from "../components/InlineEditSelect";
import Colors from "../../../../styles/Colors";

export default function ProviderListEdit({
  addRelation,
  deleteRelation,
  permissions,
  providerList,
  setToggleEdit,
  required,
}) {
  const { accessToken } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providerOptions, setProviderOptions] = useState(null);

  const getValue = (value) => {
    if (value === "" && !required) {
      return null;
    }
    return value;
  };

  const createFieldOptions = useCallback(async (results) => {
    return SelectOptionsProviderDataProcessor(results, "No Selection");
  }, []);

  const fetchFieldOptionsData = useCallback(async () => {
    const fieldOptionsUrls = [provider.getProviderTaggedEntities(accessToken)];
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let res = error.response;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  }, [accessToken]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (isLoading && !providerOptions) {
        fetchFieldOptionsData()
          .then(async (results) => {
            if (results) {
              if (results[0]) {
                let options = await createFieldOptions(results[0].results);
                setProviderOptions(options);
              }
            }
            return results;
          })
          .then((results) => {
            setIsLoading(false);
            return results;
          })
          .catch((error) => {
            let res = error.response;
            let errArr = ApiRequestErrorHandler(res);
            setIsError(true);
            setResponseModal(true);
            setResponseBreakdown(errArr);
          });
      }
    }
    return () => {
      active = false;
    };
  }, [
    isLoading,
    providerOptions,
    setProviderOptions,
    createFieldOptions,
    fetchFieldOptionsData,
  ]);

  const renderEditUi = () => {
    return (
      <div>
        {permissions.canDelete &&
        <Stack
          direction="row"
          useFlexGap
          flexWrap="wrap"
          spacing={1}
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "10px",
          }}
        >
          {providerList && providerList.length > 0 ? (
            providerList.map((provider) => (
              <Tooltip title={"Remove " + provider.entity.name}>
              <Chip
                key={provider.id}
                label={provider.entity.name}
                onDelete={() => deleteRelation(provider)}
                clickable={false}
                sx={{
                  "& .MuiChip-label": { fontWeight: "bold" },
                  "& .MuiChip-icon": { order: 1, color: Colors.white },
                  "& .MuiChip-deleteIcon:hover": {color:Colors.white},
                  "&:hover": { backgroundColor: Colors.red[600] },
                  backgroundColor: Colors.green[600],
                  color: Colors.white,
                }}
              />
              </Tooltip>
            ))
          ) : (
            <p>
              No Providers Relationships Found. Please add a Provider
              Relationship.
            </p>
          )}
        </Stack>}
        {permissions.canAdd && providerOptions.length > 0 && (
          <div>
            <InlineEditSelect
              setToggleEdit={setToggleEdit}
              fieldValue={selectedProvider}
              label={"Add a Provider Relation"}
              placeholder={"Select a Provider Relationship"}
              required={true}
              optionsList={providerOptions}
              submitData={addRelation}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div id="Provider-List-Edit" data-testid="Provider-List-Edit">
      {isLoading ? <CircularProgress color="secondary" /> : renderEditUi()}
    </div>
  );
}
