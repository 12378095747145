import {
  COMM_LOG_SEARCH,
  CURRENT_PAGE_SET,
  CURRENT_PAGE_URL_SET,
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  RESLIMIT_SET,
  RESULT_COUNT_SET,
  SHOULD_FETCH_LOGS,
} from "./actions";

export const initialState = {
  shouldFetchLogs: true,
  loading: true,
  success: null,
  error: null,
  errorArray: [],
  commLogs: null,
  logSearch: "",
  resLimit: 25,
  currentPage: 1,
  paginationUrl: "",
  resultCount: { total: "", current: "" },
};

export function commLogReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_LOGS: {
      return { ...state, shouldFetchLogs: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, commLogs: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case COMM_LOG_SEARCH: {
      return { ...state, logSearch: action.payload };
    }
    case RESLIMIT_SET: {
      return { ...state, resLimit: action.payload };
    }
    case RESULT_COUNT_SET: {
      return { ...state, resultCount: action.payload };
    }
    case CURRENT_PAGE_SET: {
      return { ...state, currentPage: action.payload };
    }
    case CURRENT_PAGE_URL_SET: {
      return { ...state, paginationUrl: action.payload };
    }
    default:
      return state;
  }
}
