import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export default function AccordionColumns({
  log,
  colstyle,
  callDirectionIcon,
  getFormattedDate,
  getFormattedTime,
  formatted,
  fallbackString,
  displayNameWithFallback,
}) {
  const multipleUsersColumn = {
    border: "1px solid #ccc",
    padding: "1rem",
    margin: "2rem",
  };

  return (
    <Accordion
      className="w-full mr-2 relative z-10 bg-white"
      defaultExpanded={false}
      sx={{
        padding: "0px",
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        sx={{
          padding: "0px",
          ".MuiAccordionSummary-contentGutters": {
            padding: " 5px 0px",
            margin: "0px",
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            position: "absolute",
            right: "5px",
          },
        }}
        expandIcon={
          <div className="flex items-center" style={{ position: "relative" }}>
            <ExpandMore />
          </div>
        }
        aria-controls={log && log.id ? `${log.id}-content` : ""}
        id={log && log.id ? `${log.id}-header` : ""}
      >
        <div className={colstyle.inner} style={{ border: "0px" }}>
          <div
            className={colstyle.column1}
            id={log.id + "_direction"}
            data-testid={log.id + "_test_direction"}
          >
            {callDirectionIcon(log.voice_system_direction)}
          </div>
          <div
            className={colstyle.column2}
            id={log.id + "_caller_userName"}
            data-testid={log.id + "_test_caller_userName"}
          >
            {log.caller
              ? log.caller?.users && log.caller?.users.length > 0
                ? log.caller?.users[0]?.user_name
                : fallbackString
              : fallbackString}
            {log.caller && log.caller?.users.length > 1 ? "*" : ""}
          </div>
          <div
            className={colstyle.column3}
            id={log.id + "_receiver_userName"}
            data-testid={log.id + "_test_receiver_userName"}
          >
            {log.receiver
              ? log.receiver?.users && log.receiver?.users.length > 0
                ? log.receiver?.users[0]?.user_name
                : fallbackString
              : fallbackString}
            {log.receiver && log.receiver?.users.length > 1 ? "*" : ""}
          </div>
          <div
            className={colstyle.column4}
            id={log.id + "_date"}
            data-testid={log.id + "_test_date"}
          >
            <div className="date text-xs leading-6">
              {log.start_time ? getFormattedDate(log.start_time) : " "}
            </div>
            <div
              className="date text-sm leading-6"
              id={log.id + "_time"}
              data-testid={log.id + "_test_time"}
            >
              {log.start_time ? getFormattedTime(log.start_time) : " "}
            </div>
          </div>
          <div
            className={colstyle.column5}
            id="duration"
            data-testid={log.id + "_test_duration"}
          >
            {log.duration ? formatted(log.duration) : " "}
          </div>
          <div
            className={colstyle.column6}
            id="outcome"
            data-testid={log.id + "_test_outcome"}
          >
            {log.outcome ?? " "}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="border-t border-gray-300 flex">
        <div className={colstyle.inner} style={{ border: "0px" }}>
          <div
            id={log.id + "_detail_caller_userName"}
            data-testid={log.id + "_test_detail_caller_userName"}
            colstyle={multipleUsersColumn}
          >
            {log.caller
              ? log.caller.users && log.caller.users.length > 0
                ? displayNameWithFallback(
                    log.id,
                    log.caller?.users,
                    log.caller?.number
                  )
                : ""
              : fallbackString}
          </div>
          <div
            colstyle={multipleUsersColumn}
            id={log.id + "_detail_receiver_userName"}
            data-testid={log.id + "_test_detail_receiver_userName"}
          >
            {log.receiver
              ? log.receiver.users && log.receiver.users.length > 0
                ? displayNameWithFallback(
                    log.id,
                    log.receiver?.users,
                    log.receiver?.number
                  )
                : ""
              : fallbackString}
          </div>
          <div></div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
