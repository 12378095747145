import React from "react";
import { NetworkBackground } from "./components/NetworkBackground";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function UnauthAccess() {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen grid content-center justify-center">
      <div className="mb-56 rounded-xl bg-gray-200 shadow-lg p-8">
        <NetworkBackground />
        <h1 className="text-center p-8 text-2xl">This page does not exist</h1>
        <br />
        <Link
          onClick={() => navigate(-1)}
          className="text-2xl block mx-auto center p-2 text-purple-800 hover:bg-purple-600 hover:text-white rounded-md text-center"
        >
          Return <ArrowForwardIcon />
        </Link>
      </div>
    </div>
  );
}
