import { useState, useEffect, useCallback } from "react";
import axios from "../../../../../../api/axios";

async function sendAPIRequest(url, accessToken) {
  const response = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
  });
  return await response.data;
}

async function getDataRequest(url, accessToken) {
  return await sendAPIRequest(url, accessToken);
}
let ignore = false;

export default function useApiData(url, accessToken) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resultCount, setResultCount] = useState({ total: 0, current: 0 });
  const [needFetching, setNeedFetching] = useState(false);

  const getData = useCallback(
    async (url, accessToken) => {
      setIsLoading(true);

      try {
        const response = await getDataRequest(url, accessToken);
        const result = await response;
        if (!ignore) {
          setData(result);
          setResultCount({
            total: result.count,
            current: result.results.length,
          });
          setError(null);
        }
      } catch {
        if (!ignore) {
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [accessToken, error]
  );
  useEffect(() => {
    if (!data || data.length || needFetching) return;

    setNeedFetching(true);
  }, [data]);

  // this only trigger when needFetching state is changed
  useEffect(() => {
    if (!needFetching) return;

    const fetchData = async () => {
      await getData(url, accessToken);
      needFetching(false);
    };
  }, [needFetching]);

  return [data, error, isLoading, getData, resultCount];
}
