import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountCircleRounded } from "@mui/icons-material";

import Colors from "../../../../../../../styles/Colors";
import Avatar from "@mui/material/Avatar";
import {
  TableCell,
  TableRow,
  //  Checkbox,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";

export function UserTableRow({ user, onclick, languageObj }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const inActiveStyle = () =>
    user.status === "Active" ? Colors.black : Colors.gray[400];

  return (
    <TableRow
      data-testid={"user-row"}
      sx={{ "& td": { color: inActiveStyle } }}
    >
      {/* <TableCell component="th" scope="row">
        <Checkbox />
      </TableCell> */}
      <TableCell
        align="center"
        sx={{
          position: "sticky",
          left: 0,
          background: "white",
          display: "flex",
          alignItems: "center",
          boxShadow: "-1px 3px 6px lightgray",
        }}
      >
        <Tooltip title={"View User Profile"}>
          <IconButton
            className="hover:bg-gray-300"
            onClick={() => onclick("edit-user", user.userDetail)}
          >
            {user.photo ? (
              <Avatar
                src={user.photo}
                alt={user.name}
                className="w-[50px] h-[50px]"
              />
            ) : (
              <AccountCircleRounded
                sx={{ fontSize: 55, color: Colors.gray[400] }}
              />
            )}
          </IconButton>
        </Tooltip>
        &nbsp;{user.name ? user.name : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.status ? user.status : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.entity ? user.entity : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.phone ? user.phone : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.email ? user.email : "-"}
      </TableCell>
      {/*<TableCell align="center" sx={{ whiteSpace: "nowrap" }}>{user.reportsTo ? user.reportsTo : "-"}</TableCell>*/}
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.dateOfBirth ? user.dateOfBirth : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {!user.primaryLanguage
          ? "-"
          : languageObj
          ? languageObj[user.primaryLanguage].description
          : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.passwordUpdatedAt
          ? new Date(user.passwordUpdatedAt).toLocaleString()
          : "-"}
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {user.dateJoined ? new Date(user.dateJoined).toLocaleString() : "-"}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          position: "sticky",
          right: 0,
          background: "white",
          boxShadow: "2px 3px 6px lightgray",
          whiteSpace: "nowrap",
        }}
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {user.options
            .filter((option) => option.permission)
            .map((option) => (
              <MenuItem
                key={option.id}
                onClick={() => {
                  onclick(
                    option.id,
                    !option.userDetail ? user : option.userDetail
                  );
                  handleClose();
                }}
              >
                {option.displayName}
              </MenuItem>
            ))}
        </Menu>
      </TableCell>
    </TableRow>
  );
}
