import React from "react";
import { CommLogPanelContext } from "../context/CommLogPanelContext";
import CommLogHeader from "./CommLogHeader";
import CommLogList from "./CommLogList";
import CommLogSearch from "./CommLogSearch";
import CommLogPagination from "./CommLogPagination";
import CommLogError from "./CommLogError";
import CommLogFilters from "./CommLogFilters";
export default function CommLogPanel({ children }) {
  return (
    <CommLogPanelContext.Provider value={{}}>
      <div id="Log-Panel" data-testid="Log-Panel" className="bg-white rounded-md border-gray-100 border-y px-0">
      {React.Children.map(children, (child) => React.cloneElement(child))}
      </div>
    </CommLogPanelContext.Provider>
  );
}

CommLogPanel.Header = CommLogHeader;
CommLogPanel.List = CommLogList;
CommLogPanel.Search = CommLogSearch;
CommLogPanel.Pagination = CommLogPagination;
CommLogPanel.Error = CommLogError;
CommLogPanel.Filters = CommLogFilters;
