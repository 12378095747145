import React, { useState, useEffect, useContext } from "react";
import { ListItem } from "@mui/material";
import CurrencyValueEditV2 from "../../../../../global/CurrencyValueEdit_v2";
import ReceiveCheckButton from "../SettlementAttorneyDetail/ReceiveCheck/ReceiveCheckButton";

export default function MedicalListItem({
  detail,
  trigger,
  setTrigger,
  disabled,
  typeChoices,
}) {
  const [details] = useState(detail);
  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:last-of-type:border-0 sm:p-2 px-2 py-2 text-center self-center";

  return (
    <ListItem className="p-0 items-start">
      {!details ? (
        ""
      ) : (
        <div
          className={
            "text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 justify-evenly"
          }
        >
          <div className={`block pl-2 w-1/5 ${resultClasses}`}>
            {details.provider_name}
          </div>
          <div className={`block pl-2 w-[15%] ${resultClasses}`}>
            ${details.initial_amount}
          </div>
          <div className={`block pl-2 w-1/5 ${resultClasses}`}>
            {!disabled ? (
              <CurrencyValueEditV2
                title="Reduction Amount"
                apiUrl={`/api/provider_settlement/${details.pk}/`}
                val={details.settled_amount}
                valKey="settled_amount"
                trigger={trigger}
                setTrigger={setTrigger}
                blockNullPost={details.has_check ? true : false}
              />
            ) : (
              "-"
            )}
          </div>
          <div
            className={`block pl-2 w-[10%] ${resultClasses}`}
            data-testid="Bank-Check-Column"
          >
            <ReceiveCheckButton
              checkReceived={detail.has_check}
              display={true}
              detail={detail}
            />
            {/* <BankCheckButton
              states={computeInitButtonState}
              hasCheck={details.has_check}
              settlementAmount={details.settled_amount}
              initialAmount={details.initial_amount}
              provider={{ id: details.provider, name: details.provider_name }}
              caseId={details.case}
              updatedBy={details.updated_by}
              recentCheck={recentCheck}
            /> */}
          </div>
          <div className={`block pl-2 w-[10%] ${resultClasses}`}>
            {Math.round(details.discount)}%
          </div>
          <div className={`block pl-2 w-1/5 ${resultClasses}`}>
            {typeChoices[details.settlement_type]}
          </div>
        </div>
      )}
    </ListItem>
  );
}
