import React from "react";
import CommLogItem from "./CommLogItem";
import { CircularProgress, List } from "@mui/material";

export default function CommLogList({ logList, logType }) {
  let style = {
    outer: "text-sm py-0 px-0",
    inner:
      "text-black w-full flex bg-white   border-gray-100 border-y items-center py-0 px-4 ",
    column1: "w-[10%] text-left align-middle ",
    column2: "w-[19%] text-left ",
    column3: "w-[19%] text-left align-middle ",
    column4: "w-[15%] text-center align-middle ",
    column5: "w-[17%] text-center align-middle ",
    column6: "w-[19%] text-center align-middle ",
    header: "text-xs uppercase font-bold py-3 align-middle ",
  };

  return (
    <div
      id="list-container"
      data-testid="list-container"
      className="h-[58.5vh]"
    >
      <div className={style.outer}>
        <div className={style.inner}>
          <div className={style.column1 + style.header}>Dir.</div>
          <div className={style.column2 + style.header + "text-left"}>
            Caller
          </div>
          <div className={style.column3 + style.header}>Receiver</div>
          <div className={style.column4 + style.header}>Start Date/Time</div>
          <div className={style.column5 + style.header}>Duration</div>
          <div className={style.column6 + style.header}>Outcome</div>
        </div>
      </div>
      {!logList ? (
        <div id="loading-spinner" data-testid="loading">
          <CircularProgress color="secondary" />
        </div>
      ) : logList.length === 0 ? (
        <div className="text-center my-2 py-20" data-testid="no-items">
          No Logs to Show
        </div>
      ) : (
        <List className="max-h-[48vh] overflow-y-auto border-gray-200 border-y">
          <div className="items-list">
            {logList.map((log, idx) => (
              <CommLogItem
                key={log.id + "_" + idx}
                logType={logType}
                log={log}
                idx={idx}
                style={style}
              />
            ))}
          </div>
        </List>
      )}
    </div>
  );
}
