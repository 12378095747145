import { CloseOutlined } from "@mui/icons-material";
import { Button, Dialog, List, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DocumentListItem from "./DocumentListItem";
import DocumentUpdateListItem from "./DocumentUpdateListItem";
import ViewDocumentModal from "./Modals/ViewDocumentModal";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import ShowMoreButton from "../../../../../../../global/ShowMoreButton";
import LoadingIndicator from "../../../../../../../global/LoadingIndicator";

export default function ViewDocuments({
  documents,
  viewDocuments,
  setViewDocuments,
  documentTypeObj,
  truncate,
  docTrigger,
  setDocTrigger,
  docResLimit,
  setDocResLimit,
  showMoreDocsVisible,
  setSearchDocs,
  searchDocs,
  docLoading,
  docNeedsType,
  clientId
}) {
  const { accessToken } = useContext(DataContext);
  const [viewDocument, setViewDocument] = useState(false);
  const [activeDoc, setActiveDoc] = useState(false);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [docValidation, setDocValidation] = useState();
  const [docSourceOptions, setDocSourceOptions] = useState();

  const closeDocModal = () => {
    setActiveDoc("");
    setViewDocument(false);
  };

  useEffect(() => {
    axios
      .options(`/api/documents/`, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setDocSourceOptions(response.data.actions.POST.source.choices);
        setDocTypeOptions(response.data.actions.POST.document_type.choices);
      })
      .catch((error) => {
        // handle errors
      });

    axios
      .get(`/api/documents/validate`, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setDocValidation(response.data);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
  }, [setDocValidation, accessToken, setDocTypeOptions]);

  const handleCloseModal = () => {
    setViewDocuments(false);
    // setEditType(false);
  };

  return (
    <Dialog open={viewDocuments} fullScreen={true}>
      <div className="md:h-100">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
            fontSize: "1.2rem",
          }}
        >
          <div>&nbsp;</div>
          <Button onClick={() => handleCloseModal()} style={{ padding: "0" }}>
            <CloseOutlined style={{ color: "#00000" }} />
          </Button>
        </div>
        <span
          style={{
            width: "fit-content",
            display: "block",
            margin: "0 auto",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Documents
        </span>
        {!docNeedsType ? (
          ""
        ) : docNeedsType.docs_with_no_type > 0 ? (
          <div
            style={{
              background: "red",
              padding: "0 10px",
              borderRadius: "5px",
              width: "fit-content",
              margin: "10px auto",
            }}
          >
            <p style={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Existing documents need a type before uploading new files
            </p>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            width: "100%",
            margin: "auto",
            padding: "0 2rem",
            display: "flex",
          }}
        >
          <Tooltip title="Search Documents">
            <TextField
              style={{ width: "25%" }}
              placeholder="Search Clients Docs"
              size="small"
              name="search"
              value={searchDocs}
              InputLabelProps={{ shrink: true }}
              label="Search"
              onChange={(event) => setSearchDocs(event.target.value)}
            />
          </Tooltip>
          {/* <div style={{ position: "relative", zIndex: "20" }}>
            <Tooltip
              title={`${
                !toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
              }`}
            >
              <IconButton
                onClick={() => setToggleFilterMenu(!toggleFilterMenu)}
              >
                <FilterAlt />
              </IconButton>
            </Tooltip>
            {toggleFilterMenu ? (
              <div
                style={{
                  position: "absolute",
                  wid: "fit-content",
                  left: "0",
                  textAlign: "center",
                  background: "#FFF",
                  overflowY: "auto",
                  borderRadius: "5px",
                  padding: "1rem",
                }}
                onMouseLeave={() => setToggleFilterMenu(false)}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    display: "flex",
                    minWidth: "400px",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>Filter</h2>
                  <Tooltip
                    title={`${
                      toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                    }`}
                  >
                    <IconButton
                      onClick={() => setToggleFilterMenu(!toggleFilterMenu)}
                      className={`${toggleFilterMenu ? "" : "rotate-180"}`}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="px-4">
                  {" "}
                  <MultiSelectFilterV2
                    title="Sort by Type"
                    responseArray={docTypeOptions}
                    selectedItems={selectedTypes}
                    setSelectedItems={setSelectedTypes}
                    resLimit={resLimit}
                    setResLimit={setResLimit}
                    setActiveItems={setActiveTypes}
                    activeItems={activeTypes}
                    nameKey="display_name"
                    valueKey="value"
                  />
                  <div className="my-2">
                    <SearchOptionGroup
                      setValue={setIsChecked}
                      value={isChecked}
                      title="Checked Documents"
                    />
                  </div>
                  <div className="my-2">
                    <SearchOptionGroup
                      setValue={setIsApproved}
                      value={isApproved}
                      title="Approved Documents"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>
        {docLoading ? <LoadingIndicator isLoading={docLoading} /> : ""}

        <div
          style={{
            borderBottom: "1px solid #EBEBEB",
            padding: "16px 0",
            display: "flex",
            width: "100%",
            maxWidth: "100%",
            margin: "0 16px",
          }}
        >
          <div style={{ width: "75%", display: "flex", padding: "0 10px" }}>
            <div
              style={{
                textAlign: "left",
                fontSize: "16px",
                alignSelf: "center",
                width: "18%",
                fontWeight: "bold",
              }}
            >
              Document Type
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                alignSelf: "center",
                width: "18%",
                fontWeight: "bold",
              }}
            >
              Time Stamps
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                alignSelf: "center",
                width: "15%",
                fontWeight: "bold",
              }}
            >
              Updated By
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                alignSelf: "center",
                width: "28%",
                fontWeight: "bold",
              }}
            >
              Provider/Referral
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                alignSelf: "center",
                width: "14%",
                fontWeight: "bold",
              }}
            >
              Source
            </div>
          </div>
        </div>

        <div style={{ maxWidth: "100%", margin: "0 auto" }}>
          <List style={{ height: "70vh", overflowY: "auto" }}>
            {!documents ? (
              ""
            ) : documents.length === 0 ? (
              <p style={{ margin: "0 auto", width: "fit-content" }}>
                No Documents
              </p>
            ) : (
              documents.map((doc) => {
                if (doc.document_type === null) {
                  return (
                    <DocumentUpdateListItem
                      document={doc}
                      key={doc.pk}
                      documentTypeObj={documentTypeObj}
                      activeDoc={activeDoc}
                      setActiveDoc={setActiveDoc}
                      setViewDocument={setViewDocument}
                      docTypeOptions={docTypeOptions}
                      docValidation={docValidation}
                      truncate={truncate}
                      docTrigger={docTrigger}
                      setDocTrigger={setDocTrigger}
                      docSourceOptions={docSourceOptions}
                      clientId={clientId}
                    />
                  );
                } else
                  return (
                    <DocumentListItem
                      document={doc}
                      key={doc.pk}
                      documentTypeObj={documentTypeObj}
                      activeDoc={activeDoc}
                      setActiveDoc={setActiveDoc}
                      setViewDocument={setViewDocument}
                      docTypeOptions={docTypeOptions}
                      docValidation={docValidation}
                      truncate={truncate}
                      docTrigger={docTrigger}
                      setDocTrigger={setDocTrigger}
                      docSourceOptions={docSourceOptions}
                      clientId={clientId}
                    />
                  );
              })
            )}
            <ShowMoreButton
              showMoreVisible={showMoreDocsVisible}
              setResLimit={setDocResLimit}
              resLimit={docResLimit}
              showMoreAmt={5}
            />
          </List>
        </div>
      </div>
      <ViewDocumentModal
        viewDocument={viewDocument}
        closeDocModal={closeDocModal}
        activeDoc={activeDoc}
      />
    </Dialog>
  );
}
