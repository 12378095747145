import React from "react";
import { TextField } from "@mui/material";

export default function SearchAutoSubmit({ onchange, placeholder, noStyle }) {
  return (
    <div className={noStyle ? "" : "relative w-full"}>
      <div className={noStyle ? "" : "flex items-center mt-3 mx-3"}>
        <TextField
          className="placeholder:text-center rounded-lg min-w-[300px] text-sm w-full"
          placeholder={placeholder}
          size="small"
          name="search"
          //value={value}
          InputLabelProps={{ shrink: true }}
          label="Search"
          onChange={(event) => onchange(event.target.value)}
        />
      </div>
    </div>
  );
}
