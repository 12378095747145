import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "../api/axios";
import DataContext from "../../context/DataContext";
import ResponseModal from "./ResponseModal";
import ApiRequestErrorHandler from "./ApiRequestErrorHandler";
import { Check, Close } from "@mui/icons-material";

export default function InlineValueEdit({
  editVal,
  setEditVal,
  canEdit,
  title,
  inputVal,
  setInputVal,
  formatter,
  valKey,
  caseInformation,
}) {
  const { accessToken, trigger, setTrigger } = useContext(DataContext);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);

  const postFunc = () => {
    let patchedData = {
      [valKey]: inputVal,
    };
    axios
      .patch(`/api/case/${caseInformation.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown(`${title} updated`);
        setIsError(false);
      })
      .catch((error) => {
        const res = error.response.data;
        const errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setTrigger(!trigger);
    setInputVal("");
    setEditVal(false);
  };

  const cancelEdit = () => {
    setEditVal(false);
    if (caseInformation[valKey] !== inputVal) {
      setInputVal(caseInformation[valKey]);
    }
  };

  return (
    <div className="flex w-fit my-2">
      {editVal === false ? (
        <Tooltip
          title={
            canEdit
              ? "You do not have permissions to edit this content"
              : `Click to edit ${title}`
          }
        >
          <span>
            <Button
              disabled={canEdit ? true : false}
              onClick={() => setEditVal(true)}
              className="cursor-pointer p-0"
            >
              <span className="font-bold text-sm">{title}:</span>
              <span className="text-sm">
                &nbsp;{!formatter ? inputVal : formatter.format(inputVal)}
              </span>
            </Button>
          </span>
        </Tooltip>
      ) : (
        <div className="flex">
          <TextField
            className="placeholder:text-center rounded-lg w-full text-sm"
            placeholder={title}
            size="small"
            name={title}
            value={inputVal}
            InputLabelProps={{ shrink: true }}
            label={title}
            onChange={(event) => setInputVal(event.target.value)}
          />
          <Tooltip title="Sumbit">
            <IconButton
              onClick={postFunc}
              className="text-green-600 hover:text-white hover:bg-green-600 text-sm"
            >
              <Check />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel Edit">
            <IconButton onClick={() => cancelEdit()}>
              <Close className="text-red-600" />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
