import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import { List, ListItem } from "@mui/material";
import ShowMoreButton from "../../../../global/ShowMoreButton";

export default function CaseViewCounts({ caseObj }) {
  const { accessToken } = useContext(DataContext);
  const [countData, setCountData] = useState([]);
  const [countObj, setCountObj] = useState({});
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  useEffect(() => {
    if (!caseObj) {
      return;
    } else {
      axios
        .get(`/api/case/${caseObj.pk}/access_views/?limit=${resLimit}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          const subData = response.data.results;
          let countArr = [];

          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }

          for (const item in subData) {
            countArr.push(subData[item]);
          }
          setCountObj(data);
          setCountData(countArr);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    }
  }, [accessToken, setCountData, setCountObj, caseObj, resLimit]);

  return (
    <>
      {!caseObj ? (
        ""
      ) : (
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "45vh",
          }}
        >
          <h1
            style={{
              fontSize: 20,
              margin: "0 0 1rem 0",
              textAlign: "center",
              color: "#9333EA",
              fontWeight: "bold",
            }}
          >
            Case View Count: {countObj.total_access_count}
          </h1>
          <div className="flex justify-between w-full h-[35vh]">
            <List
              style={{
                width: "100%",
                maxHeight: "50vh",
                overflowY: "auto",
              }}
            >
              {!countData
                ? ""
                : countData.map((countItem, idx) => (
                    <ListItem className="w-full py-0 pt-0 px-2" key={idx}>
                      <div className="text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y px-2">
                        <div style={{ fontWeight: "bold" }}>
                          {countItem.name} -
                        </div>
                        <div>&nbsp;{countItem.access_count} views</div>
                      </div>
                    </ListItem>
                  ))}
              <ListItem className="justify-center">
                <ShowMoreButton
                  showMoreVisible={showMoreVisible}
                  setResLimit={setResLimit}
                  resLimit={resLimit}
                  showMoreAmt={5}
                />
              </ListItem>
            </List>
          </div>
        </div>
      )}
    </>
  );
}
