import React, { useState, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ResponseModal from "../../../../../global/ResponseModal";
import { Delete } from "@mui/icons-material";
import FormSubmitButton from "../../../../../global/FormComponents/FormSubmitButton";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function DeleteApptv2({
  selectedAppt,
  setOpenDeleteAppt,
  proCliTrigger,
  setProCliTrigger,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenDeleteAppt(false);
    setProCliTrigger(!proCliTrigger);
  };
  function handleApptDelete() {
    setLoading(true);
    axios
      .delete(`/api/appointments/${selectedAppt.pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
        }
        setLoading(false);
        setOpenError(!openError);
      });
  }

  return (
    <div className="max-w-[750px] bg-white px-4">
      {!selectedAppt ? (
        "Please Select an Appointment to Continue"
      ) : (
        <>
          <div>
            <div className="my-2">
              <h2
                className="underline text-lg mb-2"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                PROVIDER INFO
              </h2>
              <div>
                <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                <span>{selectedAppt.provider.name}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                <span>{selectedAppt.provider.phone}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                <span>{selectedAppt.provider.email}</span>
              </div>
            </div>

            <div className="my-2">
              <h2
                className="underline text-lg mb-2"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                CLIENT INFO
              </h2>
              <div>
                <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
                <span>{selectedAppt.client.name}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                <span>{selectedAppt.client.email}</span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                <span>{selectedAppt.client.phone}</span>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h2
              className="underline text-lg font-bold mb-2"
              style={{ fontWeight: "bold", fontSize: "20px" }}
            >
              APPOINTMENT DETAILS
            </h2>
            <div>
              {" "}
              <span style={{ fontWeight: "bold" }}>Date/Time:</span>{" "}
              <span className="justify-self-start">
                {new Date(selectedAppt.scheduled).toLocaleString()}
              </span>
            </div>
            <div>
              {" "}
              <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
              <span className="justify-self-start">{selectedAppt.status}</span>{" "}
            </div>
            <div>
              {" "}
              <span style={{ fontWeight: "bold" }}>Notes:</span>{" "}
              <span
                className="justify-self-start"
                dangerouslySetInnerHTML={{ __html: selectedAppt.notes }}
              ></span>{" "}
            </div>
          </div>
        </>
      )}

      <p className="my-4">
        <span className="text-red-600 underline font-bold">Warning:</span>{" "}
        Clicking the "Permanently Delete Appointment" button below will
        permanently remove the appointment data above from the database, after
        proceeding it <span className="underline font-bold">cannot</span> be
        recovered. Please make sure you want to delete the data before
        proceeding.
      </p>
      <FormSubmitButton
        isLoading={loading}
        handleSubmit={handleApptDelete}
        buttonText="Permanently Delete Appointment"
        buttonColor="red"
        ButtonIcon={<Delete />}
      />
      <ResponseModal
        title="Appointment Deleted"
        description="Successfully Deleted Appointment"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Deleting Appointment Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </div>
  );
}
