import React, { useCallback, useContext, useState, useEffect } from "react";
import ProviderListView from "./ProviderListView";
import ProviderListEdit from "./ProviderListEdit";
import { ControlPointOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import PermissionWrapper from "../../PermissionWrapper";
import { provider } from "../../../api/endpoints/provider";
import DataContext from "../../../../context/DataContext";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import ResponseModal from "../../ResponseModal";

export default function ProviderToProvider({
  providerId,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [providers, setProviders] = useState(null);


  const perms = {
    canViewAll:userRoles.permissions.includes("provider.view_all_provider_relations"),
    canView: userRoles.permissions.includes("provider.view_providerrelation"),
    canEdit: userRoles.permissions.includes("provider.change_providerrelation"),
    canAdd: userRoles.permissions.includes("provider.add_providerrelation"),
    canDelete: userRoles.permissions.includes("provider.delete_providerrelation"),
  };
  const getValue = (value) => {
    if (value === "") {
      return null;
    }
    return value;
  };
  const handleAddRelation = (value) => {
    const data = {
      provider: providerId,
      entity: getValue(value.id),
    };
    provider
      .postProviderRelations(data, accessToken)
      .then((res) =>res)
      .then((res) => {
        setIsLoading(true);
        setToggleEdit(false);
        setProviders(null);
        return res;
       })
      .catch((error) => {
        let res = error.response;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };


  const handleDeleteRelation = (value) => {
    
    provider.deleteProviderRelations(value.id, accessToken)
      .then((res) =>res)
      .then((res) => {
        setIsLoading(true);
        setToggleEdit(false);
        setProviders(null);
        return res;
       })
       .catch((error) => {
         let res = error.response;
         let errArr = ApiRequestErrorHandler(res);
         setResponseModal(true);
         setResponseBreakdown(errArr);
         setIsError(true);
       });
  }

  const fetchProviderRelations = useCallback(()=>{
    return provider
    .getProviderRelations(providerId, accessToken, null)
    .then((response) => {
      setProviders(response.results);
      return response;
    })
    .then((response) => {
      setIsLoading(false);
      return response;
    })
    .catch((error) => {
      let errArr = ApiRequestErrorHandler(error.response);
      setIsError(true);
      setResponseModal(true);
      setResponseBreakdown(errArr);
    });
  }, [providerId, accessToken]);


  const closeResModal = () => {
    if (isError) {
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
    } else {
      setToggleEdit(false);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
    }
  };

  useEffect(() => {
    let active = true;
    if (active) {
      if (isLoading && !providers) {
        fetchProviderRelations();
      }
    }
    return () => {
      active = false;
    };
  }, [
    fetchProviderRelations,
    isLoading,
    providers
  ]);

  const setToggleEditMode = (mode) =>{
    setToggleEdit(mode);
    setIsLoading(true);
  }

  return (
    <div
      id="Provider-To-Provider-Relationship"
      data-testid="Provider-To-Provider-Relationship"
      style={{
        border:'1px solid #ccc',
        borderRadius:'5px',
        padding:'20px',
        marginRight:'10px'
      }}
    >
      <div className="flex items-center w-full border-b mb-2 pb-1">
        <h2 className="font-bold">Provider to Provider Relationships</h2>
        {perms.canEdit && !toggleEdit ? (
          <Tooltip title="Add Provider">
            <IconButton
              onClick={() => setToggleEditMode(true)}
              className="text-green-600"
            >
              <ControlPointOutlined className="block" />
            </IconButton>
          </Tooltip>
        )
        : ""
      }
      </div>
      {!toggleEdit && (
      <PermissionWrapper permission={perms.canView}>
        <ProviderListView providerList={providers} />
      </PermissionWrapper>
      )}
      {toggleEdit && (
      <PermissionWrapper permission={perms.canEdit}>
        <ProviderListEdit
          permissions={perms}
          providerList={providers}
          addRelation={handleAddRelation}
          deleteRelation={handleDeleteRelation}
          setToggleEdit={setToggleEditMode}
          providerId={providerId}
        />
      </PermissionWrapper>
      )}
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
