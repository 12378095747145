import ApiServices from "../service";

export const provider_settlement = {
  getSettlementType: (accessToken) =>
    ApiServices.optionsData("/api/provider_settlement/", accessToken)
      .then((res) => res)
      .then((res) => res.actions.POST.settlement_type.choices)
      .catch(err => console.log(err)),

  retrieve: (id, accessToken) =>
  ApiServices.getData(`/api/provider_settlement/?case=${id}`, accessToken)
    .then((res) => res)
    .then((res) => res)
    .catch(err => console.log(err)),
};
