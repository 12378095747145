import React from "react";
import { Alert, Button } from "@mui/material";

export default function Success({
  handleCloseFunc
}) {
  return (
    <div data-testid="Bank-Check-Success">
      <div variant="outlined" severity="success" role="alert">
        <p className="text-center">
          <Alert>
            <h1 className="text-center text-[1.2rem] font-bold">
              Success!
            </h1>
            You have successfully submitted.
          </Alert>
          <Button
          onClick={(e) =>handleCloseFunc(e)}
        >
          OK
        </Button>
        </p>
      </div>
    </div>
  );
}
