import {
  CheckCircleOutline,
  Email,
  HighlightOff,
  Lock,
  LockOpen,
  LockReset,
  Warning,
} from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ResponseModal from "../../../../../../global/ResponseModal";
import ModalShell from "../../../../../../global/ModalShell";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import { useNavigate } from "react-router-dom";

export default function Toolbar({
  trigger,
  setTrigger,
  userInfo,
  isDrawer,
  reloadTrigger,
  setReloadTrigger,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const [confirmLockUser, setConfirmLockUser] = useState(false);
  const [confirmUnLockUser, setConfirmUnLockUser] = useState(false);
  const [userEmailCompare, setUserEmailCompare] = useState("");
  const navigate = useNavigate();

  const resetUserPassword = () => {
    const postData = { email: userInfo.email };

    axios
      .post(`/api/password_reset/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Password reset email sent to address on file");
        setIsError(false);
        setConfirmReset(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const lockUserAccount = () => {
    axios
      .delete(`/api/users/${userInfo.pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("User has been deleted");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const unLockUserAccount = () => {
    const patchData = {
      is_active: true,
    };

    axios
      .patch(`/api/users/${userInfo.pk}/`, patchData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("User account has been returned to active");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setConfirmLockUser(false);
    setConfirmUnLockUser(false);
    setTrigger(!trigger);
    if (!isError && !isDrawer) {
      navigate("/my-account");
    }
    if (reloadTrigger === true || reloadTrigger === false) {
      setReloadTrigger(!reloadTrigger);
    }
  };

  return (
    <div className=" shadow-lg p-4 h-fit xl:mr-6 mt-6 rounded-sm">
      <div className="flex gap-2">
        {userRoles.permissions.includes("users.change_user") ? (
          <>
            {" "}
            <Button
              endIcon={<LockReset />}
              variant="outlined"
              className="border-yellow-600 text-yellow-600 hover:bg-yellow-600 hover:border-yellow-600 hover:text-white"
              onClick={() => setConfirmReset(true)}
            >
              Reset Users Password
            </Button>
          </>
        ) : (
          ""
        )}
        {userRoles.permissions.includes("users.delete_user") &&
        userInfo.is_active ? (
          <Button
            endIcon={<Lock />}
            variant="outlined"
            className="border-red-600 text-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white"
            onClick={() => setConfirmLockUser(true)}
          >
            Lock User Account
          </Button>
        ) : (
          ""
        )}
        {userRoles.permissions.includes("users.change_user") &&
        !userInfo.is_active ? (
          <Button
            endIcon={<LockOpen />}
            variant="outlined"
            className="border-green-600 text-green-600 hover:bg-green-600 hover:border-green-600 hover:text-white"
            onClick={() => setConfirmUnLockUser(true)}
          >
            Unlock User Account
          </Button>
        ) : (
          ""
        )}
      </div>
      <ModalShell
        open={confirmReset}
        setOpen={setConfirmReset}
        customWidth={"sm"}
        title="Confirm Password Reset"
      >
        <p className="text-center mx-auto w-3/4">
          Clicking below will send an email to your email address on file
          prompting you to reset your existing password.{" "}
          <span className="block font-bold my-1">
            <Warning className="text-yellow-600" /> Any existing logins or
            sessions on other devices will no longer be active once the email is
            sent.
          </span>
        </p>
        <Button
          onClick={() => resetUserPassword()}
          className="text-yellow-600 hover:bg-yellow-600 border-yellow-600 hover:border-yellow-600 hover:text-white mt-4"
          variant="outlined"
          startIcon={<Email />}
        >
          Send Reset Password Email
        </Button>
      </ModalShell>
      <ModalShell
        open={confirmLockUser}
        setOpen={setConfirmLockUser}
        customWidth={"sm"}
        color={"#d32f2f"}
        title="Confirm Lock User"
      >
        <p className="text-center mx-auto w-full">
          Clicking below will remove this account from the NetProApp system.{" "}
          <span className="block font-bold my-1">
            <Warning className="text-red-600" /> This user will immediately lose
            access and the user information will not be accessible. If you need
            to unlock this account, please use the 'Help' form below to request
            assistance.
          </span>
        </p>
        <div className="my-2 w-full">
          <p className="text-center text-[14px] text-gray-600">
            Confirm user email to delete
          </p>
          <TextField
            className="w-full"
            placeholder="Confirm User Email"
            error={userEmailCompare !== userInfo.email ? true : false}
            name="user-email"
            value={userEmailCompare}
            InputProps={{
              endAdornment:
                userEmailCompare !== userInfo.email ? (
                  <InputAdornment position="end" className="text-red-600">
                    <HighlightOff />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end" className="text-green-600">
                    <CheckCircleOutline />
                  </InputAdornment>
                ),
            }}
            onChange={(e) => {
              setUserEmailCompare(e.target.value);
            }}
          />
        </div>
        <Button
          onClick={() => lockUserAccount()}
          disabled={userEmailCompare !== userInfo.email ? true : false}
          className="border-red-600 text-red-600 hover:bg-red-600 hover:border-red-600 hover:text-white"
          variant="outlined"
          endIcon={<Lock />}
        >
          Confirm account removal
        </Button>
      </ModalShell>
      <ModalShell
        open={confirmUnLockUser}
        setOpen={setConfirmUnLockUser}
        customWidth={"sm"}
        color={"#32CD30"}
        title="Confirm Unlock User"
      >
        <p className="text-center mx-auto w-full">
          Clicking below will return this account and the user's access to the
          NetProApp system.{" "}
          <span className="block font-bold my-1">
            <Warning className="text-green-600" /> This user will immediately
            regain access to the application. If you need more information on
            process, please use the 'Help' form below to request assistance.
          </span>
        </p>
        <Button
          onClick={() => unLockUserAccount()}
          className="border-green-600 text-green-600 hover:bg-green-600 hover:border-green-600 hover:text-white"
          variant="outlined"
          endIcon={<LockOpen />}
        >
          Confirm account unlock
        </Button>
      </ModalShell>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
