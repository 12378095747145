import React, { useContext } from "react";
import DataContext from "../../context/DataContext";
import { IconButton, List, ListItem } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import generatedGitInfo from "../../generatedGitInfo.json";

export default function PatchInfo({ open, setOpenPatchInfo }) {
  const { patchNotif } = useContext(DataContext);
  return (
    <div className={`p-2 bg-white relative shadow-lg md:max-w-[50vw] z-30`}>
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-xl font-bold">Patch Notes</h1>
          <p className="text-[14px]">
            App Version: {generatedGitInfo.gitCommitHash}
          </p>
        </div>
        <IconButton onClick={() => setOpenPatchInfo(false)}>
          <CloseOutlined />
        </IconButton>
      </div>
      <List className="max-h-[90vh] overflow-y-auto">
        {!patchNotif ? (
          <ListItem className="bg-gray-200 shadow-md my-2 flex-col flex-start items-start">
            No Current Updates
          </ListItem>
        ) : (
          patchNotif
            .sort((a, b) => {
              return new Date(b.version_hash) - new Date(a.version_hash);
            })
            .map((item) => {
              let val;
              if (item.title === "hidden") {
                return;
              } else {
                val = (
                  <ListItem
                    className="bg-gray-200 shadow-md my-2 flex-col flex-start items-start"
                    key={item.pk}
                  >
                    {" "}
                    <h2 className="text-xl font-bold ">{item.title}</h2>
                    {!item.release_at ? (
                      ""
                    ) : (
                      <p>{new Date(item.release_at).toLocaleString()}</p>
                    )}
                    <div
                      dangerouslySetInnerHTML={{ __html: item.content }}
                      className="notes"
                    />
                  </ListItem>
                );
              }
              return val;
            })
        )}
      </List>
    </div>
  );
}
