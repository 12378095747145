import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import AutoCompleteSearch from "../../../../../../global/AutoCompleteSearch/AutoCompleteSearch";

export default function ProviderToLocation({
  managedLocations,
  selectedLocations,
  setSelectedLocations,
  setSelectedRole,
  selectedRole,
}) {
  return (
    <div>
      <h3 className="text-gray-500 mt-4 mx-2">
        Assign user to Provider Locations (optional)
      </h3>
      <div className="flex">
        <div className="w-2/3 mr-2">
          <Autocomplete
            className="w-full"
            multiple
            options={
              !managedLocations ? [] : managedLocations.map((option) => option)
            }
            getOptionLabel={(option) => option.name}
            value={selectedLocations}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(e, value) => {
              setSelectedLocations(value);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Locations"
                error={selectedRole && !selectedLocations.length}
                helperText={
                  selectedRole && !selectedLocations.length
                    ? "Add at least one provider"
                    : ""
                }
              />
            )}
          />
        </div>
        <div className="w-1/3">
          <AutoCompleteSearch
            apiUrl="/api/user_roles/?for_type=provider"
            keyVal="role"
            title="Role"
            setVal={setSelectedRole}
            // activeEditObj={userToProviders[0].role}
            displayKey="name"
            customSize={"normal"}
            hasError={!selectedRole && selectedLocations.length}
            helperText={
              !selectedRole && selectedLocations.length ? "Add Role" : ""
            }
          />
        </div>
      </div>
    </div>
  );
}
