import {
  Button,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";
import ShowMoreButton from "./ShowMoreButton";

export default function StatusSelectDropdown({
  editActive,
  setEditActive,
  val,
  optionsArr,
  title,
  postFunc,
  valKey,
  bgVal,
  showRemove,
  showMoreVisible,
  setResLimit,
  resLimit,
  showMoreAmt,
  objDispKey,
  objValKey,
}) {
  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };
  const displayVal = (val) => {
    let newDisplayVal;
    for (const item in optionsArr) {
      if (optionsArr[item].value === val) {
        newDisplayVal = optionsArr[item].display_name;
      } else if (optionsArr[item].id === val) {
        newDisplayVal = optionsArr[item].reason;
      } else if (optionsArr[item].pk === val) {
        newDisplayVal = optionsArr[item].name;
      } else if (optionsArr[item].key === val) {
        newDisplayVal = optionsArr[item].label;
      }
    }
    return newDisplayVal;
  };

  return (
    <div className="flex items-end mr-2">
      {!editActive ? (
        ""
      ) : (
        <FormGroup className="justify-center p-0 text-sm w-full">
          <InputLabel id={valKey} className="text-sm">
            {title}
          </InputLabel>
          <Select
            size="small"
            labelId={valKey}
            value={!val ? "" : val}
            label={title}
            onChange={(e) => postFunc(e, valKey)}
            className="text-sm"
          >
            {!showRemove ? (
              ""
            ) : (
              <MenuItem
                value={null}
                id={"null"}
                className="text-sm"
                key={"null"}
              >
                Remove
              </MenuItem>
            )}
            {!optionsArr
              ? ""
              : optionsArr.map((opt, idx) => (
                  <MenuItem
                    value={opt[objValKey]}
                    id={opt[objValKey]}
                    className="text-sm"
                    key={idx}
                  >
                    <Tooltip title={`${opt[objDispKey]}`} placement="right">
                      {truncate(opt[objDispKey], 20, 25)}
                    </Tooltip>
                  </MenuItem>
                ))}
            {!showMoreAmt ? (
              ""
            ) : (
              <ShowMoreButton
                showMoreVisible={showMoreVisible}
                setResLimit={setResLimit}
                resLimit={resLimit}
                showMoreAmt={5}
              />
            )}
          </Select>
        </FormGroup>
      )}
      {postFunc === false ? (
        <Tooltip title="You do not have permissions to edit this content">
          <span>
            {" "}
            <Button
              disabled={true}
              className={`font-bold normal-case text-black rounded-md bg-gray-100`}
            >
              {val === null ? `Add ${title}` : displayVal(val)}
            </Button>
          </span>
        </Tooltip>
      ) : editActive ? (
        <Button onClick={() => setEditActive(false)}>x</Button>
      ) : (
        <Tooltip
          title={
            val === null || val === ""
              ? `Click to edit ${title}`
              : !displayVal(val)
              ? "Status deprecated please update"
              : displayVal(val)
          }
        >
          <span>
            <Button
              onClick={() => setEditActive(true)}
              className={`font-bold normal-case text-black rounded-md ${
                !bgVal ? "bg-gray-100" : bgVal(val)
              }
      `}
            >
              {val === null || val === ""
                ? `Add ${title}`
                : !displayVal(val)
                ? "Status deprecated please update"
                : displayVal(val)}
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  );
}
