import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../context/DataContext";
import EmailDisplayRow from "./EmailDisplayRow";
import axios from "../../../api/axios";
import { IconButton, Tooltip } from "@mui/material";
import { ControlPointOutlined } from "@mui/icons-material";
import InlineEdit from "../components/InlineEdit";
import { createDisplayObj } from "../../helpers";

export default function ProviderEmails({ providerDetail, activeProvObj }) {
  const { userRoles, accessToken, loggedInUser } = useContext(DataContext);
  const [providerEmails, setProviderEmails] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [emailTypeOptions, setEmailTypeOptions] = useState([]);
  const [typeDisplayObj, setTypeDisplayObj] = useState("");
  const [providerTypesActive, setProviderTypesActive] = useState("");

  useEffect(() => {
    // field_name=email_type
    if (!userRoles.permissions.includes("utilities.view_fieldoptions")) {
      return;
    } else {
      axios
        .get(
          `/api/field_options/?content_type=provideremail&field_name=email_type`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          const data = response.data.results;
          setEmailTypeOptions(data);
          setTypeDisplayObj(createDisplayObj(data, "email_type"));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [accessToken, setEmailTypeOptions, userRoles.permissions]);

  const addFormFields = () => {
    setFormValues([...formValues, formValues.length]);
  };

  const handleCancelEdit = () => {
    setFormValues([]);
    setToggleEdit(false);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    if (
      !providerDetail ||
      !userRoles.permissions.includes("provider.view_provideremail")
    ) {
      return;
    } else {
      axios
        .get(
          `/api/provider_email/?provider=${providerDetail.pk}&ordering=created_at`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then(function (response) {
          let data = response.data.results;
          setProviderEmails(data);
          let activeObj = {};

          for (const item in data) {
            activeObj[data[item].email_type] = data[item].email_type;
          }
          setProviderTypesActive(activeObj);
        })
        .catch(function (error) {});
    }
  }, [providerDetail, accessToken, trigger, userRoles.permissions]);

  const disableAddMore =
    providerEmails.length === emailTypeOptions.length ||
    providerEmails.length + formValues.length === emailTypeOptions.length
      ? true
      : false;
  return (
    <div className="w-full mb-4">
      <div className="flex items-center">
        <h2 className="font-bold">Additional Emails:</h2>
        {loggedInUser.entity &&
        loggedInUser.entity.entity_type_label === "Provider" &&
        providerDetail.pk !== activeProvObj[providerDetail.pk] ? (
          ""
        ) : userRoles.permissions.includes("provider.add_provideremail") ? (
          <Tooltip
            title={
              disableAddMore
                ? "No additional email types available"
                : "Add Email"
            }
          >
            <span>
              <IconButton
                onClick={() => addFormFields()}
                disabled={disableAddMore}
                className="text-green-600"
              >
                <ControlPointOutlined />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}
      </div>{" "}
      {!providerEmails.length ? (
        <p className="text-gray-400 italic">No additional emails added</p>
      ) : (
        providerEmails.map((email) => (
          <EmailDisplayRow
            email={email}
            provider={providerDetail}
            trigger={trigger}
            setTrigger={setTrigger}
            activeProvObj={activeProvObj}
            formValues={formValues}
            setFormValues={setFormValues}
            emailTypeOptions={emailTypeOptions}
            setEmailTypeOptions={emailTypeOptions}
            handleCancelEdit={handleCancelEdit}
            typeDisplayObj={typeDisplayObj}
            providerTypesActive={providerTypesActive}
          />
        ))
      )}
      {/* //*Handles new value additions! */}
      {userRoles.permissions.includes("provider.add_provideremail")
        ? formValues.map((item, idx) => (
            <InlineEdit
              index={idx}
              setFormValues={setFormValues}
              formValues={formValues}
              toggleEdit={toggleEdit}
              setToggleEdit={setToggleEdit}
              handleCancelEdit={handleCancelEdit}
              trigger={trigger}
              setTrigger={setTrigger}
              provider={providerDetail}
              emailTypeOptions={emailTypeOptions}
              setEmailTypeOptions={emailTypeOptions}
              apiUrl="/api/provider_email/"
              removeFormFields={removeFormFields}
              providerTypesActive={providerTypesActive}
            />
          ))
        : ""}
    </div>
  );
}
