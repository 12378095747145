import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDebouncedEffect } from "../../../../../../hooks/useDebounceEffect";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import ShowMoreButton from "../../../../../../global/ShowMoreButton";

export default function AutoCompleteSearch({
  apiUrl,
  keyVal,
  title,
  setVal,
  val,
  activeEditObj,
  nameKey,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  useDebouncedEffect(
    () => {
      axios
        .get(
          apiUrl +
            `&limit=${resLimit}&ordering=name` +
            `${search ? `&search=${search}` : ""}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          setOptions(response.data.results);
          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }
        });
    },
    [userRoles.permissions, accessToken, setOptions, search, resLimit],
    250
  );

  const editDefaultDisplay = activeEditObj
    ? { defaultValue: !val && activeEditObj ? activeEditObj : val }
    : "";
  return (
    <Autocomplete
      {...editDefaultDisplay}
      className={`w-full`}
      disablePortal
      freeSolo
      size="small"
      clearOnBlur={true}
      options={!options ? [] : options}
      getOptionLabel={(option) => option[nameKey]}
      noOptionsText="No Results"
      onChange={(e, value) => {
        setVal(value, keyVal);
        setSearch("");
      }}
      sx={{
        "& .MuiInputBase-input": {
          color: !val && activeEditObj ? "grey" : "black",
        },
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} id={option[nameKey]} key={option[nameKey]}>
            {option[nameKey]}
          </li>
        );
      }}
      renderInput={(params) => (
        <div className="flex items-center">
          <>
            <TextField
              {...params}
              // ref={params.InputProps.ref}
              // inputProps={params.inputProps}
              className="w-full"
              autoFocus
              label={title}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              // InputProps={{
              //   className: `${
              //     !val && activeEditObj ? "text-gray-400" : "text-gray"
              //   }`,
              //   style: {
              //     padding: "6px 5px",
              //   },
              //   endAdornment: !val ? (
              //     ""
              //   ) : (
              //     <InputAdornment>
              //       <IconButton
              //         onClick={() => {
              //           setVal("");
              //           setSearch("");
              //         }}
              //       >
              //         <Close />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
            />
            <ShowMoreButton
              showMoreVisible={showMoreVisible}
              setResLimit={setResLimit}
              resLimit={resLimit}
              showMoreAmt={10}
            />
          </>
        </div>
      )}
    />
  );
}
