import React from "react";
import PermissionWrapper from "../PermissionWrapper";
import CommLog from "./CommLog";

/**
 * Checks Permissions before running API call
 * @returns CommLog || No Permissions Error
 */

export default function CommLogComponent({ view_calllog, logType, title }) {

  const noAccessStyle = {
    width: "100%",
    height: "100%",
  };

  const messageStyle = {
    padding: "0.75rem",
    color: "white",
    backgroundColor: "#888",
    textAlign: "center",
    border: "1px solid #888",
    borderRadius: "10px",
    width: "50%",
    margin: "30% auto",
  };

  return (
    <div id="Comm-Log" data-testid="Comm-Log" className="w-full h-full">
      <PermissionWrapper permission={view_calllog}>
        <CommLog logType={logType} title={title}/>
      </PermissionWrapper>
      <PermissionWrapper permission={!view_calllog}>
        <div id="no-access" data-testid="no-access" style={noAccessStyle}>
          <div id="message" data-testid="message" style={messageStyle}>
            You do not have permission to access this information.
          </div>
        </div>
      </PermissionWrapper>
    </div>
  );
}
