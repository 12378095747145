import {
  Add,
  Archive,
  Check,
  Close,
  CloseOutlined,
  PinDrop,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  FormGroup,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import qs from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import InlineValueEdit from "../../../../../../global/InlineValueEdit";
import StatusSelectDropdown from "../../../../../../global/StatusSelectDropdown";
import { useDebouncedEffect } from "../../../../../../hooks/useDebounceEffect";
import CaseChecklist from "../CaseChecklist";
import AddCallbackButton from "../../AddCallbackButton";
import ModalShell from "../../../../../../global/ModalShell";
import LostStatus from "../CaseColumns/Modals/LostStatus";
import AutoCompleteSearch from "../CaseColumns/CaseColumn/EditModal/AutoCompleteSearch";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import LoadingIndicator from "../../../../../../global/LoadingIndicator";

export default function CaseInfo({
  printable,
  caseName,
  caseInformation,
  handleCaseStatusSubmit,
  statusEdit,
  setStatusEdit,
  editCaseName,
  setEditCaseName,
  setCaseName,
  handleName,
  clientLawfirm,
  setEditUmStatus,
  editUmStatus,
  handleUmStatusSubmit,
  canEdit,
}) {
  const {
    selectedClient,
    setSelectedClient,
    accessToken,
    trigger,
    setTrigger,
    caseManagerPk,
    userRoles,
  } = useContext(DataContext);
  const [policyLimitAmount, setPolicyLimitAmount] = useState("");
  const [liabilityStatus, setLiabilityStatus] = useState("UNREQ");
  const [crashReport, setCrashReport] = useState("null");
  const [movingViolation, setMovingViolation] = useState("");
  const [damageAmount, setDamageAmount] = useState(null);
  const [editInvolved, setEditInvolved] = useState(false);
  const [relatedCases, setRelatedCases] = useState([]);
  const [editViolation, setEditViolation] = useState(false);
  const [editDamage, setEditDamage] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [caseStatus, setCaseStatus] = useState();
  const [providerMax, setProviderMax] = useState();
  const [editProviderMax, setEditProviderMax] = useState(false);
  const [caseSearchQuery, setCaseSearchQuery] = useState();
  const [caseList, setCaseList] = useState([]);
  const [caseSources, setCaseSources] = useState();
  const [sourcesEdit, setSourcesEdit] = useState(false);
  const [editPolicyLimit, setEditPolicyLimit] = useState(false);
  const [umValue, setUmValue] = useState("");
  const [editUmValue, setEditUmValue] = useState(false);
  const [editLiabilityValue, setEditLiabilityValue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [umStatusChoices, setUmStatusChoices] = useState([]);
  const [liabilityStatusChoices, setLiabilityStatusChoices] = useState([]);
  const [openCbModal, setOpenCbModal] = useState(false);
  const [callback, setCallback] = useState(new Date());
  const [callBackReason, setCallBackReason] = useState("");
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [caseStatusHistory, setCaseStatusHistory] = useState(false);
  const [lostDetails, setLostDetails] = useState("");
  const [openLostModal, setOpenLostModal] = useState(false);
  const [activeDialog, setActiveDialog] = useState({ color: "", title: "" });

  const [stateNameObj, setStateNameObj] = useState({});
  const [activeStateObj, setActiveStateObj] = useState("");
  const [incidentState, setIncidentState] = useState("");
  const [editIncidentState, setEditIncidentState] = useState(false);

  const [activeCityObj, setActiveCityObj] = useState("");
  const [incidentCity, setIncidentCity] = useState("");
  const [editIncidentCity, setEditIncidentCity] = useState(false);
  const [cityTrigger, setCityTrigger] = useState(false);
  const [involvedLoading, setInvolvedLoading] = useState(false);
  const [navigateToRelatedCase, setNavigateToRelatedCase] = useState(false);

  const navigate = useNavigate();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleNavToRelated = (clientId) => {
    setNavigateToRelatedCase(true);
    axios
      .get(`/api/case/?client=${clientId}&simplified=true`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        const foundCasePk = data[0].pk;
        setSelectedClient(foundCasePk);
        setNavigateToRelatedCase(false);
        navigate(`/client-detail/${foundCasePk}`);
      })
      .catch((error) => {
        setNavigateToRelatedCase(false);
        handleError(error);
      });
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setEditInvolved(false);
    setTrigger(!trigger);
  };
  const handleOpenError = () => {
    setOpenError(!openError);
  };

  const params = useParams();

  const handleError = (error) => {
    const res = error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setOpenError(!openError);
    setErrorArray(errArr);
  };

  const filterFieldData = (data, field) => {
    let filtered;
    filtered = data.filter((item) => item.field_name === field);
    return filtered;
  };

  const createDisplayObj = (data, field) => {
    let newObj = {};
    for (const item in data) {
      if (data[item].field_name === field) {
        newObj[data[item].key] = data[item].label;
      }
    }
    return newObj;
  };

  const createActiveObj = (data, valKey) => {
    let newObj;

    for (const item in data) {
      if (caseInformation[valKey] === data[item].key) {
        newObj = data[item];
      }
    }
    return newObj;
  };

  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_fieldoptions")) {
      axios
        .get(`/api/field_options/?content_type=case&ordering=order&limit=500`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setCaseStatusOptions(filterFieldData(data, "status"));
          setLiabilityStatusChoices(filterFieldData(data, "liability_status"));
          setUmStatusChoices(filterFieldData(data, "um_status"));
          setActiveStateObj(
            createActiveObj(
              filterFieldData(data, "incident_location"),
              "incident_location"
            )
          );
          setStateNameObj(
            createDisplayObj(
              filterFieldData(data, "incident_location"),
              "incident_location"
            )
          );
          setActiveCityObj(
            !caseInformation.incident_city
              ? null
              : { name: caseInformation.incident_city }
          );
        });
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [
    accessToken,
    caseInformation,
    setLiabilityStatusChoices,
    setUmStatusChoices,
    setActiveStateObj,
    setStateNameObj,
  ]);

  const handleSubmit = (e) => {
    setLiabilityStatus(e.target.value);

    const liabilityStat = {
      liability_status: e.target.value,
    };
    axios
      .patch(`/api/case/${caseInformation.pk}/`, liabilityStat, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function () {
        setTrigger(!trigger);
        setOpenSuccess(true);
        setEditLiabilityValue(false);
      })
      .catch(function (error) {
        handleError(error);
      });
  };

  const handleIncidentLocUpdate = () => {
    const patchObj = {
      incident_location: !incidentState ? null : incidentState.key,
    };
    axios
      .patch(`/api/case/${caseInformation.pk}/`, patchObj, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditIncidentState(false);
          setIncidentState("");
          setCityTrigger(!cityTrigger);
        }
      })
      .catch(function (error) {
        handleError(error);
      });
  };

  const handleIncidentCityUpdate = () => {
    const patchObj = {
      incident_city: !incidentCity ? null : incidentCity.name,
    };

    axios
      .patch(`/api/case/${caseInformation.pk}/`, patchObj, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditIncidentCity(false);
          setIncidentCity("");
        }
      })
      .catch(function (error) {
        handleError(error);
      });
  };

  //* Need to change all patches on this component to just use this one function !!
  const handleCaseValUpdate = (e, key) => {
    let patchedData = {
      [key]: e.target.value,
    };
    axios
      .patch(`/api/case/${caseInformation.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setSourcesEdit(false);
        }
      })
      .catch(function (error) {
        handleError(error);
      });
  };

  const sendRequest = () => {
    axios
      .get(
        `/api/case/${caseInformation.pk}/relatable_cases/?${qs.stringify(
          {
            name_search: !caseSearchQuery ? null : caseSearchQuery,
          },
          {
            skipNull: true,
          }
        )}`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setCaseList(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [caseSearchQuery],
    250
  );

  useEffect(() => {
    if (userRoles.permissions.includes("leads.view_campaign")) {
      axios
        .get(`/api/leads/campaigns/?limit=100`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          setCaseSources(data);
        });
    }
  }, [setCaseSources, accessToken, userRoles.permissions]);

  useEffect(() => {
    setTimeout(() => {
      if (!caseInformation) {
        return;
      } else {
        setLoading(false);
        setRelatedCases(caseInformation.related_cases);
        setPolicyLimitAmount(caseInformation.policy_limit);
        setMovingViolation(caseInformation.moving_violation);
        setDamageAmount(caseInformation.property_damage_amount);
        setLiabilityStatus(caseInformation.liability_status);
        setCrashReport(caseInformation.crash_report_received);
        setCallback(caseInformation.callback_time);
        setProviderMax(caseInformation.provider_max);
        setUmValue(caseInformation.um_value);
        setCaseStatus(caseInformation.status);
      }
    }, "1000");
  }, [
    accessToken,
    selectedClient,
    caseManagerPk,
    trigger,
    caseInformation,
    setCallback,
    userRoles.permissions,
  ]);

  useEffect(() => {
    if (
      userRoles.permissions.includes("casemanager.view_casestatushistory") &&
      caseInformation
    ) {
      axios
        .get(`/api/case/${caseInformation.pk}/status-history/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setCaseStatusHistory(response.data.results[0]);
        })
        .catch((error) => {
          if (error.response.status === 404) setCaseStatus(false);
        });
    }
  }, [
    userRoles.permissions,
    caseInformation,
    setCaseStatusHistory,
    accessToken,
    trigger,
    params,
  ]);

  const addCallback = (type) => {
    let patchedData;
    if (type === "reset") {
      patchedData = {
        callback_time: null,
        callback_reason: null,
      };
    } else {
      patchedData = {
        callback_time: callback,
        callback_reason: callBackReason,
      };
    }

    axios
      .patch(`/api/case/${caseInformation.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setTrigger(!trigger);
          setOpenCbModal(false);
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        if (error.response) {
          handleError(error);
        }
      });
  };

  const addCaseRelationship = () => {
    setInvolvedLoading(true);
    const ids = !relatedCases ? [] : relatedCases.map(({ id, pk }) => id || pk);

    let patchedData = {
      related_cases: ids,
    };

    axios
      .patch(`/api/case/${caseInformation.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditInvolved(false);
          setCaseSearchQuery("");
          setTrigger(!trigger);
          setInvolvedLoading(false);
        }
      })
      .catch(function (error) {
        handleError(error);
        setInvolvedLoading(false);
      });
  };

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const handleDialogOpen = (title, color) => {
    setOpenLostModal(true);
    setActiveDialog({ color: color, title: title });
  };

  useEffect(() => {
    if (!caseInformation || !caseInformation.lost) {
      setLostDetails("");
      return;
    } else {
      axios
        .get(`/api/case/${caseInformation.pk}/lost/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setLostDetails(response.data);
        });
    }
  }, [caseInformation, accessToken, setLostDetails, params, trigger]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <>
        <LoadingIndicator isLoading={navigateToRelatedCase} />
        {!printable ? (
          loading ? (
            <div className="w-full min-h-[45vh] flex justify-center items-center px-2">
              <CircularProgress color="secondary" className="block m-auto" />
            </div>
          ) : (
            <div>
              <div className="flex justify-center items-center flex-col">
                <div className="flex">
                  <h2 className="text-l font-bold py-2">Case Name:</h2>
                  <span className="flex">
                    {editCaseName === false ? (
                      <span className="font-bold">
                        <Button
                          onClick={() => setEditCaseName(true)}
                          className="text-black font-bold normal-case text-lg"
                          disabled={canEdit ? false : true}
                        >
                          <Tooltip title="Click to edit case name">
                            {caseName}
                          </Tooltip>
                        </Button>
                      </span>
                    ) : (
                      <span className="flex ml-10">
                        <TextField
                          className="placeholder:text-center rounded-lg w-full"
                          placeholder="Case Name"
                          size="small"
                          value={caseName}
                          InputLabelProps={{ shrink: true }}
                          label="Case Name"
                          onChange={(event) => setCaseName(event.target.value)}
                        />
                        <Button
                          onClick={handleName}
                          className="text-purple-600 hover:text-white hover:bg-purple-600 rounded-md"
                        >
                          Save
                        </Button>
                      </span>
                    )}
                  </span>
                </div>
                {!caseInformation ? (
                  ""
                ) : caseInformation.archived === true ? (
                  <div className="border rounded-md bg-red-600 font-bold">
                    <p className="px-4 py-2 text-white">
                      CASE ARCHIVED <Archive />
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={` mb-2 flex lg:flex-row flex-col justify-evenly shadow-lg py-4 md:min-h-[45vh]`}
              >
                <div className="lg:w-1/2 mx-4">
                  <div className="flex items-center">
                    <div className="mr-2">
                      {!statusEdit ? (
                        ""
                      ) : (
                        <FormGroup className="justify-center p-0 text-sm w-full">
                          <InputLabel id="case_status" className="text-sm">
                            Case Status
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="case-status"
                            id="Filter"
                            value={caseStatus}
                            label="Case Status"
                            onChange={(e) => handleCaseStatusSubmit(e)}
                            className="text-sm"
                          >
                            {caseStatusOptions.map((opt) => (
                              <MenuItem
                                value={`${opt.key}`}
                                className="text-sm"
                                key={opt.key}
                                style={{
                                  color: !opt.additional_attributes.color
                                    ? ""
                                    : opt.additional_attributes.color,
                                }}
                              >
                                {opt.order === 0 ? "00" : +opt.order - 1} -{" "}
                                {opt.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormGroup>
                      )}
                      {statusEdit ? (
                        <Button onClick={() => setStatusEdit(false)}>x</Button>
                      ) : (
                        <div>
                          <Tooltip
                            title={
                              canEdit
                                ? "Click to edit status"
                                : "You do not have permissions to edit this content"
                            }
                          >
                            <span>
                              <Button
                                onClick={() => setStatusEdit(true)}
                                disabled={canEdit ? false : true}
                                className={`font-bold normal-case text-black rounded-md
                            ${
                              !caseInformation
                                ? ""
                                : caseInformation.status === "Pending"
                                ? "bg-orange-100"
                                : caseInformation.status ===
                                  "Client Unresponsive"
                                ? "bg-orange-100"
                                : caseInformation.status === "Intake"
                                ? "bg-purple-100"
                                : caseInformation.status === "Scheduled"
                                ? "bg-purple-100"
                                : caseInformation.status === "Treating"
                                ? "bg-purple-100"
                                : caseInformation.status ===
                                  "Waiting on Medical"
                                ? "bg-purple-100"
                                : caseInformation.status ===
                                  "Progression Complete"
                                ? "bg-purple-100"
                                : caseInformation.status === "Waiting on Offer"
                                ? "bg-amber-100"
                                : caseInformation.status ===
                                  "Sent to Trial Attorney"
                                ? "bg-amber-100"
                                : caseInformation.status === "Settled"
                                ? "bg-amber-100"
                                : caseInformation.status === "Disbursed"
                                ? "bg-purple-100"
                                : "bg-gray-100"
                            }`}
                              >
                                {!caseInformation
                                  ? ""
                                  : !caseInformation.status
                                  ? "Add Status"
                                  : !caseStatus
                                  ? caseInformation.status
                                  : caseStatus}
                              </Button>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div>
                      {userRoles.permissions.includes("leads.view_campaign") ? (
                        <StatusSelectDropdown
                          editActive={sourcesEdit}
                          setEditActive={setSourcesEdit}
                          val={
                            !caseInformation
                              ? ""
                              : caseInformation.campaign === null
                              ? ""
                              : caseInformation.campaign
                          }
                          optionsArr={caseSources}
                          title="Campaign"
                          postFunc={!canEdit ? false : handleCaseValUpdate}
                          valKey="campaign"
                          showRemove={true}
                          objDispKey="name"
                          objValKey="pk"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {!caseStatusHistory ? (
                    ""
                  ) : (
                    <div className="text-[10px] w-full mt-2">
                      <span className="block">
                        Updated at:{" "}
                        {new Date(
                          caseStatusHistory.created_at
                        ).toLocaleString()}
                      </span>
                      <span>
                        Updated by:{" "}
                        {!caseStatusHistory.created_by
                          ? "-"
                          : caseStatusHistory.created_by.name}
                      </span>
                    </div>
                  )}
                  <div className="my-2 flex">
                    {editIncidentCity ? (
                      ""
                    ) : (
                      <div className={editIncidentState ? "w-full" : "mr-2"}>
                        {!editIncidentState ? (
                          <Tooltip
                            title={
                              !canEdit
                                ? ""
                                : caseInformation.incident_location
                                ? "Click to edit Incident State"
                                : "Click to add Incident State"
                            }
                          >
                            <span>
                              <Button
                                className="font-bold normal-case text-black rounded-md bg-gray-100"
                                startIcon={<PinDrop />}
                                disabled={canEdit ? false : true}
                                onClick={() => setEditIncidentState(true)}
                              >
                                {!caseInformation.incident_location
                                  ? "Add Incident State"
                                  : stateNameObj[
                                      caseInformation.incident_location
                                    ]}
                              </Button>
                            </span>
                          </Tooltip>
                        ) : (
                          <div className="flex items-center">
                            <AutoCompleteSearch
                              apiUrl="/api/field_options/?content_type=case&ordering=label&field_name=incident_location"
                              customLimit={100}
                              keyVal="incident_location"
                              title="Incident State"
                              setVal={setIncidentState}
                              activeEditObj={activeStateObj}
                              displayKey="label"
                            />
                            <Tooltip title="Cancel Edit">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setEditIncidentState(false);
                                  setIncidentState(
                                    caseInformation.incident_location
                                  );
                                }}
                              >
                                <Close className="text-red-600" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Submit">
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => handleIncidentLocUpdate()}
                                  disabled={!canEdit ? true : false}
                                >
                                  <Check
                                    className={
                                      incidentState ? "text-green-600" : ""
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                    {editIncidentState ? (
                      ""
                    ) : (
                      <div className={editIncidentCity ? "w-full" : ""}>
                        {!editIncidentCity ? (
                          <Tooltip
                            title={
                              !canEdit
                                ? ""
                                : caseInformation.incident_city
                                ? "Click to edit Incident City"
                                : caseInformation.incident_location &&
                                  !caseInformation.incident_city
                                ? "Click to add Incident City"
                                : "Please add Incident State before adding Incident City"
                            }
                          >
                            <span>
                              <Button
                                className="font-bold normal-case text-black rounded-md bg-gray-100"
                                startIcon={<PinDrop />}
                                disabled={
                                  !caseInformation.incident_location
                                    ? true
                                    : canEdit
                                    ? false
                                    : true
                                }
                                onClick={() => setEditIncidentCity(true)}
                              >
                                {!caseInformation.incident_city
                                  ? "Add Incident City"
                                  : caseInformation.incident_city}
                              </Button>
                            </span>
                          </Tooltip>
                        ) : (
                          <div className="flex items-center">
                            <AutoCompleteSearch
                              apiUrl={`/api/address/cities/?state=${caseInformation.incident_location}&ordering=name`}
                              customLimit={500}
                              keyVal="incident_city"
                              title="Incident City"
                              setVal={setIncidentCity}
                              activeEditObj={activeCityObj}
                              displayKey="name"
                              trigger={cityTrigger}
                            />
                            <Tooltip title="Cancel Edit">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setEditIncidentCity(false);
                                  setIncidentCity(
                                    caseInformation.incident_city
                                  );
                                }}
                              >
                                <Close className="text-red-600" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Submit">
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => handleIncidentCityUpdate()}
                                  disabled={!incidentCity ? true : false}
                                >
                                  <Check
                                    className={
                                      incidentCity ? "text-green-600" : ""
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <InlineValueEdit
                      title="Moving Violation"
                      inputVal={movingViolation}
                      setInputVal={setMovingViolation}
                      editVal={editViolation}
                      setEditVal={setEditViolation}
                      canEdit={canEdit ? false : true}
                      valKey="moving_violation"
                      caseInformation={caseInformation}
                    />
                    <InlineValueEdit
                      title="Property Damage Cost"
                      inputVal={damageAmount}
                      setInputVal={setDamageAmount}
                      editVal={editDamage}
                      setEditVal={setEditDamage}
                      canEdit={canEdit ? false : true}
                      formatter={formatter}
                      valKey="property_damage_amount"
                      caseInformation={caseInformation}
                    />
                    <InlineValueEdit
                      title="Provider Max"
                      inputVal={providerMax}
                      setInputVal={setProviderMax}
                      editVal={editProviderMax}
                      setEditVal={setEditProviderMax}
                      canEdit={canEdit ? false : true}
                      formatter={formatter}
                      valKey="provider_max"
                      caseInformation={caseInformation}
                    />
                  </div>
                  <h2 className="my-4 font-bold border-b text-center">
                    Policy
                  </h2>
                  <div className="">
                    <StatusSelectDropdown
                      editActive={editUmStatus}
                      setEditActive={setEditUmStatus}
                      val={!caseInformation ? "" : caseInformation.um_status}
                      optionsArr={umStatusChoices}
                      title="UM Status"
                      postFunc={!canEdit ? false : handleUmStatusSubmit}
                      valKey="um_status"
                      objDispKey="label"
                      objValKey="key"
                    />
                    <InlineValueEdit
                      title="UM Value"
                      inputVal={umValue}
                      setInputVal={setUmValue}
                      editVal={editUmValue}
                      setEditVal={setEditUmValue}
                      canEdit={canEdit ? false : true}
                      formatter={formatter}
                      valKey="um_value"
                      caseInformation={caseInformation}
                    />
                    <InlineValueEdit
                      title="Policy Limit"
                      inputVal={policyLimitAmount}
                      setInputVal={setPolicyLimitAmount}
                      editVal={editPolicyLimit}
                      setEditVal={setEditPolicyLimit}
                      canEdit={canEdit ? false : true}
                      formatter={formatter}
                      valKey="policy_limit"
                      caseInformation={caseInformation}
                    />
                  </div>
                </div>
                <div className="flex px-4 lg:w-1/2">
                  <div className="justify-center text-center block w-full">
                    <div className="flex items-center flex-wrap">
                      <div className="lg:w-[50%]">
                        <StatusSelectDropdown
                          editActive={editLiabilityValue}
                          setEditActive={setEditLiabilityValue}
                          val={
                            !caseInformation
                              ? ""
                              : caseInformation.liability_status
                          }
                          optionsArr={liabilityStatusChoices}
                          title="Liability Status"
                          postFunc={!canEdit ? false : handleSubmit}
                          valKey="liability_status"
                          objDispKey="label"
                          objValKey="key"
                        />
                      </div>
                      <div className="lg:w-[50%]">
                        <AddCallbackButton
                          caseObj={caseInformation}
                          onclick={() => setOpenCbModal(!openCbModal)}
                          canEdit={canEdit}
                          note={
                            canEdit
                              ? "Add/Edit Callback"
                              : "You do not have permissions to edit this content"
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <CaseChecklist
                        isIntake={canEdit ? false : true}
                        caseInformation={caseInformation}
                        clientLawfirm={clientLawfirm}
                      />
                    </div>
                    {editInvolved === false ? (
                      <div className="mt-4">
                        <div className="flex items-center">
                          <span className="font-bold text-sm">
                            Related Cases:&nbsp;
                          </span>

                          <div className="flex flex-wrap">
                            {!caseInformation
                              ? ""
                              : !caseInformation.related_cases
                              ? ""
                              : caseInformation.related_cases.map((inv) =>
                                  !inv.show_delete ? (
                                    <Chip
                                      className="text-sm m-1"
                                      key={inv.id}
                                      onClick={() =>
                                        handleNavToRelated(inv.client_id)
                                      }
                                      variant="outlined"
                                      label={truncate(inv.client_name, 5, 15)}
                                    />
                                  ) : (
                                    <Chip
                                      className="text-sm m-1"
                                      key={inv.id}
                                      onClick={() =>
                                        handleNavToRelated(inv.client_id)
                                      }
                                      variant="outlined"
                                      label={truncate(inv.client_name, 5, 15)}
                                    />
                                  )
                                )}
                          </div>
                          <Tooltip
                            title={
                              canEdit
                                ? "Update Involved Cases"
                                : "You do not have permissions to edit this content"
                            }
                          >
                            <span>
                              <IconButton
                                onClick={() => setEditInvolved(true)}
                                disabled={canEdit ? false : true}
                              >
                                <Add />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    ) : (
                      <div className="relative flex items-center">
                        <Autocomplete
                          size="small"
                          multiple
                          options={
                            !caseList ? [] : caseList.map((option) => option)
                          }
                          getOptionLabel={(option) =>
                            !option.client
                              ? option.client_name
                              : option.client.name
                          }
                          value={relatedCases}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={
                                  !option.client
                                    ? option.client_name
                                    : option.client.name
                                }
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(e, value) => {
                            let newArr = value.filter((obj, index) => {
                              return (
                                index ===
                                value.findIndex((o) => obj.pk === o.pk)
                              );
                            });
                            setRelatedCases(newArr);
                          }}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Cases"
                              onChange={(event) =>
                                setCaseSearchQuery(event.target.value)
                              }
                            />
                          )}
                        />
                        {involvedLoading ? (
                          <div className="flex px-2 items-center">
                            <p>Loading...</p>
                            <div className="block pl-4 relative mt-2">
                              <CircularProgress
                                color="secondary"
                                sx={{
                                  maxWidth: "25px",
                                  maxHeight: "25px",
                                }}
                              />
                            </div>{" "}
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <Tooltip title="Sumbit">
                              <IconButton onClick={() => addCaseRelationship()}>
                                <Check className="text-green-600" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel edit">
                              <IconButton
                                onClick={() => {
                                  setEditInvolved(false);
                                  setRelatedCases(
                                    caseInformation.related_cases
                                  );
                                }}
                              >
                                <Close className="text-red-600" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}

                        {/* <div className="flex items-center">
                          <TextField
                            className="placeholder:text-center rounded-lg w-full text-sm"
                            placeholder="Case Search"
                            size="small"
                            name="case-search"
                            value={caseSearchQuery}
                            InputLabelProps={{ shrink: true }}
                            label="Case Search"
                            onChange={(event) =>
                              setCaseSearchQuery(event.target.value)
                            }
                          />
                          <IconButton onClick={() => setEditInvolved(false)}>
                            <Close />
                          </IconButton>
                        </div>

                        <div className="absolute max-h-[100px] overflow-y-auto bg-white z-20 shadow-md">
                          {!caseList
                            ? ""
                            : caseList.map((caseItem) => (
                                <Tooltip
                                  title="Add Case Relationship"
                                  key={caseItem.pk}
                                >
                                  <Button
                                    className="text-left block"
                                    onClick={() =>
                                      addCaseRelationship(caseItem.pk)
                                    }
                                  >
                                    {caseItem.client.name}
                                  </Button>
                                </Tooltip>
                              ))}
                        </div> */}
                      </div>
                    )}
                    <div className="mt-6 flex justify-end">
                      <div>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() =>
                            handleDialogOpen("Report Case Lost", "#d32f2f")
                          }
                          endIcon={
                            caseInformation.lost ? (
                              <Check className="text-red-600" />
                            ) : (
                              ""
                            )
                          }
                          disabled={
                            caseInformation.lost ||
                            !userRoles.permissions.includes(
                              "casemanager.add_loststatushistory"
                            )
                              ? true
                              : false
                          }
                        >
                          Report Case Lost{" "}
                        </Button>
                        {lostDetails ? (
                          <div className="text-[10px]">
                            <span className="block">
                              Updated at:{" "}
                              {new Date(
                                lostDetails.created_at
                              ).toLocaleString()}
                            </span>
                            <span>
                              Updated by: {lostDetails.created_by.name}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <h2
              style={{
                fontSize: "1.2rem",
                textAlign: "center",
                fontWeight: "bold",
                margin: "1rem 0 0",
                padding: "0",
              }}
            >
              Summary
            </h2>
            <div
              style={{
                border: "1px solid #e3ebeb",
                borderRadius: "5px",
                margin: "0 auto 1rem",
                padding: "1rem",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Status: {caseStatus}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Onboarded:{" "}
                    {!caseInformation.onboarded ? (
                      <span style={{ color: "red" }}>False</span>
                    ) : (
                      <span style={{ color: "green" }}>True</span>
                    )}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Police Report:{" "}
                    {!caseInformation.police_report_received ? (
                      <span style={{ color: "red" }}>False</span>
                    ) : (
                      <span style={{ color: "green" }}>True</span>
                    )}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>&nbsp;</p>
                </div>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Policy Limit:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {formatter.format(+caseInformation.policy_limit)}
                    </span>
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Provider Max:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {formatter.format(
                        (+caseInformation.provider_max).toFixed(2)
                      )}
                    </span>
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>&nbsp;</p>
                </div>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Liability Status:{" "}
                    {liabilityStatus === "ACCEPT"
                      ? "Accepted"
                      : liabilityStatus === "DENY"
                      ? "Denied"
                      : liabilityStatus === "REQ"
                      ? "Requested"
                      : "Unrequested"}
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Crash Report:{" "}
                    {crashReport === "null"
                      ? "Not Uploaded"
                      : crashReport === "false"
                      ? "Requested"
                      : "Uploaded to Drive"}
                  </p>
                  {/* <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Involved Individuals:{" "}
                    <span style={{ fontWeight: "normal" }}>{caseInformation.involved}</span>
                  </p> */}
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Moving Violation:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {caseInformation.moving_violation}
                    </span>
                  </p>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Property Damage Cost:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {formatter.format(
                        +caseInformation.property_damage_amount
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <Dialog open={openSuccess}>
          <Alert variant="outlined" severity="success" role="alert">
            <AlertTitle>Success!</AlertTitle>
            <p>
              Successfully Updated Content
              <br />
            </p>
          </Alert>
          <Button onClick={handleOpenSuccess}>OK</Button>
        </Dialog>
        <Dialog open={openError}>
          <Alert variant="outlined" severity="error" role="error">
            <AlertTitle>Error Updating</AlertTitle>
            <br />
            <div className="">
              {errorArray.map((issue, index) => (
                <ListItem key={index} value={issue}>
                  {issue}
                </ListItem>
              ))}
              <br />
              <h3>
                Fix errors and then resubmit. If errors persist, contact your
                Progression Network Representative.
              </h3>
            </div>
          </Alert>
          <Button onClick={handleOpenError}>OK</Button>
        </Dialog>
        <Dialog open={openCbModal} fullWidth sx={{ top: "25%" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {" "}
            <Button onClick={() => setOpenCbModal(false)}>
              <CloseOutlined />
            </Button>
          </div>
          <div style={{ padding: "1rem" }}>
            <p style={{ textAlign: "center", margin: "0 0 1rem" }}>
              Schedule Callback
            </p>
            <DesktopDateTimePicker
              label="Callback Date/Time"
              value={!callback ? new Date() : new Date(callback)}
              className="w-full my-4 mx-auto text-black"
              onChange={(newValue) => setCallback(newValue)}
              slotProps={{ textField: { variant: "outlined" } }}
            />
            <div style={{ margin: "1rem 0 0" }}>
              <TextField
                className="w-full my-4 text-center"
                label="Change Callback Reason/Description"
                style={{ width: "100%" }}
                onChange={(e) => setCallBackReason(e.target.value)}
                value={callBackReason}
              />
            </div>
          </div>
          <div style={{ width: "fit-content", margin: "0 auto" }}>
            <Button
              onClick={() => addCallback("reset")}
              style={{ color: "red" }}
            >
              Clear
            </Button>
            <Button onClick={() => addCallback("")}>Submit</Button>
          </div>
        </Dialog>
        <ModalShell
          open={openLostModal}
          setOpen={setOpenLostModal}
          color={activeDialog.color ? activeDialog.color : "#1976d2"}
          title={activeDialog.title}
        >
          <LostStatus
            caseDetail={caseInformation}
            setOpenCustomDialog={setOpenLostModal}
          />
        </ModalShell>
      </>
    </LocalizationProvider>
  );
}
