import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "../../../../../../api/axios";
import { useDebouncedEffect } from "../../../../../../hooks/useDebounceEffect";
import ResponseModal from "../../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import DataContext from "../../../../../../../context/DataContext";

export default function PasswordChange({
  userInfo,
  setChangePassword,
  trigger,
  setTrigger,
}) {
  const { accessToken } = useContext(DataContext);
  const [newPass, setNewPass] = useState("");
  const [newPassCheck, setNewPassCheck] = useState("");
  const [passMatch, setPassMatch] = useState("empty");
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const resetPass = () => {
    const resetData = {
      new_password_1: newPass,
      new_password_2: newPassCheck,
    };

    axios
      .patch(`/api/users/${userInfo.pk}/set_password/`, resetData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setUpdateSuccess(true);
        }
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setErrorMessage(errArr);
        setOpenError(!openError);
      });
  };

  const checkPass = () => {
    if (!newPass && !newPassCheck) {
      setPassMatch("empty");
    } else if (newPass !== newPassCheck && newPass.length < 8) {
      setPassMatch(false);
    } else if (newPass === newPassCheck && newPass.length >= 8) {
      setPassMatch(true);
    }
  };

  useDebouncedEffect(
    () => {
      checkPass();
    },
    [newPass, newPassCheck],
    250
  );

  const handleSuccess = () => {
    setUpdateSuccess(false);
    setChangePassword(false);
    setTrigger(!trigger);
  };

  return (
    <div className="w-[95%]">
      <div className="mx-auto py-4">
        <>
          <div className="w-3/4 mx-auto">
            <TextField
              className="w-full my-4 text-center"
              placeholder="New Password" //
              type="password"
              autoComplete="off"
              label="Password"
              value={newPass}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => setNewPass(event.target.value)}
              error={!passMatch ? true : passMatch === "empty" ? false : false}
            />
            <TextField
              className="w-full my-4 text-center"
              placeholder="Confirm New Password" //
              type="password"
              autoComplete="off"
              label="Confirm Password"
              value={newPassCheck}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => setNewPassCheck(event.target.value)}
              error={!passMatch ? true : passMatch === "empty" ? false : false}
              helperText={passMatch ? "" : "Passwords need to match"}
            />
            <Button
              variant="outlined"
              onClick={resetPass}
              className="mx-auto block"
              disabled={
                !passMatch ? true : passMatch === "empty" ? true : false
              }
            >
              Update
            </Button>
          </div>
        </>
      </div>
      <ResponseModal
        title="Password Update Success"
        openBool={updateSuccess}
        setOpenBool={setUpdateSuccess}
        description="Your password has been reset. Sessions active on other devices will require you to login again."
        handleCloseFunc={handleSuccess}
      />
      <ResponseModal
        title="Password Update Failed"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
