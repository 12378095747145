import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import PaginationControlsV2 from "../../PaginationControlsV2";
import { CircularProgress, ListSubheader, Stack, TextField } from "@mui/material";
import PermissionWrapper from "../../PermissionWrapper";

/** Returns Arr of elements of a not in b */
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
/** Returns Arr of elements both arrays share in common */
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
/** Returns Arr of elements noy in arr b */
function difference(a, b) {
  return a.filter((value) => !b.includes(value));
}
/** Returns Arr of elements of both arrays combined */
function union(a, b) {
  return [...a, ...not(b, a)];
}
export default function ProviderServicesEdit({
  availableServices,
  setToggleEdit,
  searchRequest,
  currentServices,
  isLoading,
  postServices,
  removeServices,
  resLimit,
  availResultCount,
  setAvailServPageUrl,
  permissions,
}) {
  const [availSerCurrentPage, setAvailSerCurrentPage] = useState(1);
  const [currSerCurrentPage, setCurrSerCurrentPage] = useState(0);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const cancelButtonDefault =
    "bg-white border-solid border-blue-600 text-blue-600 border-1 hover:bg-blue-600 hover:text-white rounded-md px-6 py-2 m-6 mb-3";
  const submitButtonDefault = "invisible hidden";
  const submitButtonActive =
    "bg-white border-solid border-green-600 text-green-600 border-1 rounded-md px-6 py-2 m-6 mb-3 hover:bg-green-600 hover:text-white hover:border-green-600";
  const [submitButtonStyle, setSubmitButtonStyle] =
    useState(submitButtonDefault);

  const [availSerPageIsLoading, setAvailSerPageIsLoading] = useState(false);
  const [currSerPageIsLoading, setCurrSerPageIsLoading] = useState(false);
  const [currSearchResults, setCurrSearchResults] = useState(currentServices);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [maxPage, setMaxPage] = useState(0);  

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const searchItems = (title, value) => {
    if (title === "Available Services") {
      setAvailSerCurrentPage(1);
      setAvailServPageUrl(`limit=${resLimit}&`);
      searchRequest(value);
    }
    if (title === "Services Provided") {
      const serviceNameResults = right.filter(item =>
        item.service_name.toLowerCase().includes(value));

      const serviceCodeResults = right.filter(item =>
          item.service_code.toLowerCase().includes(value));
    
      let results = [...serviceNameResults, ...serviceCodeResults];
      setCurrSearchResults(results);
      if(value !== ""){
        setShowSearchResults(true);
      }else{
        setShowSearchResults(false);
      }
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
   
    setSubmitButtonStyle(submitButtonActive);

  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setCurrSearchResults(not(currSearchResults, rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
   
    setSubmitButtonStyle(submitButtonActive);
  
  };

  const updateCurrentServices = () => {
    const removedServices = not(currentServices, right);
    if (removedServices.length > 0) {
      const ser = removedServices.map((s) => s.id);
      removeServices(ser);
    }

    const addedServices = difference(right, currentServices);
    if (addedServices.length > 0) {
      const ser = addedServices.map((s) => s.id);
      postServices(ser);
    }
   cancelUpdatedServices();
  };
  
  const cancelUpdatedServices = () => {
    setToggleEdit(false);
    setAvailSerCurrentPage(1);
    setCurrSerCurrentPage(1);
  };

  const handleCurrentServicesPageChange = (event, value) => {
    setCurrSerCurrentPage((value-1)%maxPage);
  };

  const handleAvailableServicesPageChange = (event, value) => {
    setAvailSerPageIsLoading(true);
    setAvailSerCurrentPage(value);
    if (value === 1) {
      setAvailServPageUrl(`limit=${resLimit}&`);
    }
    setAvailServPageUrl(`limit=${resLimit}&offset=${resLimit * (value - 1)}&`);
  };
  
  useEffect(() => {
    let active = true;
    if (active) {
      if (!isLoading && availableServices) {
        setLeft(availableServices);
        setAvailSerPageIsLoading(false);
      }
    }
    return () => {
      active = false;
    };
  }, [availableServices, isLoading]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (!currSerPageIsLoading && currentServices) {
        setMaxPage(Math.ceil(currentServices.length/25));
        setRight(currentServices);
        setCurrSerPageIsLoading(false);
      }
    }
    return () => {
      active = false;
    };
  }, [currentServices, currSerPageIsLoading]);

  const customList = (title, items, loadingStatus) => (
    <div>
      <div style={{ px: 2, py: 1 }}>
        <div style={{ textAlign: "center", marginTop: "1rem" }}>{title}</div>
        <div
          style={{ textAlign: "center", fontSize: "0.8rem" }}
        >{`${numberOfChecked(items)}/${
          title === "Available Services" ? availResultCount : right.length
        } selected`}</div>
        <hr style={{ margin: "1rem 0rem" }} />
        <div style={{ margin: "0.5rem auto" }}>
          <div id="search" data-testid="search" className="lg:w-[100%] p-2">
            <TextField
              className="placeholder:text-center rounded-lg  text-sm w-full"
              placeholder={"Search Services"}
              size="small"
              name="search"
              InputLabelProps={{ shrink: true }}
              label="Search"
              onChange={(event) => searchItems(title, event.target.value)}
            />
          </div>
        </div>
      </div>

      <List
        sx={{
          height: "230px",
          bgcolor: "background.paper",
          overflowY: "auto",
          margin: "auto",
          paddingTop:"0px"
        }}
        dense
        component="div"
        role="list"
        className="sm:min-w-[320px] w-full"
      >
            {title !== "Available Services" && (
              <ListSubheader sx={{backgroundColor:"#fff"}}>
              <ListItemButton
                key={"select-all-0"}
                role="listitem"
                onClick={handleToggleAll(items)}
                style={{ margin: 0, padding: 0 }}
              >
                <ListItemIcon>
                  <Checkbox
                    onClick={handleToggleAll(items)}
                    checked={
                      numberOfChecked(items) === items.length &&
                      items.length !== 0
                    }
                    indeterminate={
                      numberOfChecked(items) !== items.length &&
                      numberOfChecked(items) !== 0
                    }
                    disabled={items.length === 0}
                    inputProps={{
                      "aria-label": "all items selected",
                    }}
                  />
                </ListItemIcon>
                <ListItemText id="select-all-toggle" primary={`Select All`} />
              </ListItemButton>
             </ListSubheader>
            )}
          
          {loadingStatus ? (
          <div style={{ position: "relative", height: "100%" }}>
            <div
              style={{
                position: "absolute",
                top: "30%",
                width: "100%",
                textAlign: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          </div>
        ) : (title === "Available Services" ?
          items.map((value) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;

            return (
              <ListItemButton
                key={value.id}
                role="listitem"
                onClick={handleToggle(value)}
                selected={checked.includes(value)}
              >
                <ListItemText
                  id={labelId}
                  primary={`${value.service_code} | ${value.service_name}`}
                />
              </ListItemButton>
            );
          })
          : !showSearchResults ?
          items
          .slice(currSerCurrentPage*25,25*(currSerCurrentPage+1))
          .map((value) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;

            return (
              <ListItemButton
                key={value.id}
                role="listitem"
                onClick={handleToggle(value)}
                selected={checked.includes(value)}
              >
                <ListItemText
                  id={labelId}
                  primary={`${value.service_code} | ${value.service_name}`}
                />
              </ListItemButton>
            );
          })
          :
          currSearchResults
          .map((value) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;

            return (
              <ListItemButton
                key={value.id}
                role="listitem"
                onClick={handleToggle(value)}
                selected={checked.includes(value)}
              >
                <ListItemText
                  id={labelId}
                  primary={`${value.service_code} | ${value.service_name}`}
                />
              </ListItemButton>
            );
          })
        )}
      </List>
    </div>
  );

  return (
    <div id="ProviderServicesEdit" data-testid="ProviderServicesEdit">
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        className="lg:flex-row flex-col w-full"
      >
        <Grid
          item
          sx={{
            border: "1px solid #eee",
            borderRadius: "0.5rem",
            boxShadow: "2px 2px 5px #ccc",
          }}
          className="w-full lg:w-[40%]"
        >
          {customList("Available Services", left, availSerPageIsLoading)}
          <div className="py-2">
            <Stack alignItems={"center"}>
              <PaginationControlsV2
                resultCount={availResultCount}
                handleChange={handleAvailableServicesPageChange}
                currentPage={availSerCurrentPage}
              />
            </Stack>
          </div>
        </Grid>
        <Grid item className="mx-4">
          <Grid
            container
            direction="column"
            alignItems="center"
            className="lg:rotate-0 rotate-90"
          >
            <PermissionWrapper permission={permissions.canAddProviderServices}>
              <Button
                sx={{ my: 0.5, mx: 2 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                <ArrowForwardIos className="pl-1" />
              </Button>
            </PermissionWrapper>
            <PermissionWrapper
              permission={permissions.canDeleteProviderServices}
            >
              <Button
                sx={{ my: 0.5, mx: 2 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                <ArrowBackIos className="pl-1" />
              </Button>
            </PermissionWrapper>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            border: "1px solid #eee",
            borderRadius: "0.5rem",
            boxShadow: "2px 2px 5px #ccc",
          }}
          className="w-full lg:w-[40%]"
        >
          {customList("Services Provided", right, currSerPageIsLoading)}
          <div className="py-2">
            <Stack alignItems={"center"}>
              <PaginationControlsV2
                resultCount={currentServices.length}
                handleChange={handleCurrentServicesPageChange}
                currentPage={currSerCurrentPage+1}
              />
            </Stack>
          </div>
        </Grid>
      </Grid>
      <div className="w-full text-center">
        <Button
          variant="outlined"
          className={cancelButtonDefault}
          onClick={() => cancelUpdatedServices()}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          className={submitButtonStyle}
          onClick={() => updateCurrentServices()}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
