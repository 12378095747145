import {
  AddAPhoto,
  Edit,
  Email,
  LockReset,
  Login,
  PersonAdd,
  PhoneIphone,
} from "@mui/icons-material";
import { Chip, IconButton, List, ListItem, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ModalShell from "../../../../../global/ModalShell";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ImageUpload from "./ImageUpload";
import ResponseModal from "../../../../../global/ResponseModal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import EditProfileDetails from "./EditProfileDetails";
import LanguagePreferences from "../LanguagePreferences";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import ManageProviderChip from "./ManageProviderChip";

export default function ProfileInfo({
  userInfo,
  trigger,
  setTrigger,
  profile,
  isDrawer,
  reloadTrigger,
  setReloadTrigger,
  managedLocations,
}) {
  const { accessToken, userRoles, loggedInUser } = useContext(DataContext);
  const [showUploadButton, setShowUploadButton] = useState("");
  const [uploadProfileImage, setUploadProfileImage] = useState(false);
  const [editLanguages, setEditLanguages] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [coordinates, setCoordinates] = useState();
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [contentUpdated, setContentUpdated] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDOB] = useState("");
  const [languageObj, setLanguageObj] = useState("");

  const chipColors = ["sky-600", "green-600", "orange-600", "purple-600"];

  const handleUpdateInfo = () => {
    let patchedUserInfo = {
      ...(fullName === "" ? {} : { name: fullName }),
      ...(email === "" ? {} : { email: email }),
      ...(phone === "" ? {} : { phone: phone }),
      ...(address === null
        ? { address: null }
        : (address.id &&
            userInfo.address &&
            address.id === userInfo.address.id) ||
          address === ""
        ? {}
        : {
            address: address,
          }),
      ...(dob === ""
        ? {}
        : { date_of_birth: new Date(dob).toISOString().substring(0, 10) }),
    };

    axios
      .patch(`/api/users/${userInfo.pk}/`, patchedUserInfo, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function () {
        setContentUpdated(true);
        setEditProfile(false);
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setErrorArray(errArr);
        setOpenError(true);
      });
  };

  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_language")) {
      axios
        .get(`/api/utilities/language/?ordering=created_at`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let obj = {};

          for (const item in data) {
            obj[data[item].pk] = data[item];
          }
          setLanguageObj(obj);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    } else {
      return;
    }
  }, [accessToken, userRoles.permissions, setLanguageObj]);

  const handleCloseEdit = () => {
    setFullName("");
    setEmail("");
    setAddress("");
    setCoordinates("");
    setPhone("");
    setDOB("");
    setEditProfile(false);
  };

  const handleCloseSuccessModal = () => {
    setTrigger(!trigger);
    setContentUpdated(false);
    if (reloadTrigger === true || reloadTrigger === false) {
      setTrigger(!trigger);
      setReloadTrigger(!reloadTrigger);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {!userInfo ? (
        ""
      ) : (
        <div
          className={`self-center shadow-lg p-4 ${
            isDrawer
              ? "xl:w-full"
              : "xl:w-1/4 xl:mr-6 xl:h-full overflow-y-auto"
          }`}
        >
          <div className="relative w-fit mx-auto">
            <div
              className="rounded-full overflow-hidden w-[125px] h-[125px] mx-auto relative shadow-md"
              onMouseEnter={() => setShowUploadButton(true)}
              onMouseLeave={() => setShowUploadButton(false)}
            >
              <img
                src={
                  !userInfo.profile_picture
                    ? "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                    : userInfo.profile_picture
                }
                alt=""
                style={{ objectFit: "cover", width: "125px", height: "125px" }}
              />
              {showUploadButton ? (
                <div className="absolute bottom-0 right-[37px] z-10">
                  <Tooltip title="Update profile photo">
                    <IconButton onClick={() => setUploadProfileImage(true)}>
                      <AddAPhoto className="text-[40px]" />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="my-2">
              <p className="text-center text-[1.2rem]">{userInfo.username}</p>
              <p className="text-center text-[1.2rem]">{userInfo.user_type}</p>
              {profile ? (
                <p
                  className={`text-center text-[1rem] ${
                    userInfo.is_active ? "bg-green-600" : "bg-red-600"
                  } text-white rounded-md w-fit px-2 m-auto`}
                >
                  {userInfo.is_active ? "Active" : "Not Active"}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="border-t border-gray relative">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-[18px]">General</h3>
              <IconButton onClick={() => setEditProfile(true)}>
                <Edit />
              </IconButton>
            </div>
            <List className="pb-2 p-0">
              <ListItem className="break-all items-start">
                {" "}
                <PhoneIphone />
                &nbsp;{!userInfo.phone ? "Not entered" : userInfo.phone}
              </ListItem>
              <ListItem className="break-all items-start">
                {" "}
                <Email />
                &nbsp;{!userInfo.email ? "Not entered" : userInfo.email}
              </ListItem>
            </List>
          </div>
          {managedLocations.length === 0 ||
          (loggedInUser.entity &&
            loggedInUser.entity.entity_type_label === "Provider" &&
            loggedInUser.pk !== userInfo.pk) ? (
            ""
          ) : (
            <div className="border-t border-gray relative">
              <List className="pb-2 p-0 flex flex-wrap">
                <ListItem className="w-fit p-0">
                  <strong>Managed Locations: </strong>
                </ListItem>
                {!managedLocations || managedLocations.length === 0
                  ? ""
                  : managedLocations.map((prov, idx) => (
                      <ManageProviderChip prov={prov} key={idx} idx={idx} />
                    ))}
              </List>
            </div>
          )}
          <div className="border-t border-gray relative">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-[18px]">Languages</h3>
              <IconButton onClick={() => setEditLanguages(true)}>
                <Edit />
              </IconButton>
            </div>
            <p>
              <strong>Preferred Language: </strong>
              {!userInfo.preferred_language
                ? "Unknown"
                : !languageObj
                ? ""
                : languageObj[userInfo.preferred_language].description}
            </p>
            <List className="pb-2 p-0 flex flex-wrap">
              <ListItem className="w-fit p-0">
                <strong>Spoken Languages: </strong>
              </ListItem>
              {!userInfo.spoken_language ||
              userInfo.spoken_language.length === 0
                ? ""
                : userInfo.spoken_language.map((lang, idx) => (
                    <ListItem key={idx} className="w-fit p-0">
                      <Tooltip
                        title={
                          !languageObj ? "" : languageObj[lang].description
                        }
                      >
                        {" "}
                        <Chip
                          className={`text-sm m-1 w-fit uppercase bg-${chipColors[idx]} text-white`}
                          label={!languageObj ? "" : languageObj[lang].subtag}
                        />
                      </Tooltip>
                    </ListItem>
                  ))}
            </List>
          </div>
          <div className="border-t border-gray relative">
            <div className="flex justify-between items-center py-2">
              <h3 className="font-bold text-[18px]">Account Activity</h3>
              <div>&nbsp;</div>
            </div>
            <List className="pb-2 p-0">
              <ListItem>
                {" "}
                <Tooltip title="Last Login" placement="right">
                  <Login />
                </Tooltip>
                &nbsp;
                {!userInfo.last_login
                  ? "Not entered"
                  : new Date(userInfo.last_login).toLocaleString()}
              </ListItem>
              <ListItem>
                {" "}
                <Tooltip title="Password Updated" placement="right">
                  <LockReset />
                </Tooltip>
                &nbsp;
                {!userInfo.password_updated_at
                  ? "Not entered"
                  : new Date(userInfo.password_updated_at).toLocaleString()}
              </ListItem>
              <ListItem>
                {" "}
                <Tooltip title="Date Joined" placement="right">
                  <PersonAdd />
                </Tooltip>
                &nbsp;
                {!userInfo.date_joined
                  ? "Not entered"
                  : new Date(userInfo.date_joined).toLocaleString()}
              </ListItem>
            </List>
          </div>
          <ModalShell
            open={editProfile}
            setOpen={setEditProfile}
            customCloseFunc={handleCloseEdit}
            color="orange"
            title="Edit Profile Details"
          >
            <EditProfileDetails
              userInfo={userInfo}
              handleUpdateInfo={handleUpdateInfo}
              fullName={fullName}
              setFullName={setFullName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              address={address}
              setAddress={setAddress}
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              dob={dob}
              setDOB={setDOB}
            />
          </ModalShell>
          <ModalShell
            open={uploadProfileImage}
            setOpen={setUploadProfileImage}
            color="#1976d2"
            title="Upload Profile Image"
          >
            <ImageUpload
              user={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              setUploadProfileImage={setUploadProfileImage}
              uploadProfileImage={uploadProfileImage}
              reloadTrigger={reloadTrigger}
              setReloadTrigger={setReloadTrigger}
            />
          </ModalShell>
          <ModalShell
            open={editLanguages}
            setOpen={setEditLanguages}
            color="#1976d2"
            title="Edit Language Preferences"
          >
            <LanguagePreferences
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              languageObj={languageObj}
            />
          </ModalShell>

          <ResponseModal
            title="Information Updated"
            description="Info Successfully updated for account"
            openBool={contentUpdated}
            setOpenBool={setContentUpdated}
            handleCloseFunc={handleCloseSuccessModal}
          />
          <ResponseModal
            title="Error Updating User Data"
            isError={true}
            openBool={openError}
            setOpenBool={setOpenError}
            errorMessage={errorArray}
          />
        </div>
      )}
    </LocalizationProvider>
  );
}
