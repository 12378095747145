import React, { useContext, useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@mui/material/";
import { AttachMoney } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  setFieldData,
  setFieldDataValdiation,
  setSendsCheckToLawFirm,
} from "../../../state/actions";
import { BankCheckStateContext } from "../../../context/BankCheckStateContext";
import { BankCheckDispatchContext } from "../../../context/BankCheckDispatchContext";
import Colors from "../../../../../../../../../styles/Colors";
import { format } from "date-fns";

export default function Form({ onClickSubmit }) {
  /**
   * Form Component
   *  -- Loading - Renders Loading when fields are loading
   *  -- Field Init - Initiates and Renders Fields when ready
   *  -- Field Handling - Manages Field Entry/Selections and passes data to parent
   *  -- Field Validation - Validates Fields
   *  -- Form Validation - Validates whether form isValid or !isValid for submission
   *
   */
  const dialogHeaderStyle = {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    padding: "1rem",
  };
  const headerTitle = {
    fontWeight: "bold",
  };
  const submitButtonDefault =
    "bg-white border-solid border-green-600 text-green-600 border-2 hover:bg-green-800 hover:text-white rounded-md px-8 py-2 m-6 mb-3";
  const submitButtonActive =
    "bg-green-600 text-white  hover:bg-green-800 rounded-md px-8 py-2 m-6 mb-3";
  const state = useContext(BankCheckStateContext);
  const dispatch = useContext(BankCheckDispatchContext);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [submitButtonStyle, setSubmitButtonStyle] =
    useState(submitButtonDefault);
  const [checkNumberIsTouched, setCheckNumberIsTouched] = useState(false);
  const [checkAmountIsTouched, setCheckAmountIsTouched] = useState(false);
  const [checkDateIsTouched, setCheckDateIsTouched] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const iconStyle = {
    color: Colors.gray[500],
  };
  const [checkDisplayDate, setCheckDisplayDate] = useState("");

  const handleFieldOnChange = async (e) => {
    if (!isTouched) {
      setIsTouched(true);
    }
    setFieldData(dispatch, {
      fieldName: e.target.name,
      data: e.target.value,
    });
    if (e.target.name === "amount") {
      setFieldDataValdiation(dispatch, {
        fieldName: "amount",
        data: fieldValidation({ name: "amount", value: e.target.value }),
      });
      if (!checkAmountIsTouched) {
        setCheckAmountIsTouched(true);
      }
    } else {
      setFieldDataValdiation(dispatch, {
        fieldName: e.target.name,
        data: !state.newCheckForm.check.law_firm_handled
          ? fieldValidation({ name: "check_number", value: e.target.value })
          : checkNumberValidation({
              name: "check_number",
              value: e.target.value,
            }),
      });
      if (e.target.name === "check_number") {
        if (!checkNumberIsTouched) {
          setCheckNumberIsTouched(true);
        }
      }
    }

    if (formIsValid && isTouched) {
      setSubmitButtonStyle(submitButtonActive);
    } else {
      setSubmitButtonStyle(submitButtonDefault);
    }
  };

  const handleDateOnChange = (e) => {
    if (!isTouched) {
      setIsTouched(true);
    }
    if (!checkDateIsTouched) {
      setCheckDateIsTouched(true);
    }
    setFieldData(dispatch, {
      fieldName: "check_date",
      data: format(new Date(e), "yyyy-MM-dd"),
    });

    setFieldDataValdiation(dispatch, {
      fieldName: "check_date",
      data: fieldValidation({
        name: "check_date",
        value: format(new Date(e), "yyyy-MM-dd"),
      }),
    });
  };

  const handleSetSendToProvider = (e) =>
    setSendsCheckToLawFirm(dispatch, e.target.checked);

  const checkNumberValidation = (field) => {
    let isValid = true;
    const checkmatch = field.value.match(/^[0-9]+$/);
    if (checkmatch === null) {
      isValid = false;
    }

    return isValid;
  };

  const fieldValidation = (field) => {
    let isValid = true;

    if (field.value === "" || field.value === " ") {
      isValid = false;
    }
    if (field.name === "check_number") {
      const checkmatch = field.value.match(/^[0-9]+$/);
      if (checkmatch === null) {
        isValid = false;
      }
    }
    if (field.name === "amount" && field.value <= 0) {
      isValid = false;
    }
    if (field.name === "amount") {
      const checkmatch = field.value.match(/^-?\d*\.\d+$/);
      if (checkmatch === null) {
        isValid = false;
      }
    }

    return isValid;
  };

  const handleSubmitForm = (e) => {
    if (!state.newCheckForm.check.law_firm_handled) {
      if (
        state.newCheckForm.validation.check_number &&
        state.newCheckForm.validation.check_date &&
        state.newCheckForm.validation.amount &&
        isTouched
      ) {
        onClickSubmit(state.showContent);
      }
    } else {
      if (
        state.newCheckForm.validation.amount &&
        state.newCheckForm.validation.check_date &&
        isTouched
      ) {
        onClickSubmit(state.showContent);
      }
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div data-testid="Add-Check-Form">
      <div data-testid="Dialog-Header" style={dialogHeaderStyle}>
        <div>
          <div>
            <span style={headerTitle}>Provider Name:</span>{" "}
            {state.provider_name}
          </div>
          <div>
            <span style={headerTitle}>Initial Bill:</span>{" "}
            {!state.newCheckForm.check.initial_amount
              ? formatter.format(0)
              : formatter.format(+state.newCheckForm.check.initial_amount)}
          </div>
          <div>
            <span style={headerTitle}>Reduction Amount:</span>{" "}
            {!state.newCheckForm.check.settled_amount
              ? formatter.format(0)
              : formatter.format(+state.newCheckForm.check.settled_amount)}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormControlLabel
          data-testid="field-checkbox-sendToProvider"
          sx={{ "& .MuiTypography-root": { fontSize: "1.15rem" } }}
          name="send_to_provider"
          label="Law Firm Sends Check to Provider"
          control={
            <Checkbox
              color="success"
              checked={state.newCheckForm.check.law_firm_handled}
              onChange={handleSetSendToProvider}
            />
          }
        />
      </div>
      <TextField
        data-testid="field-input-check-number"
        required={!state.newCheckForm.check.law_firm_handled}
        className="w-full my-4 text-center"
        id="check-number"
        name="check_number"
        label="Check Number"
        placeholder="Please enter a check number."
        value={state.newCheckForm.check.check_number}
        variant="outlined"
        onChange={(e) => handleFieldOnChange(e)}
        error={
          checkNumberIsTouched && !state.newCheckForm.validation.check_number
        }
        helperText={
          checkNumberIsTouched && !state.newCheckForm.validation.check_number
            ? "This field is required. Please enter a valid check number containing only numbers."
            : ""
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i
                data-testid="Bank-Check-Icon"
                className={"fa-solid fa-money-check-dollar"}
                style={iconStyle}
              />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        data-testid="field-input-check-amount"
        required
        className="w-full my-4 text-center"
        id="check-amount"
        name="amount"
        label="Check Amount"
        placeholder="Please enter a check amount."
        value={state.newCheckForm.check.amount}
        variant="outlined"
        onChange={(e) => handleFieldOnChange(e)}
        error={
          checkAmountIsTouched && !state.newCheckForm.validation.amount
            ? true
            : false
        }
        helperText={
          checkAmountIsTouched && !state.newCheckForm.validation.amount
            ? "This field is required. Please enter a check amount in the format of 0.00"
            : ""
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={iconStyle}>
              <AttachMoney />
            </InputAdornment>
          ),
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          data-testid="field-datepicker-check-date"
          className="w-full my-4 text-center"
          label="Check Date"
          id="check_date"
          name="check_date"
          value={
            checkDisplayDate
              ? checkDisplayDate
              : new Date(state.newCheckForm.check.check_date)
          }
          onChange={(newValue) => {
            setCheckDisplayDate(newValue);
            handleDateOnChange(newValue);
          }}
          slotProps={{
            inputAdornment: { position: "end" },
            textField: {
              variant: "outlined",
              required: true,
              error:
                checkDateIsTouched && !state.newCheckForm.validation.check_date
                  ? true
                  : false,
              helperText:
                checkDateIsTouched && !state.newCheckForm.validation.check_date
                  ? "This field is required. Please enter a valid check date"
                  : "",
            },
          }}
          disabled={activeStep === 2 ? true : false}
        />
      </LocalizationProvider>
      <Button
        data-testid="button-submit"
        onClick={(e) => handleSubmitForm(e)}
        className={submitButtonStyle}
      >
        Submit
      </Button>
    </div>
  );
}
