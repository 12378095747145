import React, { useState, useContext } from "react";
import {
  Button,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Select,
  Rating,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataContext from "../../context/DataContext";
import { CloseOutlined, FilterAlt } from "@mui/icons-material";

function FilterRange({ title, value, setValue, queryKey, setQueryKey, query }) {
  const { trigger, setTrigger } = useContext(DataContext);
  const [toggleInput, setToggleInput] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const setKey = (e) => {
    setQueryKey(e.target.value);
    if (e.target.value === `${query}__isnull`) {
      setValue(true);
      setInputDisabled(true);
      setTrigger(!trigger);
    }
  };

  const clearFunc = () => {
    setValue("");
    setTrigger(!trigger);
    setQueryKey("rating");
    setToggleInput(false);
  };

  const saveFilter = () => {
    setToggleInput(false);
  };

  const clearSubFilter = () => {
    // setValue("");
    setQueryKey("rating");
    setInputDisabled(false);
    setTrigger(!trigger);
  };

  return (
    <>
      {toggleInput === false ? (
        <>
          <Tooltip title={`Edit ${title}`}>
            <Button
              onClick={() => setToggleInput(true)}
              className={`cursor-pointer text-left flex justify-center items-center text-sm ${
                value ? "" : `xl:absolute xl:right-[20px] xl:bottom-[2px]`
              }`}
            >
              {!value ? <FilterAlt className="text-sky-600" /> : ""}
              {!value ? (
                ""
              ) : (
                <div className="flex items-center">
                  <span className="text-sky-600 text-center block">
                    {queryKey === `${query}__lt`
                      ? "Less Than"
                      : queryKey === `${query}__lte`
                      ? "Less than or equal to"
                      : queryKey === `${query}__gt`
                      ? "Greater than"
                      : queryKey === `${query}__gte`
                      ? "Greater than or Equal to"
                      : ""}{" "}
                    {value === true ? (
                      "No Value"
                    ) : (
                      <Rating value={value} disabled />
                    )}
                  </span>
                </div>
              )}
            </Button>
          </Tooltip>
          {!value ? (
            ""
          ) : (
            <Button
              onClick={clearFunc}
              className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
            >
              X
            </Button>
          )}
        </>
      ) : (
        <div className="flex relative">
          <div className="w-full">
            <FormControl fullWidth className="relative">
              <InputLabel
                id="demo-simple-select-label"
                className="text-sm absolute"
              >
                {title}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                value={value}
                disabled={inputDisabled}
                label={title}
                onChange={(e) => setValue(e.target.value)}
                className="w-full"
              >
                <MenuItem value={1}>
                  <Rating value={1} disabled />
                </MenuItem>
                <MenuItem value={2}>
                  <Rating value={2} disabled />
                </MenuItem>
                <MenuItem value={3}>
                  <Rating value={3} disabled />
                </MenuItem>
                <MenuItem value={4}>
                  <Rating value={4} disabled />
                </MenuItem>
                <MenuItem value={5}>
                  <Rating value={5} disabled />
                </MenuItem>
              </Select>
            </FormControl>
            {/* <Select
              className="placeholder:text-center rounded-lg w-full text-sm"
              placeholder={title}
              size="small"
              name="cm"
              type="number"
              value={value}
              InputLabelProps={{ shrink: true }}
              label={title}
              disabled={inputDisabled}
              onChange={(event) => setValue(event.target.value)}
            /> */}
            <Accordion className="mt-2 w-full">
              <AccordionSummary
                className="text-sm"
                expandIcon={
                  <div className="flex items-center">
                    <AddIcon className="text-black" />
                  </div>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="text-sm">
                  {queryKey === `${query}__lt`
                    ? `Less than ${value}`
                    : queryKey === `${query}__lte`
                    ? `Less than or equal to ${value}`
                    : queryKey === `${query}__gt`
                    ? `Greater than ${value}`
                    : queryKey === `${query}__gte`
                    ? `Greater than or equal to ${value}`
                    : queryKey === `${query}__isnull`
                    ? `No value`
                    : "Range Values"}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    className="text-sm w-full"
                    defaultValue={null}
                    name="radio-buttons-group"
                    value={queryKey}
                    onChange={(e) => setKey(e)}
                  >
                    <FormControlLabel
                      value={`${query}__lt`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Less than {value === true ? +0 : value}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__lte`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Less than or equal to {value === true ? +0 : value}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__gt`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Greater than {value === true ? +0 : value}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__gte`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Greater than or equal to {value === true ? +0 : value}
                        </Typography>
                      }
                      className="text-sm"
                    />
                    <FormControlLabel
                      value={`${query}__isnull`}
                      control={<Radio />}
                      label={
                        <Typography className="text-sm">
                          Has no value
                        </Typography>
                      }
                      className="text-sm"
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  onClick={clearSubFilter}
                  variant="outlined"
                  className="text-sm text-red-600 border-red-600 hover:bg-red-600 hover:text-white"
                >
                  Clear Sub-Filter
                </Button>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Button
              onClick={saveFilter}
              className="text-sky-600 hover:text-white hover:bg-sky-600 rounded-md text-sm"
            >
              Save
            </Button>
            <IconButton
              onClick={clearFunc}
              className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
            >
              <CloseOutlined />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
}

export default FilterRange;
