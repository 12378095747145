import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Close, CloseOutlined, FilterAlt, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns";
import qs from "query-string";
import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import "../../../../styles/FullCal.css";
import ResponseModal from "../../../global/ResponseModal";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import MultiSelectSearchV2 from "../../../global/MultiSelectSearchV2";
import LoadingIndicator from "../../../global/LoadingIndicator";

export default function CallbackCal({ isDashboardVersion }) {
  const { accessToken, setSelectedClient, userRoles } = useContext(DataContext);
  const [callbacks, setCallbacks] = useState([]);
  const [callBackTime, setCallbackTime] = useState(new Date());
  const [callBackReason, setCallBackReason] = useState("");
  const [openCbModal, setOpenCbModal] = useState(false);
  const [callbackPk, setCallbackPk] = useState();
  const [cquery, setCQuery] = useState("");
  const [cases, setCases] = useState([]);
  const [displayedCbReason, setDisplayedCbReason] = useState();
  const [activeClientPk, setActiveClientPk] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedAssigned, setSelectedAssigned] = useState([]);
  const [activeAssigned, setActiveAssigned] = useState([]);
  const [caseStatus, setCaseStatus] = useState("");
  const [toggleFilterMenu, setToggleFilterMenu] = useState(true);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [navigateToRelatedCase, setNavigateToRelatedCase] = useState(false);

  const navigate = useNavigate();

  const menuOpenClose = () => {
    setToggleFilterMenu(!toggleFilterMenu);
  };

  const sendRequest = () => {
    setIsLoading(true);

    let querytest = qs.stringify(
      {
        search: search === "" ? null : search,
        status: caseStatus === "" ? null : caseStatus,
      },
      {
        skipNull: true,
      }
    );

    const assignedUserQuery =
      selectedAssigned.length === 0
        ? ""
        : `&assigned_users__in=${selectedAssigned}`;
    const queryString = !querytest ? "" : `&${querytest}`;
    axios
      .get(
        `/api/case/?limit=1000&callback_time__gte=${startDate}T00%3A00%3A00%2B00%3A00&callback_time__lte=${endDate}T00%3A00%3A00%2B00%3A00` +
          queryString +
          assignedUserQuery,

        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setCallbacks(response.data.results);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [
      caseStatus,
      trigger,
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      selectedAssigned,
    ],
    250
  );

  // const handleDateSelect = (selectInfo) => {
  //   setCallbackTime(selectInfo.start);
  //   setOpenCbModal(true);

  //   //selectInfo.startStr -
  // };

  const handleEventClick = (clickInfo) => {
    let eventInfo = clickInfo.event._def;
    setCQuery(eventInfo.title);
    setCallbackPk(+eventInfo.publicId);
    setDisplayedCbReason(eventInfo.extendedProps.description);
    setActiveClientPk(eventInfo.extendedProps.clientPk);
    setCallbackTime(parseISO(eventInfo.extendedProps.callbackSummary));
    setOpenCbModal(true);
  };

  const handleModalClose = () => {
    setTrigger(!trigger);
    setCallbackPk();
    setCallbackTime();
    setDisplayedCbReason("");
    setCallBackReason("");
    setActiveClientPk("");
    setSearch("");
    setCQuery("");
    setOpenCbModal(false);
    setOpenSuccess(false);
  };

  const handleDescChange = (e) => {
    setCallBackReason(e.target.value);
  };

  const addCallback = (type) => {
    let patchedData;
    if (type === "reset") {
      patchedData = {
        callback_time: null,
        callback_reason: null,
      };
    } else {
      patchedData = {
        callback_time: callBackTime,
        callback_reason: callBackReason,
      };
    }

    axios
      .patch(`/api/case/${callbackPk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setErrorMessage(errArr);
        setOpenError(true);
      });
  };

  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        {innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {innerProps.event.title || <Fragment>&nbsp;</Fragment>}
          </div>
        </div>
      </div>
    );
  }

  const handleNavToRelated = (clientId) => {
    setNavigateToRelatedCase(true);
    axios
      .get(`/api/case/?client=${clientId}&simplified=true`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        const foundCasePk = data[0].pk;
        setSelectedClient(foundCasePk);
        setNavigateToRelatedCase(false);
        navigate(`/client-detail/${foundCasePk}`);
      })
      .catch((error) => {
        setNavigateToRelatedCase(false);
        console.log(error);
      });
  };

  const populateClientInfo = (client) => {
    setCases("");
    setCQuery(client.client.name);
    setActiveClientPk(client.client.pk);
    setCallbackPk(client.pk);
  };

  const searchUpdate = (event) => {
    setSearch(event.target.value);
    setCQuery("");
  };

  const setCallbackArr = (callbacks) => {
    let arr = [];
    callbacks.map((call) => {
      if (call.callback_time) {
        arr.push({
          id: call.pk,
          title: call.client.name,
          description: call.callback_reason,
          start: call.callback_time,
          extendedProps: {
            clientPk: call.client.pk,
            callbackSummary: call.callback_time,
          },
        });
      }
      return arr;
    });
    return arr;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            boxShadow: "0 0 10px #D8B4FE",
            borderRadius: "5px",
            left: " 50%",
            top: "50%",
            background: "white",
            zIndex: "10",
            padding: "1rem",
          }}
        >
          <CircularProgress style={{ color: "#6b21a8" }} />
        </div>
      ) : (
        ""
      )}
      <LoadingIndicator isLoading={navigateToRelatedCase} />

      <div
        className={`my-10 ${
          isDashboardVersion ? "max-h-[80vh]" : "max-h-[90vh]"
        } overflow-y-auto`}
      >
        <div className="max-w-[95%] mx-auto relative">
          <div
            className={`shadow-lg text-center 
              ${
                !toggleFilterMenu
                  ? "left-0 w-fit absolute bg-white z-10 overflow-y-auto"
                  : "w-[0px] hidden"
              }`}
          >
            <div className="font-bold text-lg pt-[16px] pb-[12px] px-4 flex lg:justify-between justify-end min-w-[200px]">
              <h2>Filter</h2>

              {!toggleFilterMenu || isDashboardVersion ? (
                <Tooltip
                  title={`${
                    toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                  }`}
                >
                  <IconButton
                    onClick={menuOpenClose}
                    className={`${toggleFilterMenu ? "" : "rotate-180"}`}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <List>
              {userRoles.permissions.includes("users.view_user") ? (
                <ListItem className="justify-center">
                  <MultiSelectSearchV2
                    title="Sort By Assigned Users"
                    searchTitle="User Search"
                    selectedItems={selectedAssigned}
                    setSelectedItems={setSelectedAssigned}
                    setActiveItems={setActiveAssigned}
                    activeItems={activeAssigned}
                    nameKey="name"
                    valueKey="pk"
                    apiUrl={`/api/users/all_assignable_users/?`}
                  />
                </ListItem>
              ) : (
                ""
              )}
              <ListItem>
                <FormControl className="justify-center text-sm w-full">
                  <InputLabel className="font-normal">
                    Case Status Filter
                  </InputLabel>
                  <Select
                    labelId="case-status"
                    id="Filter"
                    value={caseStatus}
                    label="Case Status"
                    onChange={(e) => setCaseStatus(e.target.value)}
                    className="text-sm"
                  >
                    <MenuItem value="" className="text-sm">
                      All
                    </MenuItem>
                    <MenuItem value="Pending" className="text-sm">
                      Pending
                    </MenuItem>
                    <MenuItem value="Client Unresponsive" className="text-sm">
                      Client Unresponsive
                    </MenuItem>
                    <MenuItem value="Intake" className="text-sm">
                      Intake
                    </MenuItem>
                    <MenuItem value="Scheduled" className="text-sm">
                      Scheduled
                    </MenuItem>
                    <MenuItem value="Treating" className="text-sm">
                      Treating
                    </MenuItem>
                    <MenuItem value="Waiting on Medical" className="text-sm">
                      Waiting on Medical
                    </MenuItem>
                    <MenuItem value="Progression Complete" className="text-sm">
                      Progression Complete
                    </MenuItem>
                    <MenuItem value="Waiting on Offer" className="text-sm">
                      Waiting on Offer
                    </MenuItem>
                    <MenuItem
                      value="Sent to Trial Attorney"
                      className="text-sm"
                    >
                      Sent to Trial Attorney
                    </MenuItem>
                    <MenuItem value="Settled" className="text-sm">
                      Settled
                    </MenuItem>
                    <MenuItem value="Disbursed" className="text-sm">
                      Disbursed
                    </MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
            </List>
          </div>
          <div>
            {!toggleFilterMenu ? (
              ""
            ) : (
              <Tooltip
                title={`${
                  toggleFilterMenu ? "Show Filter Menu" : "Hide Filter Menu"
                }`}
              >
                <IconButton onClick={menuOpenClose}>
                  <FilterAlt />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            weekends={isDashboardVersion ? false : true}
            events={setCallbackArr(callbacks)}
            // selectable={true}
            // select={handleDateSelect}
            eventClick={handleEventClick}
            slotMinTime={"07:00:00"}
            slotMaxTime={"20:00:00"}
            datesSet={(dateInfo) => {
              setStartDate(
                new Date(dateInfo.start).toISOString().substring(0, 10)
              ); //start of the range the calendar date
              setEndDate(new Date(dateInfo.end).toISOString().substring(0, 10)); //end of the range the calendar date
            }}
            eventContent={(arg) => {
              return (
                <Tooltip
                  title={
                    <Typography color="inherit">
                      {!arg.event._def.extendedProps.description
                        ? "No Description"
                        : arg.event._def.extendedProps.description}
                    </Typography>
                  }
                  arrow
                >
                  {renderInnerContent(arg)}
                </Tooltip>
              );
            }}
          />
          <Dialog open={openCbModal} fullWidth>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <Button onClick={handleModalClose}>
                <CloseOutlined />
              </Button>
            </div>
            {callbackPk ? (
              <div style={{ padding: "1rem" }}>
                <p style={{ textAlign: "center", margin: "0 0 1rem" }}>
                  Schedule Callback For:
                  <Tooltip title="Navigate to client detail">
                    <Button
                      onClick={() => handleNavToRelated(activeClientPk)}
                      style={{ fontWeight: "bold", color: "#000" }}
                    >
                      {cquery}
                    </Button>
                  </Tooltip>
                </p>
                <p style={{ textAlign: "center", margin: "0 0 1rem" }}>
                  Callback Summary: {displayedCbReason}
                </p>
                <DesktopDateTimePicker
                  label="Callback Date/Time"
                  value={callBackTime}
                  className="w-full my-4 mx-auto text-black"
                  slotProps={{ textField: { variant: "outlined" } }}
                  onChange={(newValue) => setCallbackTime(newValue)}
                />
                <div style={{ margin: "1rem 0 0" }}>
                  <TextField
                    className="w-full my-4 text-center"
                    label="Change Callback Reason/Description"
                    style={{ width: "100%" }}
                    onChange={(e) => handleDescChange(e)}
                    value={callBackReason}
                  />
                </div>
                <div style={{ width: "fit-content", margin: "0 auto" }}>
                  <Button
                    onClick={() => addCallback("reset")}
                    style={{ color: "red" }}
                  >
                    Clear
                  </Button>
                  <Button onClick={() => addCallback("")}>Submit</Button>
                </div>
              </div>
            ) : (
              <div style={{ padding: "1rem" }}>
                <p style={{ textAlign: "center", margin: "0 0 1rem" }}>
                  Choose a client:
                </p>
                <form className="justify-center flex pt-6">
                  <div className="flex sm:flex-row flex-col pt-5 sm:w-4/5 w-full mx-2">
                    <div className="sm:w-4/5 w-full relative">
                      <TextField
                        required
                        style={{ margin: "0 auto", width: "100%" }}
                        className="sm:mx-4 placeholder:text-center rounded-lg w-full sm:pr-4 my-4 sm:my-0"
                        placeholder="Search Clients" //{`Search ${filter}`}
                        value={!cquery ? search : cquery}
                        onChange={(event) => searchUpdate(event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="absolute z-10 mx-4 shadow-md w-full max-h-[300px] overflow-y-auto bg-white rounded-med max-w-[98%]">
                        {!search && cquery ? (
                          cquery
                        ) : !search ? (
                          ""
                        ) : cases.length !== 0 ? (
                          <div className="w-full h-full p-0 mb-2">
                            {cases.map((client, id) => (
                              <List key={id} className="p-0 my-1 w-full">
                                <div className="">
                                  <ListItemButton
                                    onClick={(e) => populateClientInfo(client)}
                                    className="place-content-center overflow-x-hidden text-center border-solid border-gray-300 rounded-lg"
                                  >
                                    {client.client.name}
                                  </ListItemButton>
                                </div>
                              </List>
                            ))}
                          </div>
                        ) : cases.length === 0 && cquery ? (
                          ""
                        ) : (
                          <h3 className="text-center bg-gray-200 rounded-lg uppercase">
                            No clients found
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            <ResponseModal
              title="Error Adding Callback"
              isError={true}
              openBool={openError}
              setOpenBool={setOpenError}
              errorMessage={errorMessage}
            />
            <ResponseModal
              title="Successfully Added Callback"
              openBool={openSuccess}
              setOpenBool={setOpenSuccess}
              handleCloseFunc={handleModalClose}
              description={"Callback successfully added to client case"}
            />
          </Dialog>
        </div>
      </div>
    </LocalizationProvider>
  );
}
