import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UserTableRow } from "../components/UserTableRow";
import OrderingArrow from "../../../../../../global/OrderingArrow";

export default function List({
  rows,
  columns,
  optionClick,
  languageObj,
  orderingVal,
  setOrderingVal,
}) {
  /**
   * List Component
   *  - Renders List and passes data to Parent UserList Component
   *  -- Loading - Renders Loading when fields are loading
   *  -- Field Init - Initiates and Renders Fields when ready
   *  -- Field Handling - Manages Field Entry/Selections and passes data to parent
   *  -- Field Validation - Validates Fields
   *  -- Form Validation - Validates whether form isValid or !isValid for submission
   *
   */

  return (
    <TableContainer
      component={Paper}
      className="relative max-w-[90vw] max-h-[70vh]"
    >
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => {
              if (idx === 0) {
                return (
                  <TableCell
                    key={idx}
                    align="center"
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: "20",
                      background: "white",
                      boxShadow: "-1px -5px 6px lightgray",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {column}{" "}
                    <OrderingArrow
                      orderingVal={orderingVal}
                      setOrderingVal={setOrderingVal}
                      val={"name"}
                    />
                  </TableCell>
                );
              }
              if (idx === columns.length - 1) {
                return (
                  <TableCell
                    key={idx}
                    align="center"
                    sx={{
                      position: "sticky",
                      right: 0,
                      background: "white",
                      boxShadow: "2px -5px 6px lightgray",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {column}{" "}
                  </TableCell>
                );
              }
              return (
                <TableCell
                  key={idx}
                  align="center"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {column}{" "}
                  {column === "Date Joined" ? (
                    <OrderingArrow
                      orderingVal={orderingVal}
                      setOrderingVal={setOrderingVal}
                      val={"created_at"}
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row, idx) => (
              <UserTableRow
                key={idx}
                user={row}
                onclick={optionClick}
                languageObj={languageObj}
              />
            ))}
          {rows?.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length} data-testid="no-users-found">
                No Users Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
