// import * as React from "react";
import { Edit, MoreVert } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useContext, useState } from "react";
import DataContext from "../../../../../../context/DataContext";
import { format } from "date-fns";

export default function PaymentDetailMenu({ handleEditAction, action }) {
  const { userRoles } = useContext(DataContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div>
      <Tooltip title="More Options" placement="right">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="flex justify-end">
          {userRoles.permissions.includes(
            "casemanager.change_settlementcheck"
          ) ? (
            <IconButton
              className={`text-orange-400 hover:bg-orange-400 hover:text-white`}
              onClick={() => {
                handleEditAction(action);
                handleClose();
              }}
            >
              <Edit className="text-[18px]" />
            </IconButton>
          ) : (
            ""
          )}
        </div>
        <div className="px-4 text-center pb-2">
          <p className="text-black">
            <strong>Payment #:</strong> {action.check_number}
          </p>
          <p>
            <strong>Amount:</strong> {formatter.format(action.amount)}
          </p>
          <p>
            <strong>Date:</strong>
            {new Date(action.check_date).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })}
          </p>
        </div>
      </Menu>
    </div>
  );
}
