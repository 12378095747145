import { useEffect } from "react";

const FreshDeskWidget = ({ userName, email, url, casePk, apiErrors }) => {
  const widget = window.FreshworksWidget;
  useEffect(() => {
    if (!widget) {
      return;
    } else {
      widget("prefill", "ticketForm", {
        name: userName,
        email: email,
        custom_fields: {
          cf_application_url: url,
          cf_case_pk: !casePk ? "No Applicable Case" : `${casePk}`,
          cf_api_error: !apiErrors ? "None Found" : apiErrors,
        },
      });
      widget("hide", "ticketForm", [
        "product_id",
        "custom_fields.cf_api_error",
        "custom_fields.cf_application_url",
        "custom_fields.cf_case_pk",
      ]);
    }
  }, [widget, userName, url, email, apiErrors, casePk]);
  return;
};

export default FreshDeskWidget;
