import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import CaseInfo from "./CaseInfo";
import CaseViewCounts from "../../CaseViewCounts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CaseSummary({
  caseName,
  progressionManagers,
  clientPM,
  caseInformation,
  handleCaseStatusSubmit,
  statusEdit,
  setStatusEdit,
  editCaseName,
  setEditCaseName,
  setCaseName,
  handleName,
  caseSources,
  clientLawfirm,
  setEditUmStatus,
  editUmStatus,
  handleUmStatusSubmit,
  setOpenCbModal,
  openCbModal,
  callback,
  setCallback,
  callBackReason,
  setCallBackReason,
  canEdit,
}) {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    //keep event param even though unused
    setTabValue(newValue);
  };
  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          padding: "0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="justify-between "
          variant="fullWidth"
          TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
          sx={{
            "& button.Mui-selected": {
              color: `#9333EA`,
            },
            ".Mui-selected": {
              color: `#9333EA`,
            },
            ".MuiBox-root.div": {
              padding: 0,
            },
          }}
        >
          <Tab label="Info" {...a11yProps(0)} className={`w-1/2`} />
          <Tab label="Case View Counts" {...a11yProps(1)} className=" w-1/2 " />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <div className="mb-4 rounded-md shadow-lg mt-5">
          <CaseInfo
            caseName={caseName}
            clientPM={clientPM}
            progressionManagers={progressionManagers}
            caseInformation={caseInformation}
            handleCaseStatusSubmit={handleCaseStatusSubmit}
            statusEdit={statusEdit}
            setStatusEdit={setStatusEdit}
            editCaseName={editCaseName}
            setEditCaseName={setEditCaseName}
            handleName={handleName}
            setCaseName={setCaseName}
            caseSourcesObj={caseSources}
            clientLawfirm={clientLawfirm}
            setEditUmStatus={setEditUmStatus}
            editUmStatus={editUmStatus}
            handleUmStatusSubmit={handleUmStatusSubmit}
            setOpenCbModal={setOpenCbModal}
            openCbModal={openCbModal}
            callback={callback}
            setCallback={setCallback}
            callBackReason={callBackReason}
            setCallBackReason={setCallBackReason}
            canEdit={canEdit}
          />
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div className="mb-4 rounded-md mx-2 shadow-lg mt-5">
          {" "}
          <CaseViewCounts caseObj={caseInformation} />
        </div>
      </TabPanel>
    </div>
  );
}
