import ApiServices from "../service";

export const document = {

getSourceFieldOptions: (accessToken) =>
ApiServices.getData(`/api/field_options/?content_type=document&field_name=source&ordering=order`, accessToken)
  .then((res) => res)
  .then((res) => res)
  .catch(err => console.log(err)),

}