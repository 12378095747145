import { useState, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import { TextField } from "@mui/material/";
import ResponseModal from "../../../../../global/ResponseModal";
import FormSubmitButton from "../../../../../global/FormComponents/FormSubmitButton";
import { ControlPointOutlined } from "@mui/icons-material";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function CreateServiceProcedurev2({
  selectedAppt,
  setOpenCreateBill,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [procedureName, setProcedureName] = useState("");
  const [procedureCode, setProcedureCode] = useState("");
  const [loading, setLoading] = useState(false);
  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setProcedureName("");
    setProcedureCode("");
    setOpenCreateBill(false);
  };

  const handleCreateProcedure = (e) => {
    setLoading(true);
    const patchedService = {
      ...(procedureName === "" ? {} : { service_name: procedureName }),
      ...(procedureCode === "" ? {} : { service_code: procedureCode }),
      ...(procedureCode === "" ? {} : { provider: [selectedAppt.provider.pk] }),
    };

    axios
      .post(`/api/services/`, patchedService, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setOpenSuccess(!openSuccess);
        setLoading(false);
        return response;
      })
      .catch(function (error) {
        const res = error.response.data;
        const resArr = ApiRequestErrorHandler(res);
        setErrorArray(resArr);
        setOpenError(!openError);
        setLoading(false);
      });
  };

  return (
    <div className="w-full bg-white">
      <h3
        className="underline"
        style={{ textAlign: "center", padding: "0 0 1rem 0" }}
      >
        Procedure and Code must be unique.
      </h3>
      <TextField
        className="w-full my-4 text-center"
        id="Procedure/Service Name"
        label="Procedure/Service Name"
        value={procedureName}
        onChange={(event) => {
          setProcedureName(event.target.value);
        }}
      />
      <TextField
        className="w-full my-4 text-center"
        id="Procedure/Service Code"
        label="Procedure/Service Code"
        value={procedureCode}
        onChange={(event) => {
          setProcedureCode(event.target.value);
        }}
      />
      <div style={{ margin: "0 0 2rem 0" }}>
        <FormSubmitButton
          isLoading={loading}
          handleSubmit={handleCreateProcedure}
          buttonText="Submit Bill"
          buttonColor="green"
          ButtonIcon={<ControlPointOutlined />}
        />
      </div>
      <ResponseModal
        title="Bill Created"
        description="Successfully created new bill type"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Creating Bill Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </div>
  );
}
