import React from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";

function SearchOptionGroup({
  title,
  value,
  setValue,
  customFalseLabel,
  customTrueLabel,
  resetPagination,
}) {
  const valueSetter = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <Accordion className="w-full bg-white">
        <AccordionSummary
          className="text-sm"
          expandIcon={
            <div className="flex items-center">
              <ExpandMoreOutlined />
            </div>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>{title}</div>
          <div>
            {!value ? (
              ""
            ) : (
              <div>
                &mdash;
                <span className="text-xs text-sky-600">&nbsp;{value}</span>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className="bg-white">
          <FormControl size="small">
            <RadioGroup
              className="text-sm bg-white"
              name="radio-buttons-group"
              value={value}
              onChange={(e) => {
                valueSetter(e);
                resetPagination();
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={customTrueLabel ? customTrueLabel : "True"}
                className="text-sm"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={customFalseLabel ? customFalseLabel : "False"}
                className="text-sm"
              />
            </RadioGroup>
          </FormControl>
          <Button
            onClick={() => setValue(null)}
            variant="outlined"
            className="text-sm text-red-600 border-red-600 hover:bg-red-600 hover:text-white"
          >
            Clear Filter
          </Button>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default SearchOptionGroup;
