export const PAGE_SET = "PAGE_SET";
export const ROWS_PER_PAGE_SET = "ROWS_PER_PAGE_SET";


//MAY NOT NEED BELOW - BUT HAVE TO FORMAT DATA... MAYBE
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";

export const setCurrentPage = (dispatch, number) =>
dispatch({type:PAGE_SET, payload:number});

export const setRowsPerPage = (dispatch, number) =>
dispatch({type:ROWS_PER_PAGE_SET, payload:number});


//MAY NOT NEED BELOW - BUT HAVE TO FORMAT DATA... MAYBE
export const getUsers = (dispatch, bool) =>
dispatch({type: GET_USERS, payload: bool});

export const setUsers = (dispatch, obj) =>
dispatch({type:SET_USERS, payload: obj});

