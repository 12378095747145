import { Circle, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";

export default function AccordionShell({
  title,
  customAdornment,
  children,
  defaultExpanded,
  customTitle,
}) {
  return (
    <Accordion
      className="w-full mr-2 relative bg-white"
      defaultExpanded={!defaultExpanded ? false : defaultExpanded}
    >
      <AccordionSummary
        className="relative z-[0]"
        expandIcon={
          <div className="flex items-center">
            {customAdornment ? (
              <Circle className={`text-${customAdornment}-300`} />
            ) : (
              ""
            )}
            <ExpandMore />
          </div>
        }
        aria-controls={`${customTitle ? customTitle : title}-content`}
        id={`${customTitle ? customTitle : title}-header`}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className="border-t border-gray-300 mx-2">
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
