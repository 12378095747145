import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import HideImageIcon from "@mui/icons-material/HideImage";
import { useContext, useState } from "react";
import ShowMoreButton from "../../../../../../global/ShowMoreButton";
import DataContext from "../../../../../../../context/DataContext";

export default function PhotoGallery({
  images,
  showMoreImgsVisible,
  imgResLimit,
  setImgResLimit,
}) {
  const { userRoles } = useContext(DataContext);
  const [loaded, setLoaded] = useState(false);
  function onLoad() {
    setLoaded(!loaded);
  }

  const getImgWidth = (dataURL) => {
    const img = new Image();

    new Promise((resolve) => {
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });
    return img.width;
  };
  const getImgHeight = (dataURL) => {
    const img = new Image();

    new Promise((resolve) => {
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });
    return img.height;
  };

  return (
    <div
      className={`${
        userRoles.permissions.includes("users.view_user")
          ? "max-w-2/3"
          : "w-full"
      }      overflow-x-auto`}
    >
      <Gallery>
        <div
          className={`flex items-center ${
            !images ? "" : images.length >= 3 ? "" : "justify-center"
          } max-w-[90vw] xl:max-h-[40vh]`}
        >
          {!images ? (
            ""
          ) : images.length === 0 ? (
            <p className="text-[2rem] text-gray-300	 text-center">
              No Images <HideImageIcon />
            </p>
          ) : (
            images.map((img, idx) => (
              <Item
                key={idx}
                original={img.file}
                width={getImgWidth(img.file)}
                height={getImgHeight(img.file)}
              >
                {({ ref, open }) => (
                  <img
                    onLoad={onLoad}
                    ref={ref}
                    onClick={open}
                    src={img.file}
                    style={{ maxWidth: "200px" }}
                    className="w-auto mr-4 my-2"
                    alt={img.file_name}
                  />
                )}
              </Item>
            ))
          )}
          <ShowMoreButton
            showMoreVisible={showMoreImgsVisible}
            setResLimit={setImgResLimit}
            resLimit={imgResLimit}
            showMoreAmt={5}
          />
        </div>
      </Gallery>
    </div>
  );
}
