import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Input,
  Button,
  List,
  ListItem,
} from "@mui/material";
import { useContext, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ResponseModal from "../../../../../../global/ResponseModal";
import { format } from "date-fns";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AutoCompleteSearch from "./AutoCompleteSearch";

export default function AddPayment({
  casePk,
  setAddOpen,
  trigger,
  setTrigger,
  paymentTypeOptions,
  caseDetail,
}) {
  const { accessToken } = useContext(DataContext);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [paymentTo, setPaymentTo] = useState("");
  const [paymentFrom, setPaymentFrom] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePaymentSubmit = () => {
    const postData = {
      check_number: paymentId,
      amount: paymentAmount,
      check_date: format(new Date(paymentDate), "yyyy-MM-dd"),
      check_type: paymentType,
      case: casePk,
      paid_to: paymentTo.pk,
      paid_by: paymentFrom.pk,
    };

    axios
      .post("/api/settlement_checks/", postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Successfully added payment information");
        setIsError(false);
      })
      .catch((error) => {
        const res = error.response.data;
        const errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const clearFormVals = () => {
    setPaymentId("");
    setPaymentAmount("");
    setPaymentDate("");
    setPaymentTo("");
    setPaymentFrom("");
    setPaymentType("");
  };

  const closeResModal = () => {
    if (!isError) {
      setAddOpen(false);
      clearFormVals();
      setResponseModal(false);
      setResponseBreakdown("");
      setTrigger(!trigger);
      setLoading(false);
    } else {
      setResponseModal(false);
      setResponseBreakdown("");
      setLoading(false);
      setTimeout(() => {
        setIsError(false);
      }, "1000");
    }
  };

  const postDisable =
    paymentFrom && paymentTo && paymentType && paymentAmount && paymentDate
      ? false
      : true;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "100%",
        minWidth: "325px",
      }}
    >
      <List className="p-0 mb-6 text-[14px]">
        <ListItem className="p-0 justify-center">
          <strong>Law Firm Name:</strong>&nbsp;{caseDetail.law_firm_name}
        </ListItem>
        <ListItem className="p-0 justify-center">
          <strong>Medical Amount:</strong>&nbsp;
          {formatter.format(caseDetail.medical_amount)}
        </ListItem>
        <ListItem className="p-0 justify-center">
          <strong>Settlement Amount:</strong>&nbsp;
          {formatter.format(caseDetail.actual_total_settlement_amount)}
        </ListItem>
      </List>
      <FormControl
        className="w-full flex"
        style={{ width: "100%", marginBottom: "1rem" }}
        size="small"
        required={true}
      >
        <InputLabel id="paymentType">Payment Type</InputLabel>
        <Select
          required
          className="w-full"
          labelId="paymentType"
          id="payment-type"
          value={paymentType}
          label="Payment Type"
          onChange={(event) => {
            setPaymentType(event.target.value);
          }}
        >
          {paymentTypeOptions.map((type, idx) => {
            return (
              <MenuItem
                value={type.key}
                key={idx}
                onClick={() => setPaymentType(type.key)}
              >
                {type.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <div className="mb-4 w-full">
        <AutoCompleteSearch
          apiUrl={`/api/entities/?case=${casePk}`}
          keyVal="paid_by"
          title="Payment From*"
          setVal={setPaymentFrom}
          val={paymentFrom}
          nameKey={"name"}
          activeEditObj={false}
        />
      </div>
      <div className="w-full">
        <AutoCompleteSearch
          apiUrl={`/api/entities/?case=${casePk}`}
          keyVal="paid_to"
          title="Payment To*"
          setVal={setPaymentTo}
          val={paymentTo}
          nameKey={"name"}
          activeEditObj={false}
        />
      </div>

      <FormControl
        fullWidth
        sx={{ m: 1 }}
        variant="standard"
        style={{ marginTop: "1rem" }}
        size="small"
      >
        <InputLabel htmlFor="payment-id">Payment Identification</InputLabel>{" "}
        <Input
          id="payment-id"
          value={paymentId}
          type="text"
          onChange={(e) => setPaymentId(e.target.value)}
          startAdornment={<InputAdornment position="start">#</InputAdornment>}
        />
      </FormControl>
      <FormControl
        fullWidth
        sx={{ m: 1 }}
        variant="standard"
        style={{ marginTop: "1rem" }}
        size="small"
        required={true}
      >
        <InputLabel htmlFor="payment-amount">Payment Amount</InputLabel>
        <Input
          id="payment-amount"
          value={paymentAmount}
          type="number"
          inputProps={{ min: 0 }}
          onChange={(e) => setPaymentAmount(e.target.value.replace("-", ""))}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="w-full ml-1 my-2 text-center"
          label="Payment Date"
          value={paymentDate}
          onChange={(newValue) => {
            setPaymentDate(newValue);
          }}
          slotProps={{
            textField: {
              variant: "standard",
              size: "small",
              error: false,
              required: true,
            },
          }}
        />
      </LocalizationProvider>
      <div style={{ marginTop: "1rem" }}>
        <Button
          variant="contained"
          onClick={handlePaymentSubmit}
          disabled={loading || postDisable}
        >
          Submit Payment
        </Button>
      </div>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
