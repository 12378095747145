import React from "react";
import { CallLogPanelContext } from "../context/CallLogPanelContext";
import CallLogHeader from "./CallLogHeader";
import CallLogList from "./CallLogList";
import CallLogSearch from "./CallLogSearch";
import CallLogPagination from "./CallLogPagination";
import CallLogError from "./CallLogError";
export default function CallLogPanel({ children }) {
  return (
    <CallLogPanelContext.Provider value={{}}>
      <div className="bg-white rounded-md border-gray-100 border-y px-0">
      {React.Children.map(children, (child) => React.cloneElement(child))}
      </div>
    </CallLogPanelContext.Provider>
  );
}

CallLogPanel.Header = CallLogHeader;
CallLogPanel.List = CallLogList;
CallLogPanel.Search = CallLogSearch;
CallLogPanel.Pagination = CallLogPagination;
CallLogPanel.Error = CallLogError;
