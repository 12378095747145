import React, { useState, useEffect, useCallback } from "react";

import { FormControl, MenuItem, Select, InputLabel} from "@mui/material";
import LoadingIndicator from "../../LoadingIndicator";

export default function SelectBasic({
  selectedVal,
  setSelectedVal,
  title,
  optionsData,
  nameKey,
  required
}) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleValSelect = (e) => {
    setSelectedVal(e.target.value);
  };

  const setOptionsState = useCallback(async () => {
    if (optionsData) {
      setOptions(optionsData);
    }
    setIsLoading(false);
  }, [optionsData]);

  useEffect(() => {
    let active = true;

    if (active) {
      if (isLoading) {
        setOptionsState();
      }
    }

    return () => {
      active = false;
    };
  }, [isLoading, setOptionsState]);

  return (
    <div data-testid="Search-Select">
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} />
      ) : (
        <div data-testid="Select-Field">
          
          <FormControl
            className=""
            required={required ? true : false}
            style={{
              width: "100%",
              padding: "0",
              marginBottom: "10px",
            }}
          >
            <InputLabel className="text-m" style={{
              padding:'0.10rem 0.45rem',
              lineHeight:'1.25rem',
              backgroundColor:'#fff'
            }}>
              {title}
            </InputLabel>
            <Select
              data-testid="select-field"
              size="small"
              value={selectedVal}
              onChange={handleValSelect}
              className="text-sm p-2"
              MenuProps={{
                sx: { height: "300px" },
              }}
            >
              {/*  {options && options.length > 0 && (
                <MenuItem data-testid="default-option" value={""}>
                  {defaultString}
                </MenuItem>
              )} */}
              {options &&
                options.length > 0 &&
                options.map((item) => (
                  <MenuItem
                    value={item}
                    key={nameKey ? item[nameKey].id : item.id}
                    id={nameKey ? item[nameKey].id : item.id}
                    className="text-sm"
                  >
                    <span className={item.id === "" ? "italic" : ""}>{nameKey ? item[nameKey].name : item.name}</span>
                  </MenuItem>
                ))}
              {options.length === 0 && (
                <MenuItem value="No Results Found">No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
}
