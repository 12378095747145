export const SHOW_NEW_USER_FORM = "SHOW_NEW_USER_FORM";
export const SEARCH = "SEARCH";
export const REFRESH_USERS_LIST = "REFRESH_USERS_LIST";
export const SHOW_COMPONENT_SET = "SHOW_COMPONENT_SET";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const VIEW_DEACTIVATE_USER = "VIEW_DEACTIVATE_USER";
export const VIEW_ADD_USER = "VIEW_ADD_USER";
export const VIEW_EDIT_USER = "VIEW_EDIT_USER";
export const VIEW_PASSWORD_RESET = "VIEW_EDIT_USER";
export const SET_API_ERRORS = "SET_API_ERRORS";
export const CURRENT_PAGE_SET = "CURRENT_PAGE_SET";
export const CURRENT_PAGE_URL_SET = "CURRENT_PAGE_URL_SET";
export const RESULT_COUNT_SET = "RESULT_COUNT_SET";

export const showNewUserForm = (dispatch, bool) =>
  dispatch({ type: SHOW_NEW_USER_FORM, payload: bool });

export const searchText = (dispatch, text) =>
  dispatch({ type: SEARCH, payload: text });

export const refreshUsersList = (dispatch, bool) =>
  dispatch({ type: REFRESH_USERS_LIST, payload: bool });

export const showComponent = (dispatch, text) =>
  dispatch({ type: SHOW_COMPONENT_SET, payload: text });

export const setSelectedUser = (dispatch, obj) =>
  dispatch({type:SET_SELECTED_USER, payload: obj});

export const setFilterData = (dispatch, obj) =>
  dispatch({ type: SET_FILTER_DATA, payload: obj });

  export const setAPIErrors = (dispatch, arr) =>
  dispatch({ type: SET_API_ERRORS, payload: arr});

  export const setCurrentPageSet = (dispatch, number) =>
  dispatch({type: CURRENT_PAGE_SET, payload:number});

  export const setCurrentPageUrlSet = (dispatch, text) =>
  dispatch({type: CURRENT_PAGE_URL_SET, payload: text});

  export const setResultCount = (dispatch, obj) =>
  dispatch({type: RESULT_COUNT_SET, payload: obj});


