import { Close } from "@mui/icons-material";
import {
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Input,
  Autocomplete,
  TextField,
} from "@mui/material";
import qs from "query-string";
import { useContext, useEffect, useState } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ModalButton from "../../../../../global/ModalButton";
import ResponseModal from "../../../../../global/ResponseModal";
import CustomProvModal from "./CustomProvModal";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function AddMedItem({
  casePk,
  addOpen,
  setAddOpen,
  trigger,
  setTrigger,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [medTypeOptions, setMedTypeOptions] = useState([]);
  const [medType, setMedType] = useState(0);
  const [openCustomProv, setOpenCustomProv] = useState(false);

  const [selectedProvider, setSelectedProvider] = useState("");
  const [provSearch, setProvSearch] = useState("");
  const [provList, setProvList] = useState([]);

  const handleProvSelect = (e, prov) => {
    setSelectedProvider(prov);
  };

  const handleOpenSuccess = () => {
    setAddOpen(false);
    setSelectedProvider("");
    setMedType(0);
    setOpenSuccess(!openSuccess);
    setTrigger(!trigger);
    setExpenseAmount("");
  };

  useEffect(() => {
    axios
      .options("/api/provider_settlement/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setMedTypeOptions(response.data.actions.POST.settlement_type.choices);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setMedTypeOptions, accessToken]);

  const handleExpenseSubmit = () => {
    const postData = {
      provider: +selectedProvider.pk,
      case: +casePk,
      settled_amount: +expenseAmount,
      settlement_type: +medType,
    };

    axios
      .post("/api/provider_settlement/", postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setOpenSuccess(!openSuccess);
      })
      .catch((error) => {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
        }
      });
  };

  const handleClose = () => {
    setAddOpen(false);
    setSelectedProvider("");
  };

  const sendRequest = () => {
    let querytest = qs.stringify(
      {
        search: !provSearch ? null : provSearch,
      },
      {
        skipNull: true,
      }
    );

    let queryBase = `/api/provider/?ordering=name`;

    axios
      .get(queryBase + "&" + querytest, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.results;
        setProvList(data);
      });
  };

  useDebouncedEffect(() => sendRequest(), [accessToken, provSearch], 250);

  const handleOpenProvModal = () => {
    setOpenCustomProv(!openCustomProv);
  };
  return (
    <Dialog open={addOpen}>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "100%",
          width: "500px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          {" "}
          <IconButton onClick={() => handleClose()}>
            <Close />
          </IconButton>
        </div>
        <h1
          style={{
            fontSize: 20,
            margin: "0 0 1rem 0",
            textAlign: "center",
            color: "#16A34A",
            fontWeight: "bold",
          }}
        >
          Add Custom Medical Item
        </h1>
        <FormControl
          className="w-full flex"
          style={{ width: "100%", marginBottom: "1rem" }}
        >
          <InputLabel id="medType">Type</InputLabel>
          <Select
            required
            className="w-full"
            labelId="medType"
            id="medical-type"
            value={medType}
            label="Medical Type"
            onChange={(event) => {
              setMedType(event.target.value);
            }}
          >
            {medTypeOptions.map((type, idx) => {
              return (
                <MenuItem
                  value={type.value}
                  key={idx}
                  onClick={() => setMedType(type.value)}
                >
                  {type.display_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div style={{ margin: "1rem auto 1rem" }} className="w-full">
          <Autocomplete
            className="w-full"
            disablePortal
            options={!provList ? [] : provList}
            getOptionLabel={(option) => option.name}
            noOptionsText="No Results"
            sx={{ width: 300 }}
            onChange={(e, value) => {
              handleProvSelect(e, value);
            }}
            value={!selectedProvider ? null : selectedProvider}
            renderOption={(props, option) => {
              return (
                <li {...props} id={option.name} key={option.name}>
                  {option.name}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Providers"
                onChange={(e) => setProvSearch(e.target.value)}
              />
            )}
          />
        </div>
        <div style={{ marginTop: "1rem" }}>
          <ModalButton
            action={handleOpenProvModal}
            baseColor="#9333EA"
            content={"Add Custom Provider"}
          />
        </div>
        <FormControl
          fullWidth
          sx={{ m: 1 }}
          variant="standard"
          style={{ marginTop: "1rem" }}
        >
          <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
          <Input
            id="standard-adornment-amount"
            value={expenseAmount}
            type="number"
            onChange={(e) => setExpenseAmount(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <div style={{ marginTop: "1rem" }}>
          <ModalButton
            action={handleExpenseSubmit}
            baseColor="#16A34A"
            content="Submit Med Item"
            icon={"add"}
          />
        </div>
      </div>
      <CustomProvModal
        openCustomProv={openCustomProv}
        setOpenCustomProv={setOpenCustomProv}
      />
      <ResponseModal
        title={`Custom Addition Successful`}
        description="Custom medical item has been added"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title={`Custom Addition Failed`}
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </Dialog>
  );
}
