import { Chip, ListItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import ProviderDetailv2 from "../../../../../global/ProviderDetailModal/ProviderDetailv2";

export default function ManageProviderChip({ prov, idx }) {
  const [open, setOpen] = useState(false);
  const chipColors = [
    "purple-600",
    "sky-600",
    "green-600",
    "orange-600",
    "orange-600",
  ];

  const truncate = (str, max) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, max) + "..." : str;
  };

  return (
    <ListItem className="w-fit p-0">
      <Tooltip title={`View ${prov.name} Details`}>
        {" "}
        <Chip
          className={`text-[12px] m-1 w-fit uppercase bg-${chipColors[idx]} text-white break-all`}
          label={truncate(prov.name, 25)}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      <ProviderDetailv2 providerDetail={prov} open={open} setOpen={setOpen} />
    </ListItem>
  );
}
