import React from "react";
import { TextField } from "@mui/material";

export default function CommLogSearch({
  searchValue,
  setSearchField,
  resetPagination,
}) {
  return (
    <div className="mt-6 pr-2">
      <div className="flex items-center mt-20 mx-3">
        <TextField
          className="placeholder:text-center rounded-lg w-full text-sm"
          placeholder="Search By Name or Phone Number"
          size="small"
          name="callLog-search"
          value={searchValue}
          InputLabelProps={{ shrink: true }}
          label="Search"
          onChange={(event) => {
            setSearchField(event.target.value);
            resetPagination();
          }}
        />
      </div>
    </div>
  );
}
