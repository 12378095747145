import { PAGE_SET, ROWS_PER_PAGE_SET, SET_USERS } from "./actions";

export const initialState = {
  page:0,
  rowsPerPage:10,
  users:[],
  
};

export function userListReducer(state, action) {
  
  switch (action.type) {
    case PAGE_SET: {
      return { ...state, page: action.payload };
    }
    case ROWS_PER_PAGE_SET: {
      return { ...state, rowsPerPage: action.payload };
    }
    case SET_USERS: {
      return { ...state, users: action.payload };
    }
    
    default:
      return state;
  }
  
}
