import React, { useReducer, useContext } from "react";
import { upmReducer, initialState } from "./state/upmReducer";
import PermissionWrapper from "../../../../../global/PermissionWrapper";
import { Box } from "@mui/material";
import AddUser from "../AddUser/AddUser";
import { UPMStateContext } from "./context/UPMStateContext";
import { UPMDispatchContext } from "./context/UPMDispatchContext";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";

//TODO: IMPORTANT CHange viewable permissions
export default function AddUserStandalone({ view_user_management = true }) {
  /**
   * User Profile Manager Component
   * - Loads Sub Modules in various layouts/positions
   * - Manages Communication between Sub Modules
   * - Determines Priority of SubModules access
   *
   */

  const STRINGS = {
    TITLE: "Manage Users",
    SUBTITLE:
      "Manage the people in your account - add, edit, archive and delete anyone at any time.",
    FILTERS_BTN: "Filters",
    ADD_USER_BTN: "Add User",
    NO_ACCESS: "You do not have permission to access.",
    SEARCH_PLACEHOLDER: "Search by Name, Email, or Phone",
  };

  const apiUrls = {
    getUsersIManage: "api/users/managed_users/",
    users: "api/users/",
  };

  const { loggedInUser, accessToken } = useContext(DataContext);
  const [state, dispatch] = useReducer(upmReducer, initialState);

  const handleSubmitForm = async (e, data = null, dataId = null) => {
    await axios({
      url: dataId ? apiUrls.users + dataId : apiUrls.users,
      method: dataId ? "DELETE" : "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    });

    //TODO: Triggers refresh with UseEffect. Can I get rid of?
    // refreshUsersList(dispatch, false);
  };

  const closeActiveComponent = () => {
    //Navigate
  };

  /********  Component Rendering ********/

  return (
    <div data-testid="User-Profile-Manager" className="max-w-[100vw]">
      <PermissionWrapper permission={view_user_management}>
        <UPMStateContext.Provider value={state}>
          <UPMDispatchContext.Provider value={dispatch}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <AddUser
                permissionSets={loggedInUser.permission_sets}
                submitForm={handleSubmitForm}
                close={closeActiveComponent}
                isStandalone={true}
              />
            </Box>
          </UPMDispatchContext.Provider>
        </UPMStateContext.Provider>
      </PermissionWrapper>
      <PermissionWrapper permission={!view_user_management}>
        <div data-testid="no-access-message">{STRINGS.NO_ACCESS}</div>
      </PermissionWrapper>
    </div>
  );
}
