import React, { useState, useEffect } from "react";
import { CircularProgress, List, ListItem, ListItemText, Stack, TextField, Tooltip } from "@mui/material";
import PaginationControlsV2 from "../../PaginationControlsV2";

export default function ProviderServicesView({
  currentServices,
  isCurrSerApiIsLoading
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currSearchResults, setCurrSearchResults] = useState(currentServices);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [maxPage, setMaxPage] = useState(0);


  const searchItems = (value) => {
    const serviceNameResults = currentServices.filter(item =>
      item.service_name.toLowerCase().includes(value));

    const serviceCodeResults = currentServices.filter(item =>
        item.service_code.toLowerCase().includes(value));
  
    let results = [...serviceNameResults, ...serviceCodeResults];
    setCurrSearchResults(results);
    if(value !== ""){
      setShowSearchResults(true);
    }else{
      setShowSearchResults(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage((value-1)%maxPage);
  };
  useEffect(()=>{
    let active = true;

    if(active){
      if(!isCurrSerApiIsLoading && currentServices){
        setMaxPage(Math.ceil(currentServices.length/25));
        setIsLoading(false);
      }
    }
    return ()=>{
      active = false;
    }
  }, [isCurrSerApiIsLoading, currentServices])

  return (
    <div id="ProviderServicesView" data-testid="ProviderServicesView">
       <div id="search" data-testid="search" className="lg:w-[100%] p-2">
            <TextField
              className="placeholder:text-center rounded-lg  text-sm w-full"
              placeholder={"Search Services"}
              size="small"
              name="search"
              InputLabelProps={{ shrink: true }}
              label="Search"
              onChange={(event) => searchItems(event.target.value)}
            />
          </div>
      <List
        sx={{
          height: '400px',
          overflowY: 'auto',
        }}
        className="w-full border rounded-md"
        dense
        component="div"
        role="list"
      >
        {isLoading ?
        <CircularProgress color='secondary'/>
        :
        currentServices && currentServices.length > 0
          ? !showSearchResults ? 
          currentServices
          .slice(currentPage*25,25*(currentPage+1))
          .map((service) => (
              <Tooltip
                key={"tooltip_" + service.id}
                title={`Added By: ${service.updated_by} at ${service.updated_at}`}
              >
                <ListItem key={service.id}>
                  <ListItemText>
                    {service.service_code} - {service.service_name}
                  </ListItemText>
                </ListItem>
              </Tooltip>
            ))
          : 
          currSearchResults
          .map((service) => {
            return (
              <Tooltip
                key={"tooltip_" + service.id}
                title={`Added By: ${service.updated_by} at ${service.updated_at}`}
              >
                <ListItem key={service.id}>
                  <ListItemText>
                    {service.service_code} - {service.service_name}
                  </ListItemText>
                </ListItem>
              </Tooltip>
            );
          })
          :
          <div style={{ position:'relative', height:'100%',}}>
              <div style={{ position:'absolute', top:'50%', width:'100%', textAlign:'center'}}>
                No services shown. Please add services to the List of Medical Services.
                </div>
                </div>
        }
      </List>
      {!isLoading &&
      <div className="py-2">
        <Stack alignItems={"center"}>
          <PaginationControlsV2
            resultCount={currentServices.length}
            handleChange={handlePageChange}
            currentPage={currentPage+1}
          />
        </Stack>
      </div>
      }
    </div>
  );
}
