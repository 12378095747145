import { Check, Close, Edit } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ResponseModal from "../../../../../global/ResponseModal";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
// import ResponseModal from "./ResponseModal";
// import DataContext from "../../context/DataContext";
// import axios from "../api/axios";

export default function SettlementValueEdit({
  title,
  val,
  valKey,
  trigger,
  setTrigger,
  apiUrl,
  isDisabled,
}) {
  const { accessToken } = useContext(DataContext);
  const [newVal, setNewVal] = useState(!val ? "" : val);
  const [editActive, setEditActive] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleOpenSuccess = () => {
    setTrigger(!trigger);
    setOpenSuccess(!openSuccess);
    setEditActive(false);
  };

  const updateVal = () => {
    let patchedData = {
      [valKey]: newVal === "" ? null : +newVal,
    };

    axios
      .patch(apiUrl, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
          setEditActive(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setOpenError(!openError);
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setErrorMessage(errArr);
        }
      });
  };

  const handleExitEdit = () => {
    setEditActive(false);
    setNewVal(val);
  };

  return (
    <div className="my-2 w-full">
      {!editActive ? (
        <div className="flex justify-between">
          <div>
            <InputLabel>{title}</InputLabel>

            {/* <span className="block text-[14px] text-gray-500 ">{title}</span> */}
            <div>
              {!newVal ? "" : <span className="pr-2">$</span>}
              {!newVal ? "-" : newVal}
            </div>
          </div>
          <div className="self-end">
            {" "}
            <IconButton
              className="text-black"
              onClick={() => setEditActive(!editActive)}
              disabled={isDisabled}
            >
              <Tooltip title={`Edit ${title}`}>
                <Edit />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      ) : (
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">{title}</InputLabel>
          <Input
            id="standard-adornment-amount"
            value={newVal}
            type="number"
            onChange={(e) => setNewVal(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={
              <InputAdornment position="end" className="flex items-center">
                <Tooltip title="Submit">
                  <IconButton
                    className="text-green-600"
                    variant="outlined"
                    onClick={updateVal}
                  >
                    <Check />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    className="text-red-600"
                    variant="outlined"
                    onClick={handleExitEdit}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </FormControl>
      )}
      <ResponseModal
        title={`${title} Update Successful`}
        description="Case information has been updated"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title={`${title} Update Failed`}
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
