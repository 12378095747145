import React from "react";
import { TextField } from "@mui/material";

export default function CallLogSearch({ onchange }) {
  return (
    <div className="relative pr-2">
      <div className="flex items-center mt-3 mx-3">
        <TextField
          className="placeholder:text-center rounded-lg w-full text-sm"
          placeholder="Search Call History"
          size="small"
          name="callLog-search"
          //value={value}
          InputLabelProps={{ shrink: true }}
          label="Search"
          onChange={(event) => onchange(event.target.value)}
        />
      </div>
    </div>
  );
}
