import React, { useContext, useState } from "react";
import AccordionShell from "../../../../../../../global/AccordionShell";
import DataContext from "../../../../../../../../context/DataContext";
import StatusSelect from "../ProviderListItem/StatusSelect";
import { IconButton } from "@mui/material";
import { Delete, ListAltOutlined } from "@mui/icons-material";
import ModalShell from "../../../../../../../global/ModalShell";
import DeleteProviderv2 from "../../DeleteProviderv2";
import ProviderDetailv2 from "../../../../../../../global/ProviderDetailModal/ProviderDetailv2";

export default function ProviderListItemMobile({
  prov,
  providerAmountObj,
  formatter,
  activeProvObj,
  proCliTrigger,
  setProCliTrigger,
  docTypeOptions,
  docTypeOptionsObj,
  caseInfo,
}) {
  const { userRoles } = useContext(DataContext);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openProvDetail, setOpenProvDetail] = useState(false);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  return (
    <div className="lg:hidden">
      <AccordionShell
        title={`${prov.provider.name} - ${prov.provider.type}`}
        customAdornment={!prov.provider.in_network ? "red" : false}
      >
        <div className="border-purple-600 rounded-md border p-2">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mx-2">
              {userRoles.permissions.includes(
                "provider.view_providersettlement"
              ) ? (
                <div className="mx-auto w-fit">
                  <span className="block">
                    <strong>Running:</strong>{" "}
                    {!providerAmountObj[prov.provider.pk]
                      ? formatter.format(0)
                      : formatter.format(
                          +providerAmountObj[prov.provider.pk].initial_amount
                        )}
                  </span>
                  <span className="block">
                    <strong>Settled:</strong>
                    {!providerAmountObj[prov.provider.pk]
                      ? "-"
                      : formatter.format(
                          +providerAmountObj[prov.provider.pk].settled_amount
                        )}
                  </span>
                </div>
              ) : (
                <div className="grid justify-start">
                  <span>-</span>
                </div>
              )}
            </div>
            <div className="md:w-1/2 mx-2">
              <div className="flex justify-center">
                {" "}
                <IconButton
                  onClick={() => setOpenProvDetail(true)}
                  className="text-purple-600"
                >
                  <ListAltOutlined className="text-[18px]" />
                </IconButton>{" "}
                {userRoles.permissions.includes(
                  "provider.delete_providerclientstatus"
                ) ? (
                  <IconButton
                    onClick={() => handleOpenDeleteModal()}
                    className="text-red-600"
                  >
                    <Delete className="text-[18px]" />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mx-2">
              <StatusSelect
                provider={prov}
                activeProvObj={activeProvObj}
                proCliTrigger={proCliTrigger}
                setProCliTrigger={setProCliTrigger}
                docTypeOptions={docTypeOptions}
                docTypeOptionsObj={docTypeOptionsObj}
              />
            </div>
            <div className="md:w-1/2 mx-2 text-center">
              <span className="block">
                <strong>Created by:</strong> {prov.created_by_name}
              </span>
              <span className="block">
                <strong>Created at:</strong>&nbsp;
                {new Date(prov.created_at).toLocaleString("EN-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
            <div className="md:w-1/2 mx-2 text-center">
              <span className="block">
                <strong>First Appointment:</strong>&nbsp;
                {new Date(prov.first_appointment_date).toLocaleString("EN-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          </div>
        </div>
      </AccordionShell>
      {openDeleteModal ? (
        <ModalShell
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={"Delete Provider"}
          color={"#d32f2f"}
          customWidth={"md"}
        >
          <DeleteProviderv2
            providerDetail={prov}
            setDeleteFormDisplay={setOpenDeleteModal}
            trigger={proCliTrigger}
            setTrigger={setProCliTrigger}
            caseInfo={caseInfo}
          />
        </ModalShell>
      ) : (
        ""
      )}
      {openProvDetail ? (
        <ProviderDetailv2
          providerDetail={prov.provider}
          open={openProvDetail}
          setOpen={setOpenProvDetail}
        />
      ) : (
        ""
      )}
    </div>
  );
}
