import { createContext, useState } from "react";
import { subDays } from "date-fns";
const AccountabilityContext = createContext({});

export const ActionLogProvider = ({ children }) => {
  const [search, setSearch] = useState("");
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [orderingVal, setOrderingVal] = useState("-timestamp");
  const [timeStampTo, setTimeStampTo] = useState(subDays(new Date(), 1));
  const [timeStampFrom, setTimeStampFrom] = useState(subDays(new Date(), 7));
  const [selectedActions, setSelectedActions] = useState([]);
  const [activeActions, setActiveActions] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);

  return (
    <AccountabilityContext.Provider
      value={{
        search,
        setSearch,
        paginationUrl,
        setPaginationUrl,
        currentPage,
        setCurrentPage,
        resultCount,
        setResultCount,
        orderingVal,
        setOrderingVal,
        timeStampTo,
        setTimeStampTo,
        timeStampFrom,
        setTimeStampFrom,
        selectedActions,
        setSelectedActions,
        activeActions,
        setActiveActions,
        activeFilters,
        setActiveFilters,
        selectedUsers,
        setSelectedUsers,
        activeUsers,
        setActiveUsers,
      }}
    >
      {children}
    </AccountabilityContext.Provider>
  );
};

export default AccountabilityContext;
