import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "../../../api/axios";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import ResponseModal from "../../../global/ResponseModal";
import DataContext from "../../../../context/DataContext";
import ApiRequestErrorHandler from "../../../global/ApiRequestErrorHandler";
import { useNavigate } from "react-router-dom";

export default function PasswordCreate({ userResponse, setCookies, token }) {
  const { accessToken } = useContext(DataContext);
  const [newPass, setNewPass] = useState("");
  const [newPassCheck, setNewPassCheck] = useState("");
  const [passMatch, setPassMatch] = useState("empty");
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();

  console.log(userResponse);

  const resetPass = () => {
    const resetData = {
      new_password_1: newPass,
      new_password_2: newPassCheck,
    };

    axios
      .patch(`/api/users/${userResponse.pk}/set_password/`, resetData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown("Password has been updated");
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const checkPass = () => {
    if (!newPass && !newPassCheck) {
      setPassMatch("empty");
    } else if (newPass !== newPassCheck && newPass.length < 8) {
      setPassMatch(false);
    } else if (newPass === newPassCheck && newPass.length >= 8) {
      setPassMatch(true);
    }
  };

  useDebouncedEffect(
    () => {
      checkPass();
    },
    [newPass, newPassCheck],
    250
  );

  const closeResModal = () => {
    setResponseModal(false);
    setResponseBreakdown("");
    if (!isError) {
      setCookies(userResponse, token);
      navigate("/dashboard");
    } else {
      setIsError(false);
    }
  };

  return (
    <div className=" sm:w-1/2 w-[95%] sm:mx-0 mb-56 pt-10 rounded-xl border 2 bg-gray-200 shadow-lg h-fit">
      <div className="w-3/4 mx-auto py-4 ">
        <>
          <div className="w-3/4 mx-auto">
            <h3 className="text-xl font-bold text-center">
              Create New Password
            </h3>
            <TextField
              style={{ width: "100%", margin: "1rem 0" }}
              className="w-full my-4 text-center"
              placeholder="New Password" //
              type="password"
              autoComplete="off"
              label="Password"
              value={newPass}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => setNewPass(event.target.value)}
              error={!passMatch ? true : passMatch === "empty" ? false : false}
            />
            <TextField
              style={{ width: "100%", margin: "1rem 0" }}
              className="w-full my-4 text-center"
              placeholder="Confirm New Password" //
              type="password"
              autoComplete="off"
              label="Confirm Password"
              value={newPassCheck}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => setNewPassCheck(event.target.value)}
              error={!passMatch ? true : passMatch === "empty" ? false : false}
              helperText={passMatch ? "" : "Passwords need to match"}
            />
            <Button
              className="mx-auto block"
              variant="outlined"
              onClick={resetPass}
              disabled={
                !passMatch ? true : passMatch === "empty" ? true : false
              }
            >
              Update
            </Button>
          </div>
        </>
      </div>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
