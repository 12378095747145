import { IconButton, ListItem } from "@mui/material";
import React, { useContext, useState } from "react";
import { Edit } from "@mui/icons-material";
import DataContext from "../../../../context/DataContext";
import InlineEdit from "../components/InlineEdit";

export default function EmailDisplayRow({
  email,
  provider,
  trigger,
  setTrigger,
  activeProvObj,
  setFormValues,
  emailTypeOptions,
  setEmailTypeOptions,
  typeDisplayObj,
  providerTypesActive,
}) {
  const { userRoles, loggedInUser } = useContext(DataContext);
  const [toggleEdit, setToggleEdit] = useState(false);

  const handleCancelEdit = () => {
    setToggleEdit(false);
  };

  return (
    <ListItem className="py-0 w-full">
      {!toggleEdit ? (
        <div className="flex items-center justify-between w-full border-b py-1">
          <div className="flex items-center w-full">
            <p>
              <span className="font-bold">
                {typeDisplayObj[email.email_type]}
              </span>
              : {email.email}
            </p>
            {loggedInUser.entity &&
            loggedInUser.entity.entity_type_label === "Provider" &&
            provider.pk !== activeProvObj[provider.pk] ? (
              ""
            ) : userRoles.permissions.includes("provider.add_provideremail") ||
              userRoles.permissions.includes("provider.change_provideremail") ||
              userRoles.permissions.includes(
                "provider.delete_provideremail"
              ) ? (
              <IconButton
                onClick={() => setToggleEdit(true)}
                className="text-gray-400 text-[18px]"
              >
                <Edit className="text-gray-400 text-[18px]" />
              </IconButton>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="w-full mt-1 shadow-lg p-4 rounded-md mb-2">
          {!userRoles.permissions.includes("provider.change_provideremail") ? (
            ""
          ) : (
            <InlineEdit
              activeWindow={email}
              handleCancelEdit={handleCancelEdit}
              setToggleEdit={setToggleEdit}
              trigger={trigger}
              setTrigger={setTrigger}
              provider={provider}
              apiUrl="/api/provider_email/"
              emailTypeOptions={emailTypeOptions}
              setEmailTypeOptions={setEmailTypeOptions}
              typeDisplayObj={typeDisplayObj}
              providerTypesActive={providerTypesActive}
            />
          )}
        </div>
      )}
    </ListItem>
  );
}
