import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import LogItem from "./components/LogItem";
import { Chip, CircularProgress, List, TextField } from "@mui/material";
import qs from "query-string";
import PaginationControls from "../../../../global/PaginationControls";
import { Storage } from "@mui/icons-material";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import { createDisplayObj, filterFieldData } from "../../../../global/helpers";
import OrderingArrow from "../../../../global/OrderingArrow";
import DateRange from "../../../../global/DateRange";
import { format } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MultiSelectFilterV2 from "../../../../global/MultiSelectFilterV2";
import { CustomChip } from "../../../../global/CustomQueryChip";
import { useNavigate } from "react-router-dom";
import AccountabilityContext from "../../../../../context/AccountabilityLogContext";
import MultiSelectSearchV2 from "../../../../global/MultiSelectSearchV2";

export default function AccountabilityLog() {
  const { accessToken, userRoles, loggedInUser } = useContext(DataContext);
  const {
    search,
    setSearch,
    paginationUrl,
    setPaginationUrl,
    currentPage,
    setCurrentPage,
    resultCount,
    setResultCount,
    orderingVal,
    setOrderingVal,
    timeStampTo,
    setTimeStampTo,
    timeStampFrom,
    setTimeStampFrom,
    selectedActions,
    setSelectedActions,
    activeActions,
    setActiveActions,
    activeFilters,
    setActiveFilters,
    selectedUsers,
    setSelectedUsers,
    activeUsers,
    setActiveUsers,
  } = useContext(AccountabilityContext);
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [actionGroupOptions, setActionGroupOptions] = useState([]);
  const [actionGroupLabels, setActionGroupLabels] = useState("");
  const [crudLabels, setCrudLabels] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedUsers.length) {
      setSelectedUsers([loggedInUser.pk]);
      setActiveUsers([loggedInUser.name]);
    } else if (
      selectedUsers.length > 1 &&
      selectedUsers.includes(loggedInUser.pk)
    ) {
      setSelectedUsers(
        selectedUsers.filter((item) => item !== loggedInUser.pk)
      );
      setActiveUsers(activeUsers.filter((item) => item !== loggedInUser.name));
    }
  }, [
    setSelectedUsers,
    loggedInUser,
    setActiveUsers,
    activeUsers,
    selectedUsers,
  ]);

  useEffect(() => {
    axios
      .get(`/api/field_options/?ordering=label&content_type=actionlog`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        setActionGroupLabels(createDisplayObj(data, "action_group"));
        setActionGroupOptions(filterFieldData(data, "action_group"));
        setCrudLabels(createDisplayObj(data, "crud_type"));
        return response;
      })
      .catch((response) => {
        return response;
      });
  }, [setActionGroupLabels, setActionGroupOptions, accessToken]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  const resetPagination = () => {
    setPaginationUrl("");
    setCurrentPage(1);
  };

  const queryActionLogs = () => {
    if (userRoles.permissions.includes("utilities.view_actionlog")) {
      setLoading(true);

      const query = qs.stringify(
        {
          search: search === "" ? null : search,
          timestamp__gte: !timeStampFrom
            ? null
            : format(new Date(timeStampFrom), "yyyy-MM-dd"),
          timestamp__lte: !timeStampTo
            ? null
            : format(new Date(timeStampTo), "yyyy-MM-dd"),
          ordering: !orderingVal ? null : orderingVal,
        },
        {
          skipNull: true,
        }
      );

      const usersQuery =
        selectedUsers.length === 0 ? "" : `&user__in=${selectedUsers}`;

      const actionQuery =
        selectedActions.length === 0
          ? ""
          : `&action_group__in=${selectedActions}`;

      axios
        .get(`/api/logs/?` + paginationUrl + query + actionQuery + usersQuery, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data.results;
          setResultCount({
            total: response.data.count,
            current: response.data.results.length,
          });
          setResponseData(data);
          setLoading(false);
          return response;
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage("There was an error loading this content");
          if (error.response.status === 403) {
            navigate("/dashboard");
          }
          return error;
        });
      const parsedQuery = qs.parse(query);
      setActiveFilters(parsedQuery);
    } else {
      setErrorMessage("You do not have permission to access this content");
    }
  };

  const displayFilters = (object) => {
    let returnArr = [];
    for (let [key, value] of Object.entries(object)) {
      if (key === "timestamp__gte") {
        returnArr.push(`Date After: ${value}`);
      } else if (key === "timestamp__lte") {
        returnArr.push(`Date Before: ${value}`);
      } else {
        returnArr.push(`${key}: ${value}`);
      }
    }
    return returnArr;
  };

  useDebouncedEffect(
    () => {
      queryActionLogs();
    },
    [
      search,
      paginationUrl,
      orderingVal,
      timeStampFrom,
      timeStampTo,
      selectedActions,
      selectedUsers,
    ],
    500
  );

  const clearFilter = (val) => {
    const key = val.split(":")[0];
    if (key === "Date After") {
      setTimeStampFrom();
    } else if (key === "Date Before") {
      setTimeStampTo();
    } else if (key === "search") {
      setSearch("");
    } else if (key === "ordering") {
      setOrderingVal("");
    }
  };

  const tableHeaderClasses = "text-center font-bold";

  return userRoles.permissions.includes("utilities.view_actionlog") ? (
    <div className="mx-2 relative">
      <div className="flex">
        <h1 className="text-[1.2rem] my-4 w-1/4">
          <Storage /> Action Log
        </h1>
        <div className="flex m-2 items-center w-[25%]">
          <div className="w-[25%] mx-4 rounded-md shadow-md px-4 py-2 absolute right-[52%] top-2 z-10 bg-white">
            {userRoles.permissions.includes(
              "users.view_usertomanagerassignments"
            ) ? (
              <MultiSelectSearchV2
                title="Filter by Managed User"
                searchTitle="User Search"
                selectedItems={selectedUsers}
                setSelectedItems={setSelectedUsers}
                setActiveItems={setActiveUsers}
                activeItems={activeUsers}
                resetPagination={resetPagination}
                nameKey={"name"}
                valueKey={"pk"}
                apiUrl={`/api/users/managed_users/?`}
                buttonFullW={true}
              />
            ) : (
              <p>Filter unavailable</p>
            )}
          </div>
          <div className="w-[25%] mx-4 rounded-md shadow-md px-4 py-2 absolute right-[26%] top-2 z-10 bg-white">
            <MultiSelectFilterV2
              title="Filter by Group"
              responseArray={actionGroupOptions}
              selectedItems={selectedActions}
              setSelectedItems={setSelectedActions}
              setActiveItems={setActiveActions}
              activeItems={activeActions}
              nameKey="label"
              valueKey="key"
              resetPagination={resetPagination}
              searchTitle="Actions"
              buttonFullW={true}
            />
          </div>
          <div className="w-[25%] mx-4 absolute right-0 top-2 z-10 bg-white">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRange
                val="Filter by Date"
                dateFrom={timeStampFrom}
                dateTo={timeStampTo}
                setDateFrom={setTimeStampFrom}
                setDateTo={setTimeStampTo}
                resetPagination={resetPagination}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className="mx-4 flex justify-between">
        <div className="w-3/4">
          {activeFilters
            ? displayFilters(activeFilters).map((filt, idx) => (
                <Chip
                  className={`px-2 m-1 text-center rounded-lg shadow-inner`}
                  key={filt + idx}
                  onDelete={() => clearFilter(filt)}
                  label={filt}
                />
              ))
            : ""}
          <CustomChip
            setActiveItems={setActiveActions}
            activeItems={activeActions}
            selectedItems={selectedActions}
            setSelectedItems={setSelectedActions}
            title="Groups"
            resetPagination={resetPagination}
          />
          <CustomChip
            setActiveItems={setActiveUsers}
            activeItems={activeUsers}
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
            title="Users"
            resetPagination={resetPagination}
          />
        </div>
        <p className="pl-5 py-4 self-end">
          Showing: {resultCount.current} / {resultCount.total}
        </p>
      </div>
      <div className="flex">
        <TextField
          className="w-full my-2 text-center"
          id="action-log-search"
          label="Search logs"
          value={search}
          size="small"
          onChange={(event) => {
            setSearch(event.target.value);
            resetPagination();
          }}
        />
      </div>
      <div className="flex px-6 justify-between">
        <div className={`w-[5%] ${tableHeaderClasses}`}>Action</div>
        <div className={`w-[15%] ${tableHeaderClasses}`}>
          User{" "}
          <OrderingArrow
            val="user__name"
            orderingVal={orderingVal}
            setOrderingVal={setOrderingVal}
          />
        </div>
        <div className={`w-[15%] ${tableHeaderClasses}`}>
          Client{" "}
          <OrderingArrow
            val="related_case__name"
            orderingVal={orderingVal}
            setOrderingVal={setOrderingVal}
          />
        </div>
        <div className={`w-[15%] ${tableHeaderClasses}`}>
          Date
          <OrderingArrow
            val="timestamp"
            orderingVal={orderingVal}
            setOrderingVal={setOrderingVal}
          />
        </div>
        <div className={`w-[15%] ${tableHeaderClasses}`}>Group</div>
        <div className={`w-[15%] ${tableHeaderClasses}`}>Label</div>
        <div className={`w-[5%] ${tableHeaderClasses}`}>&nbsp;</div>
      </div>
      <List className="h-[70vh] overflow-y-auto">
        {errorMessage ? (
          <p className="text-center">{errorMessage}</p>
        ) : loading ? (
          <div className="w-fit mx-auto">
            <CircularProgress color="secondary" />
          </div>
        ) : responseData.length === 0 || !responseData ? (
          <p className="text-center">No results found</p>
        ) : (
          responseData.map((log) => (
            <LogItem
              log={log}
              key={log.id}
              actionGroupLabels={actionGroupLabels}
              crudLabels={crudLabels}
            />
          ))
        )}
      </List>
      <div className="w-fit mx-auto my-4">
        <PaginationControls
          resultCount={resultCount}
          handleChange={handleChange}
          currentPage={currentPage}
        />
      </div>
    </div>
  ) : (
    <p className="text-center">{errorMessage}</p>
  );
}
