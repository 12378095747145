import React, { useContext, useState } from "react";
import {
  Dialog,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Alert,
  AlertTitle,
  Button,
} from "@mui/material";
import { Close, CloseOutlined, HighlightOff } from "@mui/icons-material";
import DataContext from "../../../../../../../../context/DataContext";
import axios from "../../../../../../../api/axios";
import ResponseModal from "../../../../../../../global/ResponseModal";

export default function DeleteModal({
  removeAvailModal,
  setRemoveAvailModal,
  trigger,
  setTrigger,
  availibilityData,
  daysOfWeek,
}) {
  const { accessToken } = useContext(DataContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [availItem, setAvailItem] = useState("");
  const [postSuccess, setPostSuccess] = useState(false);
  const [postError, setPostError] = useState(false);
  const [errResponse, setErrResponse] = useState([]);

  const deleteAvail = (item) => {
    setAvailItem(item);
    setConfirmDelete(true);
  };

  const handleSuccess = () => {
    setAvailItem("");
    setTrigger(!trigger);
    setConfirmDelete(false);
    setPostSuccess(false);
    setRemoveAvailModal(false);
  };

  const postDelete = (id) => {
    axios
      .delete(`/api/user_contact_hours/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        setPostSuccess(true);
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setPostError(true);
        setErrResponse(errArr);
      });
  };
  return (
    <div>
      {" "}
      {!removeAvailModal || confirmDelete ? (
        ""
      ) : (
        <Dialog open={removeAvailModal}>
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                fontSize: "20px",
              }}
            >
              {" "}
              <IconButton onClick={() => setRemoveAvailModal(false)}>
                <Close />
              </IconButton>
            </div>
            <h1
              style={{
                fontSize: 20,
                margin: "0 0 0",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Remove Availability
            </h1>

            <div style={{ margin: "1rem 0" }}>
              <List>
                {availibilityData
                  .sort((a, b) => a.daysOfWeek[0] - b.daysOfWeek[0])
                  .map((item) => (
                    <ListItem
                      key={item.id}
                      style={{ padding: "0", justifyContent: "space-between" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <strong>{daysOfWeek[item.daysOfWeek[0]]}</strong>{" "}
                          {item.startTime} - {item.endTime}&nbsp;
                        </div>
                      </div>{" "}
                      <div>
                        <Tooltip title="Remove time window">
                          <IconButton onClick={() => deleteAvail(item)}>
                            <HighlightOff style={{ color: "#d32f2f" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </ListItem>
                  ))}
              </List>
            </div>
          </div>
        </Dialog>
      )}
      {confirmDelete ? (
        <Dialog open={confirmDelete}>
          <Alert
            variant="outlined"
            severity="error"
            role="error"
            style={{ paddingRight: "40px" }}
          >
            <AlertTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 0 1rem",
              }}
            >
              Remove
              <Button
                onClick={() => {
                  setConfirmDelete(false);
                  setRemoveAvailModal(true);
                }}
                style={{ padding: "0" }}
              >
                <CloseOutlined />
              </Button>
            </AlertTitle>
            <h1
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                margin: "1rem 0",
              }}
            >
              Clicking below will remove this availability from the client.
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ fontSize: "18px", textAlign: "center" }}>
                <strong style={{ fontSize: "18px", textAlign: "center" }}>
                  {daysOfWeek[availItem.daysOfWeek[0]]}
                </strong>
                <br /> {availItem.startTime} - {availItem.endTime}&nbsp;
              </div>
              <br />
              <Button
                onClick={() => postDelete(availItem.id)}
                style={{ color: "#d32f2f", borderColor: "#d32f2f" }}
                variant="outlined"
              >
                Remove
              </Button>
            </div>
          </Alert>
        </Dialog>
      ) : (
        ""
      )}
      <ResponseModal
        title="Success"
        description="Availability Successfully Removed"
        openBool={postSuccess}
        setOpenBool={setPostSuccess}
        handleCloseFunc={handleSuccess}
      />
      <ResponseModal
        title="Availability Update Failed"
        isError={true}
        openBool={postError}
        setOpenBool={setPostError}
        errorMessage={errResponse}
      />
    </div>
  );
}
