export function callLogReducer(state, action) {
  switch (action.type) {
    case "FETCH_INIT": {
      return { ...state, loading: true };
    }
    case "FETCH_INPROGRESS": {
      return { ...state, callLogs: action.payload };
    }
    case "FETCH_SUCCESS": {
      return { ...state, success: action.payload };
    }
    case "FETCH_ERROR": {
      return { ...state, error: action.payload };
    }
    case "FETCH_DONE": {
      return { ...state, loading: false };
    }
    case "CALL_LOG_SEARCH": {
      return { ...state, logSearch: action.payload };
    }
    case "RESLIMIT_SET": {
      return { ...state, resLimit: action.payload };
    }
    case "RESULT_COUNT_SET": {
      return { ...state, resultCount: action.payload };
    }
    case "CURRENT_PAGE_SET": {
      return { ...state, currentPage: action.payload };
    }
    case "CURRENT_PAGE_URL_SET": {
      return { ...state, paginationUrl: action.payload };
    }
    default:
      return state;
  }
}
