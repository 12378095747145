import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

function ModalButton({ baseColor, content, action, icon }) {
  const [toggleHoverBg, setToggleHoverBg] = useState("white");
  const [toggleHoverText, setToggleHoverText] = useState(baseColor);

  // COLOR HEX LEGEND
  // blue #1976d2
  // purple #9333EA
  // red - #d32f2f
  // green - #32CD30
  // orange - #FFA500

  function ButtonIcon() {
    let renderIcon;
    if (icon === "delete") {
      renderIcon = <DeleteForeverOutlinedIcon />;
    } else if (icon === "add") {
      renderIcon = <ControlPointOutlinedIcon />;
    } else if (icon === "edit") {
      renderIcon = <EditOutlinedIcon />;
    } else if (icon === "refresh") {
      renderIcon = <RefreshIcon />;
    } else {
      return;
    }

    return <span style={{ paddingLeft: "5px" }}>{renderIcon}</span>;
  }
  const hoverActive = () => {
    setToggleHoverBg(baseColor);
    setToggleHoverText("white");
  };

  const hoverOff = () => {
    setToggleHoverBg("white");
    setToggleHoverText(baseColor);
  };

  return (
    <Button
      onClick={action}
      style={{
        padding: "5px 2rem",
        display: "flex",
        justifyContent: "center",
        border: `1px solid ${baseColor}`,
        width: "fit-content",
        margin: "0 auto",
        backgroundColor: toggleHoverBg,
        color: toggleHoverText,
      }}
      onMouseEnter={hoverActive}
      onMouseLeave={hoverOff}
    >
      {content}
      <ButtonIcon />
    </Button>
  );
}

export default ModalButton;
