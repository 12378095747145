import React, { useMemo, useReducer, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FilterList } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateRange from "../../../../../../global/DateRange";
import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FiltersMenu({
  handleFilterSubmit,
  displayText,
  currentFilterState,
  /* managedBy, */
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStatusOnChange = (e) => {
    handleFilterSubmit("is_active", e.target.value);
  };

  //  const handleOptionsOnChange = (e, options) => {
  //   let nameOptions = options.map(option => option.name);
  //   handleFilterSubmit('managedBy', nameOptions);
  //   }

  return (
    <div className="mt-2">
      <IconButton variant="outlined" onClick={(e) => handleClick(e)}>
        <FilterList className="text-sky-600" />
      </IconButton>
      <Menu
        id="filters-menu"
        MenuListProps={{
          "aria-labelledby": "filters-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/*  <MenuItem >
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={managedBy}
          name="managedBy"
          
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                
              />
              {option.name}
            </li>
          )}
          style={{ width: 500 }}
          onChange={(e, options)=>handleOptionsOnChange(e, options)}
          renderInput={(params) => (
            <TextField {...params} label="Reports To" placeholder="Reports To"  />
          )}
        />
        </MenuItem> */}
        <MenuItem className="block">
          <FormLabel id="status-label">Status</FormLabel>
          <RadioGroup
            aria-labelledby="status"
            defaultValue="true"
            name="status"
            className="flex-row"
            onChange={handleStatusOnChange}
          >
            <FormControlLabel
              value={""}
              control={<Radio />}
              label="All"
              className="block"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Active"
              className="block"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Not Active"
            />
          </RadioGroup>
        </MenuItem>
        <MenuItem>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRange
              val="Last Login"
              dateFrom={currentFilterState.last_login__gte}
              dateTo={currentFilterState.last_login__lte}
              setDateFrom={(dateFrom) =>
                handleFilterSubmit("last_login__gte", dateFrom)
              }
              setDateTo={(dateTo) =>
                handleFilterSubmit("last_login__lte", dateTo)
              }
            />
          </LocalizationProvider>
        </MenuItem>
        <MenuItem>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRange
              val="Password Last Updated"
              dateFrom={currentFilterState.password_updated_at__gte}
              dateTo={currentFilterState.password_updated_at__lte}
              setDateFrom={(dateFrom) =>
                handleFilterSubmit("password_updated_at__gte", dateFrom)
              }
              setDateTo={(dateTo) =>
                handleFilterSubmit("password_updated_at__lte", dateTo)
              }
            />
          </LocalizationProvider>
        </MenuItem>
      </Menu>
    </div>
  );
}
