import React, { useContext, useState } from "react";
import PermissionWrapper from "../../../../../../../../global/PermissionWrapper";
import SelectBasic from "../../../../../../../../global/FormComponents/SelectBasic";
import DataContext from "../../../../../../../../../context/DataContext";

/**
 *
 * @param {number} docSource
 * @param {Function} setDocSourceParent
 * @param {Array<{id:number; label:strting}>} optionsList
 * @returns Select Dropdown Field wrapped in a Permission wrapper.
 */

export default function DocSourceDropdown({
  docSource,
  setDocSourceParent,
  optionsList,
  isRequired,
}) {
  const { userRoles } = useContext(DataContext);

  const [options] = useState(optionsList);
  const [selectedSource, setSelectedSource] = useState(
    optionsList.find((g) => g.id === docSource)
  );

  const perms = {
    utilities_view_fieldoptions: userRoles.permissions.includes(
      "utilities.view_fieldoptions"
    ),
  };

  const STRINGS = {
    TITLE: "Source",
  };
  const setSelectedSourceState = (value) => {
    setSelectedSource(value);
    setDocSourceParent(value.id);
  };

  return (
    <div data-testid="Source-Dropdown">
      <PermissionWrapper permission={perms.utilities_view_fieldoptions}>
        {options.length > 0 && (
          <SelectBasic
            selectedVal={selectedSource}
            setSelectedVal={setSelectedSourceState}
            placeholder={STRINGS.TITLE}
            title={STRINGS.TITLE}
            optionsData={options}
            required={isRequired}
          />
        )}
      </PermissionWrapper>
    </div>
  );
}
