import {
  DriveFolderUploadOutlined,
  FileOpenOutlined,
  RequestQuoteOutlined,
  Groups,
  HourglassBottomOutlined,
  MinorCrash,
  QuestionMark,
  Campaign,
  MonetizationOn,
  Close,
  Language,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CakeIcon from "@mui/icons-material/Cake";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import DataContext from "../../../../../../context/DataContext";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import { addYears, differenceInMonths } from "date-fns";
import LiensBalances from "../../Settlement/LiensBalances";
import axios from "../../../../../api/axios";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../../../../global/LoadingIndicator";

export default function CaseInfoStatic({
  caseInfo,
  formatter,
  setViewDocuments,
  caseSources,
  truncate,
  setUploadDocument,
  docNeedsType,
  languageObj,
}) {
  const { userRoles, accessToken, setSelectedClient } = useContext(DataContext);
  const [openExpense, setOpenExpense] = useState(false);
  const [settlementData, setSettlementData] = useState();
  const [navigateToRelatedCase, setNavigateToRelatedCase] = useState(false);

  const navigate = useNavigate();

  function navigateToDetail() {
    navigate(`/settlement/${caseInfo.pk}`);
  }

  useEffect(() => {
    if (
      userRoles.permissions.includes("provider.view_providersettlement") &&
      caseInfo
    ) {
      axios
        .get(`/api/provider_settlement/?case=${caseInfo.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;
          setSettlementData(data);
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }, [caseInfo, userRoles.permissions, accessToken]);

  const openUploadModal = () => {
    if (!docNeedsType || docNeedsType.docs_with_no_type === 0) {
      setUploadDocument(true);
      return;
    } else if (docNeedsType.docs_with_no_type > 0) {
      setUploadDocument(true);
      setViewDocuments(true);
    }
  };

  const campColorVariants = (srcPk) => {
    let colorVarObj = {};

    for (const item in caseSources) {
      colorVarObj[item] = `text-${caseSources[item].toLocaleLowerCase()}-500`;
    }

    return colorVarObj[srcPk];
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFFFF",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      maxWidth: "400px",
      boxShadow:
        "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      padding: "16px",
    },
  }));

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const handleNavToRelated = (clientId) => {
    setNavigateToRelatedCase(true);
    axios
      .get(`/api/case/?client=${clientId}&simplified=true`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        const foundCasePk = data[0].pk;
        setSelectedClient(foundCasePk);
        setNavigateToRelatedCase(false);
        navigate(`/client-detail/${foundCasePk}`);
      })
      .catch((error) => {
        setNavigateToRelatedCase(false);
        console.log(error);
      });
  };

  return (
    <div>
      <LoadingIndicator isLoading={navigateToRelatedCase} />
      <div
        className={`shadow-md rounded-md px-2 py-4 ${
          !caseInfo ? "" : caseInfo.archived === true ? "bg-red-200" : ""
        }`}
      >
        <div className="md:flex items-center justify-center grid grid-cols-2">
          <div className="md:border-r pr-2 mr-2 md:w-1/4 text-center">
            <h2 className="text-[1.2rem] font-bold text-purple-800 text-center">
              {!caseInfo ? "" : caseInfo.client.name}
            </h2>
            <div className="flex items-center justify-center">
              {!caseInfo || !caseInfo.campaign ? (
                ""
              ) : caseInfo.campaign &&
                userRoles.permissions.includes("leads.view_campaign") ? (
                <Tooltip
                  title={`Campaign: ${
                    !caseInfo
                      ? "Campaign Unavailable"
                      : !caseSources
                      ? "Source: ITN"
                      : !caseSources[caseInfo.campaign]
                      ? "Source: ITN"
                      : caseSources[caseInfo.campaign]
                  }`}
                >
                  <Campaign
                    className={`ml-1 ${
                      !campColorVariants(caseInfo.campaign)
                        ? "text-purple-600"
                        : campColorVariants(caseInfo.campaign)
                    }`}
                  />
                </Tooltip>
              ) : (
                ""
              )}
              {!caseInfo ? (
                ""
              ) : caseInfo.related_cases.length === 0 ? (
                ""
              ) : (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <div className="p-4">
                        <Typography color="inherit">Involved Cases</Typography>
                        <div className="flex flex-wrap">
                          {!caseInfo
                            ? ""
                            : !caseInfo.related_cases
                            ? ""
                            : caseInfo.related_cases.map((inv) =>
                                !inv.show_delete ? (
                                  <Chip
                                    className="text-sm m-1"
                                    style={{ margin: "5px" }}
                                    key={inv.id}
                                    onClick={() =>
                                      handleNavToRelated(inv.client_id)
                                    }
                                    variant="outlined"
                                    label={truncate(inv.client_name, 5, 15)}
                                  />
                                ) : (
                                  <Chip
                                    className="text-sm m-1"
                                    style={{ margin: "5px" }}
                                    key={inv.id}
                                    onClick={() =>
                                      handleNavToRelated(inv.client_id)
                                    }
                                    variant="outlined"
                                    label={truncate(inv.client_name, 5, 15)}
                                  />
                                )
                              )}
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  <Button>
                    <Groups className="text-green-600" />
                  </Button>
                </HtmlTooltip>
              )}
              {!caseInfo ? (
                ""
              ) : (
                <Tooltip
                  title={
                    getAge(caseInfo.client.date_of_birth) < 13
                      ? "Under 13 - Do Not Call"
                      : getAge(caseInfo.client.date_of_birth) < 18
                      ? "Under 18 - Parental consent required"
                      : ""
                  }
                >
                  {" "}
                  {getAge(caseInfo.client.date_of_birth) < 13 ? (
                    <PhoneDisabledIcon className="text-red-600" />
                  ) : getAge(caseInfo.client.date_of_birth) < 18 ? (
                    <PhoneDisabledIcon className="text-orange-400" />
                  ) : (
                    ""
                  )}
                </Tooltip>
              )}
            </div>
            <div className="flex flex-wrap justify-center items-center">
              <p className="text-sm mr-2">
                <CakeIcon className="w-[20px]" />:
                {!caseInfo
                  ? ""
                  : new Date(caseInfo.client.date_of_birth).toLocaleDateString(
                      "en-US",
                      {
                        timeZone: "UTC",
                      }
                    )}{" "}
                {!caseInfo ? (
                  ""
                ) : (
                  <span
                    className={`italic ${
                      getAge(caseInfo.client.date_of_birth) < 13
                        ? "text-red-600"
                        : getAge(caseInfo.client.date_of_birth) < 18
                        ? "text-orange-400"
                        : ""
                    }`}
                  >
                    ({getAge(caseInfo.client.date_of_birth)})
                  </span>
                )}
              </p>
              <p className="text-sm">
                <CarCrashIcon className="w-[20px]" />:
                {!caseInfo
                  ? ""
                  : new Date(caseInfo.date_of_accident).toLocaleDateString(
                      "en-US",
                      {
                        timeZone: "UTC",
                      }
                    )}
              </p>
              <p
                className={`text-sm ${
                  differenceInMonths(
                    addYears(
                      new Date(!caseInfo ? "" : caseInfo.date_of_accident),
                      2
                    ),
                    new Date()
                  ) <= 6
                    ? "text-red-600"
                    : differenceInMonths(
                        addYears(
                          new Date(!caseInfo ? "" : caseInfo.date_of_accident),
                          2
                        ),
                        new Date()
                      ) >= 12
                    ? "text-black"
                    : "text-orange-600"
                }`}
              >
                <Tooltip title="Statute of Limitations">
                  <HourglassBottomOutlined className="w-[20px]" />
                </Tooltip>
                :
                {!caseInfo
                  ? ""
                  : addYears(
                      new Date(caseInfo.date_of_accident),
                      2
                    ).toLocaleDateString("en-US", {
                      timeZone: "UTC",
                    })}
              </p>
              {!caseInfo ? (
                ""
              ) : !caseInfo.client.preferred_language ||
                !userRoles.permissions.includes("utilities.view_language") ? (
                ""
              ) : (
                <p className="text-sm">
                  &nbsp;
                  <Tooltip title={`Preferred Language`}>
                    <Language className="w-[20px]" />
                  </Tooltip>
                  :
                  <span>
                    &nbsp;
                    {!languageObj
                      ? ""
                      : languageObj[caseInfo.client.preferred_language]
                          .description}
                  </span>
                </p>
              )}
            </div>
          </div>
          <div className="md:border-r pr-2 mr-2 md:w-1/4 text-center">
            <p
              className={`    text-sm  rounded-md my-1                    
              ${
                !caseInfo
                  ? ""
                  : caseInfo.status === "Pending"
                  ? "bg-orange-100"
                  : caseInfo.status === "Client Unresponsive"
                  ? "bg-orange-100"
                  : caseInfo.status === "Intake"
                  ? "bg-purple-100"
                  : caseInfo.status === "Scheduled"
                  ? "bg-purple-100"
                  : caseInfo.status === "Treating"
                  ? "bg-purple-100"
                  : caseInfo.status === "Waiting on Medical"
                  ? "bg-purple-100"
                  : caseInfo.status === "Progression Complete"
                  ? "bg-purple-100"
                  : caseInfo.status === "Waiting on Offer"
                  ? "bg-amber-100"
                  : caseInfo.status === "Sent to Trial Attorney"
                  ? "bg-amber-100"
                  : caseInfo.status === "Settled"
                  ? "bg-amber-100"
                  : caseInfo.status === "Disbursed"
                  ? "bg-purple-100"
                  : "bg-gray-100"
              }
              `}
            >
              <strong>Case Status:</strong> {!caseInfo ? "" : caseInfo.status}
            </p>
            <p className="text-sm">
              <strong>Liability Status:</strong>{" "}
              <span
                className={`${
                  !caseInfo
                    ? ""
                    : caseInfo.liability_status === "UNREQ"
                    ? "bg-red-400 rounded-md px-2"
                    : caseInfo.liability_status === "ACCEPT"
                    ? "bg-green-400 rounded-md px-2"
                    : caseInfo.liability_status === "PARTIAL"
                    ? "bg-green-200 rounded-md px-2"
                    : caseInfo.liability_status === "DENY"
                    ? "bg-red-400 rounded-md px-2"
                    : "bg-yellow-300 rounded-md px-2"
                }`}
              >
                {!caseInfo ? "" : caseInfo.liability_status}
              </span>
            </p>
            <p className="text-sm">
              <strong>UM Value:</strong>{" "}
              {!caseInfo
                ? ""
                : !caseInfo.um_value
                ? "Unknown"
                : formatter.format(+caseInfo.um_value)}
            </p>
            <p className="text-sm flex justify-center">
              <strong>PD Severity:</strong>{" "}
              {!caseInfo ? (
                ""
              ) : caseInfo.property_damage_severity === null ? (
                <Tooltip title="Unknown">
                  {" "}
                  <QuestionMark className="text-red-600" />
                </Tooltip>
              ) : caseInfo.property_damage_severity === 0 ? (
                <Tooltip title="Surface Damage">
                  <MinorCrash className="text-orange-600" />
                </Tooltip>
              ) : caseInfo.property_damage_severity === 1 ? (
                <Tooltip title="Body Damage">
                  <MinorCrash className="text-yellow-600" />
                </Tooltip>
              ) : (
                <Tooltip title="Totaled">
                  <CarCrashIcon className="text-green-600" />
                </Tooltip>
              )}
            </p>
          </div>
          <div className="md:border-r pr-2 mr-2 md:w-1/4 text-center">
            <p className="text-sm">
              <strong>Policy Limit:</strong>{" "}
              {!caseInfo ? "" : formatter.format(+caseInfo.policy_limit)}
            </p>
            <p className="text-sm">
              <strong>Provider Max:</strong>{" "}
              {!caseInfo ? "" : formatter.format(+caseInfo.provider_max)}
            </p>
            {userRoles.permissions.includes(
              "provider.view_providersettlement"
            ) ? (
              <p className="text-sm">
                <strong>Running Total:</strong>{" "}
                {!settlementData
                  ? ""
                  : formatter.format(+settlementData.total_medical_bills)}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="pr-2 mr-2 md:w-1/4">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="flex items-center">
                  {userRoles.permissions.includes(
                    "provider.view_providersettlement"
                  ) ? (
                    <Tooltip title="View Settlement Detail">
                      <IconButton
                        onClick={navigateToDetail}
                        className={`hover:bg-sky-600 hover:text-white border-none text-sky-600`}
                      >
                        <RequestQuoteOutlined />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {userRoles.permissions.includes(
                    "provider.view_additionalexpense"
                  ) ? (
                    <Tooltip title="Add Lien/Balance">
                      <IconButton
                        onClick={() => setOpenExpense(true)}
                        className={`hover:bg-sky-600 hover:text-white border-none text-sky-600`}
                      >
                        <MonetizationOn />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
                {userRoles.permissions.includes("filemanager.add_document") ? (
                  <Tooltip title="Upload Document to Case">
                    <IconButton
                      className="hover:bg-sky-600 hover:text-white border-none text-sky-600"
                      onClick={() => openUploadModal()}
                    >
                      <DriveFolderUploadOutlined />
                    </IconButton>
                  </Tooltip>
                ) : (
                  "-"
                )}
              </div>
              {userRoles.permissions.includes("filemanager.view_document") ? (
                <div className="flex items-center">
                  <Tooltip title="Open Document List">
                    <IconButton
                      className="hover:bg-sky-600 hover:text-white border-none text-sky-600"
                      onClick={() => setViewDocuments(true)}
                    >
                      <FileOpenOutlined />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog open={openExpense}>
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "100%",
            width: "500px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              fontSize: "20px",
            }}
          >
            {" "}
            <IconButton onClick={() => setOpenExpense(false)}>
              <Close />
            </IconButton>
          </div>
          <LiensBalances />
        </div>
      </Dialog>
    </div>
  );
}
