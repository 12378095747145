import {
  SET_FIELD_DATA,
  SET_ADDRESS,
  SET_SEND_EMAIL,
  SET_TOGGLE_ERROR_UI,
  SET_TOGGLE_SUCCESS_UI,
  SUBMIT_FORM_INIT,
  SET_PERMISSION_SETS,
  SET_API_ERRORS,
} from "./actions";

export const initialState = {
  errorArray: [],
  newUserForm: {
    user: {
      name: "",
      email: "",
      phone: "",
      date_of_birth: "",
      address: {
        latitude: 0,
        longitude: 0,
        raw: "",
      },
    },
    send_email: true,
    permission_sets: [],
  },
  submitForm: false,
  openError: false,
  openSuccess: false,
  toggleSuccessUI: false,
  toggleErrorUI: false,
};
export function addUserReducer(state, action) {
  switch (action.type) {
    case SET_FIELD_DATA: {
      return {
        ...state,
        newUserForm: {
          ...state.newUserForm,
          user: {
            ...state.newUserForm.user,
            [action.payload.fieldName]: action.payload.data,
          },
        },
      };
    }
    case SET_ADDRESS: {
      return {
        ...state,
        newUserForm: {
          ...state.newUserForm,
          user: {
            ...state.newUserForm.user,
            address: {
              ...state.newUserForm.user.address,
              ...action.payload,
            },
          },
        },
      };
    }
    case SET_SEND_EMAIL: {
      return {
        ...state,
        newUserForm: {
          ...state.newUserForm,
          send_email: action.payload,
        },
      };
    }
    case SET_PERMISSION_SETS: {
      return {
        ...state,
        newUserForm: {
          ...state.newUserForm,
          permission_sets: action.payload,
        },
      };
    }
    case SUBMIT_FORM_INIT: {
      return { ...state, submitForm: action.payload };
    }
    case SET_TOGGLE_SUCCESS_UI: {
      return { ...state, toggleSuccessUI: action.payload };
    }
    case SET_TOGGLE_ERROR_UI: {
      return { ...state, toggleErrorUI: action.payload };
    }
    case SET_API_ERRORS: {
      return { ...state, errorArray: action.payload };
    }
    case "CLEAR_USER": {
      return {
        ...state,
        newUserForm: initialState.newUserForm,
      };
    }
    default:
      return state;
  }
}
