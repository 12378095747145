import { CloseOutlined } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingIndicator from "../../../../../../../../global/LoadingIndicator";

export default function ViewDocumentModal({
  viewDocument,
  closeDocModal,
  activeDoc,
}) {
  const [loading, setLoading] = useState(true);

  function getExtension(filename) {
    if (filename.includes(".")) {
      return filename.split(".").pop();
    } else {
      return "";
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, "1500");
  }, []);
  return (
    <Dialog open={viewDocument} fullScreen>
      <div style={{ padding: "2rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 0 1rem",
          }}
        >
          <IconButton onClick={() => closeDocModal()}>
            <CloseOutlined />
          </IconButton>
        </div>
        <div style={{ margin: "0 auto" }}>
          {!activeDoc.file || loading ? (
            <LoadingIndicator isLoading={loading} />
          ) : getExtension(activeDoc.file_name) === "" ? (
            <p
              style={{
                fontSize: "1.2rem",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              No File Preview Available
            </p>
          ) : (
            <object
              data={activeDoc.file}
              aria-label={activeDoc.file_name}
              style={{
                width: "100%",
                height: "100%",
                display: "block",
                minHeight: "80vh",
              }}
            ></object>
          )}
        </div>{" "}
      </div>
    </Dialog>
  );
}
