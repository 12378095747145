import { ListItem } from "@mui/material";
import React, { useState } from "react";
import ModalShell from "../../../../../global/ModalShell";
import EditPayment from "./Modals/EditPayment";
import PaymentDetailMenu from "./PaymentDetailMenu";

export default function PaymentDetailRow({
  paymentTypeDispObj,
  payment,
  trigger,
  setTrigger,
  paymentTypeOptions,
  caseDetail,
}) {
  const [editOpen, setEditOpen] = useState(false);
  return (
    <ListItem
      className="flex justify-between"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: 0,
      }}
    >
      <div style={{ width: "30%", fontSize: "14px" }}>
        {paymentTypeDispObj[payment.check_type]}
      </div>
      <div style={{ width: "30%", fontSize: "14px" }}>
        {!payment.paid_by ? "Unknown" : payment.paid_by.name}
      </div>
      <div style={{ width: "30%", fontSize: "14px" }}>
        {!payment.paid_to ? "Unknown" : payment.paid_to.name}
      </div>
      <div className="flex flex-col w-[10%]">
        <PaymentDetailMenu
          handleEditAction={() => setEditOpen(true)}
          action={payment}
        />
      </div>
      {!editOpen ? (
        ""
      ) : (
        <ModalShell
          open={editOpen}
          setOpen={setEditOpen}
          title={"Edit Payment"}
          color={"#1976d2"}
        >
          <EditPayment
            addOpen={editOpen}
            setAddOpen={setEditOpen}
            trigger={trigger}
            setTrigger={setTrigger}
            activePayment={payment}
            paymentTypeOptions={paymentTypeOptions}
            caseDetail={caseDetail}
          />
        </ModalShell>
      )}
    </ListItem>
  );
}
