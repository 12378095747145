import { ArrowDownward } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

export default function ShowMoreButton({
  showMoreVisible,
  setResLimit,
  resLimit,
  showMoreAmt,
}) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {" "}
      {!showMoreVisible ? (
        ""
      ) : (
        <Tooltip title="Show more results">
          <IconButton
            size="small"
            onClick={() => setResLimit(+resLimit + showMoreAmt)}
          >
            <ArrowDownward />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
