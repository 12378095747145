import { Button, CircularProgress } from "@mui/material";
import React from "react";

export default function FormSubmitButton({
  isLoading,
  handleSubmit,
  buttonText,
  ButtonIcon,
  buttonColor,
  testId,
  buttonId,
}) {
  const content = isLoading ? "Loading..." : buttonText;
  const Icon = ButtonIcon;
  const loadingStyle = isLoading ? "py-0" : "py-2";
  const buttonStyle = (buttonColor) => {
    if (buttonColor) {
      return `bg-${buttonColor}-600 hover:bg-${buttonColor}-700`;
    }
    return "";
  };
  const disabledColors = (color) => {
    if (color === "red") {
      return "rgb(185 28 28)";
    } else if (color === "orange") {
      return "rgb(194 65 12)";
    } else if (color === "sky") {
      return "rgb(3 105 161)";
    } else if (color === "green") {
      return "rgb(77 124 15)";
    } else if (color === "purple") {
      return "rgb(107 33 168)";
    } else {
      return "";
    }
  };

  return (
    <div className="w-fit mx-auto">
      <Button
        id={buttonId}
        data-testid={testId}
        onClick={(e) => handleSubmit(e)}
        variant="contained"
        disabled={isLoading}
        className={`${loadingStyle} ${buttonStyle(buttonColor)}`}
        sx={{
          "&:disabled": {
            background: disabledColors(buttonColor),
            color: "#FFF",
          },
        }}
      >
        {content}&nbsp;
        {isLoading ? (
          <div className="block pl-4 relative mt-2">
            <CircularProgress
              sx={{
                color: "#FFF",
                maxWidth: "25px",
                maxHeight: "25px",
              }}
            />
          </div>
        ) : !Icon ? (
          ""
        ) : (
          Icon
        )}
      </Button>
    </div>
  );
}
