export const SHOULD_FETCH_LOGS = "SHOULD_FETCH_LOGS";
export const FETCH_IS_LOADING = "FETCH_IS_LOADING";
export const FETCH_INPROGRESS = "FETCH_INPROGRESS";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_DONE = "FETCH_DONE";
export const COMM_LOG_SEARCH = "COMM_LOG_SEARCH";
export const RESLIMIT_SET = "RESLIMIT_SET";
export const RESULT_COUNT_SET = "RESULT_COUNT_SET";
export const CURRENT_PAGE_SET = "CURRENT_PAGE_SET";
export const CURRENT_PAGE_URL_SET = "CURRENT_PAGE_URL_SET";

export const setShouldFetchLogs = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_LOGS, payload: bool});

export const setfetchIsLoading = (dispatch, bool) =>
  dispatch({ type: FETCH_IS_LOADING, payload: bool});

  export const setfetchInProgress = (dispatch, obj) =>
  dispatch({ type: FETCH_INPROGRESS, payload: obj});

  export const setfetchSuccess = (dispatch, bool) =>
  dispatch({ type: FETCH_SUCCESS, payload: bool});

  export const setfetchError = (dispatch, arr) =>
  dispatch({ type: FETCH_ERROR, payload: arr});

  export const setfetchDone = (dispatch, bool) =>
  dispatch({ type: FETCH_DONE, payload: bool});

  export const setSearch = (dispatch, str) =>
  dispatch({ type: COMM_LOG_SEARCH, payload: str});

  export const setResetLimit = (dispatch, num) =>
  dispatch({ type: RESLIMIT_SET, payload: num});

  export const setResultCount = (dispatch, num) =>
  dispatch({ type: RESULT_COUNT_SET, payload: num});

  export const setCurrentPage = (dispatch, num) =>
  dispatch({ type: CURRENT_PAGE_SET, payload: num});

  export const setCurrentPageURL = (dispatch, str) =>
  dispatch({ type: CURRENT_PAGE_URL_SET, payload: str});