import { ListItem, MenuItem, Tooltip, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import OptionsMenu from "../../../../../../../global/OptionsMenu";
import DeleteProviderv2 from "../../DeleteProviderv2";
import ModalShell from "../../../../../../../global/ModalShell";
import DataContext from "../../../../../../../../context/DataContext";
import { Delete, ListAltOutlined } from "@mui/icons-material";
import StatusSelect from "./StatusSelect";
import ProviderDetailv2 from "../../../../../../../global/ProviderDetailModal/ProviderDetailv2";

export default function ProviderListItem({
  prov,
  providerAmountObj,
  formatter,
  proCliTrigger,
  setProCliTrigger,
  docTypeOptions,
  docTypeOptionsObj,
  caseInfo,
}) {
  const { userRoles } = useContext(DataContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openProvDetail, setOpenProvDetail] = useState(false);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  return (
    <ListItem
      className={`shadow-md rounded-md justify-between hidden lg:flex  ${
        !prov.provider.in_network ? "bg-red-200" : ""
      }`}
    >
      <div className="w-1/5 text-left text-[14px]" aria-labelledby="provider">
        <h3 className="font-bold">{prov.provider.name}</h3>
        <span className="block italic">{prov.provider.type}</span>
      </div>
      <div className="w-1/5 text-center text-[14px]" aria-labelledby="status">
        <StatusSelect
          provider={prov}
          proCliTrigger={proCliTrigger}
          setProCliTrigger={setProCliTrigger}
          docTypeOptions={docTypeOptions}
          docTypeOptionsObj={docTypeOptionsObj}
        />
      </div>
      <div className="w-1/5 text-center text-[14px]" aria-labelledby="totals">
        {userRoles.permissions.includes("provider.view_providersettlement") ? (
          <div>
            <span className="block">
              Running:{" "}
              {!providerAmountObj[prov.provider.pk]
                ? formatter.format(0)
                : formatter.format(
                    +providerAmountObj[prov.provider.pk].initial_amount
                  )}
            </span>
            <span className="block">
              Settled:
              {!providerAmountObj[prov.provider.pk]
                ? "-"
                : formatter.format(
                    +providerAmountObj[prov.provider.pk].settled_amount
                  )}
            </span>
          </div>
        ) : (
          <div className="grid justify-start">
            <span>-</span>
          </div>
        )}
      </div>
      <div
        className="w-1/4 text-center text-[14px] flex items-center justify-center"
        aria-labelledby="created"
      >
        <div>
          <span className="block">Created by: {prov.created_by_name}</span>
          <span className="block">
            Created at:&nbsp;
            {new Date(prov.created_at).toLocaleString("EN-US", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>
      </div>
      <div
        className="w-1/5 text-[14px] flex items-center justify-center"
        aria-labelledby="first-appt"
      >
        <div className="block text-center">
          {!prov.first_appointment_date
            ? "-"
            : new Date(prov.first_appointment_date).toLocaleString("EN-US", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
        </div>
      </div>
      <div className="w-[5%]">
        {" "}
        <OptionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
          <MenuItem onClick={() => setAnchorEl(null)}>
            {" "}
            <Tooltip title="View Provider Detail" placement="right">
              <IconButton
                onClick={() => setOpenProvDetail(true)}
                className="text-purple-600"
              >
                <ListAltOutlined className="text-[18px]" />
              </IconButton>
            </Tooltip>
          </MenuItem>
          {userRoles.permissions.includes(
            "provider.delete_providerclientstatus"
          ) ? (
            <MenuItem onClick={() => setAnchorEl(null)}>
              {" "}
              <Tooltip title="Delete Provider Connection" placement="right">
                <IconButton
                  onClick={() => handleOpenDeleteModal()}
                  className="text-red-600"
                >
                  <Delete className="text-[18px]" />
                </IconButton>
              </Tooltip>
            </MenuItem>
          ) : (
            ""
          )}
        </OptionsMenu>
      </div>
      {openDeleteModal ? (
        <ModalShell
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={"Delete Provider"}
          color={"#d32f2f"}
          customWidth={"md"}
        >
          <DeleteProviderv2
            providerDetail={prov}
            setDeleteFormDisplay={setOpenDeleteModal}
            trigger={proCliTrigger}
            setTrigger={setProCliTrigger}
            caseInfo={caseInfo}
          />
        </ModalShell>
      ) : (
        ""
      )}
      {openProvDetail ? (
        <ProviderDetailv2
          providerDetail={prov.provider}
          open={openProvDetail}
          setOpen={setOpenProvDetail}
        />
      ) : (
        ""
      )}
    </ListItem>
  );
}
