import React, { useState, useContext } from "react";
import { Button, Tooltip, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataContext from "../../context/DataContext";
import { CloseOutlined } from "@mui/icons-material";

function DynamicTextInputBasic({ title, value, setValue, resetPagination }) {
  const { trigger, setTrigger } = useContext(DataContext);
  const [toggleInput, setToggleInput] = useState(false);

  const handlePost = () => {
    setToggleInput(false);
    setTrigger(!trigger);
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  const clearFunc = () => {
    setValue("");
    setTrigger(!trigger);
    setToggleInput(false);
    if (!resetPagination) {
      return;
    } else {
      resetPagination();
    }
  };

  return (
    <>
      {toggleInput === false ? (
        <>
          <Tooltip title={`Edit ${title}`}>
            <div
              onClick={() => setToggleInput(true)}
              className="cursor-pointer text-left flex"
            >
              {title}&nbsp;
              {!value ? (
                <AddIcon className="text-sky-600" />
              ) : (
                <div>
                  &mdash;
                  <span className="text-xs text-sky-600">&nbsp;{value}</span>
                </div>
              )}
            </div>
          </Tooltip>
          {!value ? (
            ""
          ) : (
            <Button
              onClick={clearFunc}
              className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
            >
              X
            </Button>
          )}
        </>
      ) : (
        <div className="flex">
          <div>
            <TextField
              className="placeholder:text-center rounded-lg w-full text-sm"
              placeholder={title}
              size="small"
              name="cm"
              type="text"
              value={value}
              InputLabelProps={{ shrink: true }}
              label={title}
              onChange={(event) => setValue(event.target.value)}
            />
          </div>
          <div>
            <Button
              onClick={handlePost}
              className="text-purple-600 hover:text-white hover:bg-purple-600 rounded-md text-sm"
            >
              Save
            </Button>
            <Button
              onClick={clearFunc}
              className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
            >
              <CloseOutlined />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default DynamicTextInputBasic;
