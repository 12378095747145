import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import { Alert, AlertTitle, Button, Dialog, TextField } from "@mui/material";
import { NetworkBackground } from "../../../../NetworkBackground";
import Cookies from "js-cookie";
import ResponseModal from "../../../../global/ResponseModal";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";

export default function PasswordReset() {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    accessToken,
    setAuth,
    getAccessToken,
    setCaseManagerPk,
    setUserType,
    setSelectedClient,
  } = useContext(DataContext);
  const [newPass, setNewPass] = useState("");
  const [newPassCheck, setNewPassCheck] = useState("");
  const [updatedSuccess, setUpdatedSuccess] = useState(false);
  const [passMatch, setPassMatch] = useState("empty");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetPass = () => {
    const resetData = {
      password: newPass,
      token: location.search.slice(7),
    };
    axios
      .post("/api/password_reset/confirm/", resetData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setUpdatedSuccess(true);
          Cookies.remove("pnAccessToken");
          Cookies.remove("pnCmPk");
          Cookies.remove("userType");
          Cookies.remove("category");
          getAccessToken("");
          setCaseManagerPk("");
          setSelectedClient("");
          setUserType("");
          setNewPass("");
          setNewPassCheck("");
          setAuth(null);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let res = error.response.data;
          let errArr = [];
          for (const item in res) {
            errArr.push(item + `: ${res[item]}`);
          }
          setIsError(true);
          setErrorMessage(errArr);
        }
      });
  };

  const checkPass = () => {
    if (!newPass && !newPassCheck) {
      setPassMatch("empty");
    } else if (newPass !== newPassCheck) {
      setPassMatch(false);
    } else if (newPass === newPassCheck) {
      setPassMatch(true);
    }
  };

  const loginReturn = () => {
    setUpdatedSuccess(false);
    navigate("/");
  };

  useDebouncedEffect(
    () => {
      checkPass();
    },
    [newPass, newPassCheck],
    250
  );

  return (
    <section className="h-screen w-screen grid text-center place-items-center">
      <NetworkBackground />
      <div className=" sm:w-1/2 w-[95%] sm:mx-0 mb-56 pt-10 rounded-xl border 2 bg-gray-200 shadow-lg h-fit">
        <div className="w-3/4 mx-auto py-4 ">
          <>
            <div className="w-3/4 mx-auto">
              <h3 className="text-xl font-bold text-center">Reset Password</h3>

              <TextField
                style={{ width: "100%", margin: "1rem 0" }}
                className="w-full my-4 text-center"
                placeholder="New Password" //
                type="password"
                autoComplete="off"
                label="Password"
                value={newPass}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => setNewPass(event.target.value)}
                error={
                  !passMatch ? true : passMatch === "empty" ? false : false
                }
              />
              <TextField
                style={{ width: "100%", margin: "1rem 0" }}
                className="w-full my-4 text-center"
                placeholder="Confirm New Password" //
                type="password"
                autoComplete="off"
                label="Confirm Password"
                value={newPassCheck}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => setNewPassCheck(event.target.value)}
                error={
                  !passMatch ? true : passMatch === "empty" ? false : false
                }
                helperText={passMatch ? "" : "Passwords need to match"}
              />
              <Button
                className="mx-auto block"
                variant="outlined"
                onClick={resetPass}
                disabled={
                  !passMatch ? true : passMatch === "empty" ? true : false
                }
              >
                Update
              </Button>
            </div>
          </>
        </div>
      </div>
      <ResponseModal
        title="Password update failed"
        isError={true}
        openBool={isError}
        setOpenBool={setIsError}
        errorMessage={errorMessage}
      />
      <Dialog open={updatedSuccess}>
        <Alert variant="outlined" severity="success" role="success">
          <AlertTitle>Password Reset Successfully</AlertTitle>
          <div>
            <h3>Click below to return to the login page.</h3>
          </div>
        </Alert>
        <Button onClick={loginReturn}>Return to login</Button>
      </Dialog>
    </section>
  );
}
