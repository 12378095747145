import { Check, Close } from "@mui/icons-material";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import ResponseModal from "../../ResponseModal";

export default function InlineEditNoSelectLabel({
  trigger,
  setTrigger,
  fieldValue,
  apiUrl,
  setToggleEdit,
  label,
  placeholder,
  required,
}) {
  const { accessToken } = useContext(DataContext);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(fieldValue);

  const getValue = (value) => {
    if (value === "" && !required) {
      return null;
    }
    return value;
  };

  const patchExistingEmail = () => {
    const patchData = {
      [label.toLowerCase()]: getValue(value),
    };
    axios
      .patch(apiUrl, patchData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(() => {
        setResponseModal(true);
        setResponseBreakdown(`Provider ${label} Updated`);
        setIsError(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  };

  const closeResModal = () => {
    if (isError) {
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
    } else {
      setToggleEdit(false);
      setIsError(false);
      setResponseModal(false);
      setResponseBreakdown("");
      setTrigger(!trigger);
    }
  };

  return (
    <div className="flex items-start w-full my-2">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="flex w-full items-start">
          <span className="font-bold block">{label}: </span>
          <TextField
            autoFocus={true}
            required={required ? true : false}
            size="small"
            label={"Enter " + label}
            value={value}
            className="w-full mx-auto text-black ml-2"
            onChange={(e) => setValue(e.target.value)}
            placeholder={label}
            helperText={placeholder}
          />{" "}
        </div>
      </LocalizationProvider>
      <Tooltip title={"Submit/Update " + label}>
        <IconButton
          onClick={() => patchExistingEmail()}
          className="text-green-600"
        >
          <Check />
        </IconButton>
      </Tooltip>
      <Tooltip title="Cancel">
        <IconButton onClick={() => setToggleEdit(false)}>
          <Close />
        </IconButton>
      </Tooltip>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </div>
  );
}
