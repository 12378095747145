import React, { useContext, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import a11yProps from "./a11yProps";
import CallLog from "../CallLog";
import ClientNotes from "../ClientNotes";
import TabPanel from "./TabPanel";
import DataContext from "../../../../../../context/DataContext";
export default function TabClientNotesCallLog({
  caseObj,
  clientLawfirm,
  params,
}) {
  const { casePk, userRoles } = useContext(DataContext);

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    //keep event param even though unused
    setTabValue(newValue);
  };

  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          padding: "0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="justify-between "
          variant="fullWidth"
          TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
          sx={{
            "& button.Mui-selected": {
              color: `#9333EA`,
            },
            ".Mui-selected": {
              color: `#9333EA`,
            },
            ".MuiBox-root.div": {
              padding: 0,
            },
          }}
        >
          <Tab label={"Client Notes"} {...a11yProps(0)} className={"w-1/2"} />
          <Tab label={"Call Log"} {...a11yProps(1)} className={"w-2/2"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} className="">
        <div className="mb-4 rounded-md  shadow-lg">
          <ClientNotes
            casePk={!caseObj ? casePk : caseObj.pk}
            caseObj={!caseObj ? "" : caseObj}
            clientLawfirm={clientLawfirm}
            params={params}
          />
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div className="mb-4 rounded-md  shadow-lg">
          <CallLog
            view_calllog={userRoles.permissions.includes(
              "call_log.view_calllog"
            )}
            caseObj={caseObj}
          />
        </div>
      </TabPanel>
    </div>
  );
}
