import {
  CircularProgress,
  List,
  ListItem,
  TextField,
  Tooltip,
} from "@mui/material/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import qs from "query-string";
import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import PaginationControls from "../../../global/PaginationControls";
import { useDebouncedEffect } from "../../../hooks/useDebounceEffect";
import ClientListItemUniversal from "../../../global/ClientListItemUniversal";

export default function NeedsAppointment({ isDashboardVersion }) {
  const { accessToken, trigger, userType } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [paginationUrl, setPaginationUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const sendRequest = () => {
    setLoading(true);
    axios
      .get(
        `/api/case/?         ${
          paginationUrl +
          qs.stringify(
            {
              needs_appointment: true,
              status: "Treating",
              archived: false,
              lost__isnull: true,
              search: !search ? null : search,
            },
            {
              skipNull: true,
            }
          )
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [search, paginationUrl, setPaginationUrl, trigger],
    250
  );

  const columnClasses =
    userType !== "Law Firm" ? "md:w-1/6 w-1/3" : "md:w-1/5 w-1/2";
  const columnClassesSmall =
    userType !== "Law Firm" ? "md:w-[15%] w-1/4" : "md:w-[20%] w-1/3";
  const columnClassesLg =
    userType !== "Law Firm" ? "md:w-1/5 w-1/3" : "md:w-1/4 w-1/2";

  const handleChange = (event, value) => {
    setCurrentPage(value);
    if (value === 1) {
      setPaginationUrl(`?limit=25&`);
      return;
    }
    setPaginationUrl(`limit=25&offset=${25 * (value - 1)}&`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={` px-4 ${
          isDashboardVersion
            ? "max-h-[85vh] overflow-auto"
            : "max-h-[95vh] overflow-auto"
        } flex`}
        style={{ position: "relative", width: "100%", background: "#FFF" }}
      >
        <div className={`w-full md:ml-4`}>
          {isDashboardVersion ? (
            ""
          ) : (
            <h1
              className={`text-xl text-orange-600 text-center my-4 font-bold`}
            >
              Needs Appointment
            </h1>
          )}
          <div className="flex justify-center mt-4">
            <Tooltip title="Search by Name">
              <TextField
                className="placeholder:text-center rounded-lg w-full text-sm h-[2rem]"
                placeholder="Search"
                size="small"
                name="search"
                value={search}
                InputLabelProps={{ shrink: true }}
                label="Search"
                onChange={(event) => setSearch(event.target.value)}
              />
            </Tooltip>
          </div>
          <div className="h-full overflow-hidden">
            <div className="flex justify-between my-2">
              <p className="pl-5 py-4">Results: {resultCount.total}</p>
            </div>{" "}
            <div className="flex md:px-4 text-center py-2 border-b border-1 border-gray l:justify-start justify-evenly">
              <div className={`${columnClassesLg} font-bold`}>
                <h2 className="md:block hidden">CLIENT NAME</h2>
                <h2 className="md:hidden">NAME</h2>
              </div>
              <div className={`${columnClasses} font-bold hidden lg:block`}>
                <Tooltip title="Scheduled/Completed">
                  <h2>APPTS </h2>
                </Tooltip>
              </div>
              <div
                className={`${columnClassesSmall} font-bold hidden lg:block`}
              >
                <h2>CREATED </h2>
              </div>
              <div className={`${columnClassesSmall} font-bold`}>
                <h2>DOA </h2>
              </div>
              <div className={`${columnClassesSmall} font-bold`}>
                <h2>STATUS</h2>
              </div>
              {userType !== "Law Firm" ? (
                <div className={`w-1/5 font-bold hidden md:block`}>
                  <h2>LAW FIRM</h2>
                </div>
              ) : (
                ""
              )}
            </div>
            <List
              className={`${
                isDashboardVersion ? `h-[65vh]` : "max-h-[100%]"
              } overflow-y-auto`}
            >
              {loading ? (
                <div className="flex justify-center my-24">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {responseData.length === 0 ? (
                    <div>
                      <p className="my-4">No Results Found</p>
                    </div>
                  ) : (
                    responseData.map((client, index) => (
                      <ClientListItemUniversal
                        key={index}
                        client={client}
                        index={index}
                        currentPage={currentPage}
                        isDashboardVersion={isDashboardVersion}
                        customBgLayer={"bg-orange-300"}
                      />
                    ))
                  )}
                </>
              )}
              <ListItem className={`justify-center py-10 md:px-4 px-0`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={currentPage}
                />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}
