import React from "react";
import PaginationControls from "../../../global/PaginationControls";
import Stack from "@mui/material/Stack";

export default function CommLogPagination({
  alignment,
  resultCount,
  handleChange,
  currentPage,
}) {
  return (
    <div className="py-2">
      <Stack alignItems={alignment}>
        <PaginationControls
          resultCount={resultCount}
          handleChange={handleChange}
          currentPage={currentPage}
        />
      </Stack>
    </div>
  );
}
