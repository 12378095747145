import React, { useEffect, useState, useCallback, useContext } from "react";
import DocSourceDropdown from "./DocSourceDropdown";
import { document } from "../../../../../../../../api/endpoints/document";
import { SelectOptionsDataProcessor } from "../../../../../../../../global/SelectOptionsDataProcessor";
import DataContext from "../../../../../../../../../context/DataContext";
import ApiRequestErrorHandler from "../../../../../../../../global/ApiRequestErrorHandler";
import { CircularProgress } from "@mui/material";

/**
 * - Facilitates communication between dropdown with Parent Component and external API
 * - Get options from db for Document Source Dropdown.
 * - Sends Selected Option to Parent Component
 * - Gets 'isRequired' value from Parent
 * @returns DocSourceDropdown
 */
export default function DocSourceField({
  isFieldRequired,
  docSource,
  setDocSource,
}) {
  const { accessToken } = useContext(DataContext);
  const [options, setOptions] = useState(null);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState(null);

  const createFieldOptions = useCallback(async (results) => {
    return SelectOptionsDataProcessor(results, "No Selection");
  }, []);

  const fetchFieldOptionsData = useCallback(async () => {
    const fieldOptionsUrls = [document.getSourceFieldOptions(accessToken)];

    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorMessage(errArr);
      });
  }, [accessToken]);

  useEffect(() => {
    let active = true;

    if (active) {
      if (!options) {
        fetchFieldOptionsData()
          .then(async (results) => {
            if (results) {
              if (results[0]) {
                let options = await createFieldOptions(results[0].results);
                setOptions(options);
              }
            }
            return results;
          })
          .catch((error) => {
            let errArr = ApiRequestErrorHandler(error.response);
            setErrorMessage(errArr);
          });
      }
    }
    return () => {
      active = false;
    };
  }, [fetchFieldOptionsData, createFieldOptions, options]);

  return (
    <div id="document-source-field" data-testid="document-source-field">
      {options ? (
        <DocSourceDropdown
          docSource={docSource}
          setDocSourceParent={setDocSource}
          optionsList={options}
          isRequired={isFieldRequired}
        />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
