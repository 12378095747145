import {
  // Button,
  // ButtonGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import AccordionShell from "../../../../../global/AccordionShell";
// import DataContext from "../../../../../../context/DataContext";
// import Cookies from "js-cookie";
import {
  // DashboardOutlined,
  Edit,
  Password,
  // ViewColumnOutlined,
} from "@mui/icons-material";
import ResponseModal from "../../../../../global/ResponseModal";
import ModalShell from "../../../../../global/ModalShell";
import PasswordChange from "./PasswordChange";

export default function AccountSettings({ userInfo, trigger, setTrigger }) {
  // const { setDashboardV, dashboardV, setActivePath } = useContext(DataContext);
  const [resetEmailDialog, setResetEmailDialog] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  // const updateDashboardV = (version) => {
  //   setDashboardV(version);
  //   Cookies.set("dashVersion", version);
  //   if (version === "new") {
  //     setActivePath("search");
  //   } else {
  //     setActivePath("status-search");
  //   }
  // };

  return (
    <div className=" shadow-lg p-4 h-fit xl:mr-6 mt-6 rounded-sm">
      <h2 className="text-[1.2rem] font-bold mb-2">Account Settings</h2>
      <div className="ml-2">
        <AccordionShell title="General">
          <div>
            <div className="mt-2">
              <div className="my-2">
                <p className="font-bold">Username:&nbsp;{userInfo.username}</p>

                <p className="font-bold">
                  Password:&nbsp;
                  <Password />{" "}
                  <Tooltip title="Change Password">
                    <IconButton
                      onClick={() => setChangePassword(true)}
                      className="text-orange-400"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </p>
              </div>
              {/* <div className="my-2 flex items-center">
                <p className="font-bold">Dashboard View:&nbsp;</p>
                <ButtonGroup className="mt-2">
                  <Tooltip title="Column View">
                    <Button
                      onClick={() => updateDashboardV("new")}
                      className={
                        dashboardV === "new" ? "bg-sky-600 text-white" : ""
                      }
                    >
                      Column{" "}
                      <ViewColumnOutlined
                        className={
                          dashboardV === "new" ? " text-white" : "text-sky-600"
                        }
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Quadrant View">
                    <Button
                      onClick={() => updateDashboardV("legacy")}
                      className={
                        dashboardV === "legacy"
                          ? "bg-green-600 text-white"
                          : "text-green-600 border-r border-green-600"
                      }
                    >
                      Quadrant{" "}
                      <DashboardOutlined
                        className={
                          dashboardV === "legacy"
                            ? " text-white"
                            : "text-green-600"
                        }
                      />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </div>
              <p className="text-[12px] text-gray-500">
                *Based on your permissions, Quadrant view may vary in layout.
              </p> */}
            </div>
          </div>
        </AccordionShell>
      </div>
      <ModalShell
        open={changePassword}
        setOpen={setChangePassword}
        title="Reset Password"
        color="orange"
      >
        <PasswordChange
          userInfo={userInfo}
          setChangePassword={setChangePassword}
          trigger={trigger}
          setTrigger={setTrigger}
        />
      </ModalShell>
      <ResponseModal
        title="Success"
        description="Please check your email for a link to reset your password!"
        openBool={resetEmailDialog}
        setOpenBool={setResetEmailDialog}
      />
    </div>
  );
}
