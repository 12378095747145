// import * as React from "react";
import { Delete, Edit, MoreVert } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useState } from "react";
import DataContext from "../../../../../../context/DataContext";

export default function ExpenseOptionsMenu({
  handleEditAction,
  handleDeleteAction,
  action,
}) {
  const { userRoles } = useContext(DataContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="More Options" placement="right">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {" "}
        <MenuItem onClick={handleClose}>
          {" "}
          <Tooltip
            placement="right"
            title={
              !action
                ? false
                : action.status === "done"
                ? "Mark item incomplete to edit"
                : "Edit Action Item"
            }
          >
            <span>
              <IconButton
                className={`${
                  !action
                    ? "text-orange-400 hover:bg-orange-400 hover:text-white"
                    : action.status === "done"
                    ? ""
                    : "text-orange-400 hover:bg-orange-400 hover:text-white"
                }`}
                onClick={() => handleEditAction(action)}
                disabled={
                  !action
                    ? false
                    : action.status === "done"
                    ? true
                    : !userRoles.permissions.includes(
                        "provider.change_additionalexpense"
                      )
                    ? true
                    : false
                }
              >
                <Edit className="text-[18px]" />
              </IconButton>
            </span>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {" "}
          <Tooltip
            placement="right"
            title={
              !action
                ? false
                : action.status === "done"
                ? "Mark item incomplete to delete"
                : "Delete Action Item"
            }
          >
            <span>
              <IconButton
                className={`${
                  !action
                    ? "text-red-400 hover:bg-red-400 hover:text-white"
                    : action.status === "done"
                    ? ""
                    : "text-red-400 hover:bg-red-400 hover:text-white"
                }`}
                onClick={() => handleDeleteAction(action)}
                disabled={
                  !action
                    ? false
                    : action.status === "done"
                    ? true
                    : !userRoles.permissions.includes(
                        "provider.delete_additionalexpense"
                      )
                    ? true
                    : false
                }
              >
                <Delete className="text-[18px]" />
              </IconButton>
            </span>
          </Tooltip>
        </MenuItem>
      </Menu>
    </div>
  );
}
