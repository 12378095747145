import React, { useContext, useState, useEffect } from "react";
import { ListItem, Tooltip } from "@mui/material";
import DataContext from "../../../../context/DataContext";
import InlineEditNoSelectLabel from "../components/InlineEditNoSelectLabel";
import { Edit } from "@mui/icons-material";

export default function ProviderInfoDisplayRow({
  provider,
  trigger,
  setTrigger,
  activeProvObj,
  fieldLabel,
  placeholder,
  required,
}) {
  const { userRoles, loggedInUser } = useContext(DataContext);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (provider[fieldLabel.toLowerCase()]) {
      setFieldValue(provider[fieldLabel.toLowerCase()]);
    }
  }, [provider, fieldLabel]);

  const canEdit =
    loggedInUser.entity &&
    loggedInUser.entity.entity_type_label === "Provider" &&
    provider.pk !== activeProvObj[provider.pk]
      ? false
      : userRoles.permissions.includes("provider.change_provider")
      ? true
      : false;

  return (
    <ListItem className="p-0 w-full">
      {!toggleEdit ? (
        <div className="flex items-center justify-between w-full py-1">
          <div className="flex items-center w-full">
            <div className="font-bold">{fieldLabel}</div>
            <div>: </div>
            {canEdit ? (
              <Tooltip title="Click to Edit" placement="top">
                <button
                  className={`px-2 bg-white hover:bg-gray-100 inline-block flex items-center ${
                    !fieldValue ? "" : "w-fit"
                  } h-6 rounded text-left`}
                  onClick={() => setToggleEdit(!toggleEdit)}
                >
                  {provider[fieldLabel.toLowerCase()]}&nbsp;
                  <Edit className="text-gray-400 text-[18px]"></Edit>
                </button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="w-full mt-1 rounded-md mb-2">
          {!userRoles.permissions.includes("provider.change_provider") ? (
            ""
          ) : (
            <InlineEditNoSelectLabel
              setToggleEdit={setToggleEdit}
              trigger={trigger}
              setTrigger={setTrigger}
              provider={provider}
              fieldValue={fieldValue}
              setFieldValue={setFieldValue}
              apiUrl={`/api/provider/${provider.pk}/`}
              label={fieldLabel}
              placeholder={placeholder}
              required={required}
            />
          )}
        </div>
      )}
    </ListItem>
  );
}
