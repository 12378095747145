import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

export default function OrderingArrow({ orderingVal, setOrderingVal, val }) {
  return (
    <>
      {" "}
      {orderingVal === val ? (
        <IconButton onClick={() => setOrderingVal(`-${val}`)} className="p-0">
          <ArrowDropUp className="text-purple-600" />
        </IconButton>
      ) : orderingVal === `-${val}` ? (
        <IconButton onClick={() => setOrderingVal(val)} className="p-0">
          <ArrowDropDown className="text-purple-600" />
        </IconButton>
      ) : (
        <IconButton onClick={() => setOrderingVal(val)} className="p-0">
          <ArrowDropDown />
        </IconButton>
      )}
    </>
  );
}
