import { ListItem, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/DataContext";
import { ListAltOutlined, OpenInNew } from "@mui/icons-material";
import ProviderDetailv2 from "./ProviderDetailModal/ProviderDetailv2";

export default function ClientListItemProCli({ client, index, currentPage }) {
  const { userType, setSelectedClient } = useContext(DataContext);

  const [detailModal, setDetailModal] = useState(false);

  const navigate = useNavigate();
  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:last-of-type:border-0 sm:p-2 px-2 py-2 sm:border-b-0";
  const columnClasses =
    userType !== "Law Firm" ? "md:w-1/5 w-1/3" : "md:w-1/4 w-1/3";

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const navigateToDetail = (client) => {
    setSelectedClient(client.case.pk);
    navigate(`/client-detail/${client.case.pk}`);
  };

  return (
    <ListItem
      key={index}
      value={client}
      className="py-0 pt-0 md:px-4 px-2 items-center"
    >
      <div
        className={
          "text-black shadow-lg py-1 my-1 w-full flex bg-white rounded-md border-gray-100 border-y  justify-evenly"
        }
      >
        <div
          className={`block md:w-[20%] w-1/3 text-left pl-2 text-left ${resultClasses}`}
        >
          {currentPage > 1
            ? index + 1 * ((currentPage - 1) * 25) + 1
            : index + 1}
          .&nbsp;
          <Tooltip title={!client ? "" : client.client.name}>
            {!client ? "" : truncate(client.client.name, 11, 15)}&nbsp;
          </Tooltip>
          <IconButton onClick={() => navigateToDetail(client)}>
            <OpenInNew />
          </IconButton>
        </div>
        <div
          className={`block md:w-[15%] w-1/3 text-left pl-2 text-center ${resultClasses} hidden md:block`}
        >
          {!client
            ? ""
            : new Date(client.created_at).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
        </div>
        <div
          className={`block ${columnClasses} text-left pl-2 text-center ${resultClasses}`}
        >
          {!client || !client.case ? "" : client.case.status}
        </div>
        <div
          className={`block md:w-[25%] w-1/3 text-left pl-2 text-center ${resultClasses} ${
            userType !== "Law Firm" ? "border-none" : ""
          }`}
        >
          <Tooltip title={!client ? "" : client.provider.name}>
            {!client ? "" : truncate(client.provider.name, 14, 20)}{" "}
          </Tooltip>
          <IconButton
            onClick={() => setDetailModal(true)}
            className="text-purple-600"
          >
            <ListAltOutlined />
          </IconButton>
        </div>
        {userType !== "Law Firm" ? (
          <div
            className={`block w-1/5 text-left pl-2 text-center hidden md:block ${resultClasses} border-none`}
          >
            {!client || !client.case ? "" : client.case.law_firm_name}
          </div>
        ) : (
          ""
        )}
      </div>
      {!detailModal ? (
        ""
      ) : (
        <ProviderDetailv2
          providerDetail={client.provider}
          open={detailModal}
          setOpen={setDetailModal}
        />
      )}
    </ListItem>
  );
}
