import { useState, useContext, useEffect } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import {
  Button,
  TextField,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItemButton,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material/";
import SearchIcon from "@mui/icons-material/Search";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CreateServiceProcedurev2 from "./CreateServiceProcedurev2";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Add, Close, Info } from "@mui/icons-material";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import FormSubmitButton from "../../../../../global/FormComponents/FormSubmitButton";

export default function CreateApptProcedurev2({
  selectedAppt,
  setOpenAddBill,
  caseInfo,
}) {
  const { selectedClient, trigger, setTrigger, accessToken } =
    useContext(DataContext);
  const [service, setService] = useState(null);
  const [price, setPrice] = useState("");
  const [signature, setSignature] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [procedures, getProcedures] = useState([]);
  const [filter, setFilterType] = useState("service_name");
  const [cquery, setCQuery] = useState("");
  const [openCreateBill, setOpenCreateBill] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [loading, setLoading] = useState(false);

  const { width } = useWindowDimensions();

  const handleCreateBill = () => {
    setOpenCreateBill(false);
    setOpenAddBill(true);
  };

  const createABill = () => {
    setOpenCreateBill(true);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenAddBill(false);
    setTrigger(!trigger);
  };

  const handleFilter = (event) => {
    const { value } = event.target;
    setFilterType(value);
  };

  const populateServiceInfo = (procedure) => {
    setService(procedure.id);
    setCQuery(procedure.service_name);
    getProcedures([]);
  };

  const setQuery = (e) => {
    setCQuery(e.target.value);
    setService(null);
  };

  const handleNewApptProcedureSubmit = (e) => {
    setLoading(true);
    let createNewProcedure = !service
      ? {
          administered_to: caseInfo.client.pk,
          administered_by: selectedAppt.provider.pk,
          appointment: selectedAppt.pk,
          price: price,
          signature: signature,
        }
      : {
          administered_to: caseInfo.client.pk,
          administered_by: selectedAppt.provider.pk,
          service: service,
          appointment: selectedAppt.pk,
          price: price,
          signature: signature,
        };
    axios
      .post("/api/administered/", createNewProcedure, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setLoading(false);
        }
      })
      .catch(function (error) {
        const err = error.response.data;
        const errArr = ApiRequestErrorHandler(err);
        setErrorArray(errArr);
        setOpenError(!openError);
        setLoading(false);
      });
  };
  // calling all services for provider
  useEffect(() => {
    axios
      .get(
        `/api/services/?provider=${selectedAppt.provider.pk}&simplified=true`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then((proceduresList) => {
        getProcedures(proceduresList.data.results);
      });
  }, [selectedClient, accessToken, selectedAppt]);

  const removeService = () => {
    setShowServices(false);
    setCQuery("");
    setService(null);
  };

  return (
    <div className="w-full px-20 justify-center bg-white">
      <div className="mt-6" style={{ width: width > 640 ? "300px" : "100%" }}>
        {!showServices ? (
          <div>
            <Button
              style={{
                color: "#1976d2",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
              onClick={() => setShowServices(true)}
            >
              Attach Service <Add />
            </Button>
            <p
              style={{
                fontSize: "10px",
                color: "gray",
                textAlign: "center",
                margin: "0 0 10px 0",
              }}
            >
              <Info style={{ fontSize: "14px" }} /> If there are no related
              Services or CPT code, only use inputs&nbsp;below
            </p>
          </div>
        ) : (
          <FormControl className="w-full">
            <div className="w-full">
              <InputLabel id="PF">Filter</InputLabel>
              <Select
                labelId="PF"
                id="PF"
                size="small"
                value={filter}
                fullWidth
                label="PF"
                onChange={handleFilter}
              >
                <MenuItem value="service_name">Bill Name</MenuItem>
                <MenuItem value="service_code">Bill Code</MenuItem>
              </Select>
            </div>
            <div className="w-full mt-4 flex">
              <TextField
                size="small"
                className={`placeholder:text-center rounded-lg`}
                placeholder="Search Procedures" //{`Search ${filter}`}
                value={cquery}
                fullWidth
                onChange={(event) => setQuery(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {service ? (
                        <CheckCircleOutlineIcon className="text-green-600" />
                      ) : cquery === "NP Generic" ? (
                        <CheckCircleOutlineIcon className="text-purple-600" />
                      ) : (
                        ""
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Tooltip title={service ? "Remove service" : "Cancel"}>
                <IconButton
                  style={{
                    color: "#d32f2f",
                  }}
                  onClick={() => removeService()}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </FormControl>
        )}

        <div>
          {!cquery ? (
            ""
          ) : (
            <div className="">
              {procedures.length !== 0 ? (
                <div className="w-full h-full p-0 mb-2">
                  {procedures
                    .filter((procedure) => {
                      if (cquery === "") {
                        return procedure;
                      } else if (
                        procedure[filter]
                          .toLocaleLowerCase()
                          .includes(cquery.toLocaleLowerCase())
                      ) {
                        return procedures;
                      }
                      return false;
                    })
                    .slice(0, 5)
                    .map((procedure, index) => (
                      <List key={index} className="p-0 my-1 w-full">
                        <div className="">
                          <ListItemButton
                            onClick={(e) => populateServiceInfo(procedure)}
                            className="place-content-center overflow-x-hidden text-center border-solid border-gray-300 rounded-lg"
                          >
                            <span>{procedure.service_name}</span>
                            <span className="ml-6">
                              {procedure.service_code}
                            </span>
                          </ListItemButton>
                        </div>
                      </List>
                    ))}
                  <div className="mt-2">
                    <Button
                      className="text-center text-green-500 hover:bg-gray-200 rounded-lg uppercase"
                      onClick={createABill}
                      style={{ display: "block", margin: "0 auto" }}
                    >
                      <ControlPointOutlinedIcon /> Add Custom Procedure
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <TextField
          className="w-full my-4 text-center"
          id="Procedure Bill Total"
          label="Procedure Bill Total"
          fullWidth
          value={price}
          onChange={(event) => {
            setPrice(event.target.value);
          }}
        />
        <TextField
          className="w-full my-4 text-center"
          id="Signature"
          label="Signature"
          fullWidth
          value={signature}
          onChange={(event) => {
            setSignature(event.target.value);
          }}
        />
        <div style={{ padding: "1rem 0" }}>
          <FormSubmitButton
            isLoading={loading}
            handleSubmit={handleNewApptProcedureSubmit}
            buttonText="Add Bill to Client's Case"
            buttonColor="green"
            ButtonIcon={<ControlPointOutlinedIcon />}
          />
        </div>

        {/* create bill comp here */}
        <Dialog open={openCreateBill}>
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                fontSize: "20px",
              }}
            >
              {" "}
              <Button onClick={handleCreateBill}>
                <CloseIcon />
              </Button>
            </div>
            <h1
              className="text-green-600"
              style={{
                fontSize: 20,
                margin: "0 0 1rem 0",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Create Procedure
            </h1>
            <CreateServiceProcedurev2
              selectedAppt={selectedAppt}
              setOpenCreateBill={setOpenCreateBill}
            />
          </div>
        </Dialog>
        <ResponseModal
          title="New Bill Added"
          description="Successfully Added Bill"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleOpenSuccess}
        />
        <ResponseModal
          title="Error Adding Bill"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorArray}
        />
      </div>
    </div>
  );
}
