import { Check, Close } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import SelectBasic from "../../FormComponents/SelectBasic";
import PermissionWrapper from "../../PermissionWrapper";

export default function InlineEditSelect({

  fieldValue,
  setToggleEdit,
  label,
  placeholder,
  required,
  optionsList,
  submitData,
}) {
  const {userRoles } = useContext(DataContext);
  const [value, setValue] = useState(fieldValue);
  const [options] = useState(optionsList);

  const perms = {
    utilities_view_fieldoptions: userRoles.permissions.includes(
      "utilities.view_fieldoptions"
    ),
  };

  const setToggleEditMode = (mode) => {
    setToggleEdit(mode);
  };
 
  return (
    <div className="w-full">
      <div className="font-bold block py-4">{label}: </div>
      <div className="flex w-full items-start items-center">
        <div className="w-[80%] align-middle">
          <PermissionWrapper permission={perms.utilities_view_fieldoptions}>
            {options.length > 0 && (
              <SelectBasic
                className="w-full"
                selectedVal={value}
                setSelectedVal={setValue}
                placeholder={placeholder}
                title={placeholder}
                optionsData={options}
                required={required ? true : false}
                helperText={placeholder}
              />
            )}
          </PermissionWrapper>
        </div>
        <div className="w-[20%] align-middle">
          <Tooltip title={label}>
            <IconButton onClick={() => submitData(value)} className="text-green-600">
              <Check />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton onClick={() => setToggleEditMode(false)}>
              <Close />
            </IconButton>
          </Tooltip>
        </div>
       
      </div>
    </div>
  );
}
