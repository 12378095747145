import React, { useReducer, useContext, useCallback, useState } from "react";
import { initialState, commLogReducer } from "./state/commLogReducer";
import DataContext from "../../../context/DataContext";
import { CommLogDispatchContext } from "./context/CommLogDispatchContext";
import { CommLogStateContext } from "./context/CommLogStateContext";
import CommLogPanel from "./components/CommLogPanel";

import {
  setCurrentPage,
  setCurrentPageURL,
  setResetLimit,
  setResultCount,
  setSearch,
  setShouldFetchLogs,
  setfetchError,
  setfetchInProgress,
  setfetchIsLoading,
  setfetchSuccess,
} from "./state/actions";
import { callLog } from "../../api/endpoints/call_log";
import ApiRequestErrorHandler from "../ApiRequestErrorHandler";
import qs from "query-string";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { format, subDays } from "date-fns";

export default function CommLog({ logType, title }) {
  const { accessToken, loggedInUser } = useContext(DataContext);
  const [state, dispatch] = useReducer(commLogReducer, initialState);
  const [fromDate, setFromDate] = useState(
    format(subDays(new Date(), 7), "yyyy-MM-dd")
  );
  const [toDate, setToDate] = useState(
    format(subDays(new Date(), 1), "yyyy-MM-dd")
  );
  const [selectedUsers, setSelectedUsers] = useState([loggedInUser.pk]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [activeFiltersUsers, setActiveFiltersUsers] = useState([
    loggedInUser.name,
  ]);

  const setTextSearch = (text) => {
    setSearch(dispatch, text);
    setShouldFetchLogs(dispatch, true);
  };
  const handleStartDate = (value) => {
    setFromDate(value);
    setShouldFetchLogs(dispatch, true);
  };
  const handleEndDate = (value) => {
    setToDate(value);
    setShouldFetchLogs(dispatch, true);
  };

  const startFetchingLogs = () => {
    setShouldFetchLogs(dispatch, true);
  };

  const fetchFieldOptionsData = useCallback(async (fieldOptionsUrls) => {
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(dispatch, errArr);
      });
  }, []);

  const fetchLogData = useCallback(
    (userIdList, accessToken, search, paginationUrl) => {
      setfetchIsLoading(dispatch, true);
      const fieldOptionsUrls = [
        logType === "phone"
          ? callLog.getPhoneLogs(userIdList, accessToken, search, paginationUrl)
          : callLog.getSMSLogs(userIdList, accessToken, search, paginationUrl),
      ];

      fetchFieldOptionsData(fieldOptionsUrls)
        .then(async (results) => {
          if (results) {
            if (results[0]) {
              setfetchInProgress(dispatch, results[0].results);
              setResultCount(dispatch, {
                total: results[0].count,
                current: results[0].results.length,
              });
            }
          }
        })
        .then((results) => {
          setfetchSuccess(dispatch, true);
          return results;
        })
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response);
          setfetchError(dispatch, errArr);
        });
    },
    [logType, fetchFieldOptionsData]
  );

  useDebouncedEffect(
    () => {
      let active = true;
      if (active) {
        if (state.shouldFetchLogs) {
          let querytest = qs.stringify(
            {
              search: state.logSearch === "" ? null : state.logSearch,
              start_time__gte: !fromDate ? null : fromDate,
              start_time__lte: !toDate ? null : toDate,
            },
            { skipNull: true }
          );

          setfetchIsLoading(dispatch, true);
          fetchLogData(
            selectedUsers,
            accessToken,
            querytest,
            state.paginationUrl
          );

          querytest = qs.parse(querytest);

          setActiveFilters(querytest);
        }
      }
      return () => {
        active = false;
        setfetchIsLoading(dispatch, false);
        setShouldFetchLogs(dispatch, false);
      };
    },
    [
      state.logSearch,
      accessToken,
      fetchLogData,
      fromDate,
      toDate,
      selectedUsers,
      state.paginationUrl,
      state.shouldFetchLogs,
      state.isLoading,
    ],
    800
  );

  const handleChange = (event, value) => {
    setCurrentPage(dispatch, value);
    if (value === 1) {
      setCurrentPageURL(dispatch, `limit=${state.resLimit}`);
    }
    setCurrentPageURL(
      dispatch,
      `limit=${state.resLimit}&offset=${state.resLimit * (value - 1)}`
    );
    setShouldFetchLogs(dispatch, true);
  };

  const resetPagination = () => {
    setCurrentPage(dispatch, 1);
    setCurrentPageURL(dispatch, "");
    setShouldFetchLogs(dispatch, true);
  };

  return (
    <div id="Comm-Log" data-testid="Comm-Log">
      <CommLogStateContext.Provider value={state}>
        <CommLogDispatchContext.Provider value={dispatch}>
          <CommLogPanel>
            <CommLogPanel.Header icon={logType} title={title} />
            <CommLogPanel.Filters
              startFetchingLogs={startFetchingLogs}
              setToDate={handleEndDate}
              setFromDate={handleStartDate}
              resLimit={state.resLimit}
              setResLimit={setResetLimit}
              loggedInUser={loggedInUser}
              resetPagination={resetPagination}
              activeFilters={activeFilters}
              activeFiltersUsers={activeFiltersUsers}
              setActiveFiltersUsers={setActiveFiltersUsers}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              resultCount={state.resultCount}
              setSearchField={setTextSearch}
            />
            <CommLogPanel.Search
              value={state.logSearch}
              onchange={(text) => setTextSearch(text)}
              setSearchField={setTextSearch}
              searchValue={state.logSearch}
              resetPagination={resetPagination}
            />

            {state.errorArray.length > 0 ? (
              <CommLogPanel.Error errorArray={state.errorArray} />
            ) : (
              <CommLogPanel.List logList={state.commLogs} logType={logType} />
            )}
            <CommLogPanel.Pagination
              alignment={"center"}
              resultCount={state.resultCount}
              handleChange={handleChange}
              currentPage={state.currentPage}
            />
          </CommLogPanel>
        </CommLogDispatchContext.Provider>
      </CommLogStateContext.Provider>
    </div>
  );
}
