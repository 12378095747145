import { List, ListItem, TextField } from "@mui/material";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

export default function PlacesResponseList({
  address,
  setAddress,
  handlePlaceSelect,
  setSuggestsActive,
  inputRequired,
}) {
  return (
    <div style={{ width: "100%" }}>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handlePlaceSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ width: "100%", margin: "1rem 0" }}>
            <TextField
              required={inputRequired ? true : false}
              className="w-full h-14 rounded-md"
              label="Address"
              style={{ width: "100%" }}
              {...getInputProps({
                placeholder: "Search Address",
              })}
            />
            <List
              className="p-0 absolute z-10 bg-white w-full shadow-lg rounded-b-md max-h-[50vh] overflow-y-auto"
              style={{
                padding: "0",
                background: "white",
                width: "100%",
              }}
            >
              {!setSuggestsActive
                ? ""
                : suggestions.length === 0
                ? setSuggestsActive(false)
                : setSuggestsActive(true)}
              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? "#9333ea" : "#fff",
                  color: suggestion.active ? "#fff" : "#000",
                };
                return (
                  <ListItem
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={index}
                  >
                    {suggestion.description}
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
