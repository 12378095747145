import { CloseOutlined } from "@mui/icons-material";
import { Button, Dialog } from "@mui/material";
import React from "react";
import FileUpload from "./FileUpload";

export default function DocumentUpload({
  closeUploadModal,
  casePk,
  clientId,
  uploadSuccess,
  setUploadSuccess,
  uploadFailed,
  setUploadFailed,
  uploadErrorMessage,
  setUploadErrorMessage,
  uploadDocument,
  setUploadDocument,
  activeProvObj,
  docTrigger,
  setDocTrigger,
  setViewDocuments,
}) {
  return (
    <Dialog open={uploadDocument} fullScreen={true}>
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
            fontSize: "1.2rem",
          }}
        >
          <div>&nbsp;</div>
          <Button onClick={() => closeUploadModal()} style={{ padding: "0" }}>
            <CloseOutlined style={{ color: "#00000" }} />
          </Button>
        </div>
        <span
          style={{
            width: "fit-content",
            display: "block",
            margin: "0 auto",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Upload Document to Case
        </span>
        <div style={{ maxWidth: "75%", margin: "0 auto" }}>
          <FileUpload
            closeUploadModal={closeUploadModal}
            casePk={casePk}
            clientId={clientId}
            uploadSuccess={uploadSuccess}
            setUploadSuccess={setUploadSuccess}
            uploadFailed={uploadFailed}
            setUploadFailed={setUploadFailed}
            uploadErrorMessage={uploadErrorMessage}
            setUploadErrorMessage={setUploadErrorMessage}
            setUploadDocument={setUploadDocument}
            activeProvObj={activeProvObj}
            docTrigger={docTrigger}
            setDocTrigger={setDocTrigger}
            setViewDocuments={setViewDocuments}
          />
        </div>
      </div>
    </Dialog>
  );
}
