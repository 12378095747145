export const INIT="INIT";
export const DISABLED="DISABLED";
export const NO_CHECK_ENTERED="NO_CHECK_ENTERED";
export const CHECK_ENTERED="CHECK_ENTERED";

export const SHOW_COMPONENT_SET = "SHOW_COMPONENT_SET";
export const SHOW_CONTENT_SET = "SHOW_CHECK_TYPE_SET";

export const VIEW_CHECK_DIALOG = "VIEW_CHECK_DIALOG";

export const CONTENT_ADD_CHECK = "CONTENT_ADD_CHECK";
export const CONTENT_CHECKLIST = "CONTENT_CHECKLIST";
export const CONTENT_EDIT_CHECK = "CONTENT_EDIT_CHECK";
export const CONTENT_ERROR = "CONTENT_ERROR";
export const CONTENT_SUCCESS = "CONTENT_SUCCESS";
export const CONTENT_LOADING_TOGGLE = "CONTENT_LOADING_TOGGLE";

export const SET_FIELD_DATA = "SET_FIELD_DATA";
export const SET_FIELD_DATA_VALIDATION = "SET_FIELD_DATA_VALIDATION";
export const SET_CHECK_ID = "SET_CHECK_ID";
export const SET_PROVIDER_NAME = "SET_PROVIDER_NAME";
export const SET_SENDS_CHECK_TO_LAWFIRM = "SET_SENDS_CHECK_TO_LAWFIRM";
export const SET_CHECKLIST = "SET_CHECKLIST";
export const SET_SELECTED_CHECK = "SET_SELECTED_CHECK";
export const SET_API_ERRORS = "SET_API_ERRORS";
export const SET_CLEAR_FORM = "SET_CLEAR_FORM";
export const SET_CLEAR_FORM_FOR_ADD_CHECK = "SET_CLEAR_FORM_FOR_ADD_CHECK";


export const setCheckId = (dispatch, num) =>
  dispatch({ type: SET_CHECK_ID, payload: num });

export const setProviderName = (dispatch, text) =>
  dispatch({ type: SET_PROVIDER_NAME, payload: text });

export const showComponent = (dispatch, text) =>
  dispatch({ type: SHOW_COMPONENT_SET, payload: text });

export const showContent = (dispatch, text) =>
  dispatch({ type: SHOW_CONTENT_SET, payload: text });
  
export const setFieldData = (dispatch, obj) =>
  dispatch({ type: SET_FIELD_DATA, payload: obj });

export const setFieldDataValdiation = (dispatch, bool) =>
  dispatch({ type: SET_FIELD_DATA_VALIDATION, payload: bool });

export const setSendsCheckToLawFirm = (dispatch, bool) =>
  dispatch({ type: SET_SENDS_CHECK_TO_LAWFIRM, payload: bool });

  export const toggleContentIsLoading = (dispatch, bool) =>
  dispatch({ type: CONTENT_LOADING_TOGGLE, payload:bool });

  export const setSelectedCheck = (dispatch, num) =>
  dispatch({ type: SET_SELECTED_CHECK, payload:num });
    
  export const setCheckList = (dispatch, arr) =>
  dispatch({ type: SET_CHECKLIST, payload:arr });
  
export const setAPIErrors = (dispatch, arr) =>
  dispatch({ type: SET_API_ERRORS, payload: arr});

export const clearBankCheckForm = (dispatch, bool) =>
dispatch({type: SET_CLEAR_FORM, payload: bool});

export const clearBankCheckFormFormAddCheck = (dispatch, bool) =>
dispatch({type: SET_CLEAR_FORM_FOR_ADD_CHECK, payload: bool});