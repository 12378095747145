import React from "react";

const PermissionWrapper = ({ permission, children }) => {
  if (permission) {
    return <>{children}</>;
  }

  return null;
};

export default PermissionWrapper;
