import React, { useContext } from "react";
import Colors from "../../../../../styles/Colors";
import { styled } from "@mui/material";
import { Chip } from "@mui/material";
import DataContext from "../../../../../context/DataContext";

export default function CustomNavChip({ path, title, color }) {
  const { activePath, setActivePath } = useContext(DataContext);

  const selected = activePath === path;

  const CustomChip = styled(Chip)({
    color: selected ? Colors.white : Colors.black,
    backgroundColor: selected ? Colors[color][600] : Colors.white,
    borderColor: Colors.black,
    "&&:hover, &&:focus": {
      color: Colors.white,
      backgroundColor: selected ? Colors[color][600] : Colors[color][400],
      borderColor: selected ? Colors[color][600] : Colors[color][400],
    },
  });

  let baseStyle = "mx-1 my-1 text-center rounded-lg shadow-lg ";

  return (
    <CustomChip
      variant={selected ? "default" : "outlined"}
      label={title}
      className={baseStyle + (selected ? "active" : "")}
      onClick={() => setActivePath(path)}
    />
  );
}
