import React, { useContext, useEffect, useState } from "react";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AutoDelete, Close } from "@mui/icons-material";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import "./FullCal.css";
import { getDay } from "date-fns";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import ModalButton from "../../../../../../global/ModalButton";
import DeleteModal from "./Modals/DeleteModal";
import ResponseModal from "../../../../../../global/ResponseModal";

export default function AvailabilityCalendar({
  openCalModal,
  setOpenCalModal,
  caseInfo,
}) {
  const { accessToken, userRoles } = useContext(DataContext);
  const [availibilityData, setAvailibilityData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [availWindow, setAvailWindow] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndime] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [removeAvailModal, setRemoveAvailModal] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [postError, setPostError] = useState(false);
  const [errResponse, setErrResponse] = useState([]);
  const [date, setDate] = useState();

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleSuccess = () => {
    setAvailWindow("");
    setModalOpen(false);
    setTrigger(!trigger);
    setPostSuccess(false);
  };

  const postAvailWindow = () => {
    let start = new Date(startTime).toTimeString();
    let end = new Date(endTime).toTimeString();
    const postData = {
      user: caseInfo.client.pk,
      time_start: start.substring(0, start.length - 34),
      time_end: end.substring(0, end.length - 34),
      day: getDay(new Date(availWindow.startStr)),
    };

    axios
      .post(`/api/user_contact_hours/`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        setPostSuccess(true);
      })
      .catch(function (error) {
        let res = error.response.data;
        let errArr = [];
        for (const item in res) {
          errArr.push(item + `: ${res[item]}`);
        }
        setPostError(true);
        setErrResponse(errArr);
      });
  };

  useEffect(() => {
    if (!caseInfo) {
      return;
    } else {
      axios
        .get(`/api/user_contact_hours/?user=${caseInfo.client.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then(function (response) {
          let testArr = [];
          response.data.results.map((avail, idx) => {
            let start = avail.time_start;
            let end = avail.time_end;
            testArr.push({
              //MAKE THE CASE ID THE PK PLEASE and make the name display
              id: avail.id,
              title: avail.time_start + "-" + avail.time_end,
              daysOfWeek: [avail.day], // Monday, Tuesday, Wednesday
              startTime:
                start.length > 8 ? start.substring(0, start.length - 7) : start,
              endTime: end.length > 8 ? end.substring(0, end.length - 7) : end,
            });
            return testArr;
          });
          setAvailibilityData(testArr);
        })
        .catch(function (error) {});
    }
  }, [caseInfo, accessToken, trigger]);

  useEffect(() => {
    if (!modalOpen && availWindow) {
      setModalOpen(true);
    } else {
      return;
    }
  }, [availWindow, modalOpen]);
  return (
    <Dialog open={openCalModal} fullScreen>
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            fontSize: "20px",
          }}
        >
          {" "}
          <IconButton
            onClick={() => {
              setAvailWindow("");
              setOpenCalModal(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
        <h1
          style={{
            fontSize: 20,
            margin: "0 0 0",
            textAlign: "center",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>&nbsp;</div>
          Client Availability
          <div style={{ width: "30px" }}>&nbsp;</div>
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div>&nbsp;</div>
          <div>
            <p
              style={{
                margin: "0 0 0 0",
                padding: "0",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Click and drag to select a timeframe
            </p>
            <p
              style={{
                margin: "0 0 1rem 0",
                padding: "0",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              The purple background indicates the time frames the client is{" "}
              <span style={{ textDecoration: "underline" }}>not</span>{" "}
              available.
            </p>
          </div>
          {userRoles.permissions.includes("users.delete_usercontacthours") ? (
            <div>
              <Tooltip title="Remove existing Availability">
                <IconButton onClick={() => setRemoveAvailModal(true)}>
                  <AutoDelete style={{ color: "#d32f2f" }} />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            ""
          )}
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={""}
            businessHours={availibilityData}
            dayHeaderFormat={{ weekday: "long" }}
            selectable={
              userRoles.permissions.includes("users.add_usercontacthours")
                ? true
                : false
            }
            slotMinTime={"07:00:00"}
            slotMaxTime={"20:00:00"}
            dateClick={(selectInfo) => {
              setAvailWindow({
                startStr: `${selectInfo.dateStr}T07:00:00-06:00`,
                endStr: `${selectInfo.dateStr}T20:00:00-06:00`,
              });
              setDate(selectInfo.dateStr);
              setStartTime(`${selectInfo.dateStr}T07:00:00-06:00`);
              setEndime(`${selectInfo.dateStr}T20:00:00-06:00`);
            }}
            select={(selectInfo) => {
              setAvailWindow(selectInfo);
              setStartTime(selectInfo.startStr);
              setEndime(selectInfo.endStr);
            }}
            eventOverlap={false}
          />
        </div>
      </div>
      {!modalOpen ? (
        ""
      ) : (
        <Dialog open={modalOpen}>
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                fontSize: "20px",
              }}
            >
              {" "}
              <IconButton
                onClick={() => {
                  setAvailWindow("");
                  setModalOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </div>
            <h1
              style={{
                fontSize: 20,
                margin: "0 0 0",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Confirm Availability
            </h1>
            <p>
              for:{" "}
              <strong>
                {daysOfWeek[getDay(new Date(availWindow.startStr))]}
              </strong>
            </p>

            <div style={{ margin: "1rem 0" }}>
              <div style={{ margin: "10px 0" }}>
                <TimePicker
                  label="Start of Availability"
                  value={new Date(availWindow.startStr)}
                  className="w-full my-4 mx-auto text-black"
                  slotProps={{ textField: { variant: "outlined" } }}
                  onChange={(newValue) => setStartTime(newValue)}
                />
              </div>
              <div style={{ margin: "10px 0" }}>
                <TimePicker
                  label="End of Availability"
                  value={new Date(endTime)}
                  className="w-full my-4 mx-auto text-black"
                  slotProps={{ textField: { variant: "outlined" } }}
                  onChange={(newValue) => setEndime(newValue)}
                />
              </div>
              <div style={{ margin: "10px 0" }}>
                <ModalButton
                  className="block"
                  action={postAvailWindow}
                  baseColor="#9333EA"
                  content={"Add Availability"}
                />
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <DeleteModal
        removeAvailModal={removeAvailModal}
        setRemoveAvailModal={setRemoveAvailModal}
        trigger={trigger}
        setTrigger={setTrigger}
        availibilityData={availibilityData}
        daysOfWeek={daysOfWeek}
      />
      <ResponseModal
        title="Success"
        description="Availability Successfully Added"
        openBool={postSuccess}
        setOpenBool={setPostSuccess}
        handleCloseFunc={handleSuccess}
      />
      <ResponseModal
        title="Availability Update Failed"
        isError={true}
        openBool={postError}
        setOpenBool={setPostError}
        errorMessage={errResponse}
      />
    </Dialog>
  );
}
