import {
  ArrowDownward,
  LocationOnOutlined,
  WarningAmber,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Rating,
  Tooltip,
} from "@mui/material/";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import axios from "../../../api/axios";
import FilterRange from "../../../global/FilterRange";
import ProviderDetailv2 from "../../../global/ProviderDetailModal/ProviderDetailv2";

export default function InNetworkProviders({ isDashboardVersion }) {
  const { accessToken } = useContext(DataContext);
  const [chiroData, setChiroData] = useState([]);
  const [mriData, setMRIData] = useState([]);
  const [orthoData, setOrthoData] = useState([]);
  const [chiroResLimit, setChiroResLimit] = useState(25);
  const [chiroShowMore, setChiroShowMore] = useState(true);
  const [mriResLimit, setMriResLimit] = useState(25);
  const [mriShowMore, setMriShowMore] = useState(true);
  const [orthoResLimit, setOrthoResLimit] = useState(25);
  const [orthoShowMore, setOrthoShowMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [printableList, setPrintableList] = useState();
  const [activePrintType, setActivePrintType] = useState("All Providers");
  const [providerDetailDisplay, setProviderDetailDisplay] = useState(false);
  const [providerDetail, setProviderDetail] = useState("");
  const [sortChiroByRating, setSortChiroByRating] = useState();
  const [chiroKey, setChiroKey] = useState("rating");
  const [sortOrthoByRating, setSortOrthoByRating] = useState();
  const [orthoKey, setOrthoKey] = useState("rating");
  const [sortMriByRating, setSortMriByRating] = useState();
  const [mriKey, setMriKey] = useState("rating");

  const navigate = useNavigate();
  const componentRef = useRef();

  const handleProvider = (prov) => {
    setProviderDetail(prov);
    setProviderDetailDisplay(true);
  };

  const goToMap = () => {
    navigate(`/globalmap`);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `In Network Provider List`,
  // });

  // const printList = (type) => {
  //   setLoading(true);
  //   setActivePrintType(
  //     type === "full"
  //       ? "All Providers"
  //       : type === "chiro"
  //       ? "Chiropractors"
  //       : type === "mri"
  //       ? "MRI Providers"
  //       : "Orthopedics"
  //   );
  //   let printQuery =
  //     type === "full"
  //       ? "?limit=1000&in_network=true"
  //       : type === "chiro"
  //       ? "?limit=1000&in_network=true&type=Chiropractor"
  //       : type === "mri"
  //       ? "?limit=1000&in_network=true&type=MRI"
  //       : "?limit=1000&in_network=true&type=Orthopedic";

  //   axios
  //     .get(`/api/provider/${printQuery}&ordering=name`, {
  //       headers: { Authorization: `Token ${accessToken}` },
  //     })
  //     .then(function (response) {
  //       setPrintableList(response.data.results);
  //       setTimeout(() => {
  //         handlePrint();
  //         setLoading(false);
  //       }, "1000");
  //     })
  //     .catch(function (error) {});
  // };

  useEffect(() => {
    axios
      .get(
        `/api/provider/?limit=${chiroResLimit}&in_network=true&ordering=name&type=Chiropractor${
          sortChiroByRating ? "&" + chiroKey + "=" + sortChiroByRating : ""
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        let data = response.data.results;
        if (response.data.count <= chiroResLimit) {
          setChiroShowMore(false);
        } else {
          setChiroShowMore(true);
        }

        if (!sortChiroByRating) {
          setChiroData(data);
        } else {
          setChiroData(data.sort((a, b) => b.rating - a.rating));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [chiroResLimit, chiroKey, sortChiroByRating, accessToken]);

  useEffect(() => {
    axios
      .get(
        `/api/provider/?limit=${mriResLimit}&in_network=true&ordering=name&type=MRI${
          sortMriByRating ? "&" + mriKey + "=" + sortMriByRating : ""
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        let data = response.data.results;

        if (response.data.count <= mriResLimit) {
          setMriShowMore(false);
        } else {
          setMriShowMore(true);
        }
        setMRIData(response.data.results);

        if (!sortMriByRating) {
          setMRIData(data);
        } else {
          setMRIData(data.sort((a, b) => b.rating - a.rating));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [mriResLimit, mriKey, sortMriByRating, accessToken]);

  useEffect(() => {
    axios
      .get(
        `/api/provider/?limit=${orthoResLimit}&in_network=true&ordering=name&type=Orthopedic${
          sortOrthoByRating ? "&" + orthoKey + "=" + sortOrthoByRating : ""
        }`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        let data = response.data.results;
        if (response.data.count <= orthoResLimit) {
          setOrthoShowMore(false);
        } else {
          setOrthoShowMore(true);
        }

        if (!sortOrthoByRating) {
          setOrthoData(data);
        } else {
          setOrthoData(data.sort((a, b) => b.rating - a.rating));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [orthoResLimit, orthoKey, sortOrthoByRating, accessToken]);

  return (
    <div className="h-full overflow-y-auto">
      {!loading ? (
        ""
      ) : (
        <div className="absolute top-[50%] left-[50%] z-10">
          <div className="w-fit bg-white rounded-md p-4 shadow-md text-center">
            {" "}
            <span className="block mb-2">Generating Print</span>
            <CircularProgress color="secondary" />
          </div>
        </div>
      )}
      {isDashboardVersion ? (
        ""
      ) : (
        <div className="text-center text-xl text-purple-600 font-bold mt-10 flex justify-between">
          <h1 className="text-2xl text-center text-purple-600 uppercase font-bold w-full shadow-md px-4 rounded-lg py-2">
            In Network Providers
            <Button
              onClick={(e) => goToMap()}
              className="hover:bg-purple-600 hover:text-white text-purple-600 pb-4"
            >
              <LocationOnOutlined />
            </Button>
            {/* 
              <Button
                variant="outlined"
                color="success"
                onClick={() => printList("full")}
                className="mt-2 hover:bg-purple-600 hover:text-white text-purple-600 border-purple-600 float-right ml-6  relative"
              >
                {" "}
                Print
              </Button>
 */}
          </h1>
        </div>
      )}

      <div
        className={`grid md:grid-cols-3 mt-4 text-center w-full justify-center ${
          !isDashboardVersion ? "" : "max-h-[80vh] overflow-y-auto"
        }`}
      >
        <div>
          <div className="font-xl font-bold">
            <div className="flex justify-center items-center">
              Chiropractors{" "}
              {/* 
                <IconButton
                  variant="outlined"
                  color="success"
                  onClick={() => printList("chiro")}
                  className="mt-2 hover:bg-purple-600 hover:text-white text-purple-600 border-purple-600 ml-6  relative"
                >
                  {" "}
                  <Print />
                </IconButton>
 */}
            </div>
            <div className="mx-4 relative flex justify-center">
              <FilterRange
                title="Filter by Rating"
                value={sortChiroByRating}
                setValue={setSortChiroByRating}
                queryKey={chiroKey}
                setQueryKey={setChiroKey}
                query="rating"
              />
            </div>
          </div>
          <List
            className={`px-4  overflow-y-auto md:max-h-[80vh] max-h-[15vh] shadow-inner md:shadow-none`}
          >
            {chiroData.map((prov, index) => (
              <ListItem
                key={index}
                onClick={(e) => handleProvider(prov)}
                className="flex lg:flex-row flex-col shadow-lg p-1 my-1 bg-white rounded-lg border-gray-100 border-y hover:bg-purple-600 hover:text-white justify-between"
              >
                <div>
                  <span className="mx-4 content-start">{index + 1}.</span>
                  {prov.name}
                </div>
                {prov.rating === null ? (
                  <div className="ml-2 flex items-center w-1/4">
                    {" "}
                    Not Rated <WarningAmber style={{ color: "orange" }} />
                  </div>
                ) : (
                  <Rating
                    name={prov.pk}
                    value={prov.rating}
                    className="flex justify-center"
                    disabled
                  />
                )}
              </ListItem>
            ))}
            <ListItem>
              {!chiroShowMore ? (
                ""
              ) : (
                <Tooltip title="Show more results">
                  <IconButton
                    className="w-fit block mx-auto"
                    onClick={() => setChiroResLimit(+chiroResLimit + 25)}
                  >
                    <ArrowDownward />
                  </IconButton>
                </Tooltip>
              )}
            </ListItem>
          </List>
        </div>
        <div>
          <div className="font-xl font-bold">
            <div className="flex justify-center items-center">
              MRIs
              {/* 
                <IconButton
                  variant="outlined"
                  color="success"
                  onClick={() => printList("mri")}
                  className="mt-2 hover:bg-purple-600 hover:text-white text-purple-600 border-purple-600 ml-6  relative"
                >
                  {" "}
                  <Print />
                </IconButton> */}
            </div>
            <div className="mx-4 relative flex justify-center">
              <FilterRange
                title="Filter by Rating"
                value={sortMriByRating}
                setValue={setSortMriByRating}
                queryKey={mriKey}
                setQueryKey={setMriKey}
                query="rating"
              />
            </div>
          </div>
          <List className="px-4 md:max-h-[80vh] max-h-[15vh] shadow-inner md:shadow-none overflow-y-auto">
            {mriData.map((prov, index) => (
              <ListItem
                key={index}
                onClick={(e) => handleProvider(prov)}
                className="flex lg:flex-row flex-col shadow-lg p-1 my-1 bg-white rounded-lg border-gray-100 border-y hover:bg-purple-600 hover:text-white justify-between"
              >
                <div>
                  <span className="mx-4 content-start">{index + 1}.</span>
                  {prov.name}
                </div>
                {prov.rating === null ? (
                  <div className="ml-2 flex items-center w-1/4">
                    {" "}
                    Not Rated <WarningAmber style={{ color: "orange" }} />
                  </div>
                ) : (
                  <Rating
                    name={prov.pk}
                    value={prov.rating}
                    className="flex justify-center"
                    disabled
                  />
                )}
              </ListItem>
            ))}
            <ListItem>
              {!mriShowMore ? (
                ""
              ) : (
                <Tooltip title="Show more results">
                  <IconButton
                    className="w-fit block mx-auto"
                    onClick={() => setMriResLimit(+mriResLimit + 25)}
                  >
                    <ArrowDownward />
                  </IconButton>
                </Tooltip>
              )}
            </ListItem>
          </List>
        </div>
        <div>
          <div className="font-xl font-bold">
            <div className="flex justify-center items-center">
              Orthopedics{" "}
              {/* 
                <IconButton
                  variant="outlined"
                  color="success"
                  onClick={() => printList("ortho")}
                  className="mt-2 hover:bg-purple-600 hover:text-white text-purple-600 border-purple-600 ml-6  relative"
                >
                  {" "}
                  <Print />
                </IconButton>*/}
            </div>
            <div className="mx-4 relative flex justify-center">
              <FilterRange
                title="Filter by Rating"
                value={sortOrthoByRating}
                setValue={setSortOrthoByRating}
                queryKey={orthoKey}
                setQueryKey={setOrthoKey}
                query="rating"
              />
            </div>
          </div>
          <List className="px-4 md:max-h-[80vh] max-h-[15vh] shadow-inner md:shadow-none overflow-y-auto">
            {orthoData.map((prov, index) => (
              <ListItem
                key={index}
                onClick={(e) => handleProvider(prov)}
                className={`flex lg:flex-row flex-col shadow-lg p-1 my-1 bg-white rounded-lg border-gray-100 border-y hover:bg-purple-600 hover:text-white justify-between`}
              >
                <div>
                  <span className="mx-4 content-start">{index + 1}.</span>
                  {prov.name}
                </div>
                {prov.rating === null ? (
                  <div className={`ml-2 flex items-center `}>
                    {" "}
                    Not Rated <WarningAmber style={{ color: "orange" }} />
                  </div>
                ) : (
                  <Rating
                    name={`${prov.pk}`}
                    value={+prov.rating}
                    className="flex justify-center"
                    disabled
                  />
                )}
              </ListItem>
            ))}
            <ListItem>
              {!orthoShowMore ? (
                ""
              ) : (
                <Tooltip title="Show more results">
                  <IconButton
                    className="w-fit block mx-auto"
                    onClick={() => setOrthoResLimit(+orthoResLimit + 25)}
                  >
                    <ArrowDownward />
                  </IconButton>
                </Tooltip>
              )}
            </ListItem>
          </List>
        </div>
      </div>
      <div className="hidden" ref={componentRef}>
        {!printableList ? (
          ""
        ) : (
          <div>
            <h1 style={{ fontSize: "1.2rem", textAlign: "center" }}>
              {activePrintType}
            </h1>
            <p style={{ textAlign: "center" }}>
              Total: {printableList.length} as of{" "}
              {new Date().toLocaleDateString()}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                padding: "16px 24px",
                textAlign: "center",
                borderBottom: "1px solid #E3EBEC",
                margin: "0 1rem",
              }}
            >
              <div
                style={{
                  width: "25%",
                  fontWeight: "bold",
                }}
              >
                <h2>PROVIDER NAME</h2>
              </div>
              <div style={{ width: "25%", fontWeight: "bold" }}>
                <h2>PROVIDER EMAIL</h2>
              </div>
              <div style={{ width: "25%", fontWeight: "bold" }}>
                <h2>PROVIDER PHONE</h2>
              </div>
              <div style={{ width: "25%", fontWeight: "bold" }}>
                <h2>PROVIDER ADDRESS</h2>
              </div>
            </div>
            <div style={{ padding: "16px 24px" }}>
              {loading === "true"
                ? ""
                : printableList.map((prov, index) => (
                    <div key={index} style={{ margin: "0 1rem" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          borderBottom: "1px solid #E3EBEC",
                          padding: "5px 0",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            width: "25%",
                            color: "#000",
                          }}
                        >
                          {index + 1}.&nbsp;
                          {!prov ? "" : prov.name}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "25%",
                            color: "#000",
                            wordBreak: "break-word",
                          }}
                        >
                          {!prov ? "" : prov.email}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "25%",
                            color: "#000",
                          }}
                        >
                          {!prov ? "" : prov.phone}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "25%",
                            color: "#000",
                          }}
                        >
                          {!prov ? "" : prov.address.raw}
                        </span>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
      {!providerDetailDisplay ? (
        ""
      ) : (
        <ProviderDetailv2
          providerDetail={providerDetail}
          open={providerDetailDisplay}
          setOpen={setProviderDetailDisplay}
        />
      )}
    </div>
  );
}
