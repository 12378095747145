import { useState, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ResponseModal from "../../../../../global/ResponseModal";
import FormSubmitButton from "../../../../../global/FormComponents/FormSubmitButton";
import { Delete } from "@mui/icons-material";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";

export default function DeleteApptProcedure({
  selectedBill,
  setOpenDeleteBill,
}) {
  const { accessToken, setTrigger, trigger } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOpenDeleteBill(false);
    setTrigger(!trigger);
  };

  const handleApptProcedureDelete = () => {
    setLoading(true);
    axios
      .delete(`/api/administered/${selectedBill.pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setOpenSuccess(!openSuccess);
        setLoading(false);
        return response;
      })
      .catch(function (error) {
        const res = error.response.data;
        const errArr = ApiRequestErrorHandler(res);
        setErrorArray(errArr);
        setLoading(false);
        setOpenError(!openError);
      });
  };

  return (
    <div className="w-full px-20 flex-vertical justify-center bg-white">
      {!selectedBill ? (
        "Please Select an Procedure to Continue"
      ) : (
        <div>
          <div>
            <div className="my-2">
              <span
                className="underline text-lg"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  margin: "10px 0",
                  display: "block",
                }}
              >
                Appointment Info
              </span>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Provider Name:</span>{" "}
                <span>{selectedBill.administered_by.name}</span>
              </div>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Provider Phone:</span>{" "}
                <span>{selectedBill.administered_by.phone}</span>
              </div>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Date/Time:</span>{" "}
                <span>
                  {new Date(
                    selectedBill.appointment.scheduled
                  ).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="my-2">
              <span
                className="underline text-lg"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  margin: "10px 0",
                  display: "block",
                }}
              >
                Client Info
              </span>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Name: </span>
                <span>{selectedBill.administered_to.name}</span>
              </div>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Email:</span>
                <span>{selectedBill.administered_to.email}</span>
              </div>
              <div className="text-sm">
                <span style={{ fontWeight: "bold" }}>Phone:</span>
                <span>{selectedBill.administered_to.phone}</span>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <span
              className="underline text-lg font-bold"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                margin: "10px 0",
                display: "block",
              }}
            >
              Bill Details
            </span>
            <div className="text-sm">
              {" "}
              <span style={{ fontWeight: "bold" }}>Bill Code:</span>{" "}
              <span>
                {!selectedBill.service
                  ? "No Service"
                  : selectedBill.service.service_code}
              </span>
            </div>
            <div className="text-sm">
              {" "}
              <span style={{ fontWeight: "bold" }}>Bill Name:</span>{" "}
              <span>
                {!selectedBill.service
                  ? "No Service"
                  : selectedBill.service.service_name}
              </span>{" "}
            </div>
            <div className="text-sm">
              {" "}
              <span style={{ fontWeight: "bold" }}>Bill Price:</span>{" "}
              <span>${selectedBill.price}</span>{" "}
            </div>
            <div className="text-sm">
              {" "}
              <span style={{ fontWeight: "bold" }}>
                Bill Administered By:
              </span>{" "}
              <span>{selectedBill.signature}</span>{" "}
            </div>
          </div>
        </div>
      )}
      <p className="text-sm my-4">
        <span className="font-bold text-red-800 underline">Warning:</span>{" "}
        Clicking the "Permanently Delete Bill" button below will permanently
        remove the billed data above from the database, after proceeding it{" "}
        <span className="underline">cannot</span> be recovered. Please make sure
        you want to delete the data before proceeding.
      </p>
      <FormSubmitButton
        isLoading={loading}
        handleSubmit={handleApptProcedureDelete}
        buttonText="Permanently Delete Bill"
        buttonColor="red"
        ButtonIcon={<Delete />}
      />
      <ResponseModal
        title="Bill Data"
        description="Successfully deleted the bill from client's data."
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Deleting Billed Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </div>
  );
}
