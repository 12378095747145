function processString(err, type, parentL, errorString) {
  let plist = parentL.length > 0 ? parentL.join(": ") + ": " : "";

  if (err === " " || err === "") {
    if (type === "[object String]" ) {
      return errorString;
    }
    return plist + err + errorString;
  }
  return plist + err;
}

function processError(errorArray, errorList, parentList) {
  let typeCheck = Object.prototype.toString;
  let errorString = "Unspecified Error. Please try again.";

  if (errorList === null || errorList === undefined) {
    errorArray.push(errorString);
  }
  if (typeCheck.call(errorList) === "[object String]") {
    if (errorList === "" || errorList === " ") {
      errorArray.push(errorString);
    } else {
      errorArray.push(processString(errorList, "[object String]", parentList, errorString));
    }
  }

  if (typeCheck.call(errorList) === "[object Array]") {
    if (errorList.length === 0) {
      errorArray.push(errorString);
    } else {
      for (const err of errorList) {
        if (typeof err === "object") {
          processError(errorArray, err, parentList);
        } else {
          errorArray.push(processString(err, "[object Array]", parentList, errorString));
         
        }
      } parentList.pop();
    }
  }
  if (typeCheck.call(errorList) === "[object Object]") {
    if (Object.keys(errorList).length === 0) {
      errorArray.push(errorString);
    } else {
      for (const err in errorList) {
        parentList.push(err);
        if (typeof errorList[err] === "object") {
          processError(errorArray, errorList[err], parentList);
        } else {
          errorArray.push(
            processString(errorList[err], "[object Object]", parentList, errorString)
          );
          parentList.pop();
        }
        
      }
    }
  }
}

export default function ApiRequestErrorHandler(errorList) {
  const errorArray = [];
  const parentList = [];

  processError(errorArray, errorList, parentList);
  return errorArray;
}
