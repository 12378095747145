import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, subDays } from "date-fns";
import DateRange from "../../../DateRange";
import { CustomChip } from "../../../CustomQueryChip";
import { Chip } from "@mui/material";
import MultiSelectSearchV2 from "../../../MultiSelectSearchV2";

export default function CommLogFilters({
  setToDate,
  setFromDate,
  resetPagination,
  activeFilters,
  setActiveFiltersUsers,
  activeFiltersUsers,
  loggedInUser,
  selectedUsers,
  setSelectedUsers,
  resultCount,
  setSearchField,
}) {
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(subDays(new Date(), 1));

  const setDateFrom = (value) => {
    setStartDate(value);
    setFromDate(value ? format(new Date(value), "yyyy-MM-dd") : null);
  };

  const setDateTo = (value) => {
    setEndDate(value);
    setToDate(value ? format(new Date(value), "yyyy-MM-dd") : null);
  };

  const displayFilters = (object) => {
    let returnArr = [];
    for (let [key, value] of Object.entries(object)) {
      if (key === "start_time__gte") {
        returnArr.push(`Date After: ${value}`);
      } else if (key === "start_time__lte") {
        returnArr.push(`Date Before: ${value}`);
      } else {
        returnArr.push(`${key}: ${value}`);
      }
    }
    return returnArr;
  };

  const clearChipFilter = (filt) => {
    const key = filt.split(":")[0];
    console.log(key);
    if (key === "Date After") {
      setDateFrom(null);
    }
    if (key === "Date Before") {
      setDateTo(null);
    }
    if (key === "search") {
      setSearchField("");
    }
  };

  useEffect(() => {
    if (selectedUsers.length === 0) {
      setSelectedUsers([loggedInUser.pk]);
      setActiveFiltersUsers([loggedInUser.name]);
    } else if (
      selectedUsers.length > 1 &&
      selectedUsers.includes(loggedInUser.pk)
    ) {
      setSelectedUsers(
        selectedUsers.filter((item) => item !== loggedInUser.pk)
      );
      setActiveFiltersUsers(
        activeFiltersUsers.filter((item) => item !== loggedInUser.name)
      );
    }
  }, [
    setSelectedUsers,
    loggedInUser,
    setActiveFiltersUsers,
    activeFiltersUsers,
    selectedUsers,
  ]);

  return (
    <div
      id="CommLog-Filter-Container"
      data-testid="CommLog-Filter-Container"
      className="flex items-top relative"
    >
      <div className="px-4 py-3 rounded-md shadow-md bg-white absolute z-40  top-[10px] right-[31%] w-[25%]">
        <div>
          <MultiSelectSearchV2
            title="Filter by Managed User"
            searchTitle="User Search"
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
            setActiveItems={setActiveFiltersUsers}
            activeItems={activeFiltersUsers}
            resetPagination={resetPagination}
            nameKey={"name"}
            valueKey={"pk"}
            apiUrl={`/api/users/managed_users/?`}
          />
        </div>
      </div>
      <div className="rounded-md bg-white absolute z-40  top-[10px] right-[5%] w-[25%]">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRange
            val={"Filter By Date"}
            dateFrom={startDate}
            dateTo={endDate}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
            resetPagination={resetPagination}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full relative top-[70px] left-[10px]">
        <CustomChip
          setActiveItems={setActiveFiltersUsers}
          activeItems={activeFiltersUsers}
          selectedItems={selectedUsers}
          setSelectedItems={setSelectedUsers}
          title="Users"
          resetPagination={resetPagination}
        />
        {activeFilters
          ? displayFilters(activeFilters).map((filt, idx) => {
              return (
                <Chip
                  className={`px-2 m-1 text-center rounded-lg shadow-inner`}
                  key={filt + idx}
                  onDelete={() => clearChipFilter(filt)}
                  label={filt}
                />
              );
            })
          : ""}
        <div className="w-full text-right pr-16">
          Showing: {resultCount.current} / {resultCount.total}
        </div>
      </div>
    </div>
  );
}
