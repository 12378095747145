import React, {useContext} from 'react';
import { Button } from "@mui/material";
import { BankCheckDispatchContext } from "../../context/BankCheckDispatchContext";
import { CONTENT_ADD_CHECK, clearBankCheckFormFormAddCheck, setCheckList, showContent, toggleContentIsLoading } from '../../state/actions';

export default function AddCheckOption({}){
  const dispatch = useContext(BankCheckDispatchContext);

  const handleAddCheck = (e) => {
    e.preventDefault();
    toggleContentIsLoading(dispatch, true);
    clearBankCheckFormFormAddCheck(dispatch, true);
    setCheckList(dispatch, null);
    showContent(dispatch, CONTENT_ADD_CHECK);
    toggleContentIsLoading(dispatch, false);
  }; 

  return( <div className="border-gray-300 sm:border mt-8 p-3">
  <p>Need to Add a Check to the List?
  <Button
    data-testid="button-create-new-check"
    onClick={(e) => handleAddCheck(e)}
    className="bg-white text-green-600 border-solid  hover:bg-green-600 hover:text-white rounded-md px-3 py-2 m-2"
  >
    Create a New Check
  </Button></p>
  </div>)
}