import React, { useState, useContext } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ResponseModal from "../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import FormSubmitButton from "../../../../../global/FormComponents/FormSubmitButton";

export default function DeleteProviderv2({
  providerDetail,
  setDeleteFormDisplay,
  trigger,
  setTrigger,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [loading, setLoading] = useState("");

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setDeleteFormDisplay(false);
    setTrigger(!trigger);
  };
  function handleProviderDelete() {
    setLoading(true);
    axios
      .delete(`/api/pro_cli_status/${providerDetail.pk}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(true);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 500) {
          setErrorMessage(["Unable to remove provider from this users case"]);
        } else if (error) {
          let res = error.response.data;
          let errArr = ApiRequestErrorHandler(res);
          setErrorMessage(errArr);
        }
        setLoading(false);
        setOpenError(!openError);
      });
  }

  return (
    <div className="bg-white">
      {!providerDetail ? (
        "Please Select an Client to Continue"
      ) : (
        <div>
          <span className="text-[20px] font-bold">
            Provider Info
            <br />
            <br />
          </span>
          <div>
            <div>
              <div>
                <span className="font-bold">Provider Name:</span>{" "}
                <span>{providerDetail.provider.name}</span>
              </div>
              <div>
                <span className="font-bold">Provider Phone:</span>{" "}
                <span>{providerDetail.provider.phone}</span>
              </div>
            </div>

            <div>
              <div>
                <span className="font-bold">In Network:</span>{" "}
                <span>
                  {providerDetail.provider.in_network === true
                    ? "True"
                    : "False"}
                </span>
              </div>
              <div>
                <span className="font-bold">Provider Type:</span>{" "}
                <span>{providerDetail.provider.type}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <br />
      <h2 className="font-bold text-lg">
        <span className="text-xl text-red-600 underline font-bold">
          Warning:
        </span>{" "}
        Clicking the "Permanently Remove Provider" button below will permanently
        remove the provider data for this client (including all appointments and
        corresponding procedures) from the database, after proceeding it{" "}
        <span className="text-lg underline">cannot</span> be recovered. Please
        make sure you want to delete the data before proceeding.
      </h2>
      <br />
      <br />
      <div style={{ margin: "0 0 2rem 0" }}>
        <FormSubmitButton
          isLoading={loading}
          handleSubmit={handleProviderDelete}
          buttonText="Permanently Remove Provider"
          buttonColor="red"
        />
      </div>
      <ResponseModal
        title="Provider Removed"
        description="Successfully Removed Provider from Client's Data"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Deleting Provider from Client's Data"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
}
