import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";

export function Panel({ children }) {
  
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }
    return child;
  });

  return (
    <div  data-testid="profile-manager-access">
        {childrenWithProps}
    </div>
  );
}
