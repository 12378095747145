import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import { IconButton, List, Tooltip } from "@mui/material";
import { ControlPoint } from "@mui/icons-material";
import { createDisplayObj } from "../../../../../global/helpers";
import AddPayment from "./Modals/AddPayment";
import ModalShell from "../../../../../global/ModalShell";
import PaymentDetailRow from "./PaymentDetailRow";
import ShowMoreButton from "../../../../../global/ShowMoreButton";
import LoadingIndicator from "../../../../../global/LoadingIndicator";

export default function Payments({ caseDetail, trigger, setTrigger }) {
  const { getCasePk, accessToken, userRoles } = useContext(DataContext);
  const [paymentList, setPaymentList] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  const [paymentTypeDispObj, setPaymentTypeDispObj] = useState("");
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [resLimit, setResLimit] = useState(5);
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [loading, setLoading] = useState("");
  const params = useParams();

  useEffect(() => {
    if (!caseDetail && params) {
      getCasePk(params.id);
    }

    if (!caseDetail || !params.id) {
      return;
    } else {
      setLoading(true);
      axios
        .get(
          `/api/settlement_checks/?limit=${resLimit}&case=${
            !caseDetail ? params.id : caseDetail.pk
          }`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          const data = response.data.results;
          if (response.data.count <= resLimit) {
            setShowMoreVisible(false);
          } else {
            setShowMoreVisible(true);
          }
          setPaymentList(data);
          setLoading(false);
        })
        .catch((response) => {
          console.log(response);
          setLoading(false);
        });
    }
  }, [accessToken, caseDetail, getCasePk, params, trigger, resLimit]);

  useEffect(() => {
    axios
      .get(`/api/field_options/?field_name=check_type&ordering=label`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        const data = response.data.results;
        setPaymentTypeDispObj(createDisplayObj(data, "check_type"));
        setPaymentTypeOptions(data);
      })
      .catch((response) => {
        console.log(response);
      });
  }, [setPaymentTypeDispObj, setPaymentTypeOptions, accessToken]);

  return (
    <div
      className="border border-2 border-black mb-2 relative"
      style={{ width: "100%", border: "2px solid black" }}
    >
      <div
        className="flex justify-between"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          className="text-[1.2rem] font-bold p-2"
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "5px 10px",
            margin: "0",
          }}
        >
          Payments
        </h2>
        {userRoles.permissions.includes("casemanager.add_settlementcheck") ? (
          <div>
            <IconButton
              onClick={() => setAddOpen(true)}
              className="text-green-600"
              style={{ color: "#16A34A" }}
            >
              <Tooltip title="Add Payment">
                <ControlPoint />
              </Tooltip>
            </IconButton>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 0 0 1rem",
          borderBottom: "2px solid black",
          fontSize: "14px",
        }}
      >
        <LoadingIndicator isLoading={loading} />
        <div style={{ width: "30%", fontWeight: "bold" }}>Type</div>
        <div style={{ width: "30%", fontWeight: "bold" }}>From</div>
        <div style={{ width: "30%", fontWeight: "bold" }}>To</div>
        <div style={{ width: "10%", fontWeight: "bold" }}></div>
      </div>
      <List className="max-h-[15vh] overflow-auto px-3 pb-4 relative">
        {paymentList.length === 0
          ? "No Payments Found"
          : paymentList.map((payment) => (
              <PaymentDetailRow
                key={payment.id}
                paymentTypeDispObj={paymentTypeDispObj}
                payment={payment}
                trigger={trigger}
                setTrigger={setTrigger}
                paymentTypeOptions={paymentTypeOptions}
                caseDetail={caseDetail}
              />
            ))}
        {loading ? (
          ""
        ) : (
          <ShowMoreButton
            showMoreVisible={showMoreVisible}
            setResLimit={setResLimit}
            resLimit={resLimit}
            showMoreAmt={5}
          />
        )}
      </List>
      <ModalShell
        open={addOpen}
        setOpen={setAddOpen}
        title={"Add Payment"}
        color={"#1976d2"}
      >
        {" "}
        <AddPayment
          casePk={params.id}
          addOpen={addOpen}
          setAddOpen={setAddOpen}
          trigger={trigger}
          setTrigger={setTrigger}
          paymentTypeOptions={paymentTypeOptions}
          caseDetail={caseDetail}
        />
      </ModalShell>
    </div>
  );
}
