import ApiServices from "../service";

export const settlement_checks = {
  getSettlementChecksList: (accessToken) =>
    ApiServices.getData("/api/settlement_checks/", accessToken)
      .then((res) => res)
      .catch((err) => console.log(err)),

  createSettlementCheck: (data, accessToken) =>
    ApiServices.postData("/api/settlement_checks/", data, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  retrieveSettlementChecksByProvider: (id, caseId, accessToken) =>
    ApiServices.getData(
      `/api/settlement_checks/?provider=${id}&case=${caseId}&check_type=2`,
      accessToken
    )
      .then((res) => res.results)
      .catch((err) => console.log(err)),

  retrieveSettlementChecksByCase: (id, accessToken) =>
    ApiServices.getData(`/api/settlement_checks/?case=${id}`, accessToken)
      .then((res) => res)
      .catch((err) => console.log(err)),

  updateSettlementCheck: (data, id, accessToken) =>
    ApiServices.putData(`/api/settlement_checks/${id}`, data, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
};
