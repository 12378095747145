import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDebouncedEffect } from "../hooks/useDebounceEffect";
import axios from "../api/axios";
import DataContext from "../../context/DataContext";
import { Cancel, Check, Close } from "@mui/icons-material";
import ApiRequestErrorHandler from "./ApiRequestErrorHandler";

export default function SmartyAutoComplete({
  address,
  setAddress,
  inputRequired,
  defaultAddress,
}) {
  const { accessToken } = useContext(DataContext);
  const [searchVal, setSearchVal] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validationResponse, setValidationResponse] = useState(
    !address ? false : address.validated ? true : false
  );
  const [isError, setIsError] = useState(false);
  const [validAddressChange, setValidAddressChange] = useState(false);
  const [smartyError, setSmartyError] = useState(false);

  useEffect(() => {
    if (defaultAddress) {
      setAddress(defaultAddress);
      setValidationResponse(false);
    }
  }, [defaultAddress]);

  const clearAddressSelection = () => {
    setAddress(null);
    setSearchVal("");
    setSuggestions([]);
    setIsError(false);
  };

  const manualAddressChange = (value, key, address) => {
    setValidAddressChange(true);
    address[key] = value;
    setAddress({ ...address });
  };

  const handlePlaceSelect = (value) => {
    setLoading(true);
    setSearchVal("");
    setSuggestions([]);

    const rawAddress = value.secondary
      ? value.street_line +
        " " +
        value.secondary +
        ", " +
        value.city +
        " " +
        value.state +
        ", " +
        value.zipcode
      : value.street_line +
        ", " +
        value.city +
        " " +
        value.state +
        ", " +
        value.zipcode;
    const routeString = value.secondary
      ? value.street_line + " " + value.secondary
      : value.street_line;

    const postData = {
      raw: rawAddress,
      route: routeString,
      city: value.city,
      state: value.state,
      post_code: value.zipcode,
    };

    axios
      .post(`/api/address/`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setValidationResponse(true);
        setIsError(false);
        setAddress(response.data);
        setLoading(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setValidationResponse(true);
        setLoading(false);
        console.log(errArr);
      });
  };

  const getSuggestions = (query) => {
    if (!searchVal || !process.env.REACT_APP_SMARTY_API_KEY) {
      setLoading(false);
      setSuggestions([]);
      return;
    } else if (query.length >= 5) {
      setAddress("");
      axios
        .get(
          `https://us-autocomplete-pro.api.smarty.com/lookup?key=${process.env.REACT_APP_SMARTY_API_KEY}&search=${query}&max_results=5`,
          {
            headers: { Referer: process.env.REACT_APP_SMARTY_REFERER },
          }
        )
        .then((response) => {
          const data = response.data.suggestions;
          setSuggestions(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSmartyError(true);
          setLoading(false);
        });
    } else {
      setSuggestions([]);
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      setSmartyError(false);
      getSuggestions(searchVal);
    },
    [searchVal],
    750
  );

  const formatDisplayedAddress = (address) => {
    let addressFormatted = address.street_line;
    if (address.secondary) {
      addressFormatted =
        address.street_line +
        " " +
        address.secondary +
        ", " +
        address.city +
        " " +
        address.state +
        ", " +
        address.zipcode;
    } else {
      addressFormatted =
        address.street_line +
        ", " +
        address.city +
        " " +
        address.state +
        ", " +
        address.zipcode;
    }
    return addressFormatted;
  };

  const updateExistingAddress = () => {
    // setLoading(true);
    const secondaryType = !address.suite_type ? "" : address.suite_type;
    const secondaryNumber = !address.suite_number
      ? ""
      : secondaryType
      ? " " + address.suite_number
      : address.suite_number;

    const rawAddress =
      secondaryType || secondaryNumber
        ? address.street_number +
          " " +
          address.route +
          " " +
          secondaryType +
          secondaryNumber +
          ", " +
          address.city +
          " " +
          address.state +
          " " +
          address.post_code
        : address.street_number +
          " " +
          address.route +
          ", " +
          address.city +
          " " +
          address.state +
          " " +
          address.post_code;

    address.raw = rawAddress;
    address.formatted = rawAddress;
    setAddress({ ...address });
    axios
      .patch(`/api/address/${address.id}/`, address, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setValidationResponse(true);
        setIsError(false);
        setAddress(response.data);
        setLoading(false);
        setValidAddressChange(false);
      })
      .catch((error) => {
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setValidationResponse(true);
        setLoading(false);
        console.log(errArr);
      });
  };

  const handleSearch = (val) => {
    setLoading(true);
    setSearchVal(val);
    if (address) {
      setAddress("");
    }

    setTimeout(() => {
      setLoading(false);
    }, 750);
  };

  return (
    <div className="w-full relative">
      <div className="w-full my-4">
        <div className="flex items-center">
          <TextField
            required={inputRequired ? true : false}
            className="w-full"
            label="Address"
            value={address ? address.raw : searchVal}
            onChange={(e) => handleSearch(e.target.value)}
            error={
              isError || !process.env.REACT_APP_SMARTY_API_KEY || smartyError
            }
            disabled={!process.env.REACT_APP_SMARTY_API_KEY ? true : false}
            helperText={
              !process.env.REACT_APP_SMARTY_API_KEY
                ? "Unauthorized, please contact admin for support"
                : smartyError
                ? "Something went wrong. If problem persists contact NetProApp admin"
                : isError
                ? "Address invalid, please choose a valid address or leave the field blank"
                : searchVal.length < 5 && searchVal
                ? "Search must be at least 5 characters"
                : ""
            }
            InputProps={{
              endAdornment: loading ? (
                <CircularProgress color="secondary" />
              ) : address && validationResponse ? (
                <Tooltip title="Address Added">
                  <InputAdornment position="end">
                    <Check className="text-green-600" />
                  </InputAdornment>
                </Tooltip>
              ) : validationResponse && isError ? (
                <InputAdornment position="end">
                  <Cancel className="text-red-600" />
                </InputAdornment>
              ) : (
                ""
              ),
            }}
          />

          {!address ? (
            ""
          ) : (
            <Tooltip title="Clear selection" placement="top">
              <IconButton onClick={() => clearAddressSelection()}>
                <Close />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {!searchVal || smartyError ? (
          ""
        ) : (
          <List className="p-0 absolute z-10 bg-white w-full shadow-lg rounded-b-md max-h-[50vh] overflow-y-auto">
            {suggestions.length === 0 && searchVal.length >= 5 && !loading ? (
              <ListItem className="justify-center flex-col">
                No results found
                <button
                  onClick={() => setSearchVal("")}
                  className="underline text-sky-600"
                >
                  Clear Search
                </button>
              </ListItem>
            ) : (
              suggestions.map((suggestion, index) => {
                return (
                  <ListItemButton
                    key={index}
                    onClick={() => {
                      handlePlaceSelect(suggestion);
                    }}
                  >
                    {formatDisplayedAddress(suggestion)}
                  </ListItemButton>
                );
              })
            )}
          </List>
        )}
        {!address || (defaultAddress && !validationResponse) ? (
          ""
        ) : (
          <div className="w-full my-4">
            <div className="w-full flex">
              <TextField
                required={inputRequired ? true : false}
                className="w-full mr-4"
                label="Suite Type - Apartment, suite, etc"
                value={!address.suite_type ? "" : address.suite_type}
                onChange={(e) =>
                  manualAddressChange(e.target.value, "suite_type", address)
                }
              />
              <TextField
                required={inputRequired ? true : false}
                className="w-full"
                label="Suite Number - ex: 123"
                value={!address.suite_number ? "" : address.suite_number}
                onChange={(e) =>
                  manualAddressChange(e.target.value, "suite_number", address)
                }
              />
            </div>
            <Tooltip
              title={"To update City, State or Zip Code, search new address"}
            >
              <div className="w-full flex my-4">
                <TextField
                  required={inputRequired ? true : false}
                  className="w-full mr-4"
                  label="City"
                  disabled={true}
                  value={!address.city ? "" : address.city}
                  onChange={(e) =>
                    manualAddressChange(e.target.value, "city", address)
                  }
                />
                <TextField
                  required={inputRequired ? true : false}
                  className="w-full mr-2"
                  label="State"
                  value={!address.state ? "" : address.state}
                  disabled={true}
                  onChange={(e) =>
                    manualAddressChange(e.target.value, "state", address)
                  }
                />
                <TextField
                  required={inputRequired ? true : false}
                  className="w-full"
                  label="Zip Code"
                  value={!address.post_code ? "" : address.post_code}
                  disabled={true}
                  onChange={(e) =>
                    manualAddressChange(e.target.value, "post_code", address)
                  }
                />
              </div>
            </Tooltip>
            {validAddressChange ? (
              <div className="w-fit mx-auto">
                <Button
                  size="small"
                  onClick={() => updateExistingAddress()}
                  variant="contained"
                  className="bg-orange-600 hover:bg-orange-700"
                >
                  Update Validated Address
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}
