import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function PrintableClientList({
  componentRef,
  responseData,
  loading,
  title,
  activeFilters,
  sourcesObj,
  activeLawFirm,
  activeLost,
  notLost,
}) {
  return (
    <div ref={componentRef} className="hidden">
      <h1
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <span style={{ display: "block" }}>
          {activeFilters ? "Clients filtered by:" : ""}&nbsp;
        </span>
        {title}
        {!title ? "" : ","}
        <span style={{ display: "block" }}>
          {!activeLawFirm
            ? ""
            : activeLawFirm.length !== 0
            ? "Law Firms: " + activeLawFirm.join(", ")
            : ""}{" "}
        </span>
        <span style={{ display: "block" }}>
          {" "}
          {!activeLost
            ? ""
            : activeLost.length !== 0
            ? "Lost Codes: " + activeLost.join(", ")
            : ""}
        </span>
      </h1>
      <p style={{ textAlign: "center" }}>
        Total:{" "}
        {!responseData ? "" : !responseData.length ? "" : responseData.length}{" "}
        as of {new Date().toLocaleDateString()}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          padding: "16px 24px",
          textAlign: "center",
          borderBottom: "1px solid #E3EBEC",
          margin: "0 1rem",
        }}
      >
        <div
          style={{
            width: "15%",
            fontWeight: "bold",
          }}
        >
          <h2>NAME</h2>
        </div>
        <div style={{ width: "15%", fontWeight: "bold" }}>
          <h2>DOA</h2>
        </div>
        <div style={{ width: "15%", fontWeight: "bold" }}>
          <h2>CREATED</h2>
        </div>
        <div style={{ width: "15%", fontWeight: "bold" }}>
          <h2>CAMPAIGN</h2>
        </div>

        <div style={{ width: "15%", fontWeight: "bold" }}>
          <h2>STATUS</h2>
        </div>
        {notLost === "true" ? (
          ""
        ) : (
          <div style={{ width: "15%", fontWeight: "bold" }}>
            <h2>LOST</h2>
          </div>
        )}
        <div style={{ width: "15%", fontWeight: "bold" }}>
          <h2>LAW FIRM</h2>
        </div>
      </div>
      <div style={{ padding: "16px 24px" }}>
        {loading === "true" ? (
          ""
        ) : (
          <>
            {!responseData
              ? ""
              : responseData
                  // .sort((a, b) =>
                  //   a.name.toUpperCase().localeCompare(b.name.toUpperCase())
                  // )
                  .map((client, index) => (
                    <div
                      key={index}
                      value={client}
                      style={{ margin: "0 1rem" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          borderBottom: "1px solid #E3EBEC",
                          padding: "5px 0",
                          fontSize: "14px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            width: "15%",
                            color: "#000",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "10px",
                              alignSelf: "center",
                              color: !client
                                ? ""
                                : client.status === "Pending" ||
                                  client.status === "Client Unresponsive"
                                ? "#FFA500"
                                : client.lost
                                ? "rgb(239 68 68)"
                                : "#22C55E",
                              marginRight: "10px",
                            }}
                          >
                            <FiberManualRecordIcon
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <div>
                            {" "}
                            {index + 1}.&nbsp;
                            {!client ? "" : client.client.name}
                          </div>
                        </div>
                        <span
                          style={{
                            textAlign: "center",
                            width: "15%",
                            color: "#000",
                          }}
                        >
                          {!client.date_of_accident
                            ? ""
                            : new Date(
                                client.date_of_accident
                              ).toLocaleDateString("en-US", {
                                timeZone: "UTC",
                              })}{" "}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "15%",
                            color: "#000",
                          }}
                        >
                          {!client.created_at
                            ? ""
                            : new Date(client.created_at).toLocaleDateString(
                                "en-US",
                                { timeZone: "UTC" }
                              )}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "15%",
                            color: "#000",
                          }}
                        >
                          {!client
                            ? ""
                            : !sourcesObj
                            ? ""
                            : !client.campaign
                            ? "No Campaign"
                            : sourcesObj[client.campaign]}
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                            width: "15%",
                            color: "#000",
                          }}
                        >
                          {!client ? "" : client.status}
                        </span>
                        {notLost === "true" ? (
                          ""
                        ) : (
                          <span
                            style={{
                              textAlign: "center",
                              width: "15%",
                              color: "#000",
                            }}
                          >
                            {!client.lost ? "-" : client.lost.reason}
                          </span>
                        )}
                        <span
                          style={{
                            textAlign: "center",
                            width: "15%",
                            color: "#000",
                          }}
                        >
                          {!client ? "" : client.law_firm_name}
                        </span>
                      </div>
                      {/* </Tooltip> */}
                    </div>
                  ))}
          </>
        )}
      </div>
    </div>
  );
}
