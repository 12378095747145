import React from "react";
import { Alert, AlertTitle, List, ListItem, Button } from "@mui/material";
export default function ErrorMessage({ title, errorMessage, handleCloseFunc }) {
  return (
    <div data-testid="Bank-Check-Error">
      <div variant="outlined" severity="error" role="alert">
        <Alert variant="outlined" severity={"error"} role={"error"}>
          <AlertTitle>{title}</AlertTitle>
          <div className="">
            <List>
              {errorMessage.length === 0
                ? "Please try again"
                : errorMessage.map((issue, index) => (
                    <ListItem key={index} value={issue}>
                      {issue}
                    </ListItem>
                  ))}
            </List>
            <br />
            <h3>
              Fix errors and then resubmit. If errors persist, contact your ITN
              Representative.
            </h3>
          </div>
        </Alert>
        <Button onClick={(e) => handleCloseFunc(e)}>OK</Button>
      </div>
    </div>
  );
}
