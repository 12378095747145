import React from "react";
import { ListItem, Tooltip } from "@mui/material";
import { PhoneForwardedOutlined, CallOutlined } from "@mui/icons-material";
import { HelpOutline } from "@mui/icons-material";
import Colors from "../../../../../../../styles/Colors";
import format from "date-fns/format";

export default function CallLogItem({ log, idx, style }) {
  const callDirectionIcon = (dir) => {
    switch (dir) {
      case "Inbound":
        return (
          <Tooltip title="Inbound">
            <CallOutlined sx={{ fontSize: "2rem", color: Colors.blue[500] }} />
          </Tooltip>
        );
      case "Outbound":
        return (
          <Tooltip title="Outbound">
            <PhoneForwardedOutlined
              sx={{ fontSize: "2rem", color: Colors.green[500] }}
            />
          </Tooltip>
        );
      case "Unknown":
        return (
          <Tooltip title="Unknown">
            <HelpOutline sx={{ fontSize: "2rem", color: Colors.gray[400] }} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Unknown">
            <HelpOutline sx={{ fontSize: "2rem", color: Colors.gray[400] }} />
          </Tooltip>
        );
    }
  };

  const formatted = (time) => {
    var n = new Date(0, 0);
    n.setSeconds(time * 60);
    return n
      .toLocaleTimeString("en-Gb", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace("AM", "")
      .replace("PM", "");
  };

  const getFormattedTime = (time) => {
    if (time === undefined || time === null || time === " " || time === "") {
      return " ";
    }
    return format(new Date(time), "p");
  };
  const getFormattedDate = (date) => {
    if (date === undefined || date === null || date === " " || date === "") {
      return " ";
    }
    return format(new Date(date), "P");
  };
  const formattedPhoneNumber = (phoneNumberString) => {
    if (phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
    }
    return " ";
  };

  return (
    <>
      {!log || Object.keys(log).length === 0 ? (
        <div data-testid="no-list-item"></div>
      ) : (
        <ListItem className={style.outer} data-testid="list-item">
          <div className={style.inner}>
            <div className={style.column1} data-testid="direction">
              {callDirectionIcon(log.voice_system_direction)}
            </div>
            <div className={style.column2} data-testid="caller_userName">
              {!log.caller
                ? "Unknown"
                : log.caller.users.length > 0
                ? log.caller.users[0].user_name
                : formattedPhoneNumber(log.caller.number)}
            </div>
            <div className={style.column3} data-testid="receiver_userName">
              {!log.receiver
                ? "Unknown"
                : log.receiver.users.length > 0
                ? log.receiver.users[0].user_name
                : formattedPhoneNumber(log.receiver.number)}
            </div>
            <div className={style.column4} data-testid="date">
              <div className="date text-xs leading-6">
                {log.start_time ? getFormattedDate(log.start_time) : " "}
              </div>
              <div className="date text-sm leading-6" data-testid="time">
                {log.start_time ? getFormattedTime(log.start_time) : " "}
              </div>
            </div>
            <div className={style.column5} data-testid="duration">
              {log.duration ? formatted(log.duration) : " "}
            </div>
            <div className={style.column6} data-testid="outcome">
              {log.outcome ?? " "}
            </div>
          </div>
        </ListItem>
      )}
    </>
  );
}
